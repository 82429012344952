import { Skeleton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { DocumentSearch } from "./components/DocumentSearch";
import { Header } from "./components/Header";
import { useDocumentSearch } from "./hooks/useDocumentSearch";

interface DocumentSearchModalProps {
  loading?: boolean;
  title?: string;
  documentText?: string;
  onClose: () => void;
}

export const DocumentSearchModal = ({ title = "", documentText = "", loading, onClose }: DocumentSearchModalProps) => {
  const {
    currentOccurrence,
    getHighlightedText,
    goToNextOccurrence,
    goToPrevOccurrence,
    occurrences,
    searchInputText,
    searchTerm,
    setSearchInputText,
    textContainerRef,
  } = useDocumentSearch({ documentText });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateRows: "repeat(2, min-content) minmax(0, 1fr)",
        bgcolor: "common.white",
        flexDirection: "column",
      }}
    >
      <Header
        onClose={onClose}
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="body">
          <strong>Título:</strong>{" "}
          {loading ? (
            <Skeleton
              variant="text"
              width="300px"
              sx={{
                display: "inline-block",
              }}
            />
          ) : (
            title
          )}
        </Typography>
      </Header>

      <DocumentSearch
        searchInputText={searchInputText}
        setSearchInputText={setSearchInputText}
        occurrences={occurrences}
        currentOccurrence={currentOccurrence}
        goToNextOccurrence={goToNextOccurrence}
        goToPrevOccurrence={goToPrevOccurrence}
      />

      <Box
        ref={textContainerRef}
        height="100%"
        width="100%"
        margin="0"
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ p: 2, display: "flex" }}>
          <Typography
            variant="multiLineBody"
            sx={{
              width: "100%",
              maxWidth: "600px",
              marginX: "auto",
            }}
          >
            {loading ? <TextSkeleton /> : getHighlightedText(searchTerm)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const TextSkeleton = () => {
  const randomWidths = useMemo(() => {
    return Array.from({ length: 50 }, () => `${Math.floor(Math.random() * 20 + 80)}%`);
  }, []);

  return (
    <>
      {randomWidths.map((width, index) => (
        <Skeleton key={index} variant="text" width={width} />
      ))}
    </>
  );
};
