import { env } from "@/constants/environment";
import { StorageTokenService } from "@/services/storageToken";
import { TokenType } from "@/services/storageToken/types";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const FirebaseService = {
  getAuth() {
    return getAuth(firebaseApp);
  },
  signOut() {
    StorageTokenService.removeToken(TokenType.FIREBASE);
    return firebaseApp.auth().signOut();
  },
  signInWithEmailAndPassword(email: string, password: string) {
    return firebaseApp.auth().signInWithEmailAndPassword(email.trim(), password);
  },
  signInWithCustomToken(token: string) {
    return firebaseApp.auth().signInWithCustomToken(token);
  },
  getCurrentUser() {
    return firebaseApp.auth().currentUser;
  },
  async getToken() {
    const token = StorageTokenService.getToken(TokenType.FIREBASE);

    if (token) {
      return token;
    }
    return this.refreshToken();
  },
  async refreshToken() {
    const firebaseUser = this.getCurrentUser();
    if (!firebaseUser) throw new Error("Firebase user not found");
    const newToken = await firebaseUser.getIdToken(true);
    StorageTokenService.saveToken(newToken, TokenType.FIREBASE);
    return newToken;
  },
  updatePassword(newPassword: string) {
    return this.getCurrentUser()?.updatePassword(newPassword);
  },
  reauthenticateWithCredential(email: string, password: string) {
    return this.getCurrentUser()?.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(email, password)
    );
  },
};
