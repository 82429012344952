import { PageLayout } from "@/components/PageLayout";
import React, { useMemo } from "react";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { ChatView } from "./components/ChatView";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { CardsView } from "./components/CardsView";
import { SearchRounded as SearchRoundedIcon } from "@mui/icons-material";
import { DeleteCasesModal } from "./components/CardsView/components/DeleteCasesModal";
import { ArchiveCasesModal } from "./components/CardsView/components/ArchiveCasesModal";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useDeleteCases } from "@/hooks/lexZap/useDeleteCases";
import { UnarchiveCasesModal } from "./components/CardsView/components/UnarchiveCasesModal";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { CreateCaseModal } from "@/components/Cases/createCaseModal";
import { Case, CaseWithChat } from "@/hooks/lexZap/types";

type InternalPageType = "CHAT" | "ACTIVE_CASES_CARDS" | "ARCHIVED_CASES_CARDS";

export const WhatsappAssistantCases = () => {
  const { data: casesData, isLoading: isLoadingCases } = useLexZapCases();
  const { mutateAsync: archiveCases, isPending: isArchivingCases } = useArchiveCases();
  const { mutateAsync: deleteCases, isPending: isDeletingCases } = useDeleteCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCases } = useUnarchiveCases();

  const cases = useMemo(() => {
    if (!casesData) return [];

    return casesData.sort((a, b) => (b.lastMessage?.timestamp ?? 0) - (a.lastMessage?.timestamp ?? 0));
  }, [casesData]);

  function isCaseWithChat(input: CaseWithChat | Case): input is CaseWithChat {
    return input.lastMessage !== null && input.chatId !== null;
  }

  const casesWithChat = useMemo(() => cases.filter(isCaseWithChat), [cases]);

  const [internalPage, setInternalPage] = React.useState<InternalPageType>("CHAT");
  const [selectedCases, setSelectedCases] = React.useState<string[]>([]);
  const [queryCases, setQueryCases] = React.useState<string>("");
  const [isOpenDeleteCases, setIsOpenDeleteCases] = React.useState(false);
  const [isOpenArchiveCases, setIsOpenArchiveCases] = React.useState(false);
  const [isOpenUnarchiveCases, setIsOpenUnarchiveCases] = React.useState(false);
  const [isOpenCreateCase, setIsOpenCreateCase] = React.useState(false);

  const handleOpenDeleteCases = () => {
    setIsOpenDeleteCases(true);
  };

  const handleCloseDeleteCases = () => {
    setIsOpenDeleteCases(false);
  };

  const handleOpenArchiveCases = () => {
    setIsOpenArchiveCases(true);
  };

  const handleCloseArchiveCases = () => {
    setIsOpenArchiveCases(false);
  };

  const handleOpenUnarchiveCases = () => {
    setIsOpenUnarchiveCases(true);
  };

  const handleCloseUnarchiveCases = () => {
    setIsOpenUnarchiveCases(false);
  };

  const handleDeleteCases = async () => {
    await deleteCases({ casesIds: selectedCases });
    handleCloseDeleteCases();
    setSelectedCases([]);
  };

  const handleArchiveCases = async () => {
    await archiveCases({ casesIds: selectedCases });
    handleCloseArchiveCases();
    setSelectedCases([]);
  };

  const handleUnarchiveCases = async () => {
    await unarchiveCases({ casesIds: selectedCases });
    handleCloseUnarchiveCases();
    setSelectedCases([]);
  };

  const handleSelectCase = (caseId: string) => {
    if (selectedCases.includes(caseId)) {
      setSelectedCases(selectedCases.filter((selectedCase) => selectedCase !== caseId));
    } else {
      setSelectedCases((prev) => [...prev, caseId]);
    }
  };

  const selectInternalPage = (page: InternalPageType) => {
    setSelectedCases([]);
    setInternalPage(page);
    setQueryCases("");
  };

  const handleOpenCreateCase = () => {
    setIsOpenCreateCase(true);
  };

  const handleCloseCreateCase = () => {
    setIsOpenCreateCase(false);
  };

  const filteredCases = React.useMemo(() => {
    if (!cases) return [];
    return cases.filter((caseItem) => caseItem.name.toLowerCase().includes(queryCases.toLowerCase()));
  }, [cases, queryCases]);

  const archivedCases = React.useMemo(() => filteredCases?.filter((c) => c.status === "ARCHIVED"), [filteredCases]);

  const activeCases = React.useMemo(() => filteredCases?.filter((c) => c.status === "ACTIVE"), [filteredCases]);

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
      headerProps={{
        extraContent: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: 4,
              gap: 1,
              paddingY: 2,
              backgroundColor: "common.dorian",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="pageTitle">Gestão de casos</Typography>
                <Typography variant="body" color="#4A4A68" sx={{ fontSize: "16px" }}>
                  Aqui estão centralizados os atendimentos e casos feitos e gerenciados pela e na Lexter
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {internalPage !== "ARCHIVED_CASES_CARDS" && (
                  <Button variant="outlined" onClick={handleOpenCreateCase}>
                    <Typography variant="bold" color="orange.dark.85">
                      Novo caso
                    </Typography>
                  </Button>
                )}
                {internalPage !== "CHAT" && (
                  <TextField
                    label="Buscar caso"
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: "14px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchRoundedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setQueryCases(e.target.value)}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
                height: "76px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <PageSelector
                  page="CHAT"
                  selectedPage={internalPage}
                  amount={casesWithChat?.length || 0}
                  onSelect={selectInternalPage}
                />
                <PageSelector
                  page="ACTIVE_CASES_CARDS"
                  selectedPage={internalPage}
                  amount={activeCases?.length || 0}
                  onSelect={selectInternalPage}
                />
                <PageSelector
                  page="ARCHIVED_CASES_CARDS"
                  selectedPage={internalPage}
                  amount={archivedCases?.length || 0}
                  onSelect={selectInternalPage}
                />
              </Box>
              {selectedCases.length > 0 && internalPage !== "CHAT" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: 1,
                  }}
                >
                  <Typography variant="bold">
                    {selectedCases.length} {selectedCases.length === 1 ? "caso" : "casos"} selecionados
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {internalPage === "ACTIVE_CASES_CARDS" ? (
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "grey.500",
                          ":hover": {
                            borderColor: "grey.600",
                          },
                        }}
                        onClick={handleOpenArchiveCases}
                      >
                        <Typography variant="bold" color="grey.500">
                          Arquivar
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "grey.500",
                          ":hover": {
                            borderColor: "grey.600",
                          },
                        }}
                        onClick={handleOpenUnarchiveCases}
                      >
                        <Typography variant="bold" color="grey.500">
                          Desarquivar
                        </Typography>
                      </Button>
                    )}

                    <Button variant="outlined" onClick={handleOpenDeleteCases}>
                      <Typography variant="bold" color="orange.dark.85">
                        Deletar
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ),
      }}
    >
      {internalPage === "CHAT" ? (
        <ChatView cases={casesWithChat} isLoadingCases={isLoadingCases} />
      ) : (
        <CardsView
          cases={filteredCases.filter(
            (c) => c.status === (internalPage === "ACTIVE_CASES_CARDS" ? "ACTIVE" : "ARCHIVED")
          )}
          isLoadingCases={isLoadingCases}
          onSelect={handleSelectCase}
          selectedCases={selectedCases}
          archived={internalPage === "ARCHIVED_CASES_CARDS"}
        />
      )}
      <DeleteCasesModal
        isOpen={isOpenDeleteCases}
        onConfirm={handleDeleteCases}
        onCancel={handleCloseDeleteCases}
        isLoading={isDeletingCases}
        amount={selectedCases.length}
      />

      <ArchiveCasesModal
        isOpen={isOpenArchiveCases}
        onConfirm={handleArchiveCases}
        onCancel={handleCloseArchiveCases}
        isLoading={isArchivingCases}
        amount={selectedCases.length}
      />

      <UnarchiveCasesModal
        isOpen={isOpenUnarchiveCases}
        onConfirm={handleUnarchiveCases}
        onCancel={handleCloseUnarchiveCases}
        isLoading={isUnarchivingCases}
        amount={selectedCases.length}
      />

      {isOpenCreateCase && <CreateCaseModal onClose={handleCloseCreateCase} />}
    </PageLayout>
  );
};

const PageSelector = ({
  selectedPage,
  page,
  onSelect,
  amount,
}: {
  selectedPage: InternalPageType;
  page: InternalPageType;
  onSelect: (page: InternalPageType) => void;
  amount: number;
}) => {
  enum SelectorName {
    CHAT = "Conversas",
    ACTIVE_CASES_CARDS = "Casos ativos",
    ARCHIVED_CASES_CARDS = "Casos arquivados",
  }

  return (
    <Box
      sx={{
        borderBottom: "2px solid",
        borderBottomColor: selectedPage === page ? "orange.main" : "transparent",
      }}
    >
      <Typography
        variant="body"
        onClick={() => {
          onSelect(page);
        }}
        sx={{
          cursor: "pointer",
          color: selectedPage === page ? "orange.main" : "grey.400",
        }}
      >
        {SelectorName[page]} ({amount})
      </Typography>
    </Box>
  );
};
