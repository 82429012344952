import { ModalRight } from "@/components/Modal/ModalRight";
import { Popper } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { ActionsList } from "@/pages/Home/components/ActionsList";
import { useAddFavoriteSkill } from "@/hooks/skills/useAddFavoriteSkill";
import { useDeleteFavoriteSkill } from "@/hooks/skills/useDeleteFavoriteSkill";
import { ActionProps } from "@/core/actions";
import { useActions } from "@/hooks/useActions";

interface SelectSkillProps {
  isOpen: boolean;
  onClose: () => void;
  threadId: string;
}

export const SelectSkill = ({ isOpen, onClose, threadId }: SelectSkillProps) => {
  const { mutateAsync: deleteFavoriteSkill } = useDeleteFavoriteSkill();
  const { mutateAsync: addFavoriteSkill } = useAddFavoriteSkill();
  const { actions, isLoadingActions } = useActions();

  const comingSoonRef = useRef<{
    handleComingSoon: (action: ActionProps) => void;
  }>();

  const handleComingSoon = (action: ActionProps) => {
    if (comingSoonRef.current) {
      comingSoonRef.current.handleComingSoon(action);
    }
  };
  const toggleFavoriteSkill = useCallback(
    async ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => {
      if (isFavorite) {
        await deleteFavoriteSkill({ skillId });
      } else {
        await addFavoriteSkill({ skillId });
      }
    },
    [addFavoriteSkill, deleteFavoriteSkill]
  );
  return (
    <Popper open={isOpen}>
      <ModalRight title={"COMEÇAR NOVA TAREFA"} size={"EXLARGE"} open={isOpen} onClose={onClose}>
        <ActionsList
          handleComingSoon={handleComingSoon}
          actions={actions}
          toggleFavoriteSkill={toggleFavoriteSkill}
          isLoading={isLoadingActions}
          hasDescription={true}
          threadId={threadId}
        />
      </ModalRight>
    </Popper>
  );
};
