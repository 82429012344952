import { Loading } from "@/components/Loading";
import { Box, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const loadingTexts = [
  "Com o Assistente Lexter, suas peças jurídicas ficam prontas em minutos.",
  "Menos tempo com documentos, mais tempo com seus clientes.",
  "Reduza em até 70% o tempo para redigir suas petições com Lexter.",
  "A elaboração de uma peça pode levar de 3 a 5 minutos. Fique tranquilo, ela será carregada automaticamente quando estiver pronta.",
  "Otimize seu escritório e tenha mais tempo para pensar estrategicamente.",
  "Dúvidas jurídicas resolvidas em segundos com a inteligência do Lexter.",
  "Domine mais áreas do Direito com o suporte da IA jurídica.",
  "A elaboração de uma peça pode levar de 3 a 5 minutos. Fique tranquilo, ela será carregada automaticamente quando estiver pronta.",
  "Organize processos e documentos em um só lugar, de forma simples.",
  "Com Lexter, cada contrato é elaborado de forma ágil e precisa.",
  "Menos rotina, mais resultados: automatize suas tarefas jurídicas.",
  "A elaboração de uma peça pode levar de 3 a 5 minutos. Fique tranquilo, ela será carregada automaticamente quando estiver pronta.",
  "Torne-se um advogado mais eficiente com o poder da IA.",
  "Contratos elaborados em minutos, sem perder qualidade.",
  "Tome decisões rápidas com acesso instantâneo a jurisprudência.",
  "A elaboração de uma peça pode levar de 3 a 5 minutos. Fique tranquilo, ela será carregada automaticamente quando estiver pronta.",
  "Sua jornada jurídica, mais ágil e simplificada com Lexter.",
  "Dedique seu tempo ao que importa: o relacionamento com seus clientes.",
  "Automatize tarefas e tenha mais tempo para casos estratégicos.",
  "A elaboração de uma peça pode levar de 3 a 5 minutos. Fique tranquilo, ela será carregada automaticamente quando estiver pronta.",
  "Tenha insights jurídicos avançados com um clique.",
  "Com Lexter, suas dúvidas jurídicas têm respostas imediatas.",
  "Amplie sua capacidade de atendimento sem comprometer a qualidade.",
  "A elaboração de uma peça pode levar de 3 a 5 minutos. Fique tranquilo, ela será carregada automaticamente quando estiver pronta.",
  "Acelere a produção de documentos e foque no crescimento do escritório.",
  "O futuro do Direito é agora: use IA para avançar no seu dia a dia.",
];

export const RunningSkill = () => {
  const { palette } = useTheme();
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="24px"
      width="100%"
      component={motion.div}
      key="running_skill"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5 }}
    >
      <Box>
        <Loading
          isLoading={true}
          size="MEDIUM"
          sx={{
            border: `3px solid ${palette.primary.main}`,
          }}
        />
      </Box>
      <Typography textAlign="center" maxWidth="320px" variant="body3">
        {loadingTexts[textIndex]}
      </Typography>
    </Box>
  );
};
