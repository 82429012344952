import { useMutation } from "@tanstack/react-query";
import { logger } from "@/core/logger";
import { SkillsPayload } from "@/contexts/WebSocketContext";
import { ThreadService } from "@/services/thread";

export const useStartSkillProcess = () => {
  return useMutation({
    mutationKey: ["startSkillProcess"],
    mutationFn: async ({ threadId, skillPayload }: { threadId: string; skillPayload: SkillsPayload }) => {
      logger.info("Starting skill process", skillPayload);

      return await ThreadService.processSkill({
        threadId,
        ...skillPayload,
      });
    },
    onError: (error) => {
      logger.error("Error in useStartSkillProcess:", error);
    },
  });
};
