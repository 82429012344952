import { Box, Button, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { ChangeEvent } from "react";
import { WebToast } from "@/taskpane/components/core/Toast";
import { useApplicant } from "@/hooks/applicants/useApplicant";
import { useEditApplicant } from "@/hooks/applicants/useEditApplicant";
import { CPF } from "@/core/CPF";
import { Email } from "@/core/Email";

type Form = {
  name: string;
  city: string | null;
  cpf: string | null;
  email: string | null;
  phoneNumber: string | null;
  maritalStatus: string | null;
  occupation: string | null;
  state: string | null;
  streetName: string | null;
  houseNumber: string | null;
  zipCode: string | null;
};

export function EditApplicantModal({
  isOpen,
  onClose,
  applicantId,
}: {
  isOpen: boolean;
  onClose: () => void;
  applicantId: string;
}) {
  const { data, isLoading: isLoadingCaseData } = useApplicant({ applicantId });
  const { mutateAsync: updateApplicant, isPending: isUpdatingCase } = useEditApplicant();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);
  const [isValidCpf, setIsValidCpf] = React.useState<boolean>(true);
  const [form, setForm] = React.useState<Form>({
    name: "",
    city: null,
    cpf: null,
    email: null,
    phoneNumber: null,
    maritalStatus: null,
    occupation: null,
    state: null,
    streetName: null,
    houseNumber: null,
    zipCode: null,
  });

  React.useEffect(() => {
    if (data) {
      setForm({
        name: data.name,
        city: data.city,
        cpf: data.cpf,
        email: data.email,
        phoneNumber: data.phoneNumber,
        maritalStatus: data.maritalStatus,
        occupation: data.occupation,
        state: data.state,
        streetName: data.streetName,
        houseNumber: data.houseNumber,
        zipCode: data.zipCode,
      });
    }
  }, [data]);

  const handleUpdateApplicant = async () => {
    if (!data || !applicantId || form.name === "") {
      return;
    }

    try {
      setIsLoading(true);
      await updateApplicant({ applicantId, data: { ...form } });

      setIsLoading(false);

      onClose();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      setIsLoading(false);
      return WebToast.error("Falha ao alterar dados do cliente");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (prop === "cpf" || prop === "zipCode" || prop === "phoneNumber") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value.replace(/\D/g, ""),
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const saveButtonDisabled = form?.name === "" || !isValidCpf || !isValidEmail || isLoading || !data;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Editar cliente"}
        size={"SMALL"}
        onClose={onClose}
        footer={
          !isLoading && (
            <ModalFooter
              confirmLabel={"Atualizar"}
              onConfirm={handleUpdateApplicant}
              cancelLabel="Cancelar"
              onCancel={onClose}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={isOpen}
      >
        <Loading isLoading={isLoading || isLoadingCaseData || isUpdatingCase}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                Perfil do cliente
              </Typography>

              <TextField placeholder="Cliente" onChange={handleChangeForm("name")} value={form.name} label="Cliente" />

              <TextField
                placeholder="CPF"
                onChange={(e) => {
                  handleChangeForm("cpf")(e);
                  setIsValidCpf(e.target.value === "" ? true : CPF.fromString(e.target.value).isSuccess);
                }}
                value={form.cpf}
                error={!isValidCpf}
                type="text"
                inputProps={{
                  maxLength: 11,
                }}
                label="CPF"
              />

              <TextField
                placeholder="Estado Civil"
                onChange={handleChangeForm("maritalStatus")}
                value={form.maritalStatus}
                label="Estado Civil"
              />

              <TextField
                placeholder="Profissão"
                onChange={handleChangeForm("occupation")}
                value={form.occupation}
                label="Profissão"
              />

              <Typography variant="body1" fontSize={"12px"}>
                Contato
              </Typography>

              <TextField
                placeholder="Telefone"
                onChange={handleChangeForm("phoneNumber")}
                value={form.phoneNumber}
                label="Telefone"
                type="tel"
                inputProps={{
                  maxLength: 11,
                }}
                disabled={!!data?.phoneNumber}
              />

              <TextField
                placeholder="Email"
                onChange={(e) => {
                  handleChangeForm("email")(e);
                  setIsValidEmail(e.target.value === "" ? true : Email.isValidEmail(e.target.value));
                }}
                value={form.email}
                label="Email"
                type="email"
                error={!isValidEmail}
              />

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 0.45fr",
                  gap: "16px",
                }}
              >
                <TextField
                  placeholder="Rua"
                  onChange={handleChangeForm("streetName")}
                  value={form.streetName}
                  label="Rua"
                />
                <TextField
                  placeholder="Número"
                  onChange={handleChangeForm("houseNumber")}
                  value={form.houseNumber}
                  label="Número"
                />
              </Box>

              <TextField
                placeholder="CEP"
                onChange={handleChangeForm("zipCode")}
                value={form.zipCode}
                label="CEP"
                inputProps={{
                  maxLength: 8,
                }}
              />

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 0.45fr",
                  gap: "16px",
                }}
              >
                <TextField placeholder="Cidade" onChange={handleChangeForm("city")} value={form.city} label="Cidade" />
                <TextField
                  placeholder="Estado"
                  onChange={handleChangeForm("state")}
                  value={form.state}
                  label="Estado"
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm} size="small">
        {confirmLabel}
      </Button>
      <Button size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Box>
  );
};
