import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { PeriodValue } from "../types";
import { periodOptions } from "./periodOptions";

interface PeriodFilterProps {
  onChange: (date?: DateTime) => void;
}
export const PeriodFilter = ({ onChange }: PeriodFilterProps) => {
  const [selectedPeriod, setSelectedPeriod] = useState(PeriodValue.ANY);
  const date = useMemo(() => {
    let minDate: DateTime | undefined = undefined;

    const currentDate = DateTime.now();
    switch (selectedPeriod) {
      case PeriodValue.LAST_YEAR:
        minDate = currentDate.minus({ year: 1 });
        break;
      case PeriodValue.LAST_2_YEARS:
        minDate = currentDate.minus({ years: 2 });
        break;
      case PeriodValue.LAST_3_YEARS:
        minDate = currentDate.minus({ years: 3 });
        break;
      case PeriodValue.LAST_5_YEARS:
        minDate = currentDate.minus({ years: 5 });
        break;
    }

    return minDate;
  }, [selectedPeriod]);

  useEffect(() => {
    onChange(date);
  }, [onChange, date]);

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value as PeriodValue) {
      case PeriodValue.LAST_YEAR:
        setSelectedPeriod(PeriodValue.LAST_YEAR);
        break;
      case PeriodValue.LAST_2_YEARS:
        setSelectedPeriod(PeriodValue.LAST_2_YEARS);
        break;
      case PeriodValue.LAST_3_YEARS:
        setSelectedPeriod(PeriodValue.LAST_3_YEARS);
        break;
      case PeriodValue.LAST_5_YEARS:
        setSelectedPeriod(PeriodValue.LAST_5_YEARS);
        break;
      case PeriodValue.ANY:
        setSelectedPeriod(PeriodValue.ANY);
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="preTitle">Período</Typography>

      <FormControl>
        <RadioGroup name="period" value={selectedPeriod} onChange={handlePeriodChange}>
          {periodOptions.map((option, index) => (
            <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
