import { Box, Typography } from "@mui/material";
import React from "react";
import CasesNotFoundIcon from "@/assets/svgs/cases-not-found.svg?react";

export const EmptyChat = () => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          padding: 2,
          margin: "auto",
          width: "444px",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            paddingX: 10.5,
          }}
        >
          <CasesNotFoundIcon
            style={{
              flexShrink: 0,
              width: "100%",
              cursor: "pointer",
              marginBottom: "48px",
            }}
          />
        </Box>

        <Typography
          variant="body1"
          sx={{
            fontWeight: "700",
            fontSize: "22px",
          }}
        >
          Você não possui conversas ou casos
        </Typography>
        <Typography variant="body" sx={{ whiteSpace: "normal" }}>
          Um novo caso vai aparecer aqui assim que tivermos sua aprovação em algum resumo de contato com cliente
        </Typography>
      </Box>
    </Box>
  );
};
