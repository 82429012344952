export enum Alignment {
  MIXED = "Mixed",
  UNKNOWN = "Unknown",
  LEFT = "Left",
  CENTERED = "Centered",
  RIGHT = "Right",
  JUSTIFIED = "Justified",
}

export enum Underline {
  MIXED = "Mixed",
  NONE = "None",
  HIDDEN = "Hidden",
  DOT_LINE = "DotLine",
  SINGLE = "Single",
  WORD = "Word",
  DOUBLE = "Double",
  THICK = "Thick",
  DOTTED = "Dotted",
  DOTTED_HEAVY = "DottedHeavy",
  DASH_LINE = "DashLine",
  DASH_LINE_HEAVY = "DashLineHeavy",
  DASH_LINE_LONG = "DashLineLong",
  DASH_LINE_LONG_HEAVY = "DashLineLongHeavy",
  DOT_DASH_LINE = "DotDashLine",
  DOT_DASH_LINE_HEAVY = "DotDashLineHeavy",
  TWO_DOT_DASH_LINE = "TwoDotDashLine",
  TWO_DOT_DASH_LINE_HEAVY = "TwoDotDashLineHeavy",
  WAVE = "Wave",
  WAVE_HEAVY = "WaveHeavy",
  WAVE_DOUBLE = "WaveDouble",
}
