import React from "react";
import PaginationDot from "./paginationDot";

interface PaginationProps {
  dots: number;
  index: number;
  onChangeIndex: (index: number) => void;
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const Pagination: React.FC<PaginationProps> = ({ dots, index, onChangeIndex }) => {
  const handleClick = (_event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    onChangeIndex(index);
  };

  const children: React.ReactNode[] = [];

  for (let i = 0; i < dots; i += 1) {
    children.push(<PaginationDot key={i} index={i} active={i === index} onClick={handleClick} />);
  }

  return <div style={styles.root as React.CSSProperties}>{children}</div>;
};

export default Pagination;
