import { Box, Typography } from "@mui/material";
import React from "react";

import { ExpandableCard } from "@/components/ExpandableCard";
import { Card } from "../Card";
import { useParams } from "react-router-dom";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";

export const CaseSummary = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { caseData } = useLexZapCase({ caseId });

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "min-content",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} fontSize="22px" color="text.primary">
          Resumo jurídico
        </Typography>
        <Typography variant="multiLineBody" fontWeight={700} fontSize="10px" color="text.primary">
          Última atualização em 12/09/2024 às 13:36
        </Typography>
      </Box>

      <Typography variant="body1" color="text.primary">
        {caseData?.facts?.split("\n").map((paragraph, index) => (
          <React.Fragment key={index}>
            {paragraph}
            <br />
          </React.Fragment>
        ))}
      </Typography>

      {caseData?.documentationAndEvidence && (
        <SummaryItemCard title="Documentações e provas" content={caseData.documentationAndEvidence} />
      )}

      {caseData?.legalQuestions && <SummaryItemCard title="Questões jurídicas" content={caseData.legalQuestions} />}

      {/* {caseData?.recommendations && <SummaryItemCard title="Recomendações" content={caseData.recommendations} />} */}

      {caseData?.conclusion && <SummaryItemCard title="Conclusão Lexter" content={caseData.conclusion} />}
    </Card>
  );
};

const SummaryItemCard = ({ title, content }: { title: string; content: string }) => (
  <ExpandableCard
    header={
      <Typography variant="subtitle1" fontWeight={600} color="text.primary">
        {title}
      </Typography>
    }
  >
    <Typography variant="multiLineBody" fontSize="16px" color="text.primary">
      {content.split("\n").map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          <br />
        </React.Fragment>
      ))}
    </Typography>
  </ExpandableCard>
);
