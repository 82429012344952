import { authenticatedCopilotServer } from "@/core/api";
import { CreateThreadResponse } from "./types";
import { logger } from "@/core/logger";
import { MessageDTO } from "@/contexts/MessagesContext";
import axios from "axios";
import { InsufficientCreditsError } from "./errors";

export const ThreadService = {
  createThread: async ({ threadName }: { threadName?: string }) => {
    return await authenticatedCopilotServer
      .post<CreateThreadResponse>(`/threads`, {
        name: threadName,
      })
      .then((res) => res.data);
  },

  deleteThread: async (threadId: string) => {
    return await authenticatedCopilotServer.delete<"Ok">(`/threads/${threadId}`).then((res) => res.data);
  },

  renameThread: async ({ name, threadId }: { name: string; threadId: string }) => {
    return await authenticatedCopilotServer.put<"Ok">(`/threads/${threadId}/name`, { name }).then((res) => res.data);
  },

  processSkill: async ({
    threadId,
    skillId,
    messageToSave,
    requestId,
    payload,
    isOnboarding,
  }: {
    threadId: string;
    skillId: string;
    requestId: string;
    payload: object;
    messageToSave?: string;
    isOnboarding?: boolean;
  }) => {
    try {
      const requestBody = {
        skillId,
        messageToSave,
        requestId,
        payload,
        isOnboarding,
      };

      const response = await authenticatedCopilotServer.post<{ success: boolean }>(
        `/threads/${threadId}/processSkill`,
        requestBody
      );
      return response.data;
    } catch (error) {
      logger.error("Error in processSkill:", error);
      if (axios.isAxiosError(error) && error.response?.data?.message === "Créditos insuficientes") {
        throw new InsufficientCreditsError("Insufficient Credits");
      }
      throw error;
    }
  },

  sendMessage: async (threadId: string, messages: MessageDTO[], isOnboarding: boolean, content: string) => {
    try {
      const chatPayload = {
        chat: {
          messages,
          content,
        },
        isOnboarding,
      };

      const response = await authenticatedCopilotServer.post<{ success: boolean }>(
        `/threads/${threadId}/sendMessage`,
        chatPayload
      );
      return response.data;
    } catch (error) {
      logger.error("Error in sendMessage:", error);
      if (axios.isAxiosError(error) && error.response?.data?.message === "Créditos insuficientes") {
        throw new InsufficientCreditsError("Insufficient Credits");
      }
      throw error;
    }
  },
};
