import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import SmallScreenError from "@/assets/svgs/small-screen-error.svg?react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { Link, useTheme } from "@mui/material";
import { EXTERNAL_URLS } from "@/routes/routePaths";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

const ScreenSizeChecker = ({ children }: React.PropsWithChildren) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { customerServiceWhatsApp } = useFeatureFlags();
  const theme = useTheme();

  const checkConditions = () => {
    const isMobile = window.innerWidth <= 721 || window.innerHeight <= 480;
    const isOutsideOfficeClient = outsideOfficeClient();
    setShowMessage(isMobile && isOutsideOfficeClient);
  };

  useEffect(() => {
    checkConditions();
    window.addEventListener("resize", checkConditions);
    return () => window.removeEventListener("resize", checkConditions);
  }, []);

  return (
    <>
      {children}
      <Modal open={showMessage} onClose={() => {}}>
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "background.default",
            textAlign: "center",
            padding: 3,
            position: "fixed",
            top: 0,
            left: 0,
            color: undefined,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: "380px",
              height: "auto",
              margin: "auto",
              gap: 10,
            }}
          >
            <SmallScreenError />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Typography variant="h5">SUA TELA É MUITO PEQUENA</Typography>
              <Typography
                variant="body3"
                sx={{
                  textAlign: "center",
                  width: "85%",
                  fontWeight: 400,
                  lineHeight: "22.4px",
                }}
              >
                Por enquanto o Assistente Lexter funciona apenas em telas de dispositivos desktop com resolução maior
                que 721px de largura. Caso esteja usando o navegador redimensionado ajuste sua dimensão para utilizar a
                plataforma. Caso tenha dúvidas, acesse nossa{" "}
                <Link href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank">
                  Central de Ajuda
                </Link>
                {customerServiceWhatsApp && customerServiceWhatsApp.length > 0 && (
                  <>
                    {" "}
                    ou entre em contato pelo nosso{" "}
                    <Link href={customerServiceWhatsApp} target="_blank">
                      WhatsApp
                    </Link>
                  </>
                )}
                .
              </Typography>
              <LexterLogoIcon
                height={53}
                style={{
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ScreenSizeChecker;
