import { Box, Typography } from "@mui/material";
import React from "react";
import { Card } from "../Card";
import { CaseStep } from "./components/CaseStep";
import { useParams } from "react-router-dom";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";

export const NextSteps = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { caseData } = useLexZapCase({ caseId });

  const nextSteps = caseData?.nextSteps;

  if (!nextSteps?.length) {
    return null;
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          alignItems: "flex-end",
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} fontSize="22px" color="text.primary">
          Próximos passos
        </Typography>
        <Typography variant="body2" color="text.primary">
          {nextSteps.filter((item) => item.completed).length}/{nextSteps.length}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          paddingY: 2,
        }}
      >
        {nextSteps
          .filter((item) => !item.completed)
          .map((step, index) => (
            <CaseStep key={index} checked={step.completed} description={step.description} />
          ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            alignItems: "flex-end",
            borderBottomColor: "common.lightShade",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            paddingBottom: 1,
          }}
        >
          <Typography variant="body1" fontWeight={700} color="text.primary">
            Finalizados
          </Typography>
          <Typography variant="body2" color="text.primary">
            {nextSteps.filter((item) => item.completed).length}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          {nextSteps
            .filter((item) => item.completed)
            .map((step, index) => (
              <CaseStep key={index} checked={step.completed} description={step.description} />
            ))}
        </Box>
      </Box>
    </Card>
  );
};
