export const decodeJwt = (token: string) => {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid Token");
  }

  return JSON.parse(base64urlDecode(parts[1]));
};

const base64urlDecode = (str: string): string => {
  return atob(str.replace(/-/g, "+").replace(/_/g, "/"));
};
