import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";
import { skillInputToUserInput } from "@/utils/skillInputToUserInput";

const inputSchema = Type.Object({
  document: Type.String(),
  case_breakdown: Type.String(),
});

const actionId = ActionId.UPDATE_DOCUMENTS;

export const adaptDocumentSkill: Skill<typeof inputSchema> = {
  id: "adapt_document",
  name: "Adaptar peça/contrato",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["recommend", "new"],
  actionId,
  flag: "skillBetaTester",
  type: "payload",
  getPayload: (input) => ({
    skillId: ActionId.UPDATE_DOCUMENTS,
    requestId: uuidV4(),
    payload: {
      document: skillInputToUserInput(input.document),
      case_breakdown: input.case_breakdown,
    },
    messageToSave: "Adaptar peça",
  }),
};
