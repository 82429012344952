import { DialogModal, DialogModalButtonProps } from "@/components/DialogModal";
import React, { useMemo } from "react";
import { AlertModalContext } from "./context";
import { AlertModalProps } from "./types";

export const AlertModalProvider = ({ children }: React.PropsWithChildren) => {
  const [modalProps, setModalProps] = React.useState<AlertModalProps>();

  const openAlertModal = (params: AlertModalProps) => {
    setModalProps(params);
  };

  const handlePrimaryButtonClick = React.useCallback(async () => {
    setModalProps((prev) => (prev ? { ...prev, isLoading: true } : undefined));
    await modalProps?.primaryButton.onClick();
    setModalProps(undefined);
  }, [modalProps]);

  const handleSecondaryButtonClick = React.useCallback(async () => {
    setModalProps((prev) => (prev ? { ...prev, isLoading: true } : undefined));
    await modalProps?.secondaryButton?.onClick();
    setModalProps(undefined);
  }, [modalProps]);

  const handleClose = () => {
    modalProps?.onClose?.();
    setModalProps(undefined);
  };

  const buttons: DialogModalButtonProps[] = useMemo(() => {
    const modalButtons: DialogModalButtonProps[] = [];

    if (!modalProps) return modalButtons;

    modalButtons.push({
      label: modalProps.primaryButton.label,
      onClick: handlePrimaryButtonClick,
      color: "error",
    });

    if (modalProps.secondaryButton) {
      modalButtons.push({
        label: modalProps.secondaryButton?.label,
        onClick: handleSecondaryButtonClick,
        sx: {
          color: "text.primary",
          backgroundColor: "transparent",
          textDecoration: "underline",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },
      });
    }

    return modalButtons;
  }, [modalProps, handlePrimaryButtonClick, handleSecondaryButtonClick]);

  return (
    <AlertModalContext.Provider
      value={{
        openAlertModal,
      }}
    >
      {children}

      <DialogModal
        open={!!modalProps}
        onClose={modalProps?.onClose && handleClose}
        title={modalProps?.title}
        description={modalProps?.description}
        isLoading={modalProps?.isLoading}
        descriptionTextAlign="left"
        buttonsDirection="row"
        buttons={buttons}
        variant="error"
      />
    </AlertModalContext.Provider>
  );
};
