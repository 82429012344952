import { buildSkillStep } from "@/core/skillForms/builder";

const caseBreakdownMinLength = 140;

export const createContractSkillStep3 = buildSkillStep({
  description: `Descreva todas as informações sobre o caso, como objeto e vigência. Para construção do contrato é necessário um detalhamento mínimo de ${caseBreakdownMinLength} caracteres`,
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      minLength: caseBreakdownMinLength,
      ui: {
        label: "Detalhe o caso:",
        placeholder:
          "Exemplo: Maria alugou a casa de João por R$ 2.000,00 mensais. O aluguel terá duração de 1 (um) ano, com início em 1 de Junho de 2023 até 1 de Junho de 2024.",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Gerar contrato",
});
