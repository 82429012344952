import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { Button } from "@/components/Button";
// import {
//   ExpandLessRounded as ExpandLessRoundedIcon,
//   ExpandMoreRounded as ExpandMoreRoundedIcon,
// } from "@mui/icons-material";
import { Case, CaseStep } from "@/hooks/lexZap/types";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";

interface ChatHeaderProps {
  lexZapCase?: Case;
  isLoading?: boolean;
}

export const ChatHeader = ({ lexZapCase, isLoading }: ChatHeaderProps) => {
  // const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  // const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;
  const navigate = useNavigate();

  const goToCase = () => {
    if (lexZapCase) {
      navigate(ROUTE_PATHS.CASE_DETAILS.replace(":caseId", lexZapCase.id));
    }
  };

  if (isLoading || !lexZapCase) {
    return <ChatHeaderSkeleton />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.100",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopRightRadius: "24px",
      }}
    >
      <Box>
        <Typography
          variant="body"
          color="#09132C"
          sx={{
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {lexZapCase.applicantName}
        </Typography>

        {/* <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "8px",
              width: "8px",
              backgroundColor: "success.light",
              borderRadius: "50%",
              marginBottom: "2px",
            }}
          />
          <Typography variant="body">Online</Typography>
        </Box> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingX: 2,
            paddingY: 1,
            whiteSpace: "nowrap",
          }}
          onClick={goToCase}
          disabled={lexZapCase.step !== CaseStep.SUMMARY_GENERATED}
        >
          Acessar caso
        </Button>

        {/* <IconButton onClick={() => setIsExpanded((prev) => !prev)} sx={{ m: -1 }}>
          <ExpandIcon fontSize="large" />
        </IconButton> */}
      </Box>
    </Box>
  );
};

const ChatHeaderSkeleton = () => {
  // const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  // const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;

  return (
    <Box
      sx={{
        backgroundColor: "grey.100",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopRightRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Skeleton variant="text" width={200} />

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <Skeleton variant="circular" height={8} width={8} />
          <Skeleton variant="text" width={50} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled
          sx={{
            paddingX: 2,
            paddingY: 1,
            whiteSpace: "nowrap",
          }}
        >
          Acessar caso
        </Button>

        {/* <IconButton onClick={() => setIsExpanded((prev) => !prev)} sx={{ m: -1 }}>
          <ExpandIcon fontSize="large" />
        </IconButton> */}
      </Box>
    </Box>
  );
};
