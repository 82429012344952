import { z } from "zod";

export const passwordSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "min" })
      .max(200, { message: "max" })
      .regex(/[a-zA-Z]/, {
        message: "letter",
      })
      .regex(/(\d|[`!@#$%^&*()_\-+=[\]{};':"|,.<>\\/?~])/, {
        message: "number_or_special",
      }),
    confirmPassword: z.string(),
    terms: z.boolean().refine((value) => value, {
      message: "O aceite dos termos é obrigatório para finalização do cadastro.",
    }),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Senha não confere com a criada anteriormente.",
        path: ["confirmPassword"],
      });
    }
  });

export type PasswordFormData = z.infer<typeof passwordSchema>;

export interface PasswordErrors {
  min: boolean;
  max: boolean;
  letter: boolean;
  number_or_special: boolean;
}

export const passwordErrorsDefault: PasswordErrors = {
  min: false,
  max: false,
  letter: false,
  number_or_special: false,
};
