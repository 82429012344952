// start and end in 24-hour format
export const isBetweenTheseHours = (start: number, end: number) => {
  const date = new Date();
  // Adjusting to Brazil timezone
  const hours = date.getUTCHours() - 3;
  return hours >= start && hours < end;
};

export const isWeekend = () => {
  const date = new Date();
  return date.getDay() === 0 || date.getDay() === 6;
};
