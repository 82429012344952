import React, { ReactNode, useState } from "react";
import { PaywallModal } from "./components/PaywallModal";
import { PaywallContext } from "./context";
import { useOpenBilling } from "@/hooks/useOpenBilling";
import { SkillPaywallModal } from "@/contexts/PaywallContext/components/SkillPaywallModal";
import { OpenSkillPaywallModalProps } from "@/contexts/PaywallContext/types";

interface PaywallProviderProps {
  children: ReactNode;
}

export const PaywallProvider = ({ children }: PaywallProviderProps) => {
  const openBilling = useOpenBilling();
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const [skillPaywallModalProps, setSkillPaywallModalProps] = useState<OpenSkillPaywallModalProps | undefined>(
    undefined
  );

  const openPaywall = React.useCallback(() => {
    setPaywallModalOpen(true);
  }, []);

  const openSkillPaywall = React.useCallback((props: OpenSkillPaywallModalProps) => {
    setSkillPaywallModalProps({
      ...props,
    });
  }, []);

  return (
    <PaywallContext.Provider
      value={{
        openPaywall,
        openSkillPaywall,
      }}
    >
      {children}

      <PaywallModal
        open={paywallModalOpen}
        onClose={() => setPaywallModalOpen(false)}
        onOpenBilling={() => {
          openBilling();
          setPaywallModalOpen(false);
        }}
      />

      {skillPaywallModalProps && (
        <SkillPaywallModal
          open={true}
          onClose={() => setSkillPaywallModalProps(undefined)}
          onOpenBilling={() => {
            openBilling();
            setSkillPaywallModalProps(undefined);
          }}
          availabilityPlans={skillPaywallModalProps.availabilityPlans}
          skillName={skillPaywallModalProps.skillName}
        />
      )}
    </PaywallContext.Provider>
  );
};
