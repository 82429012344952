import { Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import WelcomeSvg from "@/assets/svgs/celebration.svg";

export const Welcome = () => {
  const navigate = useNavigate();

  // Prevent go back
  useEffect(() => {
    history.pushState(null, document.title, location.href);
    history.back();
    history.forward();
    window.onpopstate = function () {
      history.go(1);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff8f6",
          width: "50%",
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "70%",
            maxWidth: "483px",
            alignItems: "left",
          }}
        >
          <img src={WelcomeSvg} alt="Pessoa inspirada" style={{ width: "60%", minWidth: "200px", maxWidth: "322px" }} />
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ mt: 10, textAlign: "left", color: "text.secondary" }}
          >
            Dê o primeiro passo para transformar a sua rotina jurídica
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "grey.50",
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "70%",
            maxWidth: "389px",
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-between",
            boxShadow:
              "208px 194px 80px 0px rgba(0, 0, 0, 0.00), 133px 124px 73px 0px rgba(0, 0, 0, 0.01), 75px 70px 61px 0px rgba(0, 0, 0, 0.03), 33px 31px 46px 0px rgba(0, 0, 0, 0.04), 8px 8px 25px 0px rgba(0, 0, 0, 0.05);",
            borderRadius: "16px",
          }}
        >
          <Typography variant="subtitle" sx={{ textAlign: "left" }}>
            Experimente criar sua primeira peça gratuitamente
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 5, width: "100%" }}
            onClick={() => navigate(`${ROUTE_PATHS.CHAT}?skillGroup=create_new_document&isOnboarding=true`)}
          >
            Começar →
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
