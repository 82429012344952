import { Skill, SkillId } from "@/core/skills/types";
import { createAppealSkill } from "@/core/skills/implementations";
import { Area, DecisionType } from "@/contexts/MessagesContext";
import { Flags } from "@/hooks/useFeatureFlags/types";

interface AppealSkillConfig {
  id: SkillId;
  name: string;
  document_name: string;
  area?: Area;
  decisionType: DecisionType;
  flag?: keyof Flags;
}

const createAppealSkillVariant = ({
  id,
  name,
  document_name,
  area,
  decisionType,
  flag,
}: AppealSkillConfig): Skill<typeof createAppealSkill.inputSchema> => ({
  ...createAppealSkill,
  tags: ["litigation", "new"],
  id,
  name,
  flag: flag ?? createAppealSkill.flag,
  initialValue: {
    area,
    document_name,
    decision_type: decisionType,
  },
});

export const createExtraordinaryAppealSkill = createAppealSkillVariant({
  id: "create_extraordinary_appeal",
  name: "Escrever recurso extraordinário",
  document_name: "Recurso Extraordinário",
  decisionType: DecisionType.TERMINATIVE_DECISIONS,
  flag: "skillBetaTester",
});

export const createSpecialAppealSkill = createAppealSkillVariant({
  id: "create_special_appeal",
  name: "Escrever recurso especial",
  document_name: "Recurso Especial",
  decisionType: DecisionType.TERMINATIVE_DECISIONS,
  flag: "skillBetaTester",
});

export const createUnnamedAppealSkill = createAppealSkillVariant({
  id: "create_unnamed_appeal",
  name: "Escrever recurso inominado",
  document_name: "Recurso Inominado",
  decisionType: DecisionType.TERMINATIVE_DECISIONS,
  flag: "skillBetaTester",
});

export const createReviewAppealSkill = createAppealSkillVariant({
  id: "create_review_appeal",
  name: "Escrever recurso de revista",
  document_name: "Recurso de Revista",
  area: Area.LABOR,
  decisionType: DecisionType.TERMINATIVE_DECISIONS,
  flag: "skillBetaTester",
});

export const createAppealInStrictSenseSkill = createAppealSkillVariant({
  id: "create_appeal_in_strict_sense",
  name: "Escrever recurso em sentido estrito",
  document_name: "Recurso em Sentido Estrito",
  area: Area.CRIMINAL,
  decisionType: DecisionType.TERMINATIVE_DECISIONS,
  flag: "skillBetaTester",
});

export const createInterlocutoryAppealSkill = createAppealSkillVariant({
  id: "create_interlocutory_appeal",
  name: "Escrever agravo de instrumento",
  document_name: "Agravo de Instrumento",
  decisionType: DecisionType.INTERLOCUTORY_DECISIONS,
  flag: "skillBetaTester",
});

export const createInternalAppealSkill = createAppealSkillVariant({
  id: "create_internal_appeal",
  name: "Escrever agravo Interno",
  document_name: "Agravo Interno",
  decisionType: DecisionType.INTERLOCUTORY_DECISIONS,
  flag: "skillBetaTester",
});
