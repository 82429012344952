import { Box } from "@mui/material";
import { useEditorUI } from "@/hooks/useEditorUI";
import React from "react";

export const EditorModal = () => {
  const { modalContent } = useEditorUI();

  if (!modalContent) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 11,
      }}
    >
      {modalContent}
    </Box>
  );
};
