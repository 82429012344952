import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  contract_micro_type: Type.String(),
  party_1: Type.String(),
  party_2: Type.String(),
  case_breakdown: Type.String(),
});

const actionId = ActionId.CREATE_CONTRACT;

export const createContractSkill: Skill<typeof inputSchema> = {
  id: "create_contract",
  name: "Elaborar contrato",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  tags: ["consultative"],
  actionId,
  type: "message",
  description: "Reduza em até 50% o tempo gasto na confecção de contratos jurídicos.",
  getMessage: (input) => ({
    id: uuidV4(),
    type: "FLOW",
    direction: "SENT",
    author: "Current User",
    date: DateTime.now(),
    status: "READ",
    text: "Elaborar Contrato",
    actions: [
      {
        id: actionId,
        contract_micro_type: input.contract_micro_type,
        party_1: input.party_1,
        party_2: input.party_2,
        case_breakdown: input.case_breakdown,
      },
    ],
    files: [],
  }),
};
