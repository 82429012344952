import { createAppealSkillStep1 } from "./step1";
import { createAppealSkillStep2 } from "./step2";
import { createAppealSkillStep3 } from "./step3";
import { SkillForm } from "@/core/skillForms/types";

export const createAppealSkillForm: SkillForm = {
  skillId: "create_appeal",
  title: "Apelação",
  steps: [createAppealSkillStep1, createAppealSkillStep2, createAppealSkillStep3],
};
