import { Box, Button, FormControl, InputLabel, Select, SelectChangeEvent, TextField } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { ChangeEvent } from "react";
import { WebToast } from "@/taskpane/components/core/Toast";
import { useCreateCase } from "@/hooks/lexZap/useCreateCase";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { Applicant } from "@/hooks/applicants/types";

type Form = {
  name: string;
  applicantId: string;
};

export function CreateCaseModal({ onClose }: { onClose: () => void }) {
  const { data: applicants, isLoading: isLoadingApplicants } = useApplicants();
  const { mutateAsync: createCase, isPending: isCreatingCase } = useCreateCase();
  const [form, setForm] = React.useState<Form>({
    name: "",
    applicantId: "",
  });

  const handleCreateCase = async () => {
    if (form.applicantId === "" || form.name === "") {
      return;
    }

    try {
      await createCase({
        name: form.name,
        applicantId: form.applicantId,
      });

      onClose();
      return WebToast.success("Caso criado com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao criar o caso");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
  };

  const handleChangeSelect = <K extends keyof Form>(prop: K) => {
    return (e: SelectChangeEvent<string>) =>
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
  };

  const saveButtonDisabled = form.name === "" || form.applicantId === "" || isLoadingApplicants || isCreatingCase;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Criar caso"}
        size={"SMALL"}
        onClose={onClose}
        footer={
          !isLoadingApplicants &&
          !isCreatingCase && (
            <ModalFooter
              confirmLabel={"Criar"}
              onConfirm={handleCreateCase}
              cancelLabel="Cancelar"
              onCancel={onClose}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={true}
      >
        <Loading isLoading={isLoadingApplicants || isCreatingCase}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <>
                <TextField
                  placeholder="Nome do caso"
                  onChange={handleChangeForm("name")}
                  value={form.name}
                  label="Nome do caso"
                />
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-name-native-simple">Cliente</InputLabel>
                  <Select
                    native
                    value={form.applicantId}
                    onChange={handleChangeSelect("applicantId")}
                    label="Cliente"
                    inputProps={{
                      // name: "cliente",
                      id: "outlined-name-native-simple",
                    }}
                    // disabled={true}
                  >
                    <option aria-label="None" value="" />
                    {applicants?.map((applicant: Applicant) => (
                      <option key={applicant.id} value={applicant.id}>
                        {applicant.name}
                      </option>
                    ))}
                    {/* <option value={form.applicantId}>{form.applicantId}</option> */}
                  </Select>
                </FormControl>
              </>
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm} size="small">
        {confirmLabel}
      </Button>
      <Button size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Box>
  );
};
