import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Typography } from "@mui/material";

interface DeleteCasesModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  amount: number;
}

export const DeleteCasesModal = ({ isOpen, onConfirm, onCancel, isLoading, amount }: DeleteCasesModalProps) => {
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title={amount === 1 ? "Excluir caso?" : "Excluir casos?"}
      description={
        isLoading ? (
          <Box display="flex" color="secondary" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: "text.primary" }}>
            Tem certeza que deseja excluir {amount === 1 ? "o caso selecionado?" : "todos os casos selecionados?"}
          </Typography>
        )
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Voltar",
          onClick: isLoading ? undefined : onCancel,
          sx: {
            color: "text.primary",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          label: "Excluir",
          onClick: onConfirm,
          color: "error",
          disabled: isLoading,
        },
      ]}
      variant="error"
    />
  );
};
