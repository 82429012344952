import { TokenType } from "./types";
import { env } from "@/constants/environment";
import { getFromStorages, removeFromStorages, setInStorages } from "@/utils/storage";

const getTokenStorageKey = (tokenType: TokenType) => `token_${tokenType}_${env.ENV}`;

export const StorageTokenService = {
  getToken(tokenType: TokenType): string | null {
    return getFromStorages(getTokenStorageKey(tokenType));
  },

  saveToken(token: string, tokenType: TokenType) {
    setInStorages(getTokenStorageKey(tokenType), token);
    // Save the last login timestamp in a cookie to admin use
    setInStorages(`lastLoginTimestamp_${env.ENV}`, `${Date.now()}`);
  },

  removeToken(tokenType: TokenType) {
    removeFromStorages(getTokenStorageKey(tokenType));
    removeFromStorages(`lastLoginTimestamp_${env.ENV}`);
  },
};
