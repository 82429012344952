import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { lexZapUsersQueryKey } from "../useLexZapUsers";

export const useDeleteLexZapUser = () => {
  const { deleteLexZapUser } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteLexZapUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: lexZapUsersQueryKey });
    },
  });
};
