import { buildSkillStep } from "@/core/skillForms/builder";

export const settlementOfferSkillStep1 = buildSkillStep({
  description: "Utilize a sua peça inaugural para o Assistente Lexter elaborar uma proposta de acordo.",
  fields: [
    {
      name: "reference_piece",
      type: "file",
      ui: {
        label: "Inserir peça inaugural (petição inicial, reclamação trabalhista):",
      },
    },
  ],
});
