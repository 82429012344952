import { DateTime } from "luxon";

interface DayFromTimestampOptions {
  displayTodayAsTime?: boolean;
}

export const dayFromTimestamp = (timestamp: number, options?: DayFromTimestampOptions): string => {
  const { displayTodayAsTime } = options || {};

  const messageDate = DateTime.fromMillis(timestamp);
  const now = DateTime.now().startOf("day");
  const diffInDays = now.diff(messageDate.startOf("day"), "days").days;

  if (diffInDays === 0) {
    if (displayTodayAsTime) return messageDate.toFormat("HH:mm");
    return "Hoje";
  }
  if (diffInDays === 1) return "Ontem";
  if (diffInDays < 7) {
    return messageDate.toFormat("cccc");
  }

  return messageDate.toFormat("dd/MM/yyyy");
};
