import { useEffect } from "react";

interface UseLoadingHandlerProps {
  loading: boolean;
}

export const useLoadingHandler = ({ loading }: UseLoadingHandlerProps) => {
  useEffect(() => {
    const loadingElement = document.getElementById("app-loading");
    if (!loadingElement) return;

    if (loading) {
      loadingElement.classList.remove("hidden");
    } else {
      loadingElement.classList.add("hidden");
    }
  }, [loading]);
};
