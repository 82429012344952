import { Box, Skeleton } from "@mui/material";
import React from "react";

interface MessageCardSkeletonProps {
  fromMe: boolean;
}
export const MessageCardSkeleton = ({ fromMe }: MessageCardSkeletonProps) => {
  return (
    <Box
      sx={{
        borderRadius: "16px",
        borderBottomRightRadius: fromMe ? 0 : "16px",
        backgroundColor: fromMe ? "common.lightWarm" : "common.white",
        border: fromMe ? undefined : "1px solid",
        borderColor: fromMe ? undefined : "common.lightWarm",
        maxWidth: "65%",
        paddingX: 3,
        paddingY: 2,
      }}
    >
      <Skeleton
        variant="text"
        width={Math.floor(50 + Math.random() * 150)}
        sx={{
          maxWidth: "100%",
        }}
      />
    </Box>
  );
};
