import { ActionId, LegalPieceMacroType, LegalPieceMicroType } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  client: Type.String(),
  area: Type.String(),
  case_breakdown: Type.String(),
  theses: Type.Optional(Type.String()),
  evidence_text: Type.Optional(Type.String()),
  requests: Type.Optional(Type.String()),
});

const actionId = ActionId.CREATE_ONE_PIECE;

export const createInitialPetitionSkill = {
  id: "create_initial_petition",
  name: "Escrever petição inicial",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  tags: ["litigation"],
  actionId,
  type: "message",
  description: "Elabore petições iniciais de casos.",
  getMessage: (input) => {
    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar Peça Inaugural",
      actions: [
        {
          id: actionId,
          text: "Criar Peça Inaugural",
          client: input.client,
          legal_piece_macro_type: LegalPieceMacroType.INAUGURAL_DOCUMENT,
          legal_piece_micro_type: LegalPieceMicroType.INITIAL,
          area: input.area,
          case_breakdown: {
            source: "TEXT",
            text: input.case_breakdown,
          },
          theses: input.theses,
          evidence_text: input.evidence_text,
          requests: input.requests,
          reference_piece: null,
        },
      ],
      files: [],
    };
  },
} satisfies Skill<typeof inputSchema>;
