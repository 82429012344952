import { useCallback } from "react";
import { Config } from "@/config/Config";

export const useLinkedinAds = () => {
  const config = Config.getConfig();

  const loadLinkedinTag = useCallback(() => {
    if (config.isProd) {
      const linkedinScript = document.createElement("script");
      linkedinScript.innerHTML = `
        _linkedin_partner_id = "7484353"; 
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);
        })(window.lintrk);
      `;
      document.head.appendChild(linkedinScript);
    }
  }, []);

  return { loadLinkedinTag };
};
