import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const useRecord = ({ recordId }: { recordId?: string }) => {
  const api = useApi();
  return useQuery({
    queryKey: ["getRecord"],
    queryFn: async () => {
      return await api.getDocumentById(Number(recordId));
    },
    enabled: !!recordId,
  });
};
