import { DecisionType, LegalPieceMacroType } from "@/contexts/MessagesContext";
import { buildSkillStep, createUploadFileField } from "@/core/skillForms/builder";

export const createOtherTypeOfPieceSkillStep2Appeal = buildSkillStep({
  fields: [
    createUploadFileField({
      name: "reference_piece",
      ui: {
        label: "Anexar documento",
        description:
          "Para criar um recurso é necessário anexar a decisão/sentença. Por favor, siga as instruções abaixo:",
      },
    }),
    {
      name: "decision_type",
      type: "string",
      options: [
        {
          label: "Terminativa",
          value: DecisionType.TERMINATIVE_DECISIONS,
        },
        {
          label: "Interlocutória/Monocrática",
          value: DecisionType.INTERLOCUTORY_DECISIONS,
        },
      ],
      initialValue: DecisionType.TERMINATIVE_DECISIONS,
      ui: {
        label: "Informe qual foi o tipo de decisão tomada:",
        widget: "radio",
      },
    },
  ],
  condition: {
    value: LegalPieceMacroType.APPEAL,
    field: "legal_piece_macro_type",
  },
  title: "Anexo",
});
