import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const getApplicantsQueryKey = () => ["applicants"];

export const useApplicants = () => {
  const api = useApi();
  const { user } = useAuthContext();

  return useQuery({
    queryKey: getApplicantsQueryKey(),
    queryFn: async () => {
      return await api.getApplicants();
    },
    enabled: !!user?.userId,
  });
};
