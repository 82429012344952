import React from "react";

import { Box, Button, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import UseWordIllustration from "@/assets/svgs/use-word-illustration.svg?react";
import { PageLayout } from "@/components/PageLayout";

import { openUrl } from "@/utils/openUrl";

const WORD_WEB_LINK =
  "https://office.live.com/start/Word.aspx?culture=pt-BR&omextemplateclient=Word&omexse[%E2%80%A6]&templateid=WA200006081&templatetitle=Lexter.ai&omexsrctype=1";
const WORD_DESKTOP_LINK =
  "ms-word:https://api.addins.store.office.com/addinstemplate/pt-BR/6f297af5-fdd4-430f-842b-081e05ce05b5/WA200006081/none/Lexter-ai.docx?omexsrctype=1";

export const UseWord = () => {
  return (
    <PageLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 6,
        }}
      >
        <Typography fontWeight="700" variant="subtitle">
          Utilize o Assistente Lexter no Microsoft Word
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 6,
          }}
        >
          <UseWordIllustration />
          <Box>
            <Typography>
              Utilize o Assistente Lexter com sua inteligência artificial integrado ao Microsoft Word, o editor de texto
              mais utilizado pelos advogados.
            </Typography>
            <Typography sx={{ alignSelf: "start" }} variant="body1">
              Aproveite recursos como:
            </Typography>
            <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem", paddingTop: 0 }}>
              <ListItem sx={{ display: "list-item", padding: 0 }}>
                <ListItemText primary="Visualização e edição de textos como qualquer outro documento gerado no editor;" />
              </ListItem>
              <ListItem sx={{ display: "list-item", padding: 0 }}>
                <ListItemText primary="Utilize das opções avançadas de formatação disponíveis no editor;" />
              </ListItem>
              <ListItem sx={{ display: "list-item", padding: 0 }}>
                <ListItemText primary="Mantenha a formatação utilizada pelo seu escritório." />
              </ListItem>
            </List>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, padding: "10px 30px" }}>
            <Button variant="contained" onClick={() => openUrl(WORD_DESKTOP_LINK)} sx={{ px: 10 }} fullWidth>
              Instalar no Microsoft Word
            </Button>
            <Link
              sx={{
                "&:hover": {
                  fontWeight: "regular",
                },
              }}
              target="_blank"
              href={WORD_WEB_LINK}
            >
              <Typography
                variant="link"
                sx={{
                  "&:hover": {
                    fontWeight: "regular",
                  },
                }}
              >
                Instalar no Microsoft Word online
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
};
