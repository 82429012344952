import { alpha, Box, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { formatChatMessage } from "format-chat-message";
import parseStringToHtml from "html-react-parser";
import { CaseMessageType, CaseWithChat } from "@/hooks/lexZap/types";
import { dayFromTimestamp } from "../../../../utils/dayFromTimestamp";
import { MessageStatus } from "@/components/LexZap/MessageStatus";

interface ChatItemProps {
  isActive: boolean;
  onClick?: () => void;
  caseData: CaseWithChat;
}

const CaseStepSelector = {
  SUMMARY_GENERATED: {
    title: "Caso gerado",
    textColor: "common.white",
    bgColor: "success.light",
  },
  CASE_STARTED: {
    title: "Coletando informações",
    textColor: "common.black",
    bgColor: "#D1CECD",
  },
  AWAITING_APPLICANT_APPROVAL: {
    title: "Aguardando aprovação",
    textColor: "common.white",
    bgColor: "#B25232",
  },
  CASE_FAILED: {
    title: "Redirecionado",
    textColor: "common.black",
    bgColor: "warning.light",
  },
  LAWYER_INVOKED_BY_APPLICANT: {
    title: "Redirecionado",
    textColor: "common.black",
    bgColor: "warning.light",
  },
};

export const ChatItem = ({ isActive, caseData, onClick }: ChatItemProps) => {
  const theme = useTheme();
  const bgColor = theme.palette.orange?.light[30];

  const lastMessageBody = useMemo(() => {
    switch (caseData.lastMessage.type) {
      case CaseMessageType.AUDIO:
        return "Áudio";
      case CaseMessageType.IMAGE:
        return "Foto";
      case CaseMessageType.DOCUMENT:
        return "Documento";
      case CaseMessageType.TEXT:
        return caseData.lastMessage.body;
      default:
        return caseData.lastMessage.type;
    }
  }, [caseData.lastMessage]);

  const { title: tagTitle, bgColor: tagColor, textColor: tagTextColor } = CaseStepSelector[caseData.step];

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
        gap: 1,
        backgroundColor: isActive ? bgColor : undefined,
        paddingX: 1.5,
        paddingY: 1,
        borderRadius: "8px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: bgColor ? alpha(bgColor, 0.4) : undefined,
        },
        "&:active": {
          backgroundColor: bgColor ? alpha(bgColor, 0.7) : undefined,
        },
      }}
    >
      <Box />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="body"
            color="#09132C"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {caseData.applicantName}
          </Typography>
          <Box
            sx={{
              backgroundColor: tagColor,
              paddingX: 1,
              paddingY: 0.25,
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="body"
              color={tagTextColor}
              sx={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              {tagTitle}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <MessageStatus message={caseData.lastMessage} />

          <Typography variant="body">
            {parseStringToHtml(
              formatChatMessage({
                message: lastMessageBody,
                shouldFormat: {
                  breakline: false,
                  url: false,
                  phone: false,
                },
              })
            )}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography variant="body">
          {dayFromTimestamp(caseData.lastMessage.timestamp, { displayTodayAsTime: true })}
        </Typography>
      </Box>
    </Box>
  );
};
