import React from "react";
import { SxProps, Box, Typography } from "@mui/material";

interface BaseFieldProps {
  label: string;
  children: React.ReactNode;
  sx?: SxProps;
  errorMessage?: string | null;
  withRightIcon?: boolean;
}

export const BaseField = ({ label, children, sx, errorMessage, withRightIcon }: BaseFieldProps) => {
  return (
    <Box
      sx={{
        my: 0.5,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        ".StripeElement--focus": {
          outline: "#FF7548",
          outlineStyle: "auto",
        },
        ".credit-card-field-stripe": {
          border: "1px solid black",
          borderRadius: "4px",
          pl: 1.5,
          py: 2,
          borderColor: !!errorMessage ? "error.dark" : "common.lightShade",
          fontSize: "12px",
          fontWeight: "700",
          textTransform: "uppercase",
          "&:hover": {
            borderColor: "common.mediumShade",
          },
        },
        label: {
          fontSize: "12px",
          color: !!errorMessage ? "error.dark" : "text.primary",
          fontFamily: "lato,kommon Grotesk Extended,sans-serif",
          position: "relative",
          backgroundColor: "white",
          top: "10px",
          left: "10px",
          width: "fit-content",
          px: "4px",
        },
        span: {
          position: "relative",
          alignSelf: "end",
          left: "-10px",
          top: "-26px",
          display: "flex",
          gap: "4px",
        },
        ...sx,
      }}
    >
      <label id="label-from-credit-card-stripe">{label}</label>
      {children}
      <Typography
        sx={{
          position: "relative",
          top: withRightIcon ? "-16px" : 0,
        }}
        mt={0.25}
        color="error.dark"
        component="div"
        variant="body2"
      >
        {errorMessage}
      </Typography>
    </Box>
  );
};

export const fieldOption = {
  style: {
    base: {
      color: "text.primary",
      "::placeholder": {
        color: "#1E181861",
      },
    },
  },
};
