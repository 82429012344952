/* eslint-disable */
import React from "react";
import toast, { ToastPosition } from "react-hot-toast";

export class Toast {
  static error(msg: string, duration?: number, position?: ToastPosition) {
    toast.error(msg, { duration, position });
  }

  static success(msg: string, duration?: number, position?: ToastPosition) {
    toast.success(msg, { duration, position });
  }

  static warn(msg: string, duration?: number) {
    toast(msg, { duration });
  }
}

export class WebToast {
  static error(msg: string, duration?: number) {
    toast.error(msg, { duration, position: "top-center" });
  }

  static success(msg: string, duration?: number) {
    toast.success(msg, { duration, position: "top-center" });
  }

  static warn(msg: string, duration?: number) {
    toast(msg, { duration, position: "top-center" });
  }
}
