import { SkillForm } from "@/core/skillForms/types";
import { createContractSkillStep1 } from "./step1";
import { createContractSkillStep2 } from "./step2";
import { createContractSkillStep3 } from "./step3";

export const createContractSkillForm: SkillForm = {
  skillId: "create_contract",
  title: "Elaborar contrato - Passo 1",
  steps: [createContractSkillStep1, createContractSkillStep2, createContractSkillStep3],
};
