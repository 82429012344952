export interface Editor {
  /** Obtém o texto atualmente selecionado no editor. */
  getSelection: () => Promise<string>;
  /** Obtém o texto atualmente selecionado no editor com a formatação HTML. */
  getHtmlSelection: () => Promise<string>;
  /** Deseleciona o texto atualmente selecionado no editor. */
  clearSelection: () => Promise<void>;
  /** Obtém todo o texto do editor. */
  getBody: () => Promise<string>;
  /** Obtém o conteúdo do editor em HTML. */
  getContent: () => Promise<string>;
  /** Abre o texto formatado fornecido. */
  openFormattedText: (params: OpenFormattedDocumentParams) => Promise<PossibleErrorResponse>;
  /** Substitui todo o conteúdo do editor pelo texto fornecido. */
  replaceBody: (text: string) => Promise<PossibleErrorResponse>;
  /** Substitui o texto atualmente selecionado pelo texto fornecido. */
  replaceSelection: (text: string) => Promise<PossibleErrorResponse>;
  /** Substitui o texto atualmente selecionado pelo texto fornecido, mantendo a formatação HTML. */
  replaceHtmlSelection: (text: string) => Promise<PossibleErrorResponse>;
  /** Checa se o documento aberto está no modo read-only */
  checkIfDocumentIsReadOnly: () => Promise<boolean>;
  /** Adiciona um ouvinte de eventos ao editor. */
  addEventListener: (event: EditorEvent, listener: () => void) => void;
  /** Exporta o documento atual para um arquivo .docx */
  exportToDocx: () => Promise<void>;
  /** Insere parágrafos após a seleção atual. */
  insertParagraphs: (paragraphs: Paragraph[]) => Promise<void>;
}

export enum EditorEvent {
  SELECTION_CHANGE,
}

interface PossibleErrorResponse {
  errorMessage?: string;
}

export interface OpenFormattedDocumentParams {
  document: {
    id?: number;
    name?: string;
    formattedText: FormattedText;
  };
  wordOptions?: {
    /** Define se o documento deve ser aberto em uma nova janela.
     * Se `false`, o documento aberto será substituido.
     * @default false
     */
    openInNewWindow?: boolean;
  };
}

export interface FormattedText {
  lines: Line[];
  stylesMap: StylesMap;
}

export interface StylesMap {
  [key: string]: Style;
}

export enum StyleAlignment {
  CENTER = "CENTER",
  JUSTIFY = "JUSTIFY",
}

export interface Style {
  type: string;
  alignment: StyleAlignment;
  font_size: number;
  font_name: string;
  bold?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  lines_after?: number;
  outlineNumbered?: boolean;
}

export interface Line {
  type: string;
  text?: string;
  listId?: string;
}

export type ParagraphStyle = {
  leftIndent?: number;
  italic?: boolean;
  alignment?: "justify";
};

export type Paragraph = {
  text: string;
  style?: ParagraphStyle;
};
