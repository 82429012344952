import { Box, Button, Checkbox, Typography } from "@mui/material";
import React from "react";

interface CaseStepProps {
  checked?: boolean;
  skill?: boolean;
  description?: string;
}

export const CaseStep = ({ checked, description, skill }: CaseStepProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottomColor: "common.lightShade",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        paddingY: 1.5,
        justifyContent: "space-between",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Checkbox
          checked={checked}
          sx={{
            p: 0,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            textDecoration: checked ? "line-through" : "none",
          }}
        >
          {description}
        </Typography>
      </Box>

      <CaseStepAction checked={checked} skill={skill} />
    </Box>
  );
};

const CaseStepAction = ({ checked, skill }: CaseStepProps) => {
  if (!checked && !skill) return null;

  return (
    <Box>
      {checked ? (
        <Button variant="text">Acessar</Button>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          Gerar contrato
        </Button>
      )}
    </Box>
  );
};
