import { Box, Typography } from "@mui/material";
import { MultiSelectChip, MultiSelectChipItem } from "../../MultiSelectChip";
import React from "react";

interface UserSourceOfReferralSelectProps {
  sourceOfReferral: MultiSelectChipItem[];
  onSourceOfReferralChange: (options: MultiSelectChipItem[]) => void;
  show: boolean;
}

export const UserSourceOfReferralSelect = ({
  sourceOfReferral,
  onSourceOfReferralChange,
  show,
}: UserSourceOfReferralSelectProps) => {
  if (!show) return null;

  return (
    <Box>
      <Typography sx={{ color: "common.shade", mb: 2, fontWeight: "700" }} variant="subtitle2">
        Como ficou sabendo da Lexter?
      </Typography>
      <MultiSelectChip max={1} options={sourceOfReferral} onMultiSelectChipChange={onSourceOfReferralChange} />
    </Box>
  );
};
