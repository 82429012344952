import { FavoriteSkill } from "@/hooks/skills/types";
import { FAVORITE_SKILLS_QUERY_KEY } from "@/hooks/skills/useFavoriteSkills";
import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

export const useAddFavoriteSkill = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["addFavoriteSkill"],
    mutationFn: async ({ skillId }: { skillId: string }) => api.addFavoriteSkill({ skillId }),
    onMutate: async ({ skillId }) => {
      await queryClient.cancelQueries({ queryKey: [FAVORITE_SKILLS_QUERY_KEY] });
      const previousFavoriteSkills = queryClient.getQueryData<string[]>([FAVORITE_SKILLS_QUERY_KEY]);
      queryClient.setQueryData([FAVORITE_SKILLS_QUERY_KEY], (old) => [
        ...(old as FavoriteSkill[]),
        {
          skillId,
          createdAt: DateTime.now().toJSDate(),
        },
      ]);
      return { previousFavoriteSkills };
    },
    onError: (_error, _newFavoriteSkill, context) => {
      queryClient.setQueryData([FAVORITE_SKILLS_QUERY_KEY], context?.previousFavoriteSkills);
    },
  });
};
