import { z } from "zod";

export const basicInfoSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." }),
  email: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." })
    .email({ message: "E-mail inválido." }),
  phone: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .regex(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[0-9])[0-9]{3}-?[0-9]{4}$/, {
      message: "Telefone inválido",
    }),
});

export type BasicInfoFormData = z.infer<typeof basicInfoSchema>;
