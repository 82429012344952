import { LoggedInUserData } from "@/contexts/AuthContext";
import { env } from "@/constants/environment";
import { logger } from "@/core/logger";

export const useUserGuiding = () => {
  const initialize = (user?: LoggedInUserData) => {
    try {
      if ((env.IS_PRODUCTION && user) || (env.IS_STAGING && user)) {
        const userGuidingScript = document.createElement("script");
        userGuidingScript.innerHTML = `
            (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.id="user_guiding_lexter";k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','1Z280522Q94ID');
            `;
        document.head.appendChild(userGuidingScript);
        identify(user);
      }
    } catch (error) {
      logger.error(`Error initializing UserGuiding: ${JSON.stringify(error)}`);
    }
  };

  const remove = () => {
    const userGuidingScript = document.getElementById("user_guiding_lexter");
    if (userGuidingScript) {
      window.userGuiding = undefined;
      userGuidingScript.remove();
    }
  };

  const identify = (user?: LoggedInUserData, creditData?: { credits: number; plan?: string }) => {
    if (window.userGuiding && user) {
      const createDate = new Date(parseInt(user.userId.substring(0, 8), 16) * 1000);
      const createDateFormatted = createDate.toISOString().split("T")[0].replace(/-/g, "/");
      window.userGuiding.identify(user.userId, {
        email: user.userEmail,
        name: user.userName,
        ...creditData,
        created_at: createDateFormatted,
      });
    }
  };

  return {
    initialize,
    identify,
    remove,
  };
};
