import { useState, useEffect } from "react";

export const useDocumentOnboarding = () => {
  const [isOnboarding, setIsOnboarding] = useState(localStorage.getItem("isOnboarding") === "true");

  useEffect(() => {
    const handleStorageChange = (event: Event) => {
      const storageEvent = event as StorageEvent;
      if (storageEvent.key === "isOnboarding" || event.type === "localStorageUpdate") {
        setIsOnboarding(localStorage.getItem("isOnboarding") === "true");
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageUpdate", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageUpdate", handleStorageChange);
    };
  }, []);

  const closeOnboarding = () => {
    localStorage.setItem("isOnboarding", "false");
    window.dispatchEvent(new Event("localStorageUpdate"));
  };

  return {
    isOnboarding,
    closeOnboarding,
  };
};
