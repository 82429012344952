import { SkillGroups } from "@/core/skillGroups";
import { SkillGroupId } from "@/core/skillGroups/types";
import { useQuery } from "@tanstack/react-query";

export const useSkillGroup = (skillGroupId?: string) => {
  return useQuery({
    queryKey: ["skillGroup", skillGroupId],
    queryFn: async () => {
      return await SkillGroups.getSkillGroup(skillGroupId as SkillGroupId);
    },
  });
};
