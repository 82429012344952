import { Box, Typography } from "@mui/material";
import { MultiSelectChip, MultiSelectChipItem } from "../../MultiSelectChip";
import React from "react";

interface UserActivityDailySelectProps {
  activityDaily: MultiSelectChipItem[];
  onActivityDailyChange: (options: MultiSelectChipItem[]) => void;
  show: boolean;
}

export const UserActivityDailySelect = ({
  activityDaily,
  onActivityDailyChange,
  show,
}: UserActivityDailySelectProps) => {
  if (!show) return null;

  return (
    <Box>
      <Typography sx={{ color: "common.shade", mb: 2, fontWeight: "700" }} variant="subtitle2">
        Quais as principais atividades que você realiza no seu dia a dia? Selecione até 5 opções da lista abaixo:
      </Typography>
      <MultiSelectChip max={5} options={activityDaily} onMultiSelectChipChange={onActivityDailyChange} />
    </Box>
  );
};
