import { buildSkillStep, createUploadFileField } from "../../builder";

export const createNoticeSkillStep2 = buildSkillStep({
  title: "Anexo",
  description: `Atenção: Quanto mais detalhadas as informações inseridas, maior será a assertividade do Assistente Lexter.`,
  fields: [
    {
      name: "reference_piece",
      type: "string",
      required: false,
      options: [
        {
          label: "Upload de arquivo (DOCX ou PDF)",
          value: "upload_file",
        },
        {
          label: "Não quero adicionar fatos",
          value: null,
        },
      ],
      initialValue: "upload_file",
      ui: {
        label: "Inserir um contrato ou outro documento (Opcional):",
        widget: "radio",
      },
    },
    createUploadFileField({
      name: "reference_piece_file",
      overrideField: "reference_piece",
      condition: {
        field: "reference_piece",
        value: "upload_file",
      },
    }),
  ],
  submitButtonText: "Gerar Notificação",
});
