import { Add as AddIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import { Tab as TabComponent } from "../Tab";

interface Tab {
  id: string;
  label: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onNewTab: () => void;
  onTabChange: (tabId: string) => void;
  onTabClose: (tabId: string) => void;
  onTabsReorder: (tabs: Tab[]) => void;
  loadingSaveTabs: string[];
}

export const Tabs = ({
  tabs,
  activeTab,
  onNewTab,
  onTabChange,
  onTabClose,
  onTabsReorder,
  loadingSaveTabs,
}: TabsProps) => {
  const [draggingTabId, setDraggingTabId] = useState<string>();

  const onDragStart = (start: DragStart) => {
    setDraggingTabId(start.draggableId);
  };

  const onDragEnd = (result: DropResult) => {
    setDraggingTabId(undefined);
    if (!result.destination) {
      return;
    }

    const newTabs = Array.from(tabs);
    const [movedTab] = newTabs.splice(result.source.index, 1);
    newTabs.splice(result.destination.index, 0, movedTab);

    onTabsReorder(newTabs);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="tabs" direction="horizontal">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: "flex",
              backgroundColor: "common.dorian",
              paddingTop: 0.5,
              paddingLeft: 0.5,
              paddingRight: 2,
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "grid",
                width: "100%",
                height: "100%",
                maxWidth: `calc(${tabs.length} * 170px)`,
                gridTemplateColumns: `repeat(${tabs.length}, minmax(0, 1fr))`,
              }}
            >
              {tabs.map((tab, index) => (
                <Draggable key={tab.id} draggableId={tab.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        cursor: "default !important",
                      }}
                    >
                      <TabComponent
                        active={activeTab === tab.id}
                        onClose={() => onTabClose(tab.id)}
                        onMouseDown={() => onTabChange(tab.id)}
                        label={tab.label}
                        dragging={draggingTabId === tab.id}
                        loadingSave={loadingSaveTabs.includes(tab.id)}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>

            <AddIcon
              onClick={onNewTab}
              sx={{
                fontSize: "1rem",
                cursor: "pointer",
                color: "common.shade",
              }}
            />
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
