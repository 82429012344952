import { SkillGroups } from "@/core/skillGroups";
import { useQuery } from "@tanstack/react-query";

export const useSkillGroups = () => {
  return useQuery({
    queryKey: ["skillGroups"],
    queryFn: async () => {
      return await SkillGroups.getSkillGroups();
    },
  });
};
