import "firebase/compat/auth";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as logger from "../../../core/logger";

import { SignUpSource, SignupPayload } from "@/hooks/useApi/types";
import { useGtag } from "@/hooks/useGtag";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { isWordWeb } from "@/taskpane/config";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { SignUpContext } from "./context";
import { SignUpData, SignUpSteps } from "./types";
import { getGclid } from "@/utils/getGclid";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getParam } from "@/utils/getParams";

interface SignUpProviderProps {
  children: React.ReactNode;
}

export const SignUpProvider = ({ children }: SignUpProviderProps) => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const steps = [SignUpSteps.BASIC_INFO, SignUpSteps.PASSWORD];
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [signUpData, setSignUpData] = useState<SignUpData>({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { sendEvent } = useGtag();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const redirectTo = searchParams.get("redirectTo");

  const nextStep = () => {
    setCurrentStep((prev) => {
      const nextStepIndex = steps.indexOf(prev) + 1;
      if (nextStepIndex === steps.length) return prev;
      return steps[nextStepIndex];
    });
  };

  const previousStep = () => {
    setCurrentStep((prev) => {
      const previousStepIndex = steps.indexOf(prev) - 1;
      if (previousStepIndex < 0) return prev;
      return steps[previousStepIndex];
    });
  };

  const changeStep = (step: number) => {
    setCurrentStep(steps[step]);
  };

  const setData = (data: SignUpData) => {
    setSignUpData((prev) => ({ ...prev, ...data }));
  };

  const submitData = async (data: SignUpData) => {
    setIsLoading(true);

    sendEvent("conversion", "AW-16462471594/lj4nCP6Ip5UZEKrD9ak9");
    const fullData = { ...signUpData, ...data };

    let source: SignUpSource;
    if (outsideOfficeClient()) {
      source = SignUpSource.COPILOT_WEB;
    } else if (isWordWeb()) {
      source = SignUpSource.COPILOT_WORD_WEB;
    } else {
      source = SignUpSource.COPILOT_WORD;
    }

    const payload = {
      ...fullData,
      signUpUtm: getSignUpUtm(),
      referralCode: getParam("referralCode"),
      gclid: getGclid(),
      source,
      recaptchaToken: await executeRecaptcha!(),
    } as SignupPayload;

    try {
      logger.debug(`Submitting sign up to API with email: ${fullData.email}, url: ${window.location.href}`);
      await LexterCopilotAuthService.signup(payload);
      logger.debug("Successfully submitted sign up to API");
      return navigate(ROUTE_PATHS.ACTIVATE_ACCOUNT, {
        state: { ...state, email: payload.email, password: payload.password },
        replace: true,
      });
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`Failed to submit sign up to API: ${e.message || "no message"}`);
      }
      return navigate(ROUTE_PATHS.SIGNUP_FAILURE);
    }
  };

  const getSignUpUtm = () => {
    const signUpUtm: Record<string, string> = {
      source: "word-addin",
      campaign: "organic",
    };

    let url = window.location.href;
    if (Office.context.document?.url) {
      url = Office.context.document.url;
    }

    const documentUrl = new URL(url);
    const searchParams = documentUrl.searchParams;

    searchParams.forEach((value, key) => {
      if (key.startsWith("utm_") && key.length > 4) {
        const cleanedKey = key.substring(4);
        signUpUtm[cleanedKey] = value;
      }
    });

    return signUpUtm;
  };

  useEffect(() => {
    if (redirectTo) {
      navigate("", {
        replace: true,
        state: {
          ...state,
          redirectTo,
        },
      });
    }
  }, [state, navigate, redirectTo]);

  return (
    <SignUpContext.Provider
      value={{
        steps,
        currentStep,
        nextStep,
        previousStep,
        changeStep,
        data: signUpData,
        setData,
        submitData,
        isLoading,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
