import { useRecords } from "@/hooks/records/useRecords";
import { useLexZapMessagesMedia } from "../useLexZapMessagesMedia";
import { CaseFileType } from "../types";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { formatDateToISO } from "@/utils/formatDateToISO";

interface UseLexZapMessagesProps {
  threadId?: string;
  chatId?: string;
  search?: string;
}
export const useCaseFiles = ({ chatId, threadId, search = "" }: UseLexZapMessagesProps) => {
  const {
    data: allRecords,
    isLoading: isLoadingRecords,
    isError: isErrorRecords,
  } = useRecords({
    threadId,
    order: "CREATED_AT",
  });

  const {
    data: messagesMedia,
    isLoading: isLoadingMessagesMedia,
    isError: isErrorMedia,
  } = useLexZapMessagesMedia({
    chatId: chatId,
  });

  const files: CaseFileType[] = useMemo(() => {
    return messagesMedia
      ? messagesMedia.map((message) => {
          return {
            name: message.media.filename,
            createdAt: message.timestamp,
            id: message.id,
            url: message.media.url,
            mimeType: message.media.mimeType,
            type: "MEDIA" as const,
          };
        })
      : [];
  }, [messagesMedia]);

  const records: CaseFileType[] = useMemo(() => {
    return allRecords
      ? allRecords.data.map((record) => ({
          name: record.name,
          createdAt: DateTime.fromISO(formatDateToISO(record.createdAt)).toMillis(),
          id: record.id,
          recordType: record.type,
          type: "RECORD",
        }))
      : [];
  }, [allRecords]);

  const caseFiles = useMemo(() => {
    return (
      [...records, ...files]
        .sort((a, b) => {
          return b.createdAt - a.createdAt;
        })
        .filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) || []
    );
  }, [records, files, search]);

  return {
    data: caseFiles,
    isLoading: isLoadingRecords || isLoadingMessagesMedia,
    isError: isErrorRecords || isErrorMedia,
  };
};
