import React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { transformToBRL } from "@/utils/transformToBRL";
import { PlanType } from "@/hooks/credits/types";
import { useNewPlans } from "@/hooks/useNewPlans";

export const SelectPlan = () => {
  const { selectedPlan, onPlanChange, loading, success } = usePaymentContext();
  const plans = useNewPlans().filter((plan) => !plan.buttonRedirectTo);

  const handleSelectPlan = (event: SelectChangeEvent<PlanType>) => {
    const planOrNull = plans.find((plan) => plan.id === event.target.value);
    if (planOrNull) onPlanChange(planOrNull);
  };

  return (
    <FormControl fullWidth>
      <InputLabel sx={{ fontSize: "14px" }} id="label-select-plan">
        Selecione um plano
      </InputLabel>
      <Select
        labelId="label-select-plan"
        sx={{
          "& .MuiSelect-select": {
            span: {
              display: "none",
            },
            fontSize: "14px",
          },
          "& .MuiSelect-select:focus": {
            bgcolor: "transparent",
          },
          ".MuiTypography-body1": { fontSize: "14px" },
          mb: 2,
          bgColor: "none",
        }}
        value={selectedPlan.id}
        defaultValue={selectedPlan.id}
        onChange={handleSelectPlan}
        disabled={loading || success}
        label="Selecione um plano"
      >
        {plans.map((item) => (
          <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} key={item.id} value={item.id}>
            <Typography sx={{ minWidth: "200px", color: "common.black", fontSize: "14px" }}>{item.title}</Typography>
            <Typography
              sx={{
                minWidth: "80px",
                textAlign: "end",
              }}
              component="span"
            >
              {transformToBRL(item.price)}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
