import React, { useEffect, useState } from "react";

export const useElementWidth = (ref: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    let debounceTimer: NodeJS.Timeout;

    const handleResize = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        const newWidth = element.offsetWidth;
        if (newWidth !== width) {
          setWidth(newWidth);
        }
      }, 0);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(element);

    handleResize();

    return () => {
      clearTimeout(debounceTimer);
      resizeObserver.disconnect();
    };
  }, [ref, width]);

  return width;
};
