import { env } from "@/constants/environment";
import { useAuthContext } from "@/contexts/AuthContext";
import React, { useEffect, useRef } from "react";

interface StripeBuyButtonProps {
  buyButtonId: string;
}

export const StripeBuyButton = ({ buyButtonId }: StripeBuyButtonProps) => {
  const { user } = useAuthContext();
  const stripeBuyButtonRef = useRef<HTMLElement>(null);

  useEffect(() => {
    stripeBuyButtonRef.current?.shadowRoot?.querySelector("iframe")?.style.setProperty("width", "100%");
  }, [buyButtonId]);

  return (
    <stripe-buy-button
      key={buyButtonId}
      ref={stripeBuyButtonRef}
      buy-button-id={buyButtonId}
      customer-email={user?.userEmail}
      publishable-key={env.STRIPE.PUBLISHABLE_KEY}
    ></stripe-buy-button>
  );
};
