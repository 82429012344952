import { Skill } from "@/core/skills/types";
import { createAppealSkill } from "@/core/skills/implementations";
import { Area, DecisionType, LegalPieceMicroType } from "@/contexts/MessagesContext";

export const createOrdinaryAppealSkill: Skill<typeof createAppealSkill.inputSchema> = {
  ...createAppealSkill,
  id: "create_ordinary_appeal",
  name: "Escrever recurso ordinário trabalhista",
  initialValue: {
    area: Area.LABOR,
    document_name: LegalPieceMicroType.ORDINARY_APPEAL,
    decision_type: DecisionType.TERMINATIVE_DECISIONS,
  },
};
