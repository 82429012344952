import { Box, Typography } from "@mui/material";
import { ConfigMenu } from "@/components/ConfigMenu";
import { useAuthContext } from "@/contexts/AuthContext";
import React, { useState } from "react";
import { getInitials } from "@/utils/getInitials";

interface AvatarProps {
  onLogout?: () => void;
}

export const Avatar = ({ onLogout }: AvatarProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useAuthContext();
  const avatarUrl = "";

  const handleClick = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "2rem",
        height: "2rem",
        borderRadius: "5px",
        backgroundColor: "secondary.main",
        color: "secondary.contrastText",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      {avatarUrl ? (
        <Box
          component={"img"}
          alt="Avatar"
          src={avatarUrl}
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "5px",
            objectFit: "cover",
          }}
        />
      ) : (
        <Typography variant="body1" textAlign="center" fontWeight="700">
          {getInitials(user?.userName).toUpperCase()}
        </Typography>
      )}

      <ConfigMenu
        open={menuOpen}
        onLogout={onLogout}
        backdrop={false}
        onClose={() => setMenuOpen(false)}
        sx={{
          bottom: 0,
          left: "calc(100% + 18px)",
        }}
      />
    </Box>
  );
};
