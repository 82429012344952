import { Box, Divider } from "@mui/material";
import React from "react";

interface ThreadsScreenProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactElement;
  header: React.ReactNode;
  onScroll: () => void;
}

export const ThreadsScreen = ({ children, header, onScroll }: ThreadsScreenProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        maxWidth: "548px",
        backgroundColor: "background.paper",
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        "&:focus-visible": {
          outline: "none",
        },
      }}
    >
      <Box>{header}</Box>

      <Divider />

      <Box
        sx={{
          padding: "0",
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "common.white",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "text.secondary",
          },
          height: "100%",
          flex: 1,
        }}
        id="ThreadsScreenList"
        onScroll={onScroll}
      >
        {children}
      </Box>
    </Box>
  );
};
