import React from "react";
import { Typography, Box } from "@mui/material";
import { Carousel } from "@/components/Carousel";
import CaseResumeIllustration from "@/assets/svgs/case-resume.svg?react";
import CreatePieceIllustration from "@/assets/svgs/create-piece.svg?react";
import CaseManagementIllustration from "@/assets/svgs/case-management.svg?react";
import ClientQualificationIllustration from "@/assets/svgs/client-qualification.svg?react";

interface Slide {
  title: string;
  subtitle: string;
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const CarouselContent: React.FC = () => {
  const slides: Slide[] = [
    {
      image: CaseResumeIllustration,
      subtitle:
        "Atendimento ao cliente, detalhamento do caso até a venda dos serviços do seu escritório, feito pelo Assistente Lexter.",
      title: "Assistente no WhatsApp",
    },
    {
      image: CreatePieceIllustration,
      subtitle: "Peças jurídicas geradas pela Inteligência Artificial integrada às etapas do caso do seu cliente.",
      title: "Criação de documentos com Inteligência Artificial",
    },
    {
      image: CaseManagementIllustration,
      subtitle: "Acompanhamento de prazos, tarefas, fluxo processual e conversas com clientes em uma única tela.",
      title: "Gestão de casos e tarefas",
    },
    {
      image: ClientQualificationIllustration,
      subtitle:
        "Da qualificação do seu cliente à celebração de contrato de honorários, tenha toda jornada de vendas otimizada para ganhar mais casos.",
      title: "Qualificação de clientes",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "320px" }}>
        <Carousel autoPlay loop interval={10000}>
          {slides.map(({ image: Image, title, subtitle }, idx) => (
            <Box
              key={idx}
              sx={{
                minHeight: "100px",
                textAlign: "center",
              }}
            >
              <Image width="80%" />
              <Box sx={{ mb: "24px" }} />
              <Typography variant="h6" textTransform="none" mb={3}>
                {title}
              </Typography>
              <Typography variant="body1" mb={3}>
                {subtitle}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};
