import { Box } from "@mui/material";
import { DialogModal } from "@/components/DialogModal";
import { CreditsHistoryApiResponseItem } from "@/hooks/useApi/types";
import React, { useState } from "react";
import { CreditsHistoryItem } from "../CreditsHistoryItem";

interface CreditsHistoryListProps {
  items: CreditsHistoryApiResponseItem[];
}

export const CreditsHistoryList = ({ items }: CreditsHistoryListProps) => {
  const [detailsOpenId, setDetailsOpenId] = React.useState<string>();
  const [disputeItemModalOpen, setDisputeItemModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDisputeItem = async (item: CreditsHistoryApiResponseItem) => {
    setIsLoading(true);

    try {
      // TODO falta implementação na api
      // eslint-disable-next-line no-console
      console.log("item", item);
      await new Promise((resolve) => setTimeout(resolve, 2000));
    } finally {
      setIsLoading(false);
    }

    setDisputeItemModalOpen(true);
  };

  return (
    <>
      <Box>
        {items.map((item) => (
          <CreditsHistoryItem
            key={item.creditTransactionId}
            item={item}
            onClick={isLoading ? undefined : () => setDetailsOpenId(item.creditTransactionId)}
            collapseOpen={detailsOpenId === item.creditTransactionId}
            onDisputeItem={() => handleDisputeItem(item)}
            isLoading={isLoading}
          />
        ))}
      </Box>

      <DialogModal
        title="Contestação"
        description="Nosso time operacional foi notificado para análise e em breve entrará em contato com você em seu e-mail cadastrado."
        open={disputeItemModalOpen}
        onClose={() => setDisputeItemModalOpen(false)}
        buttons={[
          {
            label: "Ok",
            onClick: () => setDisputeItemModalOpen(false),
          },
        ]}
      />
    </>
  );
};
