import { DateTime } from "luxon";

/**
 * Formats to a string with the following format: `"30 Jul 2024 - 15:26"`
 */
export const formattedDateTimeFromISO = (date: string) => {
  return DateTime.fromISO(date)
    .toFormat("dd LLL yyyy - HH:mm")
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .replace(".", "");
};
