import React, { useMemo } from "react";
import { Done, DoneAll, Schedule } from "@mui/icons-material";
import { CaseMessage } from "@/hooks/lexZap/types";
import { blue } from "@mui/material/colors";

export const MessageStatus = ({ message }: { message: CaseMessage }) => {
  const StatusIcon = useMemo(() => {
    if (!message.statusUpdateTimestamp) {
      return Done;
    }

    if (message.statusUpdateTimestamp?.read) {
      return DoneAll;
    }
    if (message.statusUpdateTimestamp?.delivered) {
      return DoneAll;
    }
    if (message.statusUpdateTimestamp?.sent) {
      return Done;
    }

    return Schedule;
  }, [message]);

  if (!message.fromLawyer) {
    return null;
  }

  return (
    <StatusIcon
      sx={{
        color: message.statusUpdateTimestamp?.read ? blue[200] : "grey.600",
        height: "16px",
        width: "16px",
      }}
    />
  );
};
