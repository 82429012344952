import { SkillForm } from "@/core/skillForms/types";
import { createOtherTypeOfPieceSkillStep1 } from "./step1";
import { createOtherTypeOfPieceSkillStep2Response } from "./step2-conditional-response";
import { createOtherTypeOfPieceSkillStep2Appeal } from "./step2-conditional-appeal";
import { createOtherTypeOfPieceSkillStep3Appeal } from "./step3-conditional-appeal";
import { createOtherTypeOfPieceSkillStep2Initial } from "./step2-conditional-initial";
import { createOtherTypeOfPieceSkillStep3Response } from "./step3-conditional-response";

export const createOtherTypeOfPieceSkillForm: SkillForm = {
  skillId: "create_other_type_of_piece",
  title: "Outro tipo de peça",
  subtitle: "Preencha a lista a seguir com detalhes essenciais para a criação da peça:",
  steps: [
    createOtherTypeOfPieceSkillStep1,
    [
      createOtherTypeOfPieceSkillStep2Initial,
      createOtherTypeOfPieceSkillStep2Response,
      createOtherTypeOfPieceSkillStep2Appeal,
    ],
    [createOtherTypeOfPieceSkillStep3Appeal, createOtherTypeOfPieceSkillStep3Response],
  ],
};
