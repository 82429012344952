import { ActionId, WaitingForResponseType } from "../types";

export const waitingTypeFromSkillId = (skillId: ActionId) => {
  if (skillId === ActionId.LEGAL_QUESTION) {
    return WaitingForResponseType.LEGAL_QUESTION;
  }

  if (skillId === ActionId.SUMMARIZE_DOCUMENT) {
    return WaitingForResponseType.CREATE_SUMMARY;
  }

  if (skillId === ActionId.FEE_AGREEMENT) {
    return WaitingForResponseType.CONTRACT;
  }

  if (skillId === ActionId.HEARINGS_SCRIPT) {
    return WaitingForResponseType.CREATE_DOCUMENT;
  }

  return WaitingForResponseType.GENERIC;
};
