import React from "react";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";
import { OrderThreadsBy } from "@/hooks/threads/types";
import { orderThreadsOptions } from "@/hooks/threads/usePaginatedThreads";

export const ThreadsHeader = ({
  goToNewChat,
  currentListOrder,
  updateOrder,
  currentQuerySearch,
  setCurrentQuerySearch,
}: {
  goToNewChat: () => void;
  currentListOrder: OrderThreadsBy;
  updateOrder: (order: OrderThreadsBy) => void;
  currentQuerySearch: string | null;
  setCurrentQuerySearch: (query: string) => void;
}) => {
  const [query, setQuery] = React.useState<string>(currentQuerySearch || "");

  const orderOptions = Object.keys(orderThreadsOptions) as OrderThreadsBy[];

  const searchQuery = async (text: string = query) => {
    setCurrentQuerySearch(text);
  };

  const clearQuery = () => {
    setQuery("");
    searchQuery("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Button onClick={goToNewChat} variant="contained" endIcon={<AddIcon />}>
          Nova sessão
        </Button>
        <FormControl fullWidth>
          <InputLabel id="sort-select-label">Ordenar por</InputLabel>
          <Select
            labelId="sort-select-label"
            label="Ordenar por"
            size="small"
            value={currentListOrder}
            onChange={(e) => updateOrder(e.target.value as OrderThreadsBy)}
          >
            {orderOptions.map((orderOption, index) => {
              return (
                <MenuItem key={index} value={orderOption}>
                  <Typography variant="body1">{orderThreadsOptions[orderOption]}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          size="small"
          label="Buscar"
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: Boolean(query) ? (
              <InputAdornment position="end">
                <IconButton onClick={clearQuery} size="small" sx={{ color: "primary.dark" }}>
                  <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchQuery();
            }
          }}
          onBlur={() => {
            searchQuery();
          }}
        />
      </Box>
    </Box>
  );
};
