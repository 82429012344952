import { SvgIconComponent } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  InputProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from "@mui/material";
import React, { ClipboardEvent, forwardRef } from "react";

export type LegacyTextFieldProps = Omit<MuiTextFieldProps, "color"> & {
  color?: string;
  placeholderColor?: string;
  endIcon?: SvgIconComponent;
  preventPaste?: boolean;
};

export const LegacyTextField = forwardRef<InputProps, LegacyTextFieldProps>(function TextField(
  {
    color: colorProp,
    placeholderColor: placeholderColorProp,
    endIcon: EndIcon,
    error,
    helperText,
    sx,
    preventPaste,
    InputProps,
    ...rest
  }: LegacyTextFieldProps,
  ref
) {
  const color = colorProp || "common.white";
  const placeholderColor = placeholderColorProp || color;
  const handlePaste = (e: ClipboardEvent) => {
    if (preventPaste) {
      e.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <MuiTextField
        variant="standard"
        error={error}
        sx={{
          "& input::placeholder": {
            color: placeholderColor,
            opacity: 1,
          },
          ".MuiInputBase-root": {
            marginTop: "12px",
          },
          ".MuiInputBase-root, .MuiInputBase-input, .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused": {
            color,
          },
          ".MuiInputBase-root:after, .MuiInput-underline:before, .MuiInput-underline:hover:before, .MuiInput-underline:after":
            {
              borderBottomColor: color,
            },
          ".Mui-error": {
            "&.MuiInputBase-root, .MuiInputBase-input, &.MuiFormLabel-root, .MuiFormLabel-root.Mui-focused": {
              color: "error.main",
            },
            "&.MuiInput-underline:before, &.MuiInput-underline:hover:before, &.MuiInput-underline:after, &.MuiInput-underline:hover:after":
              {
                borderBottomColor: "error.main",
              },
          },
        }}
        {...rest}
        InputProps={{
          ref,
          onPaste: handlePaste,
          sx: {
            pb: "4px",
            px: "4px",
          },
          endAdornment: EndIcon ? (
            <InputAdornment
              position="end"
              sx={{
                color: "inherit",
              }}
            >
              <EndIcon fontSize="small" />
            </InputAdornment>
          ) : undefined,
          ...InputProps,
        }}
        InputLabelProps={{
          shrink: true,
          sx: {
            color: error ? "error.main" : color,
            fontSize: "12px",
            fontWeight: "700",
            textTransform: "uppercase",
          },
        }}
      />
      {helperText && (
        <Typography variant="body3" color={error ? "error.main" : undefined}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
});
