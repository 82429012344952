import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import axios from "axios";
import { Case, CaseMessage, CaseMessageType } from "../types";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { getCasesQueryKey } from "../useLexZapCases";
import { casesMock } from "../useLexZapCase/mocks";
import { useMemo } from "react";

export const getMessagesQueryKey = ({ chatId }: { chatId?: string }) => ["lexZapMessages", chatId];

interface UseLexZapMessagesProps {
  chatId?: string;
}

export const useLexZapMessages = ({ chatId }: UseLexZapMessagesProps) => {
  const queryClient = useQueryClient();
  const { getLexZapMessages } = useApi();
  useSocket({
    onWhatsAppUpdate: (update) => {
      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED || update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
        const messageChatId = update.message.chatId;

        const mergeNewMessage = (oldMessage: CaseMessage, newMessage: CaseMessage): CaseMessage => {
          return {
            ...oldMessage,
            ...newMessage,
            statusUpdateTimestamp: {
              ...oldMessage.statusUpdateTimestamp,
              ...newMessage.statusUpdateTimestamp,
            },
          };
        };

        queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ chatId: messageChatId }), (oldMessages = []) => {
          const messageExists = oldMessages.some((msg) => msg.id === update.message.id);
          if (messageExists) {
            return oldMessages.map((oldMessage) =>
              oldMessage.id === update.message.id ? mergeNewMessage(oldMessage, update.message) : oldMessage
            );
          } else if (update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
            return oldMessages;
          }
          return [...oldMessages, update.message];
        });

        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) =>
          oldCases.map((caseItem) => {
            if (caseItem.chatId === messageChatId) {
              if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED) {
                return { ...caseItem, lastMessage: update.message };
              } else {
                const isLastMessage = caseItem.lastMessage?.id === update.message.id;
                if (isLastMessage) {
                  return { ...caseItem, lastMessage: mergeNewMessage(caseItem.lastMessage!, update.message) };
                }
              }
            }

            return caseItem;
          })
        );
      }
    },
  });

  const mockedCase = useMemo(() => {
    return casesMock.find((caseItem) => caseItem.chatId === chatId);
  }, [chatId]);

  return useQuery({
    queryKey: getMessagesQueryKey({ chatId }),
    queryFn: async () => {
      if (!chatId) {
        return Promise.resolve([]);
      }

      if (mockedCase) {
        return mockedCase.messages;
      }

      const { messages: messagesResult } = await getLexZapMessages({ chatId });

      const messages = await Promise.all(
        messagesResult.map(async (message) => {
          if (message.type !== CaseMessageType.AUDIO || !message?.media || !message.media?.url) {
            return message;
          }

          try {
            const { data } = await axios.get(message.media?.url, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": message.media.mimeType,
              },
            });

            const blob = new Blob([data], {
              type: message.media.mimeType,
            });

            const url = URL.createObjectURL(blob);

            return {
              ...message,
              media: {
                ...message.media,
                url,
              },
            };
          } catch {
            return {
              ...message,
              media: {
                ...message.media,
                error: true,
              },
            };
          }
        })
      );

      const lastMessage = messages[messages.length - 1];
      if (lastMessage) {
        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) =>
          oldCases.map((caseItem) => (caseItem.chatId === chatId ? { ...caseItem, lastMessage } : caseItem))
        );
      } else {
        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) =>
          oldCases.filter((caseItem) => caseItem.chatId !== chatId)
        );
      }

      return messages;
    },
    enabled: !!chatId,
    staleTime: Infinity,
  });
};
