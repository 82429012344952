import { useCallback } from "react";

export const useGtag = () => {
  const sendEvent = useCallback((action: string, actionId: string) => {
    if (window.gtag) {
      window.gtag("event", action, { send_to: actionId });
    }
  }, []);

  return { sendEvent };
};
