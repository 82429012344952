import { buildSkillStep } from "@/core/skillForms/builder";

export const adaptDocumentSkillStep1 = buildSkillStep({
  title: "Adaptar peça/contrato",
  description: "Atualize contratos e peças com novas informações mantendo seu estilo e argumentação",
  fields: [
    {
      name: "document",
      type: "file",
      ui: {
        label: "Carregue o arquivo",
      },
      required: true,
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descreva o que deseja alterar",
        placeholder: "Digite aqui as informações do novo caso/contrato",
        widget: "textarea",
      },
      required: true,
    },
  ],
});
