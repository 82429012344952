import { SkillForm } from "@/core/skillForms/types";
import { createNoticeSkillStep1 } from "./step1";
import { createNoticeSkillStep2 } from "./step2";

export const createNoticeSkillForm: SkillForm = {
  skillId: "create_notice",
  title: "Elaborar Notificação Extrajudicial",
  subtitle: "Preencha a lista a seguir com detalhes essenciais para a criação da estratégia:",
  steps: [createNoticeSkillStep1, createNoticeSkillStep2],
};
