import { buildSkillStep } from "@/core/skillForms/builder";

export const legalAdviceSkillStep1 = buildSkillStep({
  description: "Utilize a sua peça inaugural para o Assistente Lexter elaborar uma proposta de acordo.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
