import { CaseMessage } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";

export const isLastClientMessageOlderThan24Hours = (messages?: CaseMessage[]): boolean => {
  const clientMessages = messages?.filter((message) => !message.fromLawyer);

  if (!clientMessages?.length) return false;

  const lastClientMessage = clientMessages.reduce((latest, current) =>
    current.timestamp > latest.timestamp ? current : latest
  );

  const lastMessageTime = DateTime.fromMillis(lastClientMessage.timestamp);

  const hoursDifference = DateTime.now().diff(lastMessageTime, "hours").hours;

  return hoursDifference >= 23; // gordura de 1h
};
