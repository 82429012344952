import { ReCaptchaProvider } from "@/providers/ReCaptchaProvider";
import React from "react";
import { BasicInfoForm } from "./Steps/BasicInfoForm";
import { PasswordForm } from "./Steps/PasswordForm";
import { SignUpProvider, useSignUpContext } from "./context";

export const Signup = () => (
  <ReCaptchaProvider>
    <SignUpProvider>
      <Content />
    </SignUpProvider>
  </ReCaptchaProvider>
);

const Content = () => {
  const { steps, currentStep } = useSignUpContext();
  const SignUpSteps = {
    BASIC_INFO: 0,
    PASSWORD: 1,
  };

  return (
    <>
      {steps[currentStep] === SignUpSteps.BASIC_INFO && <BasicInfoForm />}
      {steps[currentStep] === SignUpSteps.PASSWORD && <PasswordForm />}
    </>
  );
};
