import { buildActionsButtons } from "@/core/actions";
import { PlanType } from "@/hooks/credits/types";
import { useCredits } from "@/hooks/credits/useCredits";
import { useFavoriteSkills } from "@/hooks/skills/useFavoriteSkills";
import { useSkillGroups } from "@/hooks/skills/useSkillGroups";
import { useSkills } from "@/hooks/skills/useSkills";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import React from "react";

export const useActions = () => {
  const flags = useFeatureFlags();
  const { data: favoriteSkills = [], isLoading: isLoadingFavoriteSkills } = useFavoriteSkills();
  const { data: skills, isLoading: isLoadingSkills } = useSkills();
  const { data: skillGroups, isLoading: isLoadingSkillGroups } = useSkillGroups();
  const { data: credits, isLoading: isLoadingCredits } = useCredits();

  const userPlan = credits?.companyPlan?.plan ?? PlanType.FREEMIUM;

  const actions = React.useMemo(() => {
    return buildActionsButtons({
      userPlan,
      favoriteSkills,
      skills,
      skillGroups,
      userFlags: flags,
    });
  }, [favoriteSkills, skills, skillGroups, userPlan, flags]);

  return {
    actions,
    isLoadingActions: isLoadingFavoriteSkills || isLoadingSkills || isLoadingSkillGroups || isLoadingCredits,
  };
};
