import { CaseMessage } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";

export const groupMessagesByDay = (messages?: CaseMessage[]) => {
  if (!messages) return [];

  return messages.reduce<Array<{ date: number; messages: CaseMessage[] }>>((acc, message) => {
    const messageDate = DateTime.fromMillis(message.timestamp).startOf("day").toMillis();

    const existingGroup = acc.find((group) => group.date === messageDate);
    if (existingGroup) {
      existingGroup.messages.push(message);
    } else {
      acc.push({
        date: messageDate,
        messages: [message],
      });
    }
    return acc;
  }, []);
};
