import { decodeJwt } from "@/utils/decodeJwt";
import * as logger from "@/core/logger";

const FIVE_MINUTES_IN_SECONDS = 300;

export const isTokenExpired = (token: string) => {
  try {
    const decodedToken = decodeJwt(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentTimeSeconds = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTimeSeconds - FIVE_MINUTES_IN_SECONDS;
  } catch (error) {
    if (error instanceof Error) {
      logger.error(`Error decoding token: ${error.message}`, error);
    }
    return true;
  }
};
