import { LegalPieceMacroType } from "@/contexts/MessagesContext";
import { buildSkillStep, createUploadFileField } from "@/core/skillForms/builder";

export const createOtherTypeOfPieceSkillStep2Response = buildSkillStep({
  fields: [
    createUploadFileField({
      name: "reference_piece",
      ui: {
        label: "Anexar documento",
        description:
          "Para criar uma resposta, você precisa realizar o envio da peça que será respondida. Como a petição inicial ou a contestação do caso.",
      },
    }),
  ],
  condition: {
    value: LegalPieceMacroType.RESPONSE,
    field: "legal_piece_macro_type",
  },
  title: "Anexo",
});
