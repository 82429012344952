import { Box, Button, Typography } from "@mui/material";
import React from "react";
import WeGotAProblemSvg from "@/assets/svgs/we-got-a-problem.svg?react";
import { ROUTE_PATHS } from "@/routes/routePaths";

export const ErrorFallback = () => {
  return (
    <Box
      className="error-fallback"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
        gap: 1,
      }}
    >
      <WeGotAProblemSvg height={"250px"} width={"250px"} />
      <Typography variant="h4">Algo deu errado!</Typography>
      <Typography variant="subtitle1">
        Sentimos muito pelo inconveniente.
        <br />
        Estamos trabalhando para identificar o problema
        <br />e corrigi-lo o mais rápido possível.
      </Typography>
      <Button variant="contained" onClick={() => (window.location.href = ROUTE_PATHS.HOME)} sx={{ mt: 2 }}>
        Voltar para o inicio
      </Button>
    </Box>
  );
};
