import { buildSkillStep } from "@/core/skillForms/builder";

export const createBaseAppealSkillStep3 = buildSkillStep({
  title: "Detalhes do caso",
  description: "Preencha a lista abaixo para fornecer o contexto do seu caso à IA:",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo.",
        placeholder:
          "Maria da Silva, conforme RG nº 1234567 e CPF 321.654.987-00, registrada nos documentos do processo.",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description:
          "Descreva os fatos relevantes que fundamentam o recurso, destacando pontos que justificam a revisão da decisão.",
        placeholder:
          "A decisão não considerou horas extras trabalhadas e comprovadas por registros de ponto, contrariando os documentos apresentados.",
      },
    },
    {
      name: "timeliness_text",
      type: "string",
      required: false,
      ui: {
        label: "Tempestividade e Cabimento",
        description:
          "Explique por que o recurso é cabível e foi apresentado dentro do prazo legal, conforme as normas processuais.",
        placeholder:
          "O recurso ordinário é cabível nos termos do art. 895, I, da CLT e foi interposto em 05/09/2023, dentro do prazo de 8 dias úteis após a publicação da decisão.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumento jurídico",
        description:
          "Apresente os argumentos jurídicos que sustentam o recurso, citando as leis e jurisprudências aplicáveis ao caso.",
        placeholder:
          "Com base na CF e nos direitos sociais, busca-se o pagamento de horas extras devidas, reconhecido por jurisprudência do TST.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Liste os pedidos apresentados no recurso, indicando claramente as providências ou decisões solicitadas.",
        placeholder:
          "Requer a reforma da decisão para reconhecer o pagamento das horas extras devidas e o adicional de insalubridade.",
      },
    },
  ],
});
