import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  area: Type.String(),
  question: Type.String(),
});

const actionId = ActionId.LEGAL_QUESTION;

export const legalQuestionSkill: Skill<typeof inputSchema> = {
  id: "legal_questions",
  name: "Dúvida jurídica",
  credits: CreditPerSkill.ESSENTIAL_SKILL,
  inputSchema,
  tags: ["litigation", "consultative"],
  actionId,
  type: "payload",
  description: "Esclareça dúvidas jurídicas e aprofunde seu conhecimento em leis e procedimentos.",
  getPayload: (input) => ({
    skillId: ActionId.LEGAL_QUESTION,
    requestId: uuidV4(),
    payload: {
      area: input.area,
      question: input.question,
    },
    messageToSave: `Área: ${input.area}.\nPergunta: ${input.question}`,
  }),
};
