import { Box, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Config } from "@/config/Config";

export const SessionSettingsToast = () => {
  const config = Config.getConfig();

  if (!config.isProd && config.hasSessionSettings) {
    return <Toast />;
  }
  return null;
};

const Toast = () => {
  const [open, setOpen] = React.useState(true);

  return open ? (
    <Box
      sx={{
        position: "fixed",
        top: 8,
        right: 16,
        zIndex: 100,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <CloseIcon onClick={() => setOpen(false)} sx={{ cursor: "pointer" }} fontSize="small" />
      <Box sx={{ backgroundColor: "error.light", padding: 2, borderRadius: 4 }}>
        <Typography variant="body">Você está usando configurações customizadas</Typography>
      </Box>
    </Box>
  ) : null;
};
