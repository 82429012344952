import { Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { useGtag } from "@/hooks/useGtag";
import { Layout } from "@/pages/Signup/Layout";
import { ReCaptchaProvider } from "@/providers/ReCaptchaProvider";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { VerificationCodeInput } from "./VerificationCodeInput";
import { useAuthContext } from "@/contexts/AuthContext";
import { useLinkedinAds } from "@/hooks/useLinkedinAds";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { logger } from "@/core/logger";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

export const ActivateAccount = () => (
  <ReCaptchaProvider>
    <Content />
  </ReCaptchaProvider>
);

const Content = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || "";
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [resendCounter, setResendCounter] = useState(0);
  const sendCodeEnabled = resendCounter === 0;
  const [isLoading, setIsLoading] = useState(false);
  const { sendEvent } = useGtag();
  const { loginWithEmailAndPassword } = useAuthContext();
  const { loadLinkedinTag } = useLinkedinAds();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (code.length !== 6) {
      setCodeError("Código inválido.");
      return;
    }

    setIsLoading(true);

    try {
      const recaptchaToken = await executeRecaptcha!();
      await LexterCopilotAuthService.activateAccount({ email, code, recaptchaToken });
    } catch {
      setCodeError("Código inválido.");
      setIsLoading(false);
      return;
    }

    loadLinkedinTag();
    sendEvent("conversion", "AW-16462471594/sWA9CPKowpwZEKrD9ak9");

    await handleContinue();
  };

  const handleContinue = async () => {
    const password = location.state?.password;

    if (!password) {
      return navigate(ROUTE_PATHS.LOGIN, {
        state: { redirectTo: location.state?.redirectTo },
      });
    }

    setIsLoading(true);

    const response = await loginWithEmailAndPassword(email, password, "");

    if (!response.success) {
      return navigate(ROUTE_PATHS.LOGIN, {
        state: { redirectTo: location.state?.redirectTo },
      });
    }

    if (location.state?.redirectTo) {
      return navigate(location.state.redirectTo);
    }

    if (outsideOfficeClient()) {
      return navigate(ROUTE_PATHS.ONBOARDING_QUESTIONS, {
        replace: true,
      });
    }

    return navigate(ROUTE_PATHS.HOME);
  };

  const handleResendCode = async () => {
    startResendTimer();
    try {
      const recaptchaToken = await executeRecaptcha!();
      await LexterCopilotAuthService.sendEmailVerificationCode({ email, recaptchaToken });
    } catch (e) {
      logger.error("Failed to resend code", e);
    }
  };

  const startResendTimer = () => {
    setResendCounter(60);
    intervalRef.current = setInterval(() => {
      setResendCounter((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(intervalRef.current);
        }
        return prevCounter - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!email) {
      return navigate(ROUTE_PATHS.SIGNUP);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    setCodeError("");
  }, [code]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "320px",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: 3,
              mb: 2,
            }}
          >
            <Box
              component={LexterLogoIcon}
              height={54}
              sx={{
                color: "primary.main",
              }}
            />
          </Box>

          <Typography variant="h3" fontWeight={600} align="center">
            Confirme sua identidade
          </Typography>
          <Typography variant="multiLineBody" align="center">
            Enviamos um código para: <strong>{email}</strong>
          </Typography>

          <VerificationCodeInput error={codeError} onChange={setCode} />

          <Typography variant="multiLineBody" align="center">
            Não recebeu o código?{" "}
            <Button variant="text" onClick={sendCodeEnabled ? handleResendCode : undefined} disabled={!sendCodeEnabled}>
              Reenviar {resendCounter > 0 && `(${resendCounter})`}
            </Button>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button type="submit" variant="contained" fullWidth isLoading={isLoading}>
              Finalizar cadastro
            </Button>
            <Button
              onClick={() => navigate(ROUTE_PATHS.LOGIN)}
              startIcon={ArrowBackIcon}
              type="button"
              variant="text"
              fullWidth
              sx={{
                color: "common.black",
              }}
            >
              Voltar
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
