import { Config } from "@/config/Config";

const BASE_NAME = "lexter-config-";

export const loadSessionSettings = () => {
  const config = Config.getConfig();

  if (config.isProd) {
    return;
  }

  const searchParams = new URLSearchParams(location.search);

  const configParams: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    if (key.startsWith(BASE_NAME)) {
      configParams[key.replace(BASE_NAME, "")] = value;
    }
  });

  Object.keys(configParams).forEach((key) => {
    searchParams.delete(`${BASE_NAME}${key}`);
  });

  if (Boolean(Object.keys(configParams).length)) {
    const newUrl = location.pathname.replace(location.search, "");

    config.setSessionSettings(configParams);

    if (searchParams.toString()) {
      location.search = searchParams.toString();
      return;
    }

    location.replace(newUrl);
  }
};
