import { buildSkillStep } from "@/core/skillForms/builder";

export const createStrategySkillStep3 = buildSkillStep({
  title: "Informações adicionais",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas",
      },
    },
    {
      name: "evidences_list",
      type: "string",
      required: false,
      ui: {
        label: "Liste quais são as evidências disponíveis (opcional):",
        placeholder:
          "Exemplos: fotos do dano, Contrato de Prestação de Serviços, Comprovantes de despesas médicas e conversa de whatsapp.",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Elaborar estratégia",
});
