import { DialogModal } from "@/components/DialogModal";
import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header/constants";
import { useAuthContext } from "@/contexts/AuthContext";
import { ActionProps, getSkillGroupRoute, getSkillRoute } from "@/core/actions";
import { useAddFavoriteSkill } from "@/hooks/skills/useAddFavoriteSkill";
import { useDeleteFavoriteSkill } from "@/hooks/skills/useDeleteFavoriteSkill";
import { useApi } from "@/hooks/useApi";
import { Toast } from "@/taskpane/components/core/Toast";
import { alpha, Box, Button, Card, Link, Tooltip, Typography, useTheme } from "@mui/material";
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { ActionsList } from "./components/ActionsList";
import { logger } from "@/core/logger";
import { useCredits } from "@/hooks/credits/useCredits";
import { useActions } from "@/hooks/useActions";
import ContractAnalysis from "@/assets/svgs/contract-analysis.svg?react";
import WomanInComputer from "@/assets/images/womanNormalInComputer.jpg";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { notShowCredits } from "@/utils/plans";
import { UsageCard } from "@/pages/Home/components/UsageCard";

const USAGE_CARD_OPEN_STORAGE_KEY = "usageCardOpen";

export const Home = () => {
  const { data: credits } = useCredits();
  const showCredits = !notShowCredits(credits);

  const [usageCardOpen, setUsageCardOpen] = useState(() => {
    return sessionStorage.getItem(USAGE_CARD_OPEN_STORAGE_KEY) !== "false";
  });

  const closeUsageCard = () => {
    setUsageCardOpen(false);
    sessionStorage.setItem(USAGE_CARD_OPEN_STORAGE_KEY, "false");
  };

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.WELCOME,
        extraContent: usageCardOpen && showCredits ? <UsageCard onClose={closeUsageCard} /> : undefined,
      }}
      contentSx={{
        width: "100%",
      }}
      sx={{
        ".page-layout-container": {
          p: 0,
        },
      }}
    >
      <HomeContent />
    </PageLayout>
  );
};

const HomeContent = () => {
  const { mutateAsync: deleteFavoriteSkill } = useDeleteFavoriteSkill();
  const { mutateAsync: addFavoriteSkill } = useAddFavoriteSkill();

  const { actions, isLoadingActions } = useActions();

  const comingSoonRef = useRef<{
    handleComingSoon: (action: ActionProps) => void;
  }>();

  const handleComingSoon = (action: ActionProps) => {
    if (comingSoonRef.current) {
      comingSoonRef.current.handleComingSoon(action);
    }
  };

  const toggleFavoriteSkill = useCallback(
    async ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => {
      if (isFavorite) {
        await deleteFavoriteSkill({ skillId });
      } else {
        await addFavoriteSkill({ skillId });
      }
    },
    [addFavoriteSkill, deleteFavoriteSkill]
  );

  return (
    <>
      <ComingSoonModal ref={comingSoonRef} />
      <HomeBanner />
      <Box
        sx={{
          px: 8,
          py: 4,
        }}
      >
        <Typography
          sx={{
            mb: "8px",
          }}
          variant="h5"
        >
          Faça mais com o Assistente Lexter
        </Typography>
        <ActionsList
          handleComingSoon={handleComingSoon}
          actions={actions}
          toggleFavoriteSkill={toggleFavoriteSkill}
          isLoading={isLoadingActions}
        />
      </Box>
    </>
  );
};

const HomeBanner = () => {
  return (
    <Box
      sx={{
        paddingX: 8,
        paddingY: 2,
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "inline-grid",
          gridColumn: "1 / 5",
          gridRow: "1 / 3",
        }}
      >
        <NewPieceBanner />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, gridColumn: "5 / 7", gridRow: "1 / 3" }}>
        <WhatsappBanner />
        <UseAsWellBanner />
      </Box>
    </Box>
  );
};

const WhatsappBanner = () => {
  const { palette } = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        flexGrow: 1,
        color: "common.white",
        backgroundImage: `url(${WomanInComputer})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: 1,
          justifyContent: "center",
          padding: 2,
          paddingX: 3,
          backgroundColor: alpha(palette.common.black, 0.75),
        }}
      >
        <Typography variant="h6">Novo assistente para Whatsapp</Typography>
        <Typography variant="subtitle2">
          Conheça uma forma mais leve de trabalhar, deixando a Lexter fazer o primerio atendimento por você.
        </Typography>
        <Link href={ROUTE_PATHS.WHATSAPP_ASSISTANT}>Conhecer o novo assistente</Link>
      </Box>
    </Card>
  );
};

const NewPieceBanner = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: 3,
        gap: 4,
      }}
    >
      <ContractAnalysis style={{ width: "100%", height: "100%", maxHeight: "320px", maxWidth: "320px" }} />
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2, p: 2 }}
      >
        <Typography variant="h5">Seu escritório e a Lexter podem fazer muito juntos</Typography>
        <Typography variant="subtitle1">
          Um bom começo é escrever uma peça. Abaixo você encontra outras possibilidades.
        </Typography>
        <Tooltip
          title={
            "Escreva diversos tipos de peças judiciais, de iniciais a recursos e aplações. Utilize nossa inteligência artificial e otimize seu processo de escrita."
          }
          arrow
          placement="top"
        >
          <Button variant="contained" href={getSkillGroupRoute({ skillGroupId: "create_new_document" })} fullWidth>
            Escrever peça judicial
          </Button>
        </Tooltip>
      </Box>
    </Card>
  );
};

const UseAsWellBanner = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 3,
        gap: 2,
      }}
    >
      <Typography variant="h6">Utilize também</Typography>
      <Tooltip
        title={"Tire dúvidas jurídicas de forma rápida e prática utilizando o Assistente Lexter."}
        arrow
        placement="top"
      >
        <Button variant="outlined" href={getSkillRoute({ skillId: "legal_questions" })} fullWidth>
          Duvidas jurídicas
        </Button>
      </Tooltip>
      <Tooltip title={"Elabore contratos dos mais diversos tipos com apenas poucos cliques."} arrow placement="top">
        <Button variant="outlined" href={getSkillRoute({ skillId: "create_contract" })} fullWidth>
          Elaborar contrato
        </Button>
      </Tooltip>
    </Card>
  );
};

const ComingSoonModal = forwardRef(function ComingSoonModal(_props, ref) {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<ActionProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addActionOfInterest } = useApi();
  const { user } = useAuthContext();

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleComingSoon(action: ActionProps) {
      setAction(action);
      setOpen(true);
    },
  }));

  const handleActionInterest = async () => {
    try {
      setIsLoading(true);
      if (action && user) {
        await addActionOfInterest({
          userId: user?.userId,
          companyId: user?.companyId,
          actionOfInterest: action?.id.toUpperCase(),
        });
        Toast.success("Interesse cadastrado com sucesso!");
      } else {
        Toast.error("Erro ao cadastrar interesse, tente novamente mais tarde");
      }
    } catch (err) {
      Toast.error("Erro ao cadastrar interesse");
      logger.error("handleActionInterest", err);
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <DialogModal
      open={open}
      title={action?.name}
      isLoading={isLoading}
      heightLoading={160}
      description={
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          A ação de <strong>{action?.name.toUpperCase()}</strong> ainda não está disponível. Cadastre-se abaixo e seja
          informado assim que ela estiver pronta para uso.
        </Typography>
      }
      descriptionTextAlign="left"
      buttons={[
        {
          label: "Avise-me",
          onClick: handleActionInterest,
        },
        {
          label: "Cancelar",
          onClick: onClose,
          variant: "text",
          color: "primary",
          sx: {
            color: "text.primary",
            textDecoration: "underline",
          },
        },
      ]}
    />
  );
});
ComingSoonModal.displayName = "ComingSoonModal";
