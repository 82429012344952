import React, { useState } from "react";
import { CNPJ } from "@/core/CNPJ";
import { CPF } from "@/core/CPF";
import { applyCpfCnpjMask } from "@/utils/applyInputMask/cpfCnpjMask";
import { Result } from "@/core/Result";
import { TextField } from "@mui/material";

interface CpfOrCpnjFieldProps {
  onCpfCpnjChange: (cpfCnpj: string) => void;
}

export const isCpfCnpjValid = (cpfCnpj: string): Result<boolean> => {
  if (cpfCnpj.length <= 14) {
    const isValid = CPF.fromString(cpfCnpj);

    if (isValid.isFailure) return Result.fail("CPF ou CNPJ inválido");
  } else {
    const isValid = CNPJ.fromString(cpfCnpj);

    if (isValid.isFailure) return Result.fail("CNPJ inválido");
  }
  return Result.ok(true);
};

export const CpfOrCpnjField = ({ onCpfCpnjChange }: CpfOrCpnjFieldProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const isValid = isCpfCnpjValid(e.target.value);
    if (isValid.isFailure) {
      setErrorMessage(isValid.getError());
    }
    onCpfCpnjChange(e.target.value);
  };

  const onChange = () => {
    setErrorMessage(null);
  };

  return (
    <TextField
      InputProps={{
        onKeyUp: applyCpfCnpjMask,
      }}
      sx={{
        mt: 1,
        fieldset: {
          borderColor: "common.lightShade",
        },
        label: { fontSize: "14.5px" },
      }}
      onBlur={onBlur}
      onChange={onChange}
      error={!!errorMessage}
      helperText={errorMessage}
      label="CPF ou CNPJ"
    />
  );
};
