import { AppBar, Box, Divider, IconButton, Popover, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeaderWelcome } from "./HeaderWelcome";
import { HeaderGoBack } from "./HeaderGoBack";
import { HeaderEditor } from "./HeaderEditor";
import { useNotificationsContext } from "@/contexts/NotificationsContext";
import { Loading } from "@/components/Loading";
import { NotificationsNoneRounded as NotificationsNoneRoundedIcon, Close as CloseIcon } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { HeaderVariant } from "./constants";

interface HeaderProps {
  variant: HeaderVariant.WELCOME | HeaderVariant.GO_BACK | HeaderVariant.EDITOR;
  chatWidth?: number;
  onClickDocuments?: () => void;
  onClickEditor?: () => void;
}

export const Header = ({ variant, chatWidth, onClickDocuments, onClickEditor }: HeaderProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { notifications, isLoading: isLoadingNotifications, openNotification } = useNotificationsContext();
  const navigate = useNavigate();
  const { isOnboarding } = useDocumentOnboarding();
  const [showNotifications, setShowNotifications] = useState(true);

  useEffect(() => {
    if (isOnboarding) {
      setShowNotifications(false);
    } else {
      setShowNotifications(true);
    }
  }, [isOnboarding]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotification = ({ id, threadId }: { id: number; threadId?: string }) => {
    openNotification(id);
    if (threadId) {
      navigate(`${ROUTE_PATHS.CHAT}/${threadId}`);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let Content;
  switch (variant) {
    case HeaderVariant.WELCOME:
      Content = <HeaderWelcome />;
      break;
    case HeaderVariant.GO_BACK:
      Content = <HeaderGoBack />;
      break;
    case HeaderVariant.EDITOR:
    default:
      Content = (
        <HeaderEditor chatWidth={chatWidth ?? 400} onClickDocuments={onClickDocuments} onClickEditor={onClickEditor} />
      );
  }

  return (
    <AppBar
      position="static"
      sx={{
        height: "56px",
        backgroundColor: "common.dorian",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 24px",
        borderBottom: "1px solid",
        borderColor: "common.lightShade",
        zIndex: 1,
        boxShadow: "none",
        color: "common.black",
      }}
    >
      {Content}

      {showNotifications && (
        <IconButton onClick={handleClick}>
          {notifications?.some((notification) => !notification.visualizedAt) && (
            <Box
              sx={{
                borderRadius: "100%",
                position: "absolute",
                bgcolor: "primary.main",
                width: "12px",
                height: "12px",
                right: "8px",
                top: "8px",
              }}
            ></Box>
          )}
          <NotificationsNoneRoundedIcon style={{ color: theme.palette.common.black }} />
        </IconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          borderRadius: "12px",
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
          "& .MuiPaper-root": {
            borderRadius: "12px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            width: "400px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "20px",
              paddingY: "12px",
            }}
          >
            <Typography sx={{ lineHeight: "14px", paddingTop: "4px" }} variant="title">
              Notificações
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider />
          <Box
            sx={{
              height: "196px",
              overflowY: notifications?.length > 1 ? "auto" : "hidden",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
                backgroundColor: "common.white",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "text.secondary",
              },
            }}
          >
            {notifications?.length || isLoadingNotifications ? (
              <Loading isLoading={isLoadingNotifications}>
                {notifications.map((notification, idx) => {
                  getTimeSince(notification.createdAt);
                  return (
                    <Box
                      key={notification.id}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpenNotification({ id: notification.id, threadId: notification.threadId });
                      }}
                    >
                      <Box
                        sx={{
                          paddingY: "16px",
                          paddingX: "12px",
                          borderLeft: "8px",
                          borderLeftStyle: "solid",
                          borderLeftColor: notification.visualizedAt ? "transparent" : "secondary.main",
                          display: "grid",
                          gap: "4px",
                        }}
                      >
                        <Typography variant="title" sx={{ textTransform: "none" }}>
                          {notification.title}
                        </Typography>
                        <Typography variant="body" sx={{ fontSize: "12px" }}>
                          {`${notification.text} - ${getTimeSince(notification.createdAt)}`}
                        </Typography>
                      </Box>
                      {notifications.length - 1 !== idx && <Divider />}
                    </Box>
                  );
                })}
              </Loading>
            ) : (
              <Box
                sx={{
                  padding: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Nenhuma notificação
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </AppBar>
  );
};

const getTimeSince = (date: string) => {
  const now = DateTime.now();
  const dateIsoString = date.trim().replace(" ", "T").replace("Z", "") + "Z";
  const then = DateTime.fromISO(dateIsoString);

  const diff = now.diff(then, ["days", "hours", "minutes"]);
  const diffObject = diff.toObject();

  const days = getDays(diffObject?.days);

  const hours = getHours(diffObject?.hours);

  const min = getMinutes(diffObject?.minutes);

  const message = getMessage({ days, hours, min });

  if (!message) return "agora";
  return `${message} atrás`;
};

const getMessage = (diff: { days?: string; hours?: string; min?: string }) => {
  if (diff.days) {
    return diff.days;
  }

  if (diff.hours) {
    return diff.hours;
  }

  if (diff.min) {
    return diff.min;
  }

  return null;
};

const getDays = (diff?: number) => {
  if (!diff) return;
  return diff > 1 ? `${diff?.toFixed(0)} dias` : `${diff?.toFixed(0)} dia`;
};

const getHours = (diff?: number) => {
  if (!diff) return;
  return diff > 1 ? `${diff?.toFixed(0)} horas` : `${diff?.toFixed(0)} hora`;
};

const getMinutes = (diff?: number) => {
  if (!diff) return;
  return diff >= 1 ? `${diff?.toFixed(0)} min` : "<1 min";
};
