import { WarningAmberRounded as WarningAmberRoundedIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

export const CreditsError = () => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
    <WarningAmberRoundedIcon fontSize="medium" sx={{ color: "common.white" }} />
    <Typography
      variant="body3"
      color="common.white"
      sx={{
        textAlign: "center",
      }}
    >
      Não foi possível carregar as informações do seu plano. Tente novamente mais tarde.
    </Typography>
  </Box>
);
