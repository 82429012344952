import { useMemo } from "react";
import { useLexZapCases } from "../useLexZapCases";
import { casesMock } from "./mocks";
import { Case } from "../types";

interface UseLexZapCaseProps {
  caseId?: string;
}

interface UseLexZapCaseReturn {
  caseData?: Case;
  isLoading: boolean;
  isError: boolean;
}

export const useLexZapCase = ({ caseId }: UseLexZapCaseProps): UseLexZapCaseReturn => {
  const { data: cases, isLoading, isError } = useLexZapCases();

  const mockedCase = useMemo(() => {
    return casesMock.find((caseItem) => caseItem.id === caseId);
  }, [caseId]);

  if (mockedCase) {
    return {
      caseData: { ...mockedCase, lastMessage: mockedCase.messages[mockedCase.messages.length - 1] },
      isLoading: false,
      isError: false,
    };
  }

  return {
    caseData: cases?.find((caseItem) => caseItem.id === caseId),
    isLoading,
    isError,
  };
};
