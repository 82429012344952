import { useEditor } from "@/hooks/useEditor";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { FieldProps, FormContextType, RJSFSchema, StrictRJSFSchema } from "@rjsf/utils";

export const EditorContentField = <
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>({
  onChange,
}: FieldProps<T, S, F>) => {
  const { currentTabIsEmpty: editorIsEmpty, currentTabContent } = useEditor();

  useEffect(() => {
    onChange(currentTabContent as T);
  }, [currentTabContent]);

  const contentText = editorIsEmpty ? (
    <Box>
      <Box sx={{ mb: 1, alignItems: "center", display: "flex" }}>
        <WarningAmberOutlinedIcon sx={{ color: "common.shade", width: 16, height: 16, mr: 1 }} />
        <Typography variant="preTitle">O documento está vazio</Typography>
      </Box>
      <Typography variant="multiLineBody">Adicione informações no corpo do documento aberto ao lado.</Typography>
    </Box>
  ) : (
    <Typography variant="multiLineBody">O texto do documento aberto será utilizado.</Typography>
  );

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        width: "100%",
      }}
    >
      <Box
        sx={{
          minHeight: 64,
          width: "100%",
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "text.secondary",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Box
            className="editor-container"
            sx={{
              maxHeight: 300,
              textAlign: "left",
              p: 1,
            }}
          >
            {contentText}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
