import { Area, DecisionType, LegalPieceMacroType } from "@/contexts/MessagesContext";

type ActionNameVariantsType =
  | "initial_petition"
  | "tax_initial_petition"
  | "civil_initial_petition"
  | "criminal_initial_petition"
  | "pension_initial_petition"
  | "labor_initial_petition"
  | "ambiental_initial_petition"
  | "electoral_initial_petition"
  | "enterprise_initial_petition"
  | "intercurrent_motion"
  | "appeal"
  | "labor_interlocutory_appeal"
  | "tax_appeal"
  | "interlocutory_appeal"
  | "criminal_appeal"
  | "labor_terminative_appeal"
  | "reply"
  | "criminal_reply"
  | "tax_reply"
  | "labor_reply"
  | "civil_reply"
  | "administrative_reply"
  | "electoral_reply";

export const actionNameVariants: Partial<Record<ActionNameVariantsType, string[]>> = {
  initial_petition: [
    "Ação de anulação contratual",
    "Ação de cobrança",
    "Ação de execução de título extrajudicial",
    "Ação de indenização",
    "Ação de modificação de guarda",
    "Ação de restituição de valores",
    "Ação de usucapião",
    "Ação ordinária de reconhecimento",
    "Ação revisional",
    "Aditamento a petição inicial de agravo de instrumento",
    "Alegações finais",
    "Contramandado de prisão",
    "Divórcio consensual",
    "Emenda a inicial",
    "Execução de alimentos",
    "Execução de título extrajudicials",
    "Habeas corpus",
    "Impugnação a penhora",
    "Incidente de desconsideração da personalidade jurídica",
    "Inventário extrajudicial",
    "Liquidação de sentença",
    "Mandado de injunção",
    "Mandado de segurança",
    "Peça de cumprimento de sentença",
    "Peça de nulidade de confissão de dívida",
    "Peça processual de noticia crime",
    "Pedido de desbloqueio por bloqueio judicial",
    "Pedido de prisão domiciliar humanitária",
    "Petição de exceção de pré-executividades",
    "Petição de registro de marca",
    "Prosseguimento da execução c/cumprimento de sentença",
    "Reconhecimento de paternidade",
    "Reconhecimento e dissolução de união estável c/c partilha de bens",
    "Ação de vício oculto",
  ],
  tax_initial_petition: ["Anulação de débito fiscal"],
  civil_initial_petition: [
    "Abertura de inventário",
    "Ação rescisória",
    "Impugnacao ao bloqueio judicial de conta bancaria",
  ],
  criminal_initial_petition: [
    "Dosimetria da pena",
    "Memoriais ou memoriais de julgamento",
    "Notícia crime",
    "Pedido de abertura de inquérito policial",
    "Petição postulando a remissão da pena e progressão do regime",
    "Progressão de regime",
    "Queixa-crime",
    "Relaxamento de prisão",
    "Requerimento de arbitramento de fiança",
    "Requerimento de prescrição retroativa",
    "Revisão criminal",
    "Revogação de prisão preventiva",
  ],
  pension_initial_petition: [
    "Ação de concessão de benefício por invalidez",
    "Ação de declaração de inexistência de débito c/c indenização por danos morais",
    "Pedido de aposentadoria",
    "Revisão de aposentadoria",
  ],
  labor_initial_petition: ["Desistência de ação", "Petição de homologação de acordo extrajudicial"],
  intercurrent_motion: [
    "Apresentação de substabelecimento",
    "Discordância de laudo pericial",
    "Impuganação ao laudo pericial",
    "Manifestação",
    "Petição de manifestação processual",
    "Petição de penhora de bens",
  ],
  appeal: [
    "Agravo de despacho denegatório de recurso especial",
    "Agravo em recurso especial",
    "Agravo em recurso extraordinário",
    "Razões de apelação",
    "Recurso adesivo",
    "Recurso especial",
    "Recursos extraordinário",
  ],
  labor_interlocutory_appeal: ["Agravo de instrumento em recurso de revista", "Pedido de reconsideração"],
  tax_appeal: ["Recurso administrativo"],
  interlocutory_appeal: ["Agravo de instrumento", "Agravo interno", "Agravo regimental"],
  criminal_appeal: ["Agravo de execução", "Agravo em execução", "Recurso em sentido estrito"],
  labor_terminative_appeal: ["Agravo de petição", "Recurso de revista"],
  reply: [
    "Contestação com reconvenção",
    "Contraminuta de agravo de instrumento",
    "Contrarrazões à apelação",
    "Contrarrazões ao recurso inominado",
    "Contrarrazões de agravo de instrumento",
    "Contrarrazões recursais",
    "Embargos à execução",
    "Impugnação a contestação",
    "Impugnação ao cumprimento de sentença",
    "Preliminar de contestação em mandado de segurança",
    "Resposta a exceção de pré executividade",
    "Resposta ao despacho do evento",
    "Tréplica a contestação",
  ],
  criminal_reply: ["Defesa de ipm", "Defesa em tribunal do júri", "Defesa prévia"],
  tax_reply: [
    "Resposta à acusação",
    "Contrarrazões a exceção de pré-executividade",
    "Contrarrazões aos embargos de declaração",
  ],
  labor_reply: [
    "Contraminuta de agravo de petição",
    "Contrarrazões ao agravo de instrumento em recurso de revista",
    "Contrarrazões ao recurso de revista",
    "Contrarrazões ao recurso ordinário",
    "Defesa administrativa",
  ],
  civil_reply: ["Embargos à ação monitória", "Impugnação aos embargos à execução"],
  administrative_reply: ["Resposta à um recurso administrativo"],
  electoral_reply: ["Defesa eleitoral"],
  ambiental_initial_petition: ["Denúncia ao icmbio"],
  electoral_initial_petition: ["Representação eleioral"],
  enterprise_initial_petition: ["Ação de dissolução parcial de sociedade"],
};

export const mapperActionToFields = {
  initial_petition: {
    skill: "create_initial_petition",
  },
  tax_initial_petition: {
    area: Area.TAX,
  },
  civil_initial_petition: {
    area: Area.CIVIL,
  },
  criminal_initial_petition: {
    area: Area.CRIMINAL,
  },
  pension_initial_petition: {
    area: Area.SOCIAL_SECURITY,
  },
  labor_initial_petition: {
    area: Area.LABOR,
  },
  intercurrent_motion: {
    skillId: "intercurrent_motion",
  },
  appeal: {
    legal_piece_macro_type: LegalPieceMacroType.APPEAL,
    decision_type: DecisionType.TERMINATIVE_DECISIONS,
  },
  labor_interlocutory_appeal: {
    area: Area.LABOR,
    legal_piece_macro_type: LegalPieceMacroType.APPEAL,
    decision_type: DecisionType.INTERLOCUTORY_DECISIONS,
  },
  tax_appeal: {
    area: Area.TAX,
    legal_piece_macro_type: LegalPieceMacroType.APPEAL,
    decision_type: DecisionType.TERMINATIVE_DECISIONS,
  },
  interlocutory_appeal: {
    legal_piece_macro_type: LegalPieceMacroType.APPEAL,
    decision_type: DecisionType.INTERLOCUTORY_DECISIONS,
  },
  criminal_appeal: {
    area: Area.CRIMINAL,
    legal_piece_macro_type: LegalPieceMacroType.APPEAL,
    decision_type: DecisionType.TERMINATIVE_DECISIONS,
  },
  labor_terminative_appeal: {
    area: Area.LABOR,
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
    decision_type: DecisionType.TERMINATIVE_DECISIONS,
  },
  reply: {
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  criminal_reply: {
    area: Area.CRIMINAL,
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  tax_reply: {
    area: Area.TAX,
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  labor_reply: {
    area: Area.LABOR,
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  civil_reply: {
    area: Area.CIVIL,
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  administrative_reply: {
    area: Area.OTHER,
    "override:string:area": "Administrativo",
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  electoral_reply: {
    area: Area.OTHER,
    "override:string:area": "Eleitoral",
    legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
  },
  ambiental_initial_petition: {
    area: Area.OTHER,
    "override:string:area": "Ambiental",
  },
  electoral_initial_petition: {
    area: Area.OTHER,
    "override:string:area": "Eleitoral",
  },
  enterprise_initial_petition: {
    area: Area.OTHER,
    "override:string:area": "Empresarial",
  },
};

export function getSkillIdByActionNameVariant(value: string): Record<string, string> | undefined {
  for (const key in actionNameVariants) {
    if (actionNameVariants[key as ActionNameVariantsType]?.includes(value)) {
      return mapperActionToFields[key as ActionNameVariantsType];
    }
  }
  return undefined;
}
