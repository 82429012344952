import { alpha, InputAdornment, SxProps, TextField, useTheme } from "@mui/material";
import React from "react";

import { SearchOutlined } from "@mui/icons-material";

interface CaseFilesSearchProps {
  onChange: (value: string) => void;
  sx?: SxProps;
}
export const CaseFilesSearch = ({ onChange, sx }: CaseFilesSearchProps) => {
  const theme = useTheme();

  return (
    <TextField
      sx={{
        "& label.Mui-focused": {
          color: "common.mediumShade",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "common.mediumShade",
        },
        "& .MuiOutlinedInput-root": {
          pl: "8px",
          "& fieldset": {
            borderColor: "common.lightShade",
          },
          "&:hover fieldset": {
            borderColor: "common.mediumShade",
          },
          "&:focus-visible": {
            borderColor: "common.mediumShade",
          },
          "&.Mui-focused fieldset": {
            borderColor: "common.mediumShade",
          },
        },
        borderRadius: "5px",
        ...sx,
      }}
      label="Buscar arquivo"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchOutlined sx={{ color: alpha(theme.palette.common.black, 0.56) }} />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      size="small"
    />
  );
};
