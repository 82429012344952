import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Applicant } from "../types";
import { getApplicantsQueryKey } from "../useApplicants";

export const useEditApplicant = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["updateCase"],
    mutationFn: async ({
      applicantId,
      data,
    }: {
      applicantId: string;
      data: {
        name: string;
        city: string | null;
        cpf: string | null;
        email: string | null;
        houseNumber: string | null;
        maritalStatus: string | null;
        occupation: string | null;
        state: string | null;
        streetName: string | null;
        zipCode: string | null;
      };
    }) => await api.updateApplicant({ applicantId, data }),
    onMutate: async ({ applicantId, data }) => {
      await queryClient.cancelQueries({ queryKey: getApplicantsQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getApplicantsQueryKey());
      queryClient.setQueryData(getApplicantsQueryKey(), (old: Applicant[]) => [
        ...old.map((a) => (a.id === applicantId ? { ...a, ...data } : a)),
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getApplicantsQueryKey(), context?.previousCases);
    },
  });
};
