import { ValueObject } from "@/core/ValueObject";
import { Result } from "@/core/Result";

interface ConfigProps {
  [key: string]: string;
}
interface SessionSettingsProps {
  config?: ConfigProps;
}

/**
 * The SessionSettings is an object with configurable session settings
 * to be used in staging environment.
 * This object is used to test different versions of the backend
 */
export class SessionSettings extends ValueObject<SessionSettingsProps> {
  private constructor(props: SessionSettingsProps) {
    super(props);
  }

  /**
   * Get full config object
   */
  get config(): ConfigProps | undefined {
    return this.props.config;
  }

  static fromString(props: string): Result<SessionSettings> {
    try {
      const settings = JSON.parse(props);

      const sessionSettings = new SessionSettings({ config: settings });
      return Result.ok<SessionSettings>(sessionSettings);
    } catch (e) {
      return Result.fail<SessionSettings>(`Could not create Session Settings`);
    }
  }

  static fromJSON(props: { [key: string]: string }): Result<SessionSettings> {
    try {
      const sessionSettings = new SessionSettings({ config: props });
      return Result.ok<SessionSettings>(sessionSettings);
    } catch (e) {
      return Result.fail<SessionSettings>(`Could not create Session Settings`);
    }
  }

  static empty(): SessionSettings {
    return new SessionSettings({});
  }

  public isValid(setting: string): boolean {
    return !/[`´~^'"\u00C0-\u00FF ]+/i.test(setting);
  }

  /**
   * Transform the current session settings to a set of HTTP headers object
   * This object may be appended directly to a Fetch call and the back end
   * will apply that setting.
   */

  public toHeaders(): Record<string, string> {
    return Object.entries(this.config ?? {}).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [`x-lexter-config-${key}`]: value,
      }),
      {}
    );
  }

  public toDTO(): Record<string, string> {
    return this.config || {};
  }

  public toPersistence() {
    return JSON.stringify(this.config);
  }
}
