import { Either, left, right } from "./Result";
import { ValueObject } from "./ValueObject";

const ERROR_MSG =
  "A senha deve ser composta por 8 caracteres e possuir ao menos uma letra e um número ou caractere especial.";

type ERROR = "MUST_HAVE_LETTER" | "MUST_HAVE_NUMBER_OR_SPECIAL_CHARACTER" | "MUST_HAVE_MINIMUM_LENGTH";

type Errors = {
  errors: ERROR[];
  message: string;
};

interface LoginPasswordProps {
  value: string;
}

/**
 * Represents Login Password
 */
export class LoginPassword extends ValueObject<LoginPasswordProps> {
  private constructor(props: LoginPasswordProps) {
    super(props);
  }

  /**
   * Validate a LoginPassword string. It must have:
   * - at least length 8 and at most 200
   * - at least 1 letter (either upper or lower case)
   * - at least 1 number or special character
   * @param password is a string that may be a login password
   */
  private static isValid(password: string): boolean {
    if (password.length < 8 || password.length > 200) return false;
    if (!/[a-zA-Z]/.test(password)) return false;
    return /(\d|[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~])/.test(password);
  }

  private static hasLetters(password: string): boolean {
    return /(?=[a-zA-Z])/.test(password);
  }

  private static hasSpecialCharacterOrNumber(password: string): boolean {
    return /(?=\d|[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~])/.test(password);
  }

  private static hasMinimumLength(password: string): boolean {
    return password.length >= 8 && password.length <= 200;
  }

  /**
   * Creates a new Login Password instance
   * @param password LoginPassword string representation
   */
  static fromString(password: string): Either<Errors, LoginPassword> {
    if (!LoginPassword.isValid(password)) {
      const errors: ERROR[] = [];
      if (!LoginPassword.hasLetters(password)) errors.push("MUST_HAVE_LETTER");
      if (!LoginPassword.hasSpecialCharacterOrNumber(password)) errors.push("MUST_HAVE_NUMBER_OR_SPECIAL_CHARACTER");
      if (!LoginPassword.hasMinimumLength(password)) errors.push("MUST_HAVE_MINIMUM_LENGTH");
      return left({ errors, message: ERROR_MSG });
    }

    return right(new LoginPassword({ value: password }));
  }
}
