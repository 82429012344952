import React from "react";
import { TextFieldProps, Typography } from "@mui/material";
import { InputField } from "../InputField";

export const PhoneNumberField = ({ label, placeholder, value, onChange, error }: TextFieldProps) => {
  return (
    <InputField
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      label={label}
      error={error}
      inputProps={{
        maxLength: 15,
      }}
      InputProps={{
        startAdornment: (
          <Typography
            sx={{
              mr: 0.5,
            }}
          >
            +55
          </Typography>
        ),
      }}
    />
  );
};
