import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@/components/Button";
import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header/constants";
import React from "react";
import Paper from "@mui/material/Paper";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import { Config } from "@/config/Config";
import { Toast } from "@/taskpane/components/core/Toast";
import { NotFound } from "@/pages/NotFound";

type RowsProps = Array<Row>;

type Row = { id: string; name: string; value: string };

const defaultRows: RowsProps = [
  {
    id: "1",
    name: "",
    value: "",
  },
];

export const SessionSettings = () => {
  const config = Config.getConfig();

  if (config.isProd) {
    return NotFound();
  }

  return SessionSettingsPage(config);
};

const SessionSettingsPage = (config: Config) => {
  const [rows, setRows] = React.useState<RowsProps>(defaultRows);
  const [activeField, setActiveField] = React.useState<string>(`${defaultRows[0].id}-name`);

  const createRow = () => {
    const newRow = {
      id: uuid(),
      name: "",
      value: "",
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (id: string) => {
    const newRows = rows.filter((row) => row.id !== id);

    if (!Boolean(newRows.length)) {
      const newRow = [
        {
          id: uuid(),
          name: "",
          value: "",
        },
      ];

      setRows(newRow);
      return;
    }
    setRows(newRows);
  };

  const loadSettings = () => {
    const settings = config.sessionSettings.toDTO();

    if (Object.keys(settings).length) {
      setRows(settingsToRows(settings));
    }
  };

  const settingsToRows = (settings: { [key: string]: string }) => {
    return Object.keys(settings).map((key) => ({
      id: uuid(),
      name: key,
      value: settings[key],
    }));
  };

  function updateRow<K extends keyof Row>(field: K) {
    return (newRow: Row) => {
      setActiveField(`${newRow.id}-${field}`);
      setRows((prev) => {
        return prev.map((row) => {
          if (row.id === newRow.id) {
            return {
              ...newRow,
            };
          }
          return row;
        });
      });
    };
  }

  const Row = ({ row, isLastElement }: { row: Row; isLastElement: boolean }) => {
    const updateName = (name: string) => {
      updateRow("name")({ ...row, name });
    };

    const updateValue = (value: string) => {
      updateRow("value")({ ...row, value });
    };

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            value={row.name}
            onChange={(e) => updateName(e.target.value)}
            error={!config.sessionSettings.isValid(row.name)}
            helperText={!config.sessionSettings.isValid(row.name) ? "Não é permitido caracteres com acentuação" : ""}
            autoFocus={activeField === `${row.id}-name`}
          />
        </TableCell>
        <TableCell align="left">
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            value={row.value}
            onChange={(e) => updateValue(e.target.value)}
            autoFocus={activeField === `${row.id}-value`}
          />
        </TableCell>
        <TableCell padding="checkbox">
          {isLastElement ? (
            <AddIcon
              onClick={() => {
                createRow();
              }}
              sx={{ color: "common.black", cursor: "pointer" }}
            />
          ) : (
            <CloseIcon
              onClick={() => {
                deleteRow(row.id);
              }}
              sx={{ color: "common.black", cursor: "pointer" }}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const saveSettings = () => {
    const newSettings = rows
      .filter((row) => row.name !== "")
      .reduce((acc, row) => {
        return { ...acc, [row.name]: row.value };
      }, {});

    const response = config.setSessionSettings(newSettings);

    if (response.isFailure) {
      Toast.error("Erro ao salvar configurações", 3000, "bottom-right");
      return;
    }
    Toast.success("Configurações salvas", 3000, "bottom-right");
  };

  const Rows = ({ rows }: { rows: RowsProps }) => {
    return (
      <>
        {rows.map((row, index) => {
          return <Row isLastElement={rows.length - 1 === index} key={row.id} row={row} />;
        })}
      </>
    );
  };

  React.useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDisabled = React.useMemo(() => {
    return rows.some((row) => !config.sessionSettings.isValid(row.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.GO_BACK,
      }}
      contentSx={{
        display: "flex",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          marginY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width={"650px"}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "common.black" }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="title" sx={{ color: "common.white" }}>
                      Nome
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="title" sx={{ color: "common.white" }}>
                      Valor
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Rows rows={rows} />
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: 2,
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                setRows(defaultRows);
              }}
              sx={{
                color: "common.black",
                backgroundColor: "transparent",
                textDecoration: "underline",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "none",
                },
              }}
            >
              <Typography variant="button" sx={{ color: "common.black" }}>
                Cancelar
              </Typography>
            </Button>
            <Button variant="contained" onClick={saveSettings} disabled={saveDisabled}>
              <Typography variant="button" sx={{ color: "common.withe" }}>
                Salvar
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
};
