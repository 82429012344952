import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import { Box, IconButton, Modal as MuiModal, SxProps } from "@mui/material";
import React, { ReactNode } from "react";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  sx?: SxProps;
}

export const Modal = ({ children, open, onClose, sx }: ModalProps) => (
  <MuiModal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "calc(100% - 32px)",
        maxWidth: "400px",
        bgcolor: "common.white",
        borderRadius: "5px",
        padding: 2,
        outline: "none",
        ...sx,
      }}
    >
      <Box
        className="modal-box-header"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: -1,
        }}
      >
        <IconButton aria-label="fechar" onClick={onClose}>
          <CloseRoundedIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Box
        className="modal-box-content"
        sx={{
          mt: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  </MuiModal>
);
