import React, { useEffect } from "react";
import { HubspotFormData, useHubspot } from "@/hooks/useHubspot";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { casesPerMonthOptions, numberOfEmployeesOptions } from "./defaultValues";
import toast from "react-hot-toast";

interface HubspotFormProps {
  onSubmit: () => void;
}

export const HubspotForm: React.FC<HubspotFormProps> = ({ onSubmit }) => {
  const { submitHubspotForm, loading, error, success } = useHubspot();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      hs_whatsapp_phone_number: "",
      quantos_casos_novos_voce_recebe_por_mes_: "",
      numemployees: "",
      areas_de_atuacao: "",
    },
  });

  const casesPerMonthOption = watch("quantos_casos_novos_voce_recebe_por_mes_");
  const employeesOption = watch("numemployees");

  const onSubmitForm = (formData: HubspotFormData) => {
    submitHubspotForm(formData);
    onSubmit();
  };

  useEffect(() => {
    if (success) {
      toast.success("Formulário enviado com sucesso!");
    }
    if (error) {
      toast.error(error);
    }
  }, [success, error]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitForm)}
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <TextField
        label="Qual o seu e-mail?"
        placeholder="Digite seu e-mail"
        fullWidth
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : ""}
        {...register("email", { required: "Email é obrigatório" })}
      />

      <TextField
        label="Qual seu número de WhatsApp?"
        placeholder="Digite seu número de WhatsApp"
        fullWidth
        variant="outlined"
        error={!!errors.hs_whatsapp_phone_number}
        helperText={errors.hs_whatsapp_phone_number?.message ?? ""}
        {...register("hs_whatsapp_phone_number", {
          required: "Número de WhatsApp é obrigatório",
        })}
      />

      <FormControl fullWidth error={!!errors.quantos_casos_novos_voce_recebe_por_mes_}>
        <InputLabel shrink id="quantos_casos_novos_voce_recebe_por_mes_">
          Quantos casos novos você recebe por mês?
        </InputLabel>
        <Select
          labelId="quantos_casos_novos_voce_recebe_por_mes_"
          label="Quantos casos novos você recebe por mês?"
          displayEmpty
          renderValue={
            casesPerMonthOption !== "" ? undefined : () => "Selecione quantos casos novos você recebe por mês"
          }
          {...register("quantos_casos_novos_voce_recebe_por_mes_", {
            required: "Esse campo é obrigatório",
          })}
          sx={{
            textAlign: "left",
            fontSize: casesPerMonthOption == "" ? undefined : "16px",
            color: casesPerMonthOption == "" ? "common.mediumShade" : undefined,
          }}
        >
          {casesPerMonthOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {errors.quantos_casos_novos_voce_recebe_por_mes_ && (
          <FormHelperText>{errors.quantos_casos_novos_voce_recebe_por_mes_.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth error={!!errors.numemployees}>
        <InputLabel shrink id="numemployees">
          Quantos advogados trabalham em seu escritório?
        </InputLabel>
        <Select
          labelId="numemployees"
          label="Quantos advogados trabalham em seu escritório?"
          displayEmpty
          renderValue={
            employeesOption !== "" ? undefined : () => "Selecione quantos advogados trabalham em seu escritório"
          }
          {...register("numemployees")}
          sx={{
            textAlign: "left",
            fontSize: employeesOption == "" ? undefined : "16px",
            color: employeesOption == "" ? "common.mediumShade" : undefined,
          }}
        >
          {numberOfEmployeesOptions.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {errors.numemployees && <FormHelperText>{errors.numemployees.message}</FormHelperText>}
      </FormControl>

      <TextField
        label="Áreas de atuação"
        placeholder="Digite as áreas de atuação do seu escritório"
        fullWidth
        variant="outlined"
        error={!!errors.areas_de_atuacao}
        helperText={errors.areas_de_atuacao?.message}
        {...register("areas_de_atuacao")}
      />

      <Button type="submit" variant="contained" size="large" disabled={loading} sx={{ mt: 2 }}>
        {loading ? <CircularProgress size={24} sx={{ color: "common.white" }} /> : "Enviar"}
      </Button>
    </Box>
  );
};
