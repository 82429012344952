import React, { useRef, useState } from "react";
import { Stepper, StepperProps, Step, StepLabel, Typography } from "@mui/material";
import { SkillStep } from "@/core/skillForms/types";
import { useResizeObserver } from "@/hooks/useResizeObserver";

interface SkillStepperProps extends StepperProps {
  steps: SkillStep[];
  currentStepIndex: number;
}

export const SkillStepper = ({ steps, currentStepIndex }: SkillStepperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [alternativeLabel, setAlternativeLabel] = useState<boolean>(false);
  const [hideLabel, setHideLabel] = useState<boolean>(false);

  useResizeObserver({
    ref,
    onResize: (size) => {
      const width = size.width ?? 0;
      setAlternativeLabel(width < 400);
      setHideLabel(width < 210);
    },
  });

  return (
    <Stepper
      ref={ref}
      activeStep={currentStepIndex}
      alternativeLabel={alternativeLabel}
      sx={{
        width: "100%",
      }}
    >
      {steps.map((_, index) => (
        <Step key={index} completed={index < currentStepIndex}>
          <StepLabel StepIconProps={{ sx: { fontSize: "24px" } }}>
            {!hideLabel && <Typography variant="subtitle2">{steps[index].title ?? ""}</Typography>}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
