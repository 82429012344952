import { Button } from "@/components/Button";
import { useAuthContext } from "@/contexts/AuthContext";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { logger } from "@/core/logger";
import { useApi } from "@/hooks/useApi";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { getParam } from "@/utils/getParams";
import { Box, TextField } from "@mui/material";
import { AxiosError } from "axios";
import React, { useEffect } from "react";

export const CouponButton = () => {
  const { loading, success, onCouponApplied, removeCoupon, selectedPlan } = usePaymentContext();
  const [couponCode, setCouponCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { checkCoupon } = useApi();
  const { onlyUnlimitedPlanEnabled, essentialPlanCoupon } = useFeatureFlags();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const couponCode = getParam("cupom");
    if (couponCode) {
      setCouponCode(couponCode);
      handleApplyCoupon(couponCode);
    } else if (onlyUnlimitedPlanEnabled && essentialPlanCoupon !== "") {
      setCouponCode(essentialPlanCoupon);
      handleApplyCoupon(essentialPlanCoupon);
    }
  }, []);

  useEffect(() => {
    if (couponCode) {
      setCouponCode(couponCode);
      handleApplyCoupon(couponCode);
    }
  }, [selectedPlan]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      removeCoupon();
    }
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = async (couponFromParam?: string) => {
    setErrorMessage("");
    setIsLoading(true);
    removeCoupon();

    try {
      const code = couponFromParam ?? couponCode;
      const coupon = await checkCoupon({ couponCode: code, companyId: user?.companyId, planName: selectedPlan.id });
      if (coupon && coupon.active) {
        onCouponApplied({
          code,
          durationType: coupon.durationType,
          durationInMonths: coupon.durationInMonths,
          percentOff: coupon.percentOff,
          amountOff: coupon.amountOff,
        });
      }
    } catch (err: unknown) {
      logger.error("handleApplyCoupon", err);
      if (err instanceof AxiosError) {
        const message = err.response?.data?.message;
        if (message.includes("Coupon invalid for this plan")) {
          return setErrorMessage("Cupom inserido não se aplica a este plano");
        } else if (message.includes("Coupon not found")) {
          return setErrorMessage("Cupom não encontrado");
        }
      }
      setErrorMessage("Cupom inválido");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: loading || isLoading ? "center" : "baseline" }}>
      <TextField
        variant="outlined"
        disabled={loading || isLoading || success}
        onChange={handleChange}
        sx={{
          flexGrow: 1,
          label: {
            fontSize: "14px",
            fontWeight: "700",
            color: !!errorMessage ? "error.dark" : "text.primary",
          },
        }}
        value={couponCode}
        label="Cupom de desconto"
        error={!!errorMessage}
        helperText={errorMessage}
        placeholder="Insira seu cupom"
      />
      <Button
        isLoading={isLoading}
        loaderStyle={{ maxWidth: "110px" }}
        disabled={loading || couponCode === "" || isLoading}
        onClick={() => handleApplyCoupon()}
        variant="outlined"
        sx={{
          px: 4,
          py: 0,
          fontSize: "14px",
        }}
        size="small"
      >
        Aplicar
      </Button>
    </Box>
  );
};
