export const mergeColors = (color1: string, color2: string, percentage: number) => {
  const hexToRgb = (hex: string) => {
    hex = hex.replace("#", "");
    const bigint = parseInt(hex, 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  const rgbToHex = (r: number, g: number, b: number) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const r = Math.round(r1 * (1 - percentage) + r2 * percentage);
  const g = Math.round(g1 * (1 - percentage) + g2 * percentage);
  const b = Math.round(b1 * (1 - percentage) + b2 * percentage);

  return rgbToHex(r, g, b);
};
