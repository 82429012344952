import React, { ReactNode, useState } from "react";
import { CouponSelected, NewPlan } from "./types";
import { PaymentContext } from "./context";
import { useNewPlans } from "@/hooks/useNewPlans";

interface PaymentProviderProps {
  children: ReactNode;
}
export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [couponSelected, setCouponSelected] = useState<CouponSelected | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string | ReactNode | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const plans = useNewPlans();
  const [selectedPlan, setSelectedPlan] = useState<NewPlan>(plans[0]);

  const onPlanChange = (plan: NewPlan) => {
    setSelectedPlan(plan);
  };

  const onLoadingChange = (loading: boolean, loadingMessage?: string | ReactNode) => {
    setLoading(loading);
    setLoadingMessage(loadingMessage);
  };

  const handleSuccessChange = (success: boolean) => {
    setSuccess(success);
  };

  const handleErrorChange = (
    error: boolean,
    options?: {
      errorMessage?: string | ReactNode;
    }
  ) => {
    setError(error);
    setErrorMessage(options?.errorMessage);
  };

  const onCouponApplied = (coupon: CouponSelected) => {
    setCouponSelected(coupon);
  };

  const removeCoupon = () => {
    setCouponSelected(null);
  };

  const reset = () => {
    setLoading(false);
    setSelectedPlan(plans[0]);
    setError(false);
    setSuccess(false);
    setCouponSelected(null);
    setLoadingMessage(undefined);
    setErrorMessage(undefined);
  };

  return (
    <PaymentContext.Provider
      value={{
        selectedPlan,
        onPlanChange,
        onLoadingChange,
        loading,
        error,
        success,
        handleSuccessChange,
        handleErrorChange,
        onCouponApplied,
        couponSelected,
        loadingMessage,
        removeCoupon,
        errorMessage,
        reset,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
