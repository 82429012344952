import { LexterDate } from "@/core/LexterDate";

export const formatDateShortMonth = (date: string) => {
  const newDate = LexterDate.fromCortexString(date);

  if (newDate.isFailure) {
    return "-";
  }

  return newDate.getValue().toShortMonthString();
};
