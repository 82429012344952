import { env } from "@/constants/environment";
import { useAuthContext } from "@/contexts/AuthContext";
import { PlanType } from "@/hooks/credits/types";
import { useCredits } from "@/hooks/credits/useCredits";
import { useApi } from "@/hooks/useApi";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { openUrl } from "@/utils/openUrl";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useNavigate } from "react-router-dom";

export const useOpenBilling = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const flags = useFeatureFlags();
  const { data: credits } = useCredits();
  const { getPaymentUserPortal } = useApi();

  const planType = credits?.companyPlan?.plan;

  return async () => {
    if (planType && [PlanType.FREEMIUM, PlanType.TRIAL].includes(planType)) {
      if (outsideOfficeClient()) {
        if (flags.onlyUnlimitedPlanEnabled) {
          navigate(ROUTE_PATHS.INTERNAL_PAYMENT);
        } else {
          navigate(ROUTE_PATHS.PLANS);
        }
      } else {
        openBillingForFreeUsers();
      }
    } else {
      if (user) {
        const response = await getPaymentUserPortal({ companyId: user.companyId });
        openUrl(response.paymentUserPortal, false);
      }
    }
  };
};

const openBillingForFreeUsers = () => {
  const url = env.IS_PRODUCTION
    ? "https://assistente.lexter.ai/app/payment"
    : "https://assistente-staging.lexter.ai/app/payment";

  openUrl(url);
};
