export const activitiesDailyDefaultValues = [
  { value: "PREPARATION_OF_LITIGATION_DOCUMENTS", label: "Elaboração de peças do contecioso" },
  { value: "JURISPRUDENCE_SEARCH", label: "Pesquisa de jurisprudência" },
  { value: "ANALYSIS_OF_CASE_RECORDS", label: "Análise de autos de processos" },
  { value: "SUMMARY_OF_LEGAL_DOCUMENTS", label: "Resumo de peças jurídicas" },
  { value: "DEFINITION_OF_LEGAL_STRATEGY", label: "Definição de estratégia jurídica" },
  { value: "DEADLINE_TRACKING", label: "Acompanhamento de prazos" },
  { value: "LABOR_CALCULATIONS", label: "Cálculos trabalhistas" },
  { value: "TAX_CALCULATIONS", label: "Cálculos tributários" },
  { value: "CUSTOMER_INTERVIEWS", label: "Entrevistas com clientes" },
  { value: "CONTRACT_REVIEW", label: "Revisão de contratos" },
  { value: "PREPARATION_OF_CONTRACTS", label: "Elaboração de contratos" },
  { value: "EXTRAJUDICIAL_NOTIFICATIONS", label: "Notificações extrajudiciais" },
  { value: "ANALYSIS_OF_PROPERTY_REGISTRATIONS", label: "Análise de matrículas de imóveis" },
  { value: "ANALYSIS_OF_LARGE_VOLUMES_OF_DOCUMENTS", label: "Análise de grandes volumes de documentos" },
  { value: "OTHERS", label: "Outros" },
];

export const activityAreasDefaultValues = [
  { value: "CIVIL", label: "Cível" },
  { value: "LABOR", label: "Trabalhista" },
  { value: "TAX", label: "Tributário" },
  { value: "CRIMINAL", label: "Criminal" },
  { value: "CAPITAL_MARKETS", label: "Mercado de capitais" },
  { value: "REAL_ESTATE", label: "Imobiliário" },
  { value: "DIGITAL_AND_DATA_PROTECTION", label: "Direito digital e Proteção de dados" },
  { value: "CORPORATE_AND_MA", label: "Societário & M&A" },
  { value: "INTELLECTUAL_PROPERTY", label: "Propriedade intelectual" },
  { value: "INFRASTRUCTURE", label: "Infraestrutura" },
  { value: "PUBLIC_AND_REGULATORY", label: "Direito público e regulatório" },
  { value: "ENVIRONMENTAL", label: "Ambiental" },
  { value: "COMPETITIVE", label: "Concorrencial" },
  { value: "OTHERS", label: "Outros" },
];

export const sourceOfReferralDefaultValues = [
  { value: "GOOGLE_SEARCH", label: "Pesquisa no Google" },
  { value: "SOCIAL_MEDIA", label: "Redes Sociais" },
  { value: "RECOMMENDATION", label: "Indicação de amigo ou colega" },
  { value: "OFFICE_STARTED_USING", label: "Escritório começou a utilizar" },
  { value: "ADDIN_WORD_STORE", label: "Loja de suplementos no Word" },
  { value: "EVENTS", label: "Eventos" },
  { value: "NEWS", label: "Notícias" },
  { value: "IA_COURSE", label: "Curso de IA" },
  { value: "OTHERS", label: "Outros" },
];

export const numberOfEmployees = [
  {
    value: "1",
    label: "1 advogado",
  },
  { value: "2-5", label: "2 a 5 advogados" },
  { value: "6-10", label: "6 a 10 advogados" },
  { value: "11-20", label: "11 a 20 advogados" },
  { value: "21-50", label: "21 a 50 advogados" },
  { value: "51-100", label: "51 a 100 advogados" },
  { value: "100+", label: "Mais de 100 advogados" },
];

export const numberOfMotionsPerWeekOptions = [
  { value: "-2", label: "Menos de 2 peças por semana" },
  { value: "3-5", label: "3 a 5 peças por semana" },
  { value: "6-10", label: "6 a 10 peças por semana" },
  { value: "10-20", label: "10 a 20 peças por semana" },
  { value: "20+", label: "Mais de 20 peças por semana" },
];

export const brazilStates = [
  { value: "Acre" },
  { value: "Alagoas" },
  { value: "Amapá" },
  { value: "Amazonas" },
  { value: "Bahia" },
  { value: "Ceará" },
  { value: "Distrito Federal" },
  { value: "Espírito Santo" },
  { value: "Goiás" },
  { value: "Maranhão" },
  { value: "Mato Grosso" },
  { value: "Mato Grosso do Sul" },
  { value: "Minas Gerais" },
  { value: "Pará" },
  { value: "Paraíba" },
  { value: "Paraná" },
  { value: "Pernambuco" },
  { value: "Piauí" },
  { value: "Rio de Janeiro" },
  { value: "Rio Grande do Norte" },
  { value: "Rio Grande do Sul" },
  { value: "Rondônia" },
  { value: "Roraima" },
  { value: "Santa Catarina" },
  { value: "São Paulo" },
  { value: "Sergipe" },
  { value: "Tocantins" },
];

export const annualRevenueOptions = [
  { value: "0-180k", label: "Até R$ 180.000,00" },
  { value: "181k-360k", label: "De R$ 180.000,01 a R$ 360.000,00" },
  { value: "361k-720k", label: "De R$ 360.000,01 a R$ 720.000,00" },
  { value: "720k-1800k", label: "De R$ 720.000,01 a R$ 1.800.000,00" },
  { value: "1800k+", label: "Acima de R$ 1.800.000,00" },
];
