import { UserInput, UserInputSource } from "@/contexts/WebSocketContext";
import { base64ToFile } from "../base64ToFile";

export const skillInputToUserInput = (input: string): UserInput => {
  const inputFile = input.startsWith("data:") ? base64ToFile(input) : undefined;

  return inputFile
    ? {
        source: UserInputSource.FILE,
        file: inputFile,
      }
    : {
        source: UserInputSource.TEXT,
        text: input,
      };
};
