import { Box } from "@mui/material";
import React from "react";
import { CreditsHistoryItemSkeleton } from "../../CreditsHistoryItem/CreditsHistoryItemSkeleton";

export const CreditsHistoryListSkeleton = () => (
  <Box>
    {Array.from(Array(10).keys()).map((key) => (
      <CreditsHistoryItemSkeleton key={key} />
    ))}
  </Box>
);
