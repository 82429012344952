import { Box, Button, List, ListItem, SxProps, Typography } from "@mui/material";
import React from "react";
import { NewPlan } from "@/contexts/PaymentContext";
import { Check, Close } from "@mui/icons-material";
import { PlanType } from "@/hooks/credits/types";

export interface PlanCardProps {
  plan: NewPlan;
  recommended?: boolean;
  handleBuy: () => void;
  sx?: SxProps;
  actualUserPlan?: PlanType;
}

export const NewPlanCard = ({ plan, handleBuy, sx, actualUserPlan }: PlanCardProps) => {
  const isProfessionalWithWppPlan = plan.id === PlanType.PROFESSIONAL_WITH_WPP;

  return (
    <Box
      sx={{
        px: 3,
        pt: 3,
        pb: 1,
        position: "relative",
        backgroundColor: isProfessionalWithWppPlan ? "orange.light.5" : "common.white",
        display: "flex",
        flexDirection: "column",
        gap: 1.25,
        width: "360px",
        "@media (max-width: 400px)": {
          minWidth: 200,
          flex: 1,
        },
        ...sx,
      }}
    >
      <Typography
        variant="h6"
        fontWeight={isProfessionalWithWppPlan ? 700 : 400}
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        {plan.title}
        {plan.id === actualUserPlan && (
          <Typography textTransform="none" fontSize={12} bgcolor="grey.100" px={1} borderRadius={4} component="span">
            Plano atual
          </Typography>
        )}
      </Typography>

      <Typography variant="body">{plan.subtitle}</Typography>

      {plan.price && (
        <div>
          <Box sx={{ display: "flex", gap: 0.25, alignItems: "end", mt: 3 }}>
            <Typography
              variant="body1"
              color={isProfessionalWithWppPlan ? "primary.dark" : "common.black"}
              fontWeight={isProfessionalWithWppPlan ? 700 : 400}
              fontSize={24}
            >
              {plan.price
                .toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
                .replace(",00", "")}
            </Typography>
            <Typography
              color={isProfessionalWithWppPlan ? "primary.dark" : "common.black"}
              variant="body2"
              fontWeight={isProfessionalWithWppPlan ? 700 : 400}
            >
              /por mês
            </Typography>
          </Box>
          <Typography mb={3} mt={0.5} variant="body2">
            cobrança mensal
          </Typography>
        </div>
      )}

      <Button
        sx={{
          bgcolor: plan.id === PlanType.PROFESSIONAL_WITH_WPP ? "primary.main" : "secondary.main",
          ":hover": {
            bgcolor: plan.id === PlanType.PROFESSIONAL_WITH_WPP ? "primary.dark" : "orange.light.40",
          },
        }}
        variant="contained"
        onClick={handleBuy}
        size="small"
      >
        Assinar
      </Button>

      <Box
        sx={{
          height: (plan as NewPlan).id ? 270 : 80,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            background: "none",
            border: "none",
            listStyleType: "none",
            "& .MuiListItem-root": {
              p: 0,
              "& .MuiTypography-root": {
                whiteSpace: "normal",
              },
            },
          }}
        >
          <Typography mb={1} mt={2} fontSize={14} fontWeight={700}>
            Inclui:{" "}
          </Typography>
          <ListItem>
            <Check sx={{ mr: 0.5 }} fontSize="inherit" />
            <Typography variant="body2" fontWeight={700}>
              <> Créditos ilimitados mensais</>
            </Typography>
          </ListItem>
          {(plan as NewPlan).id && (
            <>
              {(plan.withoutPerksList ?? []).map((perk, index) => (
                <PerksItem key={index} notIncludePerk>
                  {perk}
                </PerksItem>
              ))}
              {(plan.perksDescription ?? []).map((perk, index) => (
                <PerksItem key={index}>{perk}</PerksItem>
              ))}
            </>
          )}
        </List>
      </Box>
    </Box>
  );
};

export const PerksItem = ({ children, notIncludePerk }: { children: string; notIncludePerk?: boolean }) => {
  return (
    <ListItem
      sx={{
        display: "flex",
        gap: 0.5,
        alignItems: "start",
      }}
    >
      {notIncludePerk ? <Close fontSize="inherit" /> : <Check fontSize="inherit" />}
      <Typography variant="body2">{children}</Typography>
    </ListItem>
  );
};
