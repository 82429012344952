import { useEffect, useRef, useState } from "react";
import { clamp } from "@/utils/clamp";

export const useResizable = () => {
  const [width, setWidth] = useState(0);
  const [isResizing, setIsResizing] = useState(false);
  const isResizingRef = useRef(false);
  const minResizeWidthRef = useRef(0);
  const maxResizeWidthRef = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const createOverlay = () => {
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.bottom = "0";
    overlay.style.right = "0";
    overlay.style.cursor = "ew-resize";
    overlay.style.zIndex = "9999";
    overlayRef.current = overlay;
    document.body.appendChild(overlay);
  };

  const removeOverlay = () => {
    if (overlayRef.current) {
      document.body.removeChild(overlayRef.current);
      overlayRef.current = null;
    }
  };

  const handleMouseDown = () => {
    isResizingRef.current = true;
    setIsResizing(true);
    createOverlay();
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizingRef.current) {
      const divLeft = containerRef.current?.getBoundingClientRect().left || 0;
      const newWidth = e.clientX - divLeft;
      setWidth(clamp(newWidth, minResizeWidthRef.current, maxResizeWidthRef.current));
    }
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    setIsResizing(false);
    removeOverlay();
  };

  const getContainerWidth = () => {
    if (!containerRef.current) return;

    return containerRef.current.getBoundingClientRect().width;
  };

  const calculateLimits = () => {
    const containerWidth = getContainerWidth();
    if (!containerWidth) return;

    const minResizeWidth = Math.round(containerWidth * 0.2);
    const maxResizeWidth = Math.round(containerWidth * 0.8);

    minResizeWidthRef.current = minResizeWidth;
    maxResizeWidthRef.current = maxResizeWidth;
  };

  useEffect(() => {
    const containerWidth = getContainerWidth();
    if (containerWidth) {
      setWidth(Math.round(containerWidth * 0.4));
    }

    calculateLimits();

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("resize", calculateLimits);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("resize", calculateLimits);
      removeOverlay();
    };
  }, []);

  return {
    containerRef,
    width,
    isResizing,
    handleMouseDown,
  };
};
