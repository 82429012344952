import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { Case, CaseMessage } from "../types";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { casesMock } from "../useLexZapCase/mocks";

export const getCasesQueryKey = () => ["lexZapCases"];

export const useLexZapCases = () => {
  const queryClient = useQueryClient();
  const { getLexZapCases } = useApi();

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (update.type === WhatsappUpdateType.CASE_UPDATE) {
        queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) => {
          const existingMessages = queryClient.getQueryData<CaseMessage[]>(
            getMessagesQueryKey({ chatId: update.caseData.chatId })
          );
          if (existingMessages && existingMessages.length > 0) {
            update.caseData.lastMessage = existingMessages[existingMessages.length - 1];
          } else if (update.caseData.lastMessage) {
            const { lastMessage } = update.caseData;
            queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ chatId: update.caseData.chatId }), () => [
              lastMessage,
            ]);
          }

          const caseExists = oldCases.some((caseItem) => caseItem.id === update.caseData.id);
          if (caseExists) {
            return oldCases.map((caseItem) =>
              caseItem.id === update.caseData.id
                ? { ...caseItem, ...update.caseData, lastMessage: caseItem.lastMessage }
                : caseItem
            );
          } else {
            const updatedCases = oldCases.filter((caseItem) => caseItem.chatId !== update.caseData.chatId);

            return [...updatedCases, update.caseData];
          }
        });
      }
    },
  });

  return useQuery<Case[]>({
    queryKey: getCasesQueryKey(),
    queryFn: async () => {
      const cases = await getLexZapCases();

      return [
        ...cases,
        ...casesMock.map((mock) => ({ ...mock, lastMessage: mock.messages[mock.messages.length - 1] })),
      ];
    },
  });
};
