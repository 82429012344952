import CreateIcon from "@mui/icons-material/Create";
import { Box, CircularProgress, IconButton, Link, TextField, Typography } from "@mui/material";
import { ExitConfirmDialog } from "@/components/ExitConfirmDialog";
import { useMessagesContext } from "@/contexts/MessagesContext";
import { useThreadContext } from "@/contexts/ThreadContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@/taskpane/components/core/Toast";
import { useRenameThread } from "@/hooks/threads/useRenameThread";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

const LINK_SX = {
  color: "text.primary",
  ":hover": {
    textDecoration: "none",
  },
};

interface HeaderEditorProps {
  chatWidth: number;
  onClickDocuments?: () => void;
  onClickEditor?: () => void;
}

export const HeaderEditor = ({ chatWidth, onClickDocuments, onClickEditor }: HeaderEditorProps) => {
  const [exitModalParams, setExitModalParams] = useState<{
    title: string;
    action: () => void;
    actionType: "NEW" | "RESTART" | "EXISTING" | "EXIT";
  }>();

  const { waitingForResponse } = useMessagesContext();
  const { currentThread, isGeneratingThreadName } = useThreadContext();

  const { name: currentThreadName, id: threadId } = currentThread || {};

  const [editThreadName, setEditThreadName] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { isOnboarding } = useDocumentOnboarding();

  const shouldDisplayExitConfirmation = !!waitingForResponse;

  const { mutateAsync: renameThread, isPending: isRenamingThread } = useRenameThread();

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditThreadName(event.target.value);
  };

  const handleFileNameClick = () => {
    setIsEditing(true);
  };

  const handleUpdateThreadName = async () => {
    if (!threadId || !editThreadName || editThreadName.length === 0 || currentThreadName === editThreadName) {
      setIsEditing(false);
      return;
    }
    try {
      await renameThread({ threadId, name: editThreadName });
      setIsEditing(false);
      WebToast.success("Nome da sessão alterado com sucesso");
    } catch (err) {
      WebToast.error("Erro ao alterar o nome da sessão");
      setIsEditing(false);
    }
  };

  const handleGoToHome = () => {
    const isOutsideOfficeClient = outsideOfficeClient();
    const goToHome = () => (isOutsideOfficeClient ? navigate(ROUTE_PATHS.HOME) : navigate(ROUTE_PATHS.CHAT));

    const saveDocumentsAndGoToHome = async () => {
      goToHome();
    };

    if (!shouldDisplayExitConfirmation) {
      saveDocumentsAndGoToHome();
      return;
    }

    setExitModalParams({
      title: "REINICIAR SESSÃO",
      action: saveDocumentsAndGoToHome,
      actionType: "RESTART",
    });
  };

  const goToDocuments = () => {
    if (onClickDocuments) {
      onClickDocuments();
    }
  };

  const goToEditor = () => {
    if (onClickEditor) {
      onClickEditor();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: `${chatWidth - 40}px`,
          justifyContent: "space-between",
          height: "100%",
          "&:hover > button": { display: "flex" },
        }}
      >
        {isEditing ? (
          <TextField
            value={editThreadName ?? currentThreadName}
            onChange={handleFileNameChange}
            onBlur={handleUpdateThreadName}
            autoFocus
            variant="standard"
            sx={{
              flex: 1,
              fontSize: "14px",
              fontWeight: "700",
              width: "100%",
              "& .MuiInputBase-root.Mui-disabled::before": {
                borderBottom: "none",
              },
            }}
            disabled={isRenamingThread || isGeneratingThreadName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdateThreadName();
              }
            }}
          />
        ) : (
          <Typography
            variant="preTitle"
            sx={{ flex: 1, width: "100%", fontSize: "14px", textTransform: "none" }}
            onClick={handleFileNameClick}
          >
            {editThreadName ?? currentThreadName}
          </Typography>
        )}
        {isRenamingThread || isGeneratingThreadName ? (
          <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <IconButton
            onClick={handleFileNameClick}
            sx={{
              display: "none", // show only when hovering
            }}
          >
            <CreateIcon />
          </IconButton>
        )}
      </Box>

      <Box sx={{ width: "1px", height: "18px", backgroundColor: "common.mediumShade", margin: "0 16px" }} />

      {!isOnboarding && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link component="button" onClick={handleGoToHome} sx={{ ...LINK_SX }}>
            <Typography variant="bold" data-label="Nova Sessão" sx={{ color: "text.primary" }}>
              Nova Sessão
            </Typography>
          </Link>
          <Link component="button" onClick={goToEditor} sx={{ ...LINK_SX }}>
            <Typography variant="bold" data-label="Editor" sx={{ color: "text.primary" }}>
              Editor
            </Typography>
          </Link>
          <Link component="button" onClick={goToDocuments} sx={{ ...LINK_SX }}>
            <Typography variant="bold" data-label="Documentos" sx={{ color: "text.primary" }}>
              Documentos
            </Typography>
          </Link>
        </Box>
      )}

      <ExitConfirmDialog
        open={!!exitModalParams}
        title={exitModalParams?.title || ""}
        onConfirm={() => {
          setExitModalParams(undefined);
          exitModalParams?.action();
        }}
        action={exitModalParams?.actionType || "NEW"}
        onCancel={() => setExitModalParams(undefined)}
      />
    </Box>
  );
};
