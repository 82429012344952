import { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps, getTemplate } from "@rjsf/utils";
import React from "react";

/** The `TextareaWidget` is a widget for rendering input fields as textarea.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function TextareaWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseInputTemplate = getTemplate<"BaseInputTemplate", T, S, F>("BaseInputTemplate", registry, options);

  let rows: string | number = 5;
  if (typeof options.rows === "string" || typeof options.rows === "number") {
    rows = options.rows;
  }

  return <BaseInputTemplate {...props} multiline rows={rows} input="" />;
}
