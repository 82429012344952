import { buildSkillStep } from "@/core/skillForms/builder";

export const createContractSkillStep1 = buildSkillStep({
  description: "Elabore contratos com base no contexto do seu caso.",
  fields: [
    {
      name: "contract_micro_type",
      type: "string",
      ui: {
        label: "Qual é o tipo do contrato?",
        placeholder: "Exemplo: Contrato de aluguel",
      },
    },
  ],
});
