import { Close as CloseIcon } from "@mui/icons-material";
import { BoxProps } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React from "react";

interface HeaderProps extends BoxProps {
  onClose: () => unknown;
  children?: React.ReactNode;
}

export const Header = ({ onClose, children, ...rest }: HeaderProps) => {
  return (
    <Box
      {...rest}
      sx={{
        py: 2,
        px: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...rest.sx,
      }}
    >
      {children}

      <IconButton aria-label={"Fechar"} onClick={onClose} size="medium">
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
