import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  to_edit_text: Type.String(),
  user_instructions: Type.String(),
  document_text: Type.String(),
});

const actionId = ActionId.EDIT_DOCUMENT;

export const editDocumentSkill: Skill<typeof inputSchema> = {
  id: "edit_document",
  name: "Editar texto",
  credits: CreditPerSkill.ESSENTIAL_SKILL,
  inputSchema,
  actionId,
  type: "payload",
  customForm: true,
  tags: ["dependsOnDocument"],
  getPayload: (input) => ({
    skillId: actionId,
    requestId: uuidV4(),
    payload: {
      to_edit_text: input.to_edit_text,
      user_instructions: input.user_instructions,
      document_text: input.document_text,
    },
    messageToSave: "Editar texto",
  }),
};
