import { Box, SxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface TextProps {
  children?: ReactNode;
  sx?: SxProps;
}

export const Text = ({ children, sx }: TextProps) => {
  return (
    <Box sx={sx}>
      <Typography variant="multiLineBody">{children}</Typography>
    </Box>
  );
};
