import React, { useCallback, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import OverworkedEmployee from "@/assets/svgs/overworked-employee.svg?react";
import Collaboration from "@/assets/svgs/collaboration.svg?react";
import { HubspotForm } from "./components/HubspotForm";

const ContentSection: React.FC = () => (
  <Box
    sx={{
      flex: 1,
      maxWidth: "540px",
      minHeight: "780px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "left",
      padding: "20px",
      gap: "45px",
    }}
  >
    <Typography variant="h5" textAlign="left" gutterBottom>
      Assistente para WhatsApp
    </Typography>
    <Typography variant="body1" textAlign="left" gutterBottom>
      Essa nova funcionalidade permite que a Lexter faça a primeira interação com seus clientes e te dê tempo livre para
      focar no que mais importa.
    </Typography>
    <Typography variant="body1" textAlign="left" gutterBottom>
      Vamos{" "}
      <Box component="span" sx={{ color: "primary.main" }}>
        gerar um resumo do caso
      </Box>{" "}
      desse cliente e disponibilizar isso pra você por aqui e pelo seu número de WhatsApp, e enquanto isso a conversa
      fica disponível pra você conferir e intervir 100% do tempo.
    </Typography>
    <Typography variant="body1" textAlign="left" gutterBottom>
      Esses são os passos para um jornada de trabalho mais leve:
    </Typography>

    <Box
      sx={{
        backgroundColor: "common.lightShade",
        px: "14px",
        py: "16px",
        borderRadius: "6px",
        gap: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="body1">• Solicite um número Lexter</Typography>
      <Typography variant="body1">• Direcione seus novos clientes para esse número</Typography>
      <Typography variant="body1">• Receba o resumo do caso</Typography>
    </Box>

    <Typography variant="body1" color="textSecondary">
      <Box component="span" sx={{ fontWeight: 700 }}>
        Lembre-se:
      </Box>{" "}
      a Lexter terá acesso às conversas do telefone associado ao nosso assistente. Direcione apenas contatos de cunho
      profissional.
    </Typography>
  </Box>
);

const FormSection: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsSubmitted(true);
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        maxWidth: "487px",
        minHeight: "780px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.paper",
        px: "38px",
        py: "52px",
        borderRadius: "16px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isSubmitted && (
          <>
            <Collaboration width={"487px"} />
            <Typography variant="h5" sx={{ mt: 8 }}>
              Tudo certo
            </Typography>
            <Typography variant="body1" sx={{ mt: "20px" }}>
              Entraremos em contato assim que tivemos seu acesso a esse novo produto
            </Typography>
          </>
        )}

        {!isSubmitted && (
          <>
            <OverworkedEmployee width={"120px"} />
            <Typography variant="h5" sx={{ mt: "17px", mb: "45px" }}>
              Tudo começa aqui
            </Typography>
            <Box
              sx={{
                width: "100%",
                flex: 1,
                minHeight: 0,
                px: 2,
                alignItems: isSubmitted ? "center" : "flex-start",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <HubspotForm onSubmit={handleSubmit} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export const WhatsAppAssistantForm: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const isShort = useMediaQuery("(max-height:850px)");

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
      }}
      contentSx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: isMobile ? "flex-start" : "center",
        alignItems: isShort && !isMobile ? "flex-start" : "center",
        overflow: "auto",
        width: "100%",
        height: "100%",
        p: 2,
        gap: 10,
      }}
    >
      <ContentSection />
      <FormSection />
    </PageLayout>
  );
};
