import { Box, Button } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { WebToast } from "@/taskpane/components/core/Toast";
import { LexZapUser } from "@/hooks/lexZap/types";
import { useUpdateLexZapUser } from "@/hooks/lexZap/admin/useUpdateLexZapUser";
import { Phone } from "@/core/Phone";
import { InputField } from "../InputField";
import { PhoneNumberField } from "../PhoneNumberField";

export const EditUserModal = ({
  isOpen,
  onClose,
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  user?: LexZapUser;
}) => {
  const [form, setForm] = React.useState<Partial<LexZapUser>>({});
  const { mutateAsync: updateUser, isPending: isLoading } = useUpdateLexZapUser();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState<boolean>(true);
  const [isValidNotificationNumber, setIsValidNotificationNumber] = React.useState<boolean>(true);

  useEffect(() => {
    if (user) {
      setForm({
        ...user,
        phoneNumber: user.phoneNumber.substring(2),
        notificationNumber: user.notificationNumber?.substring(2),
        apiKey: "",
      });
    } else {
      setForm({});
    }
  }, [user]);

  const hasChangedFields = useMemo(() => {
    return (
      form.phoneNumber?.replace(/\D/g, "") !== user?.phoneNumber.substring(2) ||
      form.notificationNumber?.replace(/\D/g, "") !== user?.notificationNumber?.substring(2) ||
      !!form.apiKey?.trim()
    );
  }, [form, user]);

  const handleUpdateUser = async () => {
    if (!user) {
      return onClose();
    }

    try {
      const phoneNumber = Phone.new(form.phoneNumber || "");
      const notificationNumber = Phone.new(form.notificationNumber || "");

      if (!phoneNumber.isSuccess) {
        WebToast.error("Número de whatsapp inválido");
        return;
      }

      if (!notificationNumber.isSuccess) {
        WebToast.error("Número de notificação inválido");
        return;
      }

      await updateUser({
        userId: user.userId,
        updates: {
          ...form,
          phoneNumber: phoneNumber.getValue().toPersistence(),
          notificationNumber: notificationNumber.getValue().toPersistence(),
        },
      });
      onClose();
      WebToast.success("Alteração feita com sucesso");
    } catch {
      WebToast.error("Falha ao alterar o usuário");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (prop === "phoneNumber" || prop === "notificationNumber") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value.replace(/\D/g, ""),
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const canUpdate = useMemo(
    () =>
      hasChangedFields &&
      Phone.new(form.phoneNumber ?? "").isSuccess &&
      Phone.new(form.notificationNumber ?? "").isSuccess,
    [form, hasChangedFields]
  );

  if (!user) return null;

  return (
    <ModalRight
      title={"Editar usuário"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !canUpdate} onClick={handleUpdateUser} size="small">
            Atualizar
          </Button>
          <Button size="small" disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <InputField placeholder="User Id" disabled={true} value={form.userId} label="User Id" />
          <InputField placeholder="Nome" disabled={true} value={form.name} label="Nome" />
          <InputField placeholder="Email" disabled={true} value={form.email} label="Email" />

          <PhoneNumberField
            label="Número do whatsapp"
            placeholder="Número do whatsapp"
            value={
              Phone.new(form.phoneNumber ?? "").isSuccess
                ? Phone.new(form.phoneNumber || "")
                    .getValue()
                    .toString()
                : form.phoneNumber
            }
            onChange={(e) => {
              handleChangeForm("phoneNumber")(e);
              setIsValidPhoneNumber(
                e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
              );
            }}
            error={!isValidPhoneNumber}
          />

          <PhoneNumberField
            label="Número para notificações"
            placeholder="Número para notificações"
            onChange={(e) => {
              handleChangeForm("notificationNumber")(e);
              setIsValidNotificationNumber(
                e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
              );
            }}
            value={
              Phone.new(form.notificationNumber || "").isSuccess
                ? Phone.new(form.notificationNumber || "")
                    .getValue()
                    .toString()
                : form.notificationNumber
            }
            error={!isValidNotificationNumber}
          />
          <InputField placeholder="Api Key" onChange={handleChangeForm("apiKey")} value={form.apiKey} label="Api Key" />
        </Box>
      </Loading>
    </ModalRight>
  );
};
