import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Typography, Box } from "@mui/material";

export const ActionButton = ({
  id,
  isOpen,
  disabled,
  onClick,
  buttonRef,
}: {
  id?: string;
  isOpen: boolean;
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
}) => {
  return (
    <Button
      disabled={disabled}
      ref={buttonRef}
      id={id}
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 150,
        padding: "8px 16px",
        borderRadius: "40px",
        zIndex: 4,
      }}
    >
      <Typography variant="title" sx={{ color: "common.dorian" }}>
        Nova ação
      </Typography>
      <Box
        component="span"
        sx={{
          display: "flex",
          transition: "transform 0.3s",
          transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
        }}
      >
        <AddIcon sx={{ color: "common.dorian" }} />
      </Box>
    </Button>
  );
};
