import { Box } from "@mui/material";
import React from "react";

interface ResizerProps {
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  isResizing?: boolean;
}
export const Resizer = ({ onMouseDown, isResizing }: ResizerProps) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 12,
      top: 0,
      bottom: 0,
      right: "-5px",
      width: "10px",
      cursor: "col-resize",
      backgroundColor: isResizing ? "common.lightShade" : undefined,
      transition: "background 0.3s ease",
      ":hover": { backgroundColor: "common.lightShade" },
    }}
    onMouseDown={onMouseDown}
  />
);
