import React from "react";
import { Box, Divider, Skeleton } from "@mui/material";

export const CardItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        backgroundColor: "common.white",
        width: "368px",
        height: "432px",
        padding: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Skeleton
              variant="text"
              sx={{
                width: "160px",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                width: "80px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                height: "40px",
                width: "80px",
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            height: "4px",
          }}
        ></Box>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton
            key={index}
            variant="text"
            sx={{
              width: "100%",
              maxWidth: "100%",
              marginBottom: "-8px",
            }}
          />
        ))}

        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "140px",
            marginTop: "32px",
          }}
        />
      </Box>
    </Box>
  );
};
