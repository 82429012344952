import { FormControl, FormHelperText } from "@mui/material";
import { StrictRJSFSchema, RJSFSchema, FormContextType, FieldTemplateProps } from "@rjsf/utils";
import React from "react";

export function FieldTemplate<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(props: FieldTemplateProps<T, S, F>) {
  const { children, hidden, rawErrors = [], help } = props;

  if (hidden) {
    return <div style={{ display: "none" }}>{children}</div>;
  }

  return (
    <FormControl fullWidth error={rawErrors.length ? true : false}>
      {children}
      {Boolean(rawErrors.length) ? (
        <FormHelperText sx={{ "&:first-letter": { textTransform: "uppercase" } }}>{rawErrors[0]}</FormHelperText>
      ) : null}
      {help}
    </FormControl>
  );
}
