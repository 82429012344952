import * as logger from "@/core/logger";

export const openUrl = (url: string, samePage?: boolean) => {
  try {
    Office.context.ui!.openBrowserWindow(url);
  } catch {
    try {
      window.open(url, `${samePage ? "_self" : "_blank"}`, "noopener,noreferrer"); //
    } catch (e) {
      logger.error(`Error opening new window (${url}): ${e}`, e);
    }
  }
};
