import React from "react";
import { Box } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import LoadingRing from "@/assets/svgs/loading-ring.svg?react";
import { useLexZap } from "@/hooks/lexZap/useLexZap";

import { WhatsappAssistantCases } from "../WhatsappAssistantCases";
import { WhatsAppAssistantForm } from "../WhatsAppAssistantForm";

export const WhatsAppAssistant = () => {
  const { data: connectionData, isLoading } = useLexZap();
  const { phoneNumber } = connectionData || {};

  if (isLoading) {
    return <LoadingWhatsAppAssistant />;
  }

  if (phoneNumber) {
    return <WhatsappAssistantCases />;
  } else {
    return <WhatsAppAssistantForm />;
  }
};

const LoadingWhatsAppAssistant = () => {
  return (
    <PageLayout
      contentSx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
        }}
      >
        <LoadingRing width="200px" />
      </Box>
    </PageLayout>
  );
};
