import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React from "react";

export const PasswordField = React.forwardRef<TextFieldProps, TextFieldProps>((textFieldProps, ref) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  return (
    <TextField
      type={isVisible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={() => setIsVisible((v) => !v)}
              edge="end"
            >
              {isVisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      /*@ts-expect-error This will be fixed after the feature flag */
      ref={ref}
      {...textFieldProps}
    />
  );
});
PasswordField.displayName = "PasswordField";
