import { SkillId } from "@/core/skills/types";
import { SkillForms } from "@/core/skillForms";
import { useQuery } from "@tanstack/react-query";

export const useSkillForm = ({ skillId }: { skillId?: SkillId }) => {
  return useQuery({
    queryKey: ["skillForm", skillId],
    queryFn: () => SkillForms.getSkillForm(skillId!),
    enabled: Boolean(skillId),
  });
};
