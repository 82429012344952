import { datadogRum } from "@datadog/browser-rum";
import { reactPlugin } from "@datadog/browser-rum-react";
import { env } from "@/constants/environment";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

export function init() {
  if (env.DATADOG_APPLICATIN_ID && env.DATADOG_CLIENTE_TOKEN_ID) {
    const isOutsideOffice = outsideOfficeClient();
    const serviceBaseName = isOutsideOffice ? "lexter-copilot-addin-web" : "lexter-copilot-addin";

    datadogRum.init({
      applicationId: env.DATADOG_APPLICATIN_ID,
      clientToken: env.DATADOG_CLIENTE_TOKEN_ID,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      service: `${serviceBaseName}`,
      env: env.DATADOG_ENV,
      sessionSampleRate: env.DATADOG_SESSION_SAMPLE_RATE,
      sessionReplaySampleRate: env.DATADOG_SESSION_REPLAY_SAMPLE_RATE,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask",
      version: env.DATADOG_VERSION,
      allowedTracingUrls: ["/https://.*.lexter.ai/"],
      enableExperimentalFeatures: ["feature_flags"],
      betaPlugins: [reactPlugin({ router: true })],
    });
  }
}
