import { useContext } from "react";
import { NotificationsContext } from "./context";

export const useNotificationsContext = () => {
  const contextValue = useContext(NotificationsContext);
  if (!contextValue) {
    throw new Error("NotificationsContext not found");
  }
  return contextValue;
};
