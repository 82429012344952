import { SvgIconComponent } from "@mui/icons-material";
import { LinearProgress, Button as MuiButton, ButtonProps as MuiButtonProps } from "@mui/material";
import React from "react";

export interface ButtonProps extends Omit<MuiButtonProps, "startIcon" | "endIcon"> {
  startIcon?: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  endIcon?: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
  bgcolor?: string;
  loadingColor?: string;
  loaderStyle?: React.CSSProperties;
  borderVariant?: "rounded";
  textAlign?: "center";
}

export const Button = ({
  children,
  bgcolor,
  loadingColor,
  isLoading,
  startIcon: StartIcon,
  endIcon: EndIcon,
  loaderStyle,
  borderVariant,
  textAlign,
  ...rest
}: ButtonProps) => {
  if (isLoading)
    return (
      <LinearProgress
        sx={{
          width: "100%",
          height: "40px",
          borderRadius: "5px",
          ...loaderStyle,
          bgcolor: loadingColor,
          "& .MuiLinearProgress-bar": {
            bgcolor,
          },
        }}
      />
    );

  return (
    <MuiButton
      {...rest}
      sx={{
        position: "relative",
        px: (StartIcon || EndIcon) && textAlign === "center" ? "44px" : undefined,
        borderRadius: borderVariant === "rounded" ? "20px" : undefined,
        bgcolor,
        ":hover": {
          bgcolor,
        },
        ...rest.sx,
      }}
      startIcon={StartIcon ? <StartIcon /> : undefined}
      endIcon={EndIcon ? <EndIcon /> : undefined}
    >
      {children}
    </MuiButton>
  );
};

interface LinkButtonProps extends ButtonProps {
  capitalize?: boolean;
}

export const LinkButton = ({
  children,
  loadingColor,
  isLoading,
  startIcon: StartIcon,
  endIcon: EndIcon,
  loaderStyle,
  capitalize,
  sx,
  ...rest
}: LinkButtonProps) => {
  return (
    <Button
      bgcolor="none"
      loadingColor={loadingColor}
      isLoading={isLoading}
      startIcon={StartIcon}
      endIcon={EndIcon}
      loaderStyle={loaderStyle}
      {...rest}
      sx={{
        color: "common.shade",
        textDecoration: "underline",
        textUnderlineOffset: "2px",
        textTransform: capitalize ? "capitalize" : "uppercase",
        ":hover": {
          textTransform: capitalize ? "capitalize" : "uppercase",
          bgcolor: "unset",
          textDecoration: "underline",
          textUnderlineOffset: "2px",
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
