import { buildSkillStep, createUploadFileField } from "@/core/skillForms/builder";

export const createDefenseSkillStep2 = buildSkillStep({
  title: "Anexo",
  description: "Anexe o arquivo abaixo para fornecer contexto à IA sobre o caso:",
  onboarding: {
    templateButton: "attachment",
  },
  fields: [
    createUploadFileField({
      name: "referencePiece",
      ui: {
        label: "Anexar petição inicial",
      },
    }),
  ],
});
