import { SkillGroup, SkillGroupShortcut } from "@/core/skillGroups/types";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import React from "react";

import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  LockClockOutlined,
} from "@mui/icons-material";
import { SKILL_FORM_MAX_WIDTH } from "@/constants/layout";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { SearchSkillsBar } from "./components/SearchSkillsBar";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

type SkillGroupSkillGroupViewProps = {
  skillGroup: SkillGroup;
  onSelectSkill: (skillId: string) => void;
  goBack: () => void;
};

export const SkillGroupView = ({ skillGroup, onSelectSkill, goBack }: SkillGroupSkillGroupViewProps) => {
  const { isOnboarding } = useDocumentOnboarding();
  const { skillGroupSearchEnabled } = useFeatureFlags();

  const isSkillEnabled = (skillId: string) => {
    if (!isOnboarding) return true;
    return skillGroup.onboarding?.enabledSkills?.includes(skillId) ?? false;
  };

  return (
    <Box sx={{ my: 8, px: 6, width: "100%" }}>
      <Box
        sx={{
          mx: "auto",
          maxWidth: SKILL_FORM_MAX_WIDTH,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          gap: 6,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}>
          <Button startIcon={<ChevronLeftIcon />} onClick={goBack} sx={{ ml: -1 }}>
            Voltar
          </Button>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="h4">{skillGroup.name}</Typography>
            <Typography variant="subtitle1">{skillGroup.subtitle}</Typography>
          </Box>
        </Box>
        <Box sx={{ mx: -2 }}>
          {isOnboarding || !skillGroupSearchEnabled ? null : <SearchSkillsBar />}
          {skillGroup.skillShortcuts.map((skillShortcut) => (
            <SkillGroupShortcutButton
              key={skillShortcut.skillId}
              skillShortcut={skillShortcut}
              disabled={!isSkillEnabled(skillShortcut.skillId)}
              onClick={() => onSelectSkill(skillShortcut.skillId)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const SkillGroupShortcutButton = ({
  skillShortcut,
  onClick,
  disabled,
}: {
  skillShortcut: SkillGroupShortcut;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Tooltip
      placement="right"
      title={disabled ? "Essa opção será liberada após experimentar a criação gratuita de peças." : ""}
    >
      <span>
        <Button
          sx={{
            justifyContent: "space-between",
            px: 2,
            py: 2.5,
            color: disabled ? "text.disabled" : "text.primary",
            textAlign: "left",
          }}
          endIcon={disabled ? <LockClockOutlined /> : <ChevronRightIcon />}
          onClick={onClick}
          fullWidth
          disabled={disabled}
        >
          <Typography
            variant="body1"
            sx={{
              color: disabled ? "text.disabled" : "text.primary",
            }}
          >
            {skillShortcut.label}
          </Typography>
        </Button>
      </span>
    </Tooltip>
  );
};
