import { Box, Typography } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Message } from "./components/Message";
import { MessageSkeleton } from "./components/Message/skeleton";
import { Case, CaseMessage } from "@/hooks/lexZap/types";
import { groupMessagesByDay } from "./utils/groupMessagesByDay";
import { dayFromTimestamp } from "../../../../utils/dayFromTimestamp";

interface ChatMessagesProps {
  messages?: CaseMessage[];
  isLoading?: boolean;
  caseData?: Case;
}

export const ChatMessages = ({ messages, isLoading, caseData }: ChatMessagesProps) => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const lastMessage = useMemo(() => messages?.[messages.length - 1], [messages]);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [isLastMessageVisible, setIsLastMessageVisible] = useState(true);
  const groupedMessages = useMemo(() => groupMessagesByDay(messages), [messages]);

  const scrollToBottom = useCallback(() => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [isLoading, caseData?.id, scrollToBottom]);

  useEffect(() => {
    if (lastMessage?.isSending) {
      scrollToBottom();
    }
  }, [lastMessage, scrollToBottom]);

  useEffect(() => {
    if (isLastMessageVisible) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage?.id, scrollToBottom]);

  const handleScroll = () => {
    const isLastVisible = () => {
      if (!lastMessageRef.current || !messagesContainerRef.current) return false;

      const lastMessageRect = lastMessageRef.current.getBoundingClientRect();
      const containerRect = messagesContainerRef.current.getBoundingClientRect();

      return lastMessageRect.bottom > containerRect.top && lastMessageRect.top < containerRect.bottom;
    };
    setIsLastMessageVisible(isLastVisible());
  };

  return (
    <Box
      ref={messagesContainerRef}
      onScroll={handleScroll}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        paddingX: 5,
        paddingTop: 3,
        paddingBottom: 2,
        overflow: "auto",
        "&::-webkit-scrollbar": { width: "6px" },
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: "common.white",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          backgroundColor: "grey.200",
        },
      }}
    >
      {isLoading ? (
        <ChatMessagesSkeleton messages={caseData && caseData.lastMessage ? [caseData.lastMessage] : undefined} />
      ) : (
        <>
          {groupedMessages.map((group, index) => (
            <Fragment key={index}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "common.white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                >
                  <Typography
                    variant="body"
                    sx={{
                      whiteSpace: "normal",
                      textTransform: "uppercase",
                    }}
                  >
                    {dayFromTimestamp(group.date)}
                  </Typography>
                </Box>
              </Box>

              {group.messages.map((message, index) => (
                <Message
                  containerRef={message.id === lastMessage?.id ? lastMessageRef : undefined}
                  key={index}
                  message={message}
                />
              ))}
            </Fragment>
          ))}
        </>
      )}
    </Box>
  );
};

interface ChatMessagesSkeletonProps {
  messages?: CaseMessage[];
}
const ChatMessagesSkeleton = ({ messages }: ChatMessagesSkeletonProps) => {
  const SkeletonMessages = useMemo(
    () => Array.from({ length: 20 }, (_, index) => <MessageSkeleton key={index} />),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages]
  );

  return (
    <>
      {SkeletonMessages}
      {messages?.map((message, index) => <Message key={index} message={message} />)}
    </>
  );
};
