import { Box, SxProps } from "@mui/material";
import React, { ReactNode } from "react";

interface CardProps {
  children?: ReactNode;
  sx?: SxProps;
}
export const Card = ({ children, sx }: CardProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        padding: 3,
        borderRadius: "6px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
