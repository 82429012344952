import { buildSkillStep } from "@/core/skillForms/builder";

export const intercurrentMotionSkillStep2 = buildSkillStep({
  title: "Detalhes do caso",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo.",
        placeholder: "Maria da Silva, conforme registrado no CPF 321.654.987-00",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description:
          "Relate de forma clara os eventos que justificam a apresentação desta petição, indicando suas circunstâncias.",
        placeholder:
          "Em 15/09/2023, o réu recusou-se a cumprir o acordo de pagamento firmado na audiência de conciliação, prejudicando a parte autora.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumentos jurídicos",
        description:
          "Indique os fundamentos legais que sustentam a reclamação, citando leis, normas ou jurisprudências pertinentes.",
        placeholder:
          "Busca-se a aplicação de multa por descumprimento da obrigação de pagar quantia certa, como previsto no CPC.",
      },
    },
    {
      name: "evidence_text",
      type: "string",
      required: false,
      ui: {
        label: "Adicionar provas",
        description:
          "Descreva as provas que respaldam suas alegações, como contratos, registros ou evidências documentais.",
        placeholder:
          "Anexo correspondência eletrônica de 12/09/2023 e extratos bancários, demonstrando o não cumprimento do acordo firmado.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Especifique os pedidos que deseja fazer nesta petição, detalhando as providências requeridas ao juiz.",
        placeholder:
          "Requer a aplicação de multa diária pelo descumprimento do acordo, bem como a execução imediata da sentença.",
      },
    },
  ],
  submitButtonText: "Escrever peça",
});
