import { SkillForm } from "@/core/skillForms/types";
import { createAppealSkillStep1 } from "../step1";
import { createBaseAppealSkillStep2 } from "../createBaseAppealSkill/step2";
import { createBaseAppealSkillStep3 } from "../createBaseAppealSkill/step3";
import { SkillId } from "@/core/skills/types";

const baseSteps = [createBaseAppealSkillStep2, createBaseAppealSkillStep3];
const baseStepsWithStep1 = [createAppealSkillStep1, ...baseSteps];

const createAppealSkillForm = (skillId: SkillId, title: string, steps = baseSteps): SkillForm => {
  return {
    skillId,
    title,
    steps,
  };
};

export const createExtraordinaryAppealSkillForm = createAppealSkillForm(
  "create_extraordinary_appeal",
  "Recurso extraordinário",
  baseStepsWithStep1
);

export const createSpecialAppealSkillForm = createAppealSkillForm(
  "create_special_appeal",
  "Recurso especial",
  baseStepsWithStep1
);

export const createUnnamedAppealSkillForm = createAppealSkillForm(
  "create_unnamed_appeal",
  "Recurso inominado",
  baseStepsWithStep1
);

export const createReviewAppealSkillForm = createAppealSkillForm(
  "create_review_appeal",
  "Recurso de revista",
  baseSteps
);

export const createInterlocutoryAppealSkillForm = createAppealSkillForm(
  "create_interlocutory_appeal",
  "Agravo de instrumento",
  baseStepsWithStep1
);

export const createInternalAppealSkillForm = createAppealSkillForm(
  "create_internal_appeal",
  "Agravo interno",
  baseStepsWithStep1
);

export const createAppealInStrictSenseSkillForm = createAppealSkillForm(
  "create_appeal_in_strict_sense",
  "Recurso em sentido estrito",
  baseSteps
);
