import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { annualRevenueOptions } from "../defaultValues";

interface UserAnnualRevenueSelectProps {
  annualRevenue: string;
  onAnnualRevenueChange: (value: string) => void;
  show: boolean;
}

export const UserAnnualRevenueSelect = ({
  annualRevenue,
  onAnnualRevenueChange,
  show,
}: UserAnnualRevenueSelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAnnualRevenueChange(event.target.value);
  };

  if (!show) return null;

  return (
    <Box>
      <FormControl>
        <Typography variant="subtitle2" sx={{ color: "common.shade", mb: 2, fontWeight: "700" }}>
          Qual é a faixa de faturamento anual do escritório?
        </Typography>
        <RadioGroup value={annualRevenue} onChange={handleChange}>
          {annualRevenueOptions.map((revenue, i) => (
            <FormControlLabel key={i} value={revenue.value} control={<Radio />} label={revenue.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
