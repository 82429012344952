import React, { ReactNode } from "react";
import { EditorUiContext } from "./context";

interface EditorUIProviderProps {
  children: ReactNode;
}
export const EditorUIProvider = ({ children }: EditorUIProviderProps) => {
  const [modalContent, setModalContent] = React.useState<ReactNode | null>(null);

  const openModal = (content: ReactNode) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <EditorUiContext.Provider
      value={{
        modalContent,
        openModal,
        closeModal,
      }}
    >
      {children}
    </EditorUiContext.Provider>
  );
};
