export const TEXT_SELECTION_MAX_LENGTH = 100000;
export const MESSAGE_LENGTH_LIMIT = 8000;
export const UPLOAD_FILE_PAGE_LIMIT = 50; // 50 pages
export const UPLOAD_FILE_SIZE_LIMIT = 50 * 1000 * 1000; // 50 MB

export const REPLACE_ENTIRE_DOCUMENT_ACTION_ID = "REPLACE_ENTIRE_DOCUMENT";
export const REPLACE_SELECTION_ACTION_ID = "REPLACE_SELECTION";
export const DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID = "DOWNLOAD_ASSISTANT_ANSWER";
const DEFAULT_PAGE_SIZE_MESSAGES = 10;
export const INITIAL_PAGINATION_MESSAGES = {
  pageSize: DEFAULT_PAGE_SIZE_MESSAGES,
  page: 1,
};
