import { PeriodValue } from "../types";

export const periodOptions: Array<{
  value: PeriodValue;
  label: string;
}> = [
  {
    value: PeriodValue.ANY,
    label: "Em qualquer data",
  },
  {
    value: PeriodValue.LAST_YEAR,
    label: "Últimos 12 meses",
  },
  {
    value: PeriodValue.LAST_2_YEARS,
    label: "Últimos 2 anos",
  },
  {
    value: PeriodValue.LAST_3_YEARS,
    label: "Últimos 3 anos",
  },
  {
    value: PeriodValue.LAST_5_YEARS,
    label: "Últimos 5 anos",
  },
];
