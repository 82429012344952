import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  case_breakdown: Type.String(),
  client: Type.String(),
});

const actionId = ActionId.LEGAL_ADVICE;

export const legalAdviceSkill: Skill<typeof inputSchema> = {
  id: "legal_advice",
  name: "Elaborar parecer jurídico",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["consultative"],
  actionId,
  type: "payload",
  description: "Receba análises detalhadas de casos complexos e opiniões jurídicas fundamentadas.",
  getPayload: (input) => ({
    skillId: ActionId.LEGAL_ADVICE,
    requestId: uuidV4(),
    payload: {
      case_breakdown: input.case_breakdown,
      client: input.client,
    },
    messageToSave: "Elaborar parecer jurídico",
  }),
};
