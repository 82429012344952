import React from "react";

import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";

import { Box, Typography } from "@mui/material";

export const GenericMediaMessage = ({ message }: MessageProps) => {
  return (
    <MessageCard message={message}>
      <Typography
        variant="body"
        sx={{
          whiteSpace: "normal",
        }}
      >
        <a href={message.media?.url} target="_blank" rel="noreferrer">
          {message.media?.filename}
        </a>

        <Box
          sx={{
            display: "inline-block",
            width: message.fromLawyer ? "50px" : "32px",
          }}
        />
      </Typography>
    </MessageCard>
  );
};
