import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { Home, ChevronRight } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import GoodWorkIllustrationSVG from "assets/svgs/good-work-illustration.svg?react";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

export const SuccessPage = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { closeOnboarding } = useDocumentOnboarding();
  const isOutsideOfficeClient = outsideOfficeClient();

  const handleButton = (route?: string) => {
    closeOnboarding();
    if (route && isOutsideOfficeClient) navigate(route);
    onClose();
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
      }}
      component={motion.div}
      key="loading_skill"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5 }}
    >
      <GoodWorkIllustrationSVG />
      <Box display="flex" flexDirection="column" gap={1} textAlign="center">
        <Typography variant="h3">Pronto!</Typography>
        <Typography variant="body">A sua peça judicial foi criada</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Button onClick={() => handleButton()} variant="contained" endIcon={<ChevronRight />}>
          Continuar utilizando
        </Button>
        <Button onClick={() => handleButton(ROUTE_PATHS.HOME)} startIcon={<Home />}>
          Ir para a Página inicial
        </Button>
      </Box>
    </Box>
  );
};
