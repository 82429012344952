import React from "react";
import { Box, Modal } from "@mui/material";

export const ImageModal = ({ url, isOpen, onClose }: { url?: string; isOpen: boolean; onClose: () => void }) => {
  const [isAmplified, setIsAmplified] = React.useState(false);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        overflow: isAmplified ? "auto" : "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: isAmplified ? undefined : "50%",
          left: "50%",
          transform: isAmplified ? "translate(-50%, 0)" : "translate(-50%, -50%)",
          maxWidth: isAmplified ? undefined : "480px",
          maxHeight: isAmplified ? undefined : "80%",
          outline: "none",
          display: "flex",
        }}
      >
        <Box sx={{}}>
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              cursor: isAmplified ? "zoom-out" : "zoom-in",
            }}
            onClick={() => {
              setIsAmplified((prev) => !prev);
            }}
            src={url}
          ></Box>
        </Box>
      </Box>
    </Modal>
  );
};
