import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { Case, CaseWithChat } from "@/hooks/lexZap/types";
import { CaseList } from "./components/CaseList";
import { Chat } from "./components/Chat";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import MessageReceivedSound from "@/assets/sounds/whatsapp-message-received.mp3";

export const ChatView = ({ cases, isLoadingCases }: { cases: Case[] | undefined; isLoadingCases: boolean }) => {
  const [activeChatId, setActiveChatId] = React.useState<string>();
  const { data: messages, isLoading: isLoadingMessagesQuery } = useLexZapMessages({ chatId: activeChatId });

  const activeCase = useMemo(() => {
    if (!cases || !activeChatId) return undefined;

    return cases.find((item) => item.chatId === activeChatId);
  }, [cases, activeChatId]);

  const isLoadingMessages = isLoadingCases || isLoadingMessagesQuery || (!!cases?.length && !activeCase);

  useEffect(() => {
    if (!isLoadingCases && cases?.length && (!activeChatId || !cases.some((item) => item.chatId === activeChatId))) {
      setActiveChatId(cases[0].chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCases, cases]);

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED && !update.message.fromLawyer) {
        const audio = new Audio(MessageReceivedSound);
        audio.play();
      }
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "minmax(0, 4fr) minmax(0, 6fr)",
        gridTemplateRows: "100%",
      }}
    >
      <CaseList
        cases={cases?.filter((c) => c.chatId && c.lastMessage) as CaseWithChat[]}
        activeChatId={activeChatId}
        isLoading={isLoadingCases}
        onItemSelect={setActiveChatId}
      />

      <Chat
        messages={messages}
        lexZapCase={activeCase}
        isLoadingCases={isLoadingCases}
        isLoadingMessages={isLoadingMessages}
      />
    </Box>
  );
};
