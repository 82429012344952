import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useElementWidth } from "@/hooks/useElementWidth";
import React, { useMemo, useRef } from "react";

interface TabProps {
  label: string;
  onMouseDown: () => void;
  active: boolean;
  dragging: boolean;
  onClose: () => void;
  loadingSave: boolean;
}
export const Tab = ({ label, onMouseDown, active, dragging, onClose, loadingSave }: TabProps) => {
  const tabText = loadingSave ? `Salvando... ${label}` : label;
  const theme = useTheme();
  const containerRef = useRef<HTMLElement>(null);
  const width = useElementWidth(containerRef);

  const { hideClose, hideText } = useMemo(() => {
    if (!width) return {};
    return {
      hideText: width < 30 || (active && width < 50),
      hideClose: !active && width < 80,
    };
  }, [width, active]);

  return (
    <Box
      title={tabText}
      onMouseDown={onMouseDown}
      sx={{
        width: "100%",
        height: "40px",
        position: "relative",
        display: "flex",
        justifyContent: hideText ? "center" : "space-between",
        alignItems: "center",
        padding: hideText ? undefined : active ? "8px 11px 8px 17px" : "8px 12px 8px 16px",
        marginLeft: active ? "-1px" : undefined,
        marginRight: active ? "1px" : undefined,
        transition: "border-radius 0.3s",
        backgroundColor: active ? "common.white" : "common.dorian",
        borderRadius: dragging ? "5px" : "5px 5px 0 0",
        gap: 0.5,
        "&:hover": {
          borderRadius: "5px",
          backgroundColor: active ? "common.white" : "common.dorianHover",
          ".hide-text-gradient": {
            backgroundImage: `linear-gradient(to right, transparent, ${active ? theme.palette.common.white : theme.palette.common.dorianHover})`,
          },
        },
      }}
      ref={containerRef}
    >
      {!hideText && (
        <Typography
          variant="body"
          sx={{
            width: "100%",
            color: active ? "common.black" : "common.mediumShade",
            textOverflow: "clip",
            maskImage: "linear-gradient(to left, transparent, black 16px)",
          }}
        >
          {tabText}
        </Typography>
      )}

      {!hideClose && (
        <CloseOutlinedIcon
          onMouseDown={(e) => e.stopPropagation()}
          onClick={onClose}
          sx={{
            fontSize: "1rem",
            cursor: "pointer",
            color: active ? "common.black" : "common.mediumShade",
          }}
        />
      )}

      {!active && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: "1px",
            height: "20px",
            backgroundColor: "common.mediumShade",
          }}
        />
      )}
    </Box>
  );
};
