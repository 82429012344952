import { LegalPieceMacroType } from "@/contexts/MessagesContext";
import { buildSkillStep } from "@/core/skillForms/builder";

export const createOtherTypeOfPieceSkillStep3Appeal = buildSkillStep({
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo.",
        placeholder: "Maria da Silva, conforme registrado no CPF 321.654.987-00",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description:
          "Descreva os acontecimentos que justificam a apelação, ressaltando as falhas ou omissões da decisão anterior.",
        placeholder:
          "Baseio-me no art. 373, I, do CPC, que determina a análise de todas as provas apresentadas, o que não ocorreu na decisão recorrida.",
      },
    },
    {
      name: "timeliness_text",
      type: "string",
      required: false,
      ui: {
        label: "Tempestividade e Cabimento",
        description:
          "Comprove que a apelação foi interposta dentro do prazo legal e justifique sua admissibilidade conforme o direito.",
        placeholder:
          "Interposta em 10/08/2023, dentro do prazo legal de 15 dias, nos termos do art. 1.003 do CPC, garantindo sua tempestividade e cabimento.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumento jurídico",
        description:
          "Apresente os argumentos que sustentam a apelação, citando as leis e jurisprudências que embasam o recurso.",
        placeholder:
          "A decisão anterior não considerou provas documentais relevantes, como o contrato assinado em 12/05/2022, que é essencial ao julgamento.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Especifique os pedidos que deseja fazer na apelação, indicando as alterações ou reversões de decisão que está buscando.",
        placeholder:
          "Conforme art. 489, §1º, do CPC, a sentença carece de fundamentação adequada, devendo ser anulada para correção dos vícios apontados.",
      },
    },
  ],
  submitButtonText: "Escrever peça",
  condition: {
    value: LegalPieceMacroType.APPEAL,
    field: "legal_piece_macro_type",
  },
  title: "Detalhes do caso",
});
