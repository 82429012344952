import { PlanType } from "@/hooks/credits/types";

export const getPlanName = (planType: PlanType) => {
  switch (planType) {
    case PlanType.TRIAL: {
      return "Período de teste";
    }
    case PlanType.INITIAL: {
      return "Plano Inicial";
    }
    case PlanType.BASIC: {
      return "Plano Básico";
    }
    case PlanType.INTERMEDIARY: {
      return "Plano Intermediário";
    }
    case PlanType.ADVANCED: {
      return "Plano Avançado";
    }
    case PlanType.PROFESSIONAL: {
      return "Plano Profissional";
    }
    case PlanType.CUSTOM: {
      return "Plano Personalizado";
    }
    case PlanType.UNLIMITED: {
      return "Plano Essencial";
    }
    case PlanType.PROFESSIONAL_WITH_WPP: {
      return "Plano Profissional";
    }
    case PlanType.PERFORMANCE: {
      return "Plano Performance";
    }
    default: {
      return "Plano Cortesia";
    }
  }
};
