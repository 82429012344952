import { FlowMessageAction } from "@/contexts/MessagesContext";
import { SkillsPayload, UserInputDTO } from "@/contexts/WebSocketContext";
import { Paginated } from "@/core/api/types";
import { Thread } from "@/hooks/threads/types";
import { THREADS_QUERY_KEY } from "@/hooks/threads/usePaginatedThreads";
import { useApi } from "@/hooks/useApi";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

export const useGenerateThreadName = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["generateThreadName"],
    mutationFn: async ({
      threadId,
      data,
    }: {
      threadId: string;
      data: FlowMessageAction | SkillsPayload<UserInputDTO>;
    }) => {
      return await api.generateThreadName({ threadId, data });
    },
    onSuccess: async ({ name }, { threadId }) => {
      await queryClient.cancelQueries({ queryKey: THREADS_QUERY_KEY });
      const previousThreadsTuple = queryClient.getQueriesData({ queryKey: THREADS_QUERY_KEY });

      for (const [queryKey] of previousThreadsTuple) {
        queryClient.setQueryData(queryKey, (old: InfiniteData<Paginated<Thread>>) => {
          return {
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data.map((r) => {
                if (r.id === threadId) {
                  return {
                    ...r,
                    name,
                  };
                }
                return r;
              }),
            })),
            pageParams: old.pageParams,
          };
        });
      }
    },
  });
};
