import { Box, Divider, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface ModalLeftProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactElement;
  header: React.ReactNode;
  onScroll?: () => void;
}

export const ModalLeft = ({ open, onClose, title, children, header, onScroll, ...rest }: ModalLeftProps) => {
  return (
    <Modal
      sx={{
        left: "3.5rem !important",
        "& .MuiBackdrop-root": {
          left: "3.5rem !important",
        },
        zIndex: "speedDial",
      }}
      {...rest}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: "380px",
          backgroundColor: "background.paper",
          left: "0",
          position: "absolute",
          height: "100vh",
          cursor: "default",
          paddingTop: "20px",
          display: "flex",
          flexDirection: "column",
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            padding: "0 16px 16px 16px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant="h3" sx={{ fontWeight: "600", color: "legalBlack" }}>
              {title}
            </Typography>
          </Box>
          <CloseIcon sx={{ width: "32px", height: "32px", cursor: "pointer" }} onClick={onClose} />
        </Box>

        <Divider />

        <Box>{header}</Box>

        <Divider />

        <Box
          sx={{
            padding: "0",
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: "common.white",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "text.secondary",
            },
            height: "100%",
            flex: 1,
          }}
          id="ThreadsList"
          onScroll={onScroll}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
