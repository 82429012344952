import { PageLayout } from "@/components/PageLayout";
import { Box } from "@mui/material";
import React, { useEffect } from "react";

import { CaseSummary } from "./components/CaseSummary";
import { CaseFiles } from "./components/CaseFiles";
import { NextSteps } from "./components/NextSteps";
import { CaseHeader } from "./components/CaseHeader";
import { CaseFooter } from "./components/CaseFooter";

import { CaseDetailsChat } from "./components/CaseDetailsChat";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { DeleteCasesModal } from "../WhatsappAssistantCases/components/CardsView/components/DeleteCasesModal";
import { useDeleteCases } from "@/hooks/lexZap/useDeleteCases";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { ArchiveCasesModal } from "../WhatsappAssistantCases/components/CardsView/components/ArchiveCasesModal";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { UnarchiveCasesModal } from "../WhatsappAssistantCases/components/CardsView/components/UnarchiveCasesModal";

export const WhatsappAssistantCaseDetails = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { caseData, isError, isLoading } = useLexZapCase({ caseId });
  const { mutateAsync: deleteCases, isPending: isDeletingCase } = useDeleteCases();
  const { mutateAsync: archiveCases, isPending: isArchivingCase } = useArchiveCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCase } = useUnarchiveCases();

  const [isOpenDeleteCase, setIsOpenDeleteCase] = React.useState(false);
  const [isOpenArchiveCase, setIsOpenArchiveCase] = React.useState(false);
  const [isOpenUnarchiveCase, setIsOpenUnarchiveCase] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenDeleteCase = () => {
    if (!caseId) return;
    setIsOpenDeleteCase(true);
  };

  const handleCloseDeleteCase = () => {
    setIsOpenDeleteCase(false);
  };

  const handleDeleteCase = async () => {
    if (!caseId) {
      handleCloseDeleteCase();
      return;
    }
    await deleteCases({ casesIds: [caseId] });
    handleCloseDeleteCase();
    navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT);
  };

  const handleOpenArchiveCase = () => {
    if (!caseId) return;
    setIsOpenArchiveCase(true);
  };

  const handleCloseArchiveCase = () => {
    setIsOpenArchiveCase(false);
  };

  const handleArchiveCase = async () => {
    if (!caseId) {
      handleCloseArchiveCase();
      return;
    }
    await archiveCases({ casesIds: [caseId] });
    handleCloseArchiveCase();
  };

  const handleOpenUnarchiveCase = () => {
    if (!caseId) return;

    setIsOpenUnarchiveCase(true);
  };

  const handleCloseUnarchiveCase = () => {
    setIsOpenUnarchiveCase(false);
  };

  const handleUnarchiveCase = async () => {
    if (!caseId) {
      handleCloseUnarchiveCase();
      return;
    }
    await unarchiveCases({ casesIds: [caseId] });
    handleCloseUnarchiveCase();
  };

  useEffect(() => {
    // @TODO tirar daqui e colocar na tela esses dados
    // eslint-disable-next-line no-console
    console.log("caseData", {
      caseId,
      caseData,
      isError,
      isLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseData, isError, isLoading]);

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          padding: 4,
        }}
      >
        <CaseHeader />

        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "minmax(0, 6fr) minmax(0, 5fr)",
            gap: 3,
          }}
        >
          <CaseSummary />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <NextSteps />

            <CaseDetailsChat archived={caseData?.status === "ARCHIVED"} />

            <CaseFiles />
          </Box>
        </Box>

        <CaseFooter
          onDelete={handleOpenDeleteCase}
          onArchive={handleOpenArchiveCase}
          onUnarchive={handleOpenUnarchiveCase}
          archived={caseData?.status === "ARCHIVED"}
        />
      </Box>

      <DeleteCasesModal
        isOpen={isOpenDeleteCase}
        onConfirm={handleDeleteCase}
        onCancel={handleCloseDeleteCase}
        isLoading={isDeletingCase}
        amount={1}
      />

      <ArchiveCasesModal
        isOpen={isOpenArchiveCase}
        onConfirm={handleArchiveCase}
        onCancel={handleCloseArchiveCase}
        isLoading={isArchivingCase}
        amount={1}
      />

      <UnarchiveCasesModal
        isOpen={isOpenUnarchiveCase}
        onConfirm={handleUnarchiveCase}
        onCancel={handleCloseUnarchiveCase}
        isLoading={isUnarchivingCase}
        amount={1}
      />

      <Outlet />
    </PageLayout>
  );
};
