import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

interface VerificationCodeInputProps {
  length?: number;
  onChange: (code: string) => void;
  error?: string;
}

export const VerificationCodeInput = ({ length = 6, onChange, error }: VerificationCodeInputProps) => {
  const { palette } = useTheme();
  const [code, setCode] = useState<string[]>(new Array(length).fill(""));
  const inputRefs = useMemo(
    () => Array.from({ length }, () => null).map(() => React.createRef<HTMLInputElement>()),
    [length]
  );

  useEffect(() => {
    onChange(code.join(""));
  }, [code, onChange]);

  const handleChange = (index: number, value: string) => {
    setCode((prev) => {
      const newCode = [...prev];
      newCode[index] = value;

      return newCode;
    });

    if (value !== "" && index < length - 1) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      inputRefs[index - 1].current?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === length) {
      const newCode = pasteData.split("").slice(0, length);
      setCode(newCode);
      newCode.forEach((value, index) => {
        handleChange(index, value);
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        px: "50px",
      }}
    >
      <Typography variant="body2" align="center" mb={1}>
        Código de confirmação
      </Typography>

      <Box
        sx={{
          display: "grid",
          height: "40px",
          gridTemplateColumns: `repeat(${length}, minmax(0, 1fr))`,
          gap: 0.5,
        }}
      >
        {Array.from({ length }, (_, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength={1}
            value={code[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            style={{
              maxWidth: "34px",
              width: "100%",
              height: "100%",
              padding: "0",
              textAlign: "center",
              fontSize: "14px",
              borderRadius: "5px",
              border: `1px solid ${palette.common.lightShade}`,
              outline: "none",
              backgroundColor: error ? palette.error.light : undefined,
              color: error ? palette.error.main : undefined,
            }}
          />
        ))}
      </Box>

      {error && (
        <Typography variant="body2" color="error.main" mt={0.5}>
          {error}
        </Typography>
      )}
    </Box>
  );
};
