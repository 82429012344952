import {
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
  KeyboardArrowUpRounded as KeyboardArrowUpRoundedIcon,
  SearchRounded as SearchIcon,
} from "@mui/icons-material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface DocumentSearchProps {
  searchInputText: string;
  setSearchInputText: React.Dispatch<React.SetStateAction<string>>;
  occurrences: number;
  currentOccurrence: number;
  goToNextOccurrence: () => void;
  goToPrevOccurrence: () => void;
}

export const DocumentSearch = ({
  searchInputText,
  setSearchInputText,
  occurrences,
  currentOccurrence,
  goToNextOccurrence,
  goToPrevOccurrence,
}: DocumentSearchProps) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 3,
        display: "grid",
        gridTemplateColumns: "minmax(0, 1fr) min-content",
        gap: 2,
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <TextField
        onChange={(e) => setSearchInputText(e.target.value)}
        placeholder="Buscar no documento"
        sx={{
          width: "100%",
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon
              fontSize="medium"
              sx={{
                mr: 1,
              }}
            />
          ),
          endAdornment: (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                p: 1,
                pr: 0,
                boxSizing: "border-box",
              }}
            >
              {searchInputText && (
                <>
                  <Typography variant="body">
                    {occurrences > 0 ? `${currentOccurrence + 1}/${occurrences}` : "0/0"}
                  </Typography>

                  <Box
                    sx={{
                      height: "100%",
                      width: "1px",
                      bgcolor: "divider",
                      mx: 2,
                    }}
                  />

                  <KeyboardArrowUpRoundedIcon
                    fontSize="medium"
                    onClick={goToPrevOccurrence}
                    sx={{
                      cursor: "pointer",
                      mr: 1,
                    }}
                  />
                  <KeyboardArrowDownRoundedIcon
                    fontSize="medium"
                    onClick={goToNextOccurrence}
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </>
              )}
            </Box>
          ),
        }}
      />
    </Box>
  );
};
