import React from "react";

import { Box, Typography } from "@mui/material";
import CasesNotFoundIcon from "@/assets/svgs/cases-not-found.svg?react";

export const EmptyCases = ({ archived }: { archived: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          width: "444px",
        }}
      >
        <Box
          sx={{
            paddingX: 10.5,
          }}
        >
          <CasesNotFoundIcon
            style={{
              flexShrink: 0,
              width: "100%",
              marginBottom: "40px",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "700",
              fontSize: "22px",
            }}
          >
            Você não possui casos {archived ? "arquivados" : "ativos"}
          </Typography>
          <Typography variant="body" sx={{ whiteSpace: "normal" }}>
            {archived
              ? "Para arquivar um caso basta selecionar um caso antigo e clicar no botão de arquivar que vai aparecer no canto superior direito da sua tela."
              : "Um novo caso vai aparecer aqui assim que tivermos sua aprovação em algum resumo de contato com cliente"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
