import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export const InputField = ({ label, placeholder, value, onChange, error, ...rest }: TextFieldProps) => {
  return (
    <TextField
      sx={{
        ".MuiFormLabel-root, .MuiInputLabel-root": {
          fontSize: "14px",
          transformOrigin: "center left",
        },
      }}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      label={label}
      error={error}
      {...rest}
    />
  );
};
