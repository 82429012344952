import {
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";

import { Checkbox } from "@/components/Checkbox";
import { Court, CourtGroup, isCourtGroup } from "../../types";

interface CourtCheckboxProps {
  data: Court | CourtGroup;
  onChange: (court: Court, checked: boolean) => void;
  selectedCourts: Court[];
}
export const CourtCheckbox = ({ data, onChange, selectedCourts }: CourtCheckboxProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;

  const handleGroupCheck = (group: CourtGroup, checked: boolean) => {
    const courtsToChange = group.courts.filter((court) => {
      const isCourtChecked = selectedCourts.some((c) => c.code === court.code);
      return checked ? !isCourtChecked : isCourtChecked;
    });

    courtsToChange.forEach((court) => onChange(court, checked));

    if (checked) {
      setIsExpanded(true);
    }
  };

  if (isCourtGroup(data)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Checkbox
            checked={data.courts.every((court) => selectedCourts.some((c) => c.code === court.code))}
            label={data.name}
            onChange={(_, checked) => handleGroupCheck(data, checked)}
          />

          <IconButton onClick={() => setIsExpanded((prev) => !prev)} sx={{ m: -1 }}>
            <ExpandIcon fontSize="medium" />
          </IconButton>
        </Box>

        <Collapse in={isExpanded}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pl: 5, pt: 2 }}>
            {data.courts.map((court, index) => (
              <Checkbox
                key={index}
                checked={selectedCourts.some((c) => c.code === court.code)}
                label={court.code}
                onChange={(_, checked) => onChange(court, checked)}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
    );
  }

  return (
    <Checkbox
      checked={selectedCourts.some((c) => c.code === data.code)}
      label={data.code}
      onChange={(_, checked) => onChange(data, checked)}
    />
  );
};
