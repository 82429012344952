export const ClarityService = {
  addUserId(userId?: string) {
    if (window.clarity && userId) {
      window.clarity("identify", userId);
    }
  },
  track(eventName: string, eventValue: string) {
    if (window.clarity) {
      window.clarity("set", eventName, eventValue);
    }
  },
};
