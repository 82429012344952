import { Box, Typography } from "@mui/material";
import React from "react";

export const CreditsHistoryListError = () => (
  <Box
    p="16px"
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "common.dorian",
      borderLeft: "none",
      borderRight: "none",
    }}
  >
    <Typography
      variant="body3"
      sx={{
        textAlign: "center",
      }}
    >
      <strong>Ops!</strong>
      <br />
      Não foi possível carregar seu histórico de uso. Aguarde alguns segundos e tente novamente.
    </Typography>
  </Box>
);
