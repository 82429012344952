import { Typography } from "@mui/material";
import { DialogModal } from "@/components/DialogModal";
import React from "react";

interface ExitConfirmDialogProps {
  open: boolean;
  title: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
  onExport?: () => void;
  action: "NEW" | "RESTART" | "EXISTING" | "EXIT";
  chatName?: string;
}

export const ExitConfirmDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  action,
  chatName,
}: ExitConfirmDialogProps) => {
  const getMessage = () => {
    switch (action) {
      case "NEW":
        return "Deseja abrir nova sessão?";
      case "RESTART":
        return "Deseja reiniciar a sessão do Assistente Lexter?";
      case "EXISTING":
        return `Deseja abrir a sessão ${chatName}?`;
      case "EXIT":
        return "Deseja sair de sua conta?";
    }
  };

  const description = getMessage();

  return (
    <DialogModal
      open={open}
      title={title}
      description={
        <>
          <Typography variant="body1" sx={{ color: "text.primary" }}>
            {description}{" "}
            <Typography>
              {message ??
                "Você poderá retornar para esta sessão para consultar o resultado de ações em andamento ou informações salvas previamente."}
            </Typography>
          </Typography>
        </>
      }
      descriptionTextAlign="left"
      buttons={[
        {
          label: "Continuar",
          onClick: onConfirm,
        },
        {
          label: "Cancelar",
          onClick: onCancel,
          variant: "text",
          color: "primary",
          sx: {
            color: "text.primary",
            textDecoration: "underline",
          },
        },
      ]}
      variant="error"
    />
  );
};
