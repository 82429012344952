import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { lexZapUsersQueryKey } from "../useLexZapUsers";
import { SetupLexZapUserParams } from "@/hooks/useApi/types";

export const useSetupLexZapUser = () => {
  const { setupLexZapUser } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userData: SetupLexZapUserParams) => setupLexZapUser(userData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: lexZapUsersQueryKey });
    },
  });
};
