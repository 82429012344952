import { useContext } from "react";
import { AuthContext } from "./context";

export function useAuthContext() {
  const contextValue = useContext(AuthContext);
  if (!contextValue) {
    throw new Error("AuthContext not found");
  }
  return contextValue;
}
