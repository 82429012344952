import React, { ChangeEventHandler, forwardRef, useState } from "react";
import { Box, TextFieldProps, Typography } from "@mui/material";
import { PasswordField } from "../PasswordField";

type PasswordValidationInputProps = TextFieldProps & {
  validations: Validation[];
};

interface Validation {
  text: string;
  valid: boolean;
}

export const PasswordValidationInput = forwardRef<TextFieldProps, PasswordValidationInputProps>(
  ({ validations, error, onChange, sx, ...rest }, ref) => {
    const [isDirty, setIsDirty] = useState(false);

    const handleOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
      setIsDirty(true);
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ...sx,
        }}
      >
        <PasswordField error={error} onChange={handleOnChange} {...rest} ref={ref} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mt: "0.5rem",
          }}
        >
          {validations.map((item, index) => {
            let color = "common.lightShade";

            if (!isDirty && error) {
              color = "error.main";
            } else if (isDirty || error) {
              color = item.valid ? "success.main" : "error.main";
            }

            return (
              <Typography
                key={index}
                variant="body2"
                color={color}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "::before": {
                    fontSize: "16px",
                    content: '"•"',
                    color,
                    mr: "0.25rem",
                  },
                }}
              >
                {item.text}
              </Typography>
            );
          })}
        </Box>
      </Box>
    );
  }
);
PasswordValidationInput.displayName = "PasswordValidationInput";
