import { Editor as TinyMCEEditor } from "tinymce";
import juice from "juice";
// @ts-expect-error No types available
import htmlToDocx from "html-to-docx";
import { getFormattedDate } from "@/utils/formattedDate";
import { saveFileAs } from "@/utils/saveFileAs";

export const exportDocx = async (editor: TinyMCEEditor) => {
  // Código necessario para negrito ser exportado corretamente pro word
  const htmlWithInlineContent = juice.inlineContent(editor.getContent(), "p:has(strong) { font-weight: bold;}");
  const docx = await htmlToDocx(htmlWithInlineContent, "", {
    orientation: "portrait",
    creator: "Lexter",
    lang: "pt_BR",
    font: "Arial",
    fontSize: 24, // 12pt
  });

  saveFileAs(docx, `Documento_${getFormattedDate()}.docx`);
};
