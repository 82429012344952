import { Skill } from "@/core/skills/types";
import { createInitialPetitionSkill } from "@/core/skills/implementations";
import { Area } from "@/contexts/MessagesContext";

export const createCriminalInitialPetitionSkill: Skill<typeof createInitialPetitionSkill.inputSchema> = {
  ...createInitialPetitionSkill,
  id: "create_criminal_initial_petition",
  name: "Escrever petição inicial criminal",
  tags: [...createInitialPetitionSkill.tags, "mostUsed"],
  initialValue: {
    area: Area.CRIMINAL,
  },
};
