import { buildSkillStep } from "@/core/skillForms/builder";

export const hearingsScriptSkillStep2 = buildSkillStep({
  fields: [
    {
      name: "reference_piece_answer",
      type: "file",
      ui: {
        label: "Enviar contestação do caso:",
      },
    },
  ],
});
