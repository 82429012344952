import React from "react";
import { Tabs as MUITabs, Tab as MUITab, TabProps, TabsProps } from "@mui/material";

export const Tab = (props: TabProps) => {
  return (
    <MUITab
      sx={{
        textTransform: "none",
        color: "common.black",
        opacity: 0.8,
        pb: "2px",
        "&:hover": {
          color: "common.black",
          opacity: 1,
        },
        "&.Mui-selected": {
          color: "common.black",
          opacity: 1,
        },
        "&.active": {
          bgcolor: "none",
        },
      }}
      {...props}
    />
  );
};

export const Tabs = (props: TabsProps) => {
  return <MUITabs TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} {...props} />;
};
