import ProhibitedImage from "@/assets/images/Prohibited.png";
import { SignupMessageScreen } from "@/components/SignupMessageScreen";
import React from "react";
import { useNavigate } from "react-router-dom";

export const SignupFailure = () => {
  const navigate = useNavigate();

  return (
    <SignupMessageScreen
      imageSrc={ProhibitedImage}
      title="Falha!"
      message="Infelizmente houve uma falha ao efetuar o seu cadastro.
      Por favor, tente novamente."
      button={{
        label: "Voltar",
        onClick: () => navigate(-1),
      }}
    />
  );
};
