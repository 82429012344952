import { Box, SxProps, TextField, Typography } from "@mui/material";
import React from "react";
import { Text } from "../Text";

interface TextInputProps {
  id?: string;
  label?: string;
  description?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
  sx?: SxProps;
  minLength?: number;
}

export const TextInput = ({ id, label, description, placeholder, onChange, value, sx, minLength }: TextInputProps) => {
  const charCount = value?.length || 0;
  const showCharCount = minLength && charCount < minLength;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Box sx={sx}>
      {(label || description) && (
        <Box sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          {label && (
            <Typography variant="preTitle" color="primary.text" sx={{ textWrap: "wrap" }}>
              {label}
            </Typography>
          )}
          {description && <Text>{description}</Text>}
        </Box>
      )}

      <TextField id={id} value={value} onChange={handleChange} placeholder={placeholder} multiline fullWidth />

      {showCharCount && (
        <Typography variant="body" color="error" sx={{ mt: 1, position: "absolute", right: 16, pointerEvents: "none" }}>
          {charCount}/{minLength} caracteres mínimos
        </Typography>
      )}
    </Box>
  );
};
