import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from "@mui/material";
import MarketingFillingSurvey from "@/assets/svgs/marketing-filling-survey.svg?react";
import React, { useState } from "react";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";
import { HideMenusPageLayout } from "@/components/HideMenusPageLayout";
import { LexterApiService } from "@/services/lexterApi";
import { useAuthContext } from "@/contexts/AuthContext";
import { AuthService } from "@/services/auth";
import { logger } from "@/core/logger";
import { MultiSelectChip, MultiSelectChipItem } from "@/components/MultiSelectChip";
import { activityAreasDefaultValues } from "@/components/Onboarding/defaultValues";

const questionsForSales = [
  {
    name: "numberOfProcessesActiveAtOffice",
    title: "Número de processos ativos atualmente no seu escritório?",
    options: [
      { label: "até 50", value: "Até 50" },
      { label: "de 51 a 299", value: "De 51 a 299" },
      { label: "de 300 a 999", value: "De 300 a 999" },
      { label: "Mais de 1000", value: "Mais de 1000" },
    ],
  },
  {
    name: "currentPositionAtOffice",
    title: "Qual seu cargo atual no escritório?",
    options: [
      { label: "Sócio", value: "Sócio" },
      { label: "Associado ou advogado sênior", value: "Associado ou advogado sênior" },
      { label: "Associado, advogado júnior ou pleno", value: "Associado, advogado júnior ou pleno" },
      { label: "Estagiário ou paralegal", value: "Estagiário ou paralegal" },
      { label: "Administrativo ou TI", value: "Administrativo ou TI" },
    ],
  },
  {
    name: "activityAreas",
    title: "Quais áreas jurídicas que mais atua? Selecione até 5 opções da lista abaixo:",
  },
];

const ICPAnswers = ["De 300 a 999", "Mais de 1000", "Sócio", "Associado ou advogado sênior"];

export const InsideSalesQuestionsPage = () => {
  return (
    <HideMenusPageLayout>
      <Illustration />
      <Questions />
    </HideMenusPageLayout>
  );
};

const Illustration = () => {
  const { breakpoints } = useTheme();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{
          maxWidth: "420px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "left",
          [breakpoints.up("md")]: {
            mb: 12,
          },
          [breakpoints.up("xl")]: {
            mb: 24,
          },
        }}
      >
        <MarketingFillingSurvey />
        <Typography variant="h4">Antes de começar, queremos te conhecer</Typography>
        <Typography variant="body1">Informe alguns dados para prosseguir:</Typography>
      </Box>
    </Box>
  );
};

const Questions = () => {
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [answers, setAnswers] = React.useState<Record<string, unknown>>({});
  const { showOnboarding, insideSalesEnabled } = useFeatureFlags();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [activityAreas, setActivityAreas] = useState<MultiSelectChipItem[]>(activityAreasDefaultValues);

  const getFieldName = (index: number) => questionsForSales[index].name;

  const handleNextQuestion = () => {
    if (currentQuestion + 1 === questionsForSales.length) {
      handleSubmit();
    } else {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const sendAnswers = async () => {
    if (!user) return logger.error("User not found in sendAnswers");
    const { token } = await AuthService.getValidToken();
    if (!token) return logger.error("Token not found in sendAnswers");

    await LexterApiService.completeProfile({
      userId: user.userId,
      companyId: user.companyId,
      token: token,
      numberOfProcessesActiveAtOffice: answers.numberOfProcessesActiveAtOffice as string,
      currentPositionAtOffice: answers.currentPositionAtOffice as string,
      activityAreas: answers.activityAreas as string[],
    });
  };

  const handleSubmit = () => {
    void sendAnswers();
    // Remove activityAreas from answers to compare with ICPAnswers
    const filteredAnswers = Object.entries(answers)
      .filter(([key]) => key !== "activityAreas")
      .map(([, value]) => value);

    if (filteredAnswers.every((answer) => ICPAnswers.includes(answer as string)) && insideSalesEnabled) {
      return navigate(ROUTE_PATHS.CALL_WITH_SPECIALIST, {
        replace: true,
      });
    }

    if (showOnboarding) {
      localStorage.setItem("isOnboarding", "true");
      return navigate(ROUTE_PATHS.WELCOME, {
        replace: true,
      });
    }

    return navigate(ROUTE_PATHS.HOME, {
      replace: true,
    });
  };

  const handlePreviousQuestion = () => setCurrentQuestion((prev) => prev - 1);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [getFieldName(currentQuestion)]: value,
    }));
  };

  const onUserActivityAreasChange = (options: MultiSelectChipItem[]) => {
    setActivityAreas(options);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [getFieldName(currentQuestion)]: options.filter(({ selected }) => selected).map(({ value }) => value),
    }));
  };

  return (
    <Box bgcolor="grey.50" display="flex" alignItems="center" justifyContent="center">
      <Box maxWidth="468px" display="flex" gap={4} flexDirection="column">
        <QuestionsTrail currentQuestion={currentQuestion + 1} />
        <QuestionsBox>
          <Typography variant="subtitle1" fontWeight={700} textAlign="left" mb={3}>
            {questionsForSales[currentQuestion].title}
          </Typography>
          {!questionsForSales[currentQuestion].options ? (
            <MultiSelectChip max={5} options={activityAreas} onMultiSelectChipChange={onUserActivityAreasChange} />
          ) : (
            <>
              <RadioGroup
                onChange={handleAnswerChange}
                value={answers[getFieldName(currentQuestion)] || ""}
                sx={{ pl: 1, flexGrow: 1 }}
              >
                {questionsForSales[currentQuestion].options.map((option, i) => (
                  <FormControlLabel key={i} value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>
            </>
          )}
          <Box alignSelf="end" display="flex" gap={1}>
            {currentQuestion !== 0 && <Button onClick={handlePreviousQuestion}>Voltar</Button>}
            <Button onClick={handleNextQuestion} disabled={!answers[getFieldName(currentQuestion)]} variant="contained">
              {currentQuestion + 1 === questionsForSales.length ? "Conhecer o Assistente" : "Próximo"}
            </Button>
          </Box>
        </QuestionsBox>
      </Box>
    </Box>
  );
};

const QuestionsTrail = ({ currentQuestion }: { currentQuestion: number }) => {
  const totalQuestions = questionsForSales.length;

  return (
    <Box display="flex" alignItems="center" maxWidth={224}>
      <Typography variant="h6" component="p" letterSpacing={6}>
        {currentQuestion}
        <Typography variant="body3" component="span" color="text.disabled" letterSpacing={6}>
          /{totalQuestions}
        </Typography>
      </Typography>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgba(0, 0, 0, 0.26)",
          height: "4px",
          borderRadius: "4px",
          overflow: "hidden",
          my: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: "text.primary",
            height: "4px",
            borderRadius: "4px 0 0 4px",
            width: `${(currentQuestion / totalQuestions) * 100}%`,
          }}
        />
      </Box>
    </Box>
  );
};

const QuestionsBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        bgcolor: "common.white",
        minHeight: "355px",
        minWidth: "500px",
        boxShadow: `8px 8px 25px 0px #0000000D, 
      33px 31px 46px 0px #0000000A,  
      75px 70px 61px 0px #00000008, 
      133px 124px 73px 0px #00000003, 
      208px 194px 80px 0px #00000000`,
        borderRadius: "16px",
        border: "1px solid #E0E0E0",
        padding: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
};
