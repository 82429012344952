import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const useLexZap = () => {
  const api = useApi();
  const { user } = useAuthContext();

  return useQuery({
    queryKey: ["lexZap"],
    queryFn: api.getLexZapUserStatus,
    enabled: !!user?.userId,
  });
};
