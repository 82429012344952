import { DateTime } from "luxon";

/**
 * Formats to a string with the following format: `"31 Jul 2024"`
 */
export const formattedDateFromISO = (date: string) => {
  return DateTime.fromISO(date)
    .toFormat("dd LLL yyyy")
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .replace(".", "");
};
