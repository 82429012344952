import { useMemo } from "react";
import { PlanType } from "../credits/types";
import { useFeatureFlags } from "../useFeatureFlags";
import { NewPlan } from "@/contexts/PaymentContext";
import { ROUTE_PATHS } from "@/routes/routePaths";

export const useNewPlans = () => {
  const {
    onlyUnlimitedPlanEnabled,
    unlimitedPlanEnabled,
    newPlansEnabled,
    whatsAppAssistantEnabled,
    newPlansInCheckoutEnabled,
  } = useFeatureFlags();

  return useMemo((): NewPlan[] => {
    const plans = [
      {
        id: PlanType.INITIAL,
        title: "Inicial",
        subtitle: "Até 5 peças por mês",
        description:
          "Comece a utilizar o Assistente Lexter com um custo acessível. Utilize o melhor da inteligência artificial gerando até 5 peças por mês.",
        price: 59,
        credits: 1000,
        perksDescription: ["Atendimento via e-mail"],
        hidden: onlyUnlimitedPlanEnabled,
      },
      {
        id: PlanType.BASIC,
        title: "Básico",
        subtitle: "Até 10 peças por mês",
        description: "Transforme suas tarefas jurídicas diárias com o melhor da inteligência artificial.",
        price: 99,
        credits: 2000,
        perksDescription: ["Atendimento via e-mail"],
        hidden: onlyUnlimitedPlanEnabled,
      },
      {
        id: PlanType.INTERMEDIARY,
        title: "Intermediário",
        subtitle: "Até 22 peças por mês",
        description: "Potencialize seu trabalho jurídico diário usando inteligência artificial de ponta.",
        price: 199,
        credits: 4500,
        hidden: unlimitedPlanEnabled || onlyUnlimitedPlanEnabled,
      },
      {
        id: PlanType.ADVANCED,
        title: "Avançado",
        subtitle: "Até 35 peças por mês",
        description: "Leve a eficiência ao máximo em suas tarefas jurídicas com o auxílio da inteligência artificial.",
        price: 299,
        credits: 7000,
        hidden: unlimitedPlanEnabled || onlyUnlimitedPlanEnabled,
      },
      {
        id: PlanType.UNLIMITED,
        title: "Essencial",
        description: "Para criar peças e documentos",
        subtitle: "Para criar peças e documentos",
        price: 199,
        credits: Infinity,
        withoutPerksList: [
          "Assistente de IA para atendimento de clientes no Whatsapp",
          "Gestor de casos, clientes e tarefas integrado a todos os assistentes de IA",
        ],
        perksDescription: [
          "Criação de peças e documentos com IA",
          "Tarefas jurídicas otimizadas com IA",
          "Suporte via e-mail",
        ],
      },
      {
        id: PlanType.PROFESSIONAL_WITH_WPP,
        title: "Profissional",
        description: "Para acelerar o seu negócio",
        subtitle: "Para acelerar o seu negócio",
        price: 799,
        credits: Infinity,
        perksDescription: [
          "Assistente de IA para atendimento de clientes no Whatsapp",
          "Gestor de casos, clientes e tarefas integrado a todos os assistentes de IA com 200 casos ativos",
          "Criação de peças e documentos com IA",
          "Tarefas jurídicas otimizadas com IA",
          "Suporte via e-mail",
        ],
        hidden: !newPlansEnabled || !whatsAppAssistantEnabled,
        buttonRedirectTo: !newPlansInCheckoutEnabled ? ROUTE_PATHS.WHATSAPP_ASSISTANT : undefined,
      },
      {
        id: PlanType.PERFORMANCE,
        title: "Performance",
        description: "Para alcançar mais clientes",
        subtitle: "Para alcançar mais clientes",
        price: 1499,
        credits: Infinity,
        perksDescription: [
          "Assistente de IA para atendimento de clientes no Whatsapp",
          "Gestor de casos, clientes e tarefas integrado a todos os assistentes de IA com 500 casos ativos",
          "Criação de peças e documentos com IA",
          "Tarefas jurídicas otimizadas com IA",
          "Suporte jurídico e técnico dedicado",
        ],
        hidden: !newPlansEnabled || !whatsAppAssistantEnabled,
        buttonRedirectTo: !newPlansInCheckoutEnabled ? ROUTE_PATHS.WHATSAPP_ASSISTANT : undefined,
      },
    ];

    return plans.filter((plan) => !plan.hidden);
  }, [unlimitedPlanEnabled, onlyUnlimitedPlanEnabled, newPlansEnabled, whatsAppAssistantEnabled]);
};
