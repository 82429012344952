import { useDebounce } from "@/hooks/useDebounce";
import { findTextOccurrences } from "@/utils/findTextOccurrences";
import React, { useEffect, useMemo, useRef, useState } from "react";

interface UseDocumentSearchProps {
  documentText: string;
}

export const useDocumentSearch = ({ documentText }: UseDocumentSearchProps) => {
  const [searchInputText, setSearchInputText] = useState("");
  const searchTerm = useDebounce(searchInputText, 300);

  const occurrences = useMemo(() => {
    return findTextOccurrences(documentText, searchTerm);
  }, [documentText, searchTerm]);
  const [currentOccurrence, setCurrentOccurrence] = useState(0);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const getHighlightedText = (highlight: string) => {
    if (!highlight.trim()) {
      return documentText;
    }

    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = documentText.split(regex);
    let occurrenceIndex = -1;

    return parts.map((part, index) =>
      regex.test(part) ? (
        <mark
          key={index}
          data-occurrence={++occurrenceIndex}
          style={occurrenceIndex === currentOccurrence ? { backgroundColor: "orange" } : {}}
        >
          {part}
        </mark>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    const element = textContainerRef.current?.querySelector(`[data-occurrence="${currentOccurrence}"]`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [currentOccurrence]);

  const goToOccurrence = (direction: number) => {
    setCurrentOccurrence((prev) => (prev + direction + occurrences) % occurrences);
  };
  const goToNextOccurrence = () => goToOccurrence(1);
  const goToPrevOccurrence = () => goToOccurrence(-1);

  useEffect(() => {
    setCurrentOccurrence(0);
  }, [occurrences, searchTerm]);

  return {
    getHighlightedText,
    searchInputText,
    setSearchInputText,
    occurrences,
    currentOccurrence,
    goToNextOccurrence,
    goToPrevOccurrence,
    searchTerm,
    textContainerRef,
  };
};
