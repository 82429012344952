import { DateTime, Settings } from "luxon";

import { Duration } from "@/core/Duration";
import { Result } from "@/core/Result";
import { ValueObject } from "@/core/ValueObject";

Settings.defaultZone = "America/Sao_Paulo";

const longStringFormat = new RegExp(
  "^" + //start of the string
    "\\s*" + //allow any number of white space at the beginning of the string
    "(\\d{2})" + //days, represented by 2 digits. Group 1
    "\\/" + //separate days from months with a /
    "(\\d{2})" + //months, represented by 2 digits. Group 2
    "\\/" + //separate months from years with a /
    "(\\d{4})" + //years, represented by 4 digits. Group 3
    "\\s+" + //allow one or more white spaces to separate the date from the time
    "(\\d{2})" + //hours, represented by 2 digits. Group 4
    ":" + //separate hours from minutes with a :
    "(\\d{2})" + //minutes, represented by 2 digits. Group 5
    "\\s*" + //allow any number of white space at the end of the string
    "$" //end of the string
);

interface LexterDateProps {
  /**
   * number representing the milliseconds elapsed since UNIX epoch
   */
  date: number;
}

/**
 * LexterDate is an internal representation of a Date
 * This way we may abstract the date library/implementation used and thus make
 * sure everyone is following a standard throughout the code
 */
export class LexterDate extends ValueObject<LexterDateProps> {
  private constructor(props: LexterDateProps) {
    super(props);
  }

  /**
   * Create a LexterDate that represents the current moment
   */
  static now(): LexterDate {
    return new LexterDate({
      date: Date.now(),
    });
  }

  /**
   * Creates a LexterDate from a JS object
   * @param date a JS date object
   */
  static fromDate(date: Date): Result<LexterDate> {
    if (!date || date.constructor !== Date) {
      return Result.fail<LexterDate>("Argument was not a JS date object");
    }

    const lexterDate = new LexterDate({
      date: date.getTime(),
    });

    return Result.ok<LexterDate>(lexterDate);
  }

  /**
   * Creates a LexterDate from a string
   *
   * Accepted formats:
   *   * DD/MM/YYYY HH:mm
   *
   * @param str date string
   */
  static fromString(str: string): Result<LexterDate> {
    if (typeof str !== "string" || str === "") {
      return Result.fail<LexterDate>("Invalid data string");
    }

    let date: DateTime;
    const longStringMatch = str.match(longStringFormat);
    if (longStringMatch !== null) {
      const [, /*full match*/ day, month, year, hour, minute] = longStringMatch;
      date = DateTime.fromObject({
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        second: 0,
        millisecond: 0,
      });
    } else {
      date = DateTime.fromISO(str);
    }

    if (date.isValid) {
      return Result.ok<LexterDate>(
        new LexterDate({
          date: date.toMillis(),
        })
      );
    }

    return Result.fail<LexterDate>("String date format not supported");
  }

  /**
   * Transform a Cortex date to a LexterDate
   *
   * Cortex format: 2022-03-20 21:47:27
   *
   * @param str Cortex date string
   */
  static fromCortexString(str: string): Result<LexterDate> {
    //We will transform a Cortex string to a ISO string and use the fromString
    //method afterwards
    const isoString = str.trim().replace(" ", "T").replace("Z", "") + "Z";

    return LexterDate.fromString(isoString);
  }

  /**
   * Returns the date timestamp, milliseconds since January 1st, 1970 at UTC
   */
  public toTimestamp(): number {
    return this.props.date;
  }

  /**
   * Transform the current LexterDate to a persistent format
   */
  public toPersistence(): Date {
    return DateTime.fromMillis(this.props.date).toJSDate();
  }

  /**
   * Return a date as in the format specified in the ISO 8601
   * Ex: 2017-04-20T11:32:00.000-04:00
   */
  public toString(): string {
    return DateTime.fromMillis(this.props.date).toISO()!;
  }

  /**
   * Return a date as in the SHORT format specified in the ISO 8601
   * Ex: 2017-04-20
   */
  public toShortString(): string {
    return DateTime.fromMillis(this.props.date).toFormat("yyyy-MM-dd");
  }

  /**
   * Return a date formatted as DD/MM/YY HH:mm
   */
  public toLongString(): string {
    return DateTime.fromMillis(this.props.date).toFormat("dd/LL/yyyy HH:mm");
  }

  /**
   * Transform the current date back to a JS date object
   */
  public toDate(): Date {
    return DateTime.fromMillis(this.props.date).toJSDate();
  }

  /**
   * Return the date by internationalizing the month
   * Ex: 03 ago 2021 10:36
   */
  public toShortMonthString(): string {
    return DateTime.fromMillis(this.props.date)
      .setLocale("pt")
      .toFormat("dd LLL yyyy - HH:mm")
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(".", "");
  }

  public toDuration(date: LexterDate): Duration {
    const ms = Math.abs(this.props.date - date.props.date);
    return Duration.fromSeconds(ms / 1000).getValue();
  }

  /**
   * Add a duration to a LexterDate
   *
   * Does not mutate the original date
   *
   * @param duration a Duration object, represents a time span
   * @returns a new LexterDate object
   */
  public addDuration(duration: Duration): LexterDate {
    const seconds = duration.toSeconds();
    const newDate = DateTime.fromMillis(this.props.date).plus({ seconds });
    return new LexterDate({
      date: newDate.toMillis(),
    });
  }

  /**
   * Subtract a duration from a LexterDate
   *
   * Does not mutate the original date
   *
   * @param duration a Duration object, represents a time span
   * @returns a new LexterDate object
   */
  public subtractDuration(duration: Duration): LexterDate {
    const seconds = duration.toSeconds();
    const newDate = DateTime.fromMillis(this.props.date).minus({ seconds });
    return new LexterDate({
      date: newDate.toMillis(),
    });
  }

  /**
   * Given another LexterDate calculate the difference
   *
   * @param date: LexterDate to compare to
   * @returns the duration between the dates (absolute value)
   */
  public difference(date: LexterDate): Result<Duration> {
    const millisecondsDifference = Math.abs(this.props.date - date.props.date);
    const millisecondsToSeconds = 1000;
    return Duration.fromSeconds(millisecondsDifference / millisecondsToSeconds);
  }

  /**
   * Returns if the current date is before another one
   *
   * @param date LexterDate to compare
   * @return true if the current date is before the passed date
   */
  public isBefore(date: LexterDate): boolean {
    return this.props.date < date.props.date;
  }

  /**
   * Returns if the current date is after another one
   *
   * @param date LexterDate to compare
   * @return true if the current date is after the passed date
   */
  public isAfter(date: LexterDate): boolean {
    return this.props.date > date.props.date;
  }

  /**
   * Returns a new date setting the time to the beginning of the day
   *
   * @return a new lexter date
   */
  public startOfTheDay(): LexterDate {
    const newDate = DateTime.fromMillis(this.props.date).startOf("day");
    return new LexterDate({
      date: newDate.toMillis(),
    });
  }

  /**
   * Returns a new date setting the time to the end of the day
   *
   * @return a new lexter date
   */
  public endOfTheDay(): LexterDate {
    const newDate = DateTime.fromMillis(this.props.date).endOf("day");
    return new LexterDate({
      date: newDate.toMillis(),
    });
  }

  /**
   * Given multiple lexter dates, returns the one that happened before
   * @param multiple LexterDates
   * @returns the smaller date, the one that precedes the other
   */
  static min(...dates: LexterDate[]): LexterDate {
    const timestamps = dates.filter((date) => date?.constructor === LexterDate).map((date) => date.toTimestamp());
    const minDate = Math.min(...timestamps);
    return new LexterDate({
      date: minDate,
    });
  }

  /**
   * Given two lexter dates, returns the one that happened after
   * @param date1 a LexterDate
   * @param date2 a LexterDate
   * @returns the biggest date
   */
  static max(...dates: LexterDate[]): LexterDate {
    const timestamps = dates.filter((date) => date?.constructor === LexterDate).map((date) => date.toTimestamp());
    const maxDate = Math.max(...timestamps);
    return new LexterDate({
      date: maxDate,
    });
  }

  static millisecondsToHours(milliseconds: number): number {
    const millisecondsInASeconds = 1000;
    const secondsInAMinute = 60;
    const minutesInAnHour = 60;
    const hours = milliseconds / (millisecondsInASeconds * secondsInAMinute * minutesInAnHour);
    return parseFloat(hours.toFixed(2));
  }
}
