import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { SendRounded as SendRoundedIcon } from "@mui/icons-material";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { MessageTemplateCharacterCounter } from "@/components/LexZap/MessageTemplateCharacterCounter";

interface ChatInputsProps {
  chatId?: string;
}

export const ChatInputs = ({ chatId }: ChatInputsProps) => {
  const { shouldUseTemplateMessage } = useLexZapChatInfo({ chatId });
  const [inputValue, setInputValue] = useState("");
  const formRef = React.useRef<HTMLFormElement>(null);

  const { mutate: sendMessage } = useSendLexZapMessage();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputValue.trim() && chatId) {
      sendMessage({
        chatId,
        message: {
          body: inputValue,
        },
      });
      setInputValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  return (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: "grey.100",
        paddingX: 3,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        gap: 3,
      }}
    >
      {/* <IconButton>
        <EmojiIcon fontSize="medium" />
      </IconButton> */}

      {/* <AddDocumentIcon
        style={{
          flexShrink: 0,
          width: "24px",
          cursor: "pointer",
        }}
      /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            bgcolor: "common.white",
            borderRadius: "24px",
            paddingY: "8.5px",
            paddingX: "14px",
          }}
        >
          {shouldUseTemplateMessage && (
            <Typography variant="multiLineBody" color="text.primary" mb={0.5}>
              Não nos falamos faz algum tempo. Segue continuação da nossa conversa:
            </Typography>
          )}
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                padding: 0,
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            autoComplete="off"
            hiddenLabel
            variant="outlined"
            placeholder="Escreva algo"
            size="small"
            multiline
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Box>

        {shouldUseTemplateMessage && <MessageTemplateCharacterCounter inputLength={inputValue.length} />}
      </Box>

      <IconButton sx={{ m: -1 }} type="submit">
        <SendRoundedIcon
          sx={{
            width: "24px",
            height: "24px",
            color: "orange.light.90",
          }}
        />
      </IconButton>

      {/* {inputValue ? (
        <IconButton sx={{ m: -1 }} type="submit">
          <SendRoundedIcon
            sx={{
              width: "24px",
              color: "orange.light.90",
            }}
          />
        </IconButton>
      ) : (
        <IconButton sx={{ m: -1 }}>
          <ChatMicIcon
            style={{
              flexShrink: 0,
              width: "24px",
              cursor: "pointer",
            }}
          />
        </IconButton>
      )} */}
    </Box>
  );
};
