import { Box, Slider, Typography } from "@mui/material";
import { BackgroundPage } from "@/components/BackgroundPage";
import { PageLayout } from "@/components/PageLayout";
import { StripeBuyButton } from "@/components/StripeBuyButton";
import { LegacyTextField } from "@/components/LegacyTextField";
import React, { useMemo, useState } from "react";
import { InternalPageHeader } from "@/taskpane/components/core/InternalPageStructure";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { PlanCard, PlanCardProps } from "./PlanCard";
import { plans } from "./plans";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { NewPlansPage } from "./NewPlansPage";
import { PlansPageWithUnlimited } from "./PlansPageWithUnlimited";

const CREDITS_TO_CREATE_DOCUMENT = 200;

export const Plans = () => {
  const { unlimitedPlanEnabled } = useFeatureFlags();

  if (outsideOfficeClient()) {
    if (unlimitedPlanEnabled) return <PlansPageWithUnlimited />;
    return <NewPlansPage />;
  }

  if (outsideOfficeClient()) {
    return (
      <PageLayout
        contentSx={{
          width: "clamp(1024px, 50%, 100%)",
        }}
      >
        <PlansComponent lightBackground />
      </PageLayout>
    );
  }

  return (
    <BackgroundPage bgColor="common.black">
      <PlansComponent />
    </BackgroundPage>
  );
};

interface PlansComponentProps {
  lightBackground?: boolean;
}
const PlansComponent = ({ lightBackground }: PlansComponentProps) => {
  const fontColor = lightBackground ? "common.shade" : "common.white";

  const [documentsCreatedPerMonth, setDocumentsCreatedPerMonth] = useState(5);

  //const customPlan: PlanCardProps = useMemo(
  //  () => ({
  //    plan: {
  //       title: "Plano Enterprise",
  //       description:
  //         "Seu volume é maior do que os planos disponíveis? Fique tranquilo, o time comercial da Lexter.Ai pode te ajudar com um plano sob medida.",
  //     },
  //      button: (
  //       <Link href={EXTERNAL_URLS.CONTACT} target="_blank">
  //        <Button
  //          fullWidth
  //          variant="contained"
  //          sx={{
  //            mt: 1,
  //           }}
  //         >
  //          Fale conosco
  //         </Button>
  //        </Link>
  //    ),
  //  }),
  //  [],
  //);

  const predefinedPlans: PlanCardProps[] = useMemo(
    () =>
      plans.map((plan) => ({
        plan,
        button: <StripeBuyButton buyButtonId={plan.buyButtonId} />,
      })),
    []
  );

  const allPlans = useMemo(() => [...predefinedPlans], [predefinedPlans]);

  const recommendedPlan = useMemo(() => {
    const monthlyCreditSpend = documentsCreatedPerMonth * CREDITS_TO_CREATE_DOCUMENT;
    const plan = predefinedPlans.find((card) => card.plan.credits && card.plan.credits >= monthlyCreditSpend);

    if (!plan) {
      return allPlans[allPlans.length - 1];
    }

    return plan;
  }, [documentsCreatedPerMonth, predefinedPlans]);

  const marks = useMemo(() => {
    return predefinedPlans.map((plan) => ({
      value: plan.plan.credits! / CREDITS_TO_CREATE_DOCUMENT,
    }));
  }, [predefinedPlans]);

  const monthlyDocumentsLargerPlan =
    predefinedPlans[predefinedPlans.length - 1].plan.credits! / CREDITS_TO_CREATE_DOCUMENT;

  const maxSliderValue = monthlyDocumentsLargerPlan + 10;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const isNumber = /^\d*$/.test(value);
    if (isNumber) {
      setDocumentsCreatedPerMonth(Number(value));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <InternalPageHeader hideOutsideOffice />

      <Typography variant="h3" color={fontColor} align="center">
        Quantas peças você cria por mês?
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 35px",
          gap: 3,
          alignItems: "flex-end",
        }}
      >
        <Slider
          sx={{
            height: 8,
            "& .MuiSlider-thumb": {
              height: 24,
              width: 24,
              backgroundColor: "common.white",
              border: "2px solid currentColor",
              "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                boxShadow: "inherit",
              },
              "&::before": {
                display: "none",
              },
            },
            "& .MuiSlider-rail": {
              backgroundColor: "slider.rail",
              opacity: 1,
            },
          }}
          min={0}
          max={maxSliderValue}
          value={documentsCreatedPerMonth}
          marks={marks}
          onChange={(_event, newValue) => setDocumentsCreatedPerMonth(newValue as number)}
        />

        <LegacyTextField
          color={fontColor}
          value={documentsCreatedPerMonth}
          sx={{ mb: 1.5 }}
          inputProps={{
            sx: {
              textAlign: "center",
            },
          }}
          onChange={handleInputChange}
        />
      </Box>

      <Typography variant="label" color={fontColor} align="center">
        Recomendado para você
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PlanCard recommended {...recommendedPlan} />
      </Box>

      <Typography variant="label" color={fontColor} align="center">
        Todos os planos
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 3,
        }}
      >
        {allPlans.map((plan, index) => (
          <PlanCard key={index} {...plan} />
        ))}
      </Box>
    </Box>
  );
};
