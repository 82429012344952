import { buildSkillStep } from "@/core/skillForms/builder";

export const createNoticeSkillStep1 = buildSkillStep({
  title: "Detalhes do caso",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente que enviará a notificação?",
        placeholder: `Exemplo: João da Silva. \nSe tiver mais de um nome, separe com vírgulas.`,
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Faça um detalhamento do caso:",
        placeholder: "Detalhe o caso, insira informações essenciais e um relato dos fatos.",
      },
    },
  ],
});
