import { CreditAction, CreditTransactionType, CreditsHistoryApiResponseItem } from "@/hooks/useApi/types";

export const getDescription = (item: CreditsHistoryApiResponseItem) => {
  if (item.type === CreditTransactionType.RECHARGE && item.byReferral) return "Bonificação por indicação";
  if (item.type === CreditTransactionType.RECHARGE) return "Recarga de créditos";
  if (item.type === CreditTransactionType.REFUND) return "Devolução de créditos";
  if (item.type === CreditTransactionType.EXPIRE) return "Expiração de créditos";

  switch (item.action) {
    case CreditAction.WRITE_INITIAL_PETITION:
      return "Escrever petição inicial";
    case CreditAction.WRITE_CONTESTATION:
      return "Escrever contestação";
    case CreditAction.WRITE_APPEAL:
      return "Escrever apelação";
    case CreditAction.WRITE_REPLY:
      return "Escrever réplica";
    case CreditAction.WRITE_LABOR_COMPLAINT:
      return "Escrever reclamação trabalhista";
    case CreditAction.WRITE_LABOR_CONTESTATION:
      return "Escrever contestação trabalhista";
    case CreditAction.WRITE_LABOR_ORDINARY_APPEAL:
      return "Escrever recurso ordinário trabalhista";
    case CreditAction.SUMMARY_OF_THE_PETITION:
      return "Resumo de documento processual";
    case CreditAction.SEARCH_PRECEDENT:
      return "Busca de jurisprudência";
    case CreditAction.INTERACTION_WITH_ASSISTANT:
      return "Interação com chat";
    case CreditAction.WRITE_ONE_PIECE:
      return "Criação de peça";
    case CreditAction.WRITE_CONTRACT:
      return "Elaboração de contrato";
    case CreditAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT:
      return "Provas e documentos necessários";
    case CreditAction.CREATE_INTERVIEW_SCRIPT_ACTION:
      return "Criação de roteiro de entrevista";
    case CreditAction.WRITE_NOTICE_ACTION:
      return "Elaboração de notificação extrajudicial";
    case CreditAction.EDIT_DOCUMENT:
      return "Edição de documento";
    case CreditAction.LEGAL_QUESTION:
      return "Dúvida jurídica";
    case CreditAction.CREATE_STRATEGY:
      return "Elaboração de estratégia";
    case CreditAction.INTERCURRENT_MOTION:
      return "Escrever petição intercorrente";
    case CreditAction.LEGAL_ADVICE:
      return "Elaboração parecer jurídico";
    case CreditAction.SETTLEMENT_OFFER:
      return "Elaboração de proposta de acordo";
    case CreditAction.FEE_AGREEMENT:
      return "Elaboração de contrato de honorários";
    case CreditAction.HEARINGS_SCRIPT:
      return "Roteiro para audiências";
    case CreditAction.OTHER_TYPE:
      return "Elaboração de outro tipo de peça";
    default:
      return "Ação não identificada";
  }
};
