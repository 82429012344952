import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";
import { skillInputToUserInput } from "@/utils/skillInputToUserInput";

const inputSchema = Type.Object({
  reference_piece: Type.String(),
  case_breakdown: Type.Optional(Type.String()),
  client: Type.String(),
  area: Type.String(),
});

const actionId = ActionId.SETTLEMENT_OFFER;

export const settlementOfferSkill: Skill<typeof inputSchema> = {
  id: "settlement_offer",
  name: "Elaborar proposta de acordo",
  credits: CreditPerSkill.BASIC_SKILL,
  inputSchema,
  tags: ["litigation", "new"],
  actionId,
  type: "payload",
  description: "Gere propostas de acordo para resolver a lide de forma justa.",
  getPayload: (input) => ({
    skillId: ActionId.SETTLEMENT_OFFER,
    requestId: uuidV4(),
    payload: {
      reference_piece: skillInputToUserInput(input.reference_piece),
      case_breakdown: input.case_breakdown,
      client: input.client,
      area: input.area,
    },
    messageToSave: "Elaborar proposta de acordo",
  }),
};
