export const getParam = (param: string) => {
  let url = window.location.href;
  if (Office.context.document?.url) {
    url = Office.context.document.url;
  }

  const documentUrl = new URL(url);
  const searchParams = documentUrl.searchParams;

  let find: string | null = null;
  searchParams.forEach((value, key) => {
    if (key.toLowerCase() === param.toLowerCase()) {
      find = value;
    }
  });
  return find;
};
