import { Box, Typography } from "@mui/material";
import React from "react";

export const EmptyList = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        paddingX: 2,
        marginTop: 17,
        marginX: "auto",
        width: "100%",
        maxWidth: "360px",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: "700",
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        oops, nada por aqui
      </Typography>
      <Typography variant="body" sx={{ whiteSpace: "normal", fontSize: "12px", textAlign: "center" }}>
        Um novo caso vai aparecer aqui assim que tivermos sua aprovação em algum resumo de contato com cliente
      </Typography>
    </Box>
  );
};
