import { useAuthContext } from "@/contexts/AuthContext";
import { ROUTE_PATHS } from "@/routes/routePaths";

export interface MenuOption {
  label: string;
  options: {
    label: string;
    path?: string;
    withPlanChip?: boolean;
    openBilling?: boolean;
    replaceRoute?: boolean;
    tooltip?: string;
  }[];
}

export const useMenuOptions = (): { menuOptions: MenuOption[] } => {
  const { user } = useAuthContext();

  const menuOptions: (MenuOption & { hidden?: boolean })[] = [
    {
      label: "Meu Perfil",
      options: [
        {
          label: "Meus Dados",
          path: ROUTE_PATHS.CLIENT_CONFIG_MY_DATA,
          replaceRoute: true,
          tooltip: "Altere seu e-mail de acesso à plataforma",
        },
        { label: "Alterar Senha", path: ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD, replaceRoute: true },
      ],
    },
    {
      label: "Gerenciar assinatura",
      options: [
        { label: "Seu plano", withPlanChip: true, openBilling: true },
        { label: "Histórico de uso", path: ROUTE_PATHS.CREDITS_HISTORY },
      ],
    },
    {
      label: "Admin",
      options: [{ label: "Usuários do Assistente WhatsApp", path: ROUTE_PATHS.WHATSAPP_USERS }],
      hidden: !user?.isAdmin,
    },
  ];

  return {
    menuOptions: menuOptions.filter((option) => !option.hidden),
  };
};
