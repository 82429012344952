import { EditorContentField } from "@/components/SkillStepsForm/fields/editorContentField";
import { BaseInputTemplate } from "@/components/SkillStepsForm/templates/baseInputTemplate";
import { FieldTemplate } from "@/components/SkillStepsForm/templates/fieldTemplate";
import { ObjectFieldTemplate } from "@/components/SkillStepsForm/templates/objectFieldTemplate";
import { FileWidget } from "@/components/SkillStepsForm/widgets/fileWidget";
import RadioWidget from "@/components/SkillStepsForm/widgets/radioWidget";
import SelectWidget from "@/components/SkillStepsForm/widgets/selectWidget";
import TextareaWidget from "@/components/SkillStepsForm/widgets/textareaWidget";
import { SelectTextField } from "@/components/SkillStepsForm/fields/selectTextField";
import { ConditionalSkillStep, SkillForm, SkillStep } from "@/core/skillForms/types";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useEditor } from "@/hooks/useEditor";
import { Editor } from "@/hooks/useEditor/types";
import { alpha, Box, Button, Typography, useTheme } from "@mui/material";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/mui";
import { RJSFValidationError } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import brazilianLocalizer from "ajv-i18n/localize/pt-BR";
import { ErrorObject } from "ajv";
import React, { useMemo, useRef, useState } from "react";
import { Skill } from "@/core/skills/types";
import { SKILL_FORM_MAX_WIDTH } from "@/constants/layout";
import { useResizeObserver } from "@/hooks/useResizeObserver";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import Markdown from "markdown-to-jsx";
import { SkillStepper } from "./SkillStepper";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import templateDocumentUrlForContestation from "@/assets/documents/peticao_inicial_contestacao.pdf";
import templateDocumentUrlForAppeal from "@/assets/documents/sentenca_anonimizada.pdf";
import { logger } from "@/core/logger";
import { DecisionType } from "@/contexts/MessagesContext";

type FormData = Record<string, string>;

type SkillStepsFormProps = {
  skill: Skill;
  skillForm?: SkillForm | null;
  skillTitle?: string | null;
  onSubmit: (data: FormData) => void;
  goBack: () => void;
};

const validator = customizeValidator({}, (errors) => {
  const rest: ErrorObject[] = [];
  for (const error of errors ?? []) {
    if (error.keyword === "required") {
      error.message = "deve ter a propriedade obrigatória";
      continue;
    }
    if (error.keyword === "minLength") {
      error.message = `deve ter ao menos ${error.params.limit} caracteres`;
      continue;
    }
    if (error.keyword === "format" && error.params.format === "data-url") {
      error.message = "Arquivo não suportado. Por favor, envie um arquivo válido.";
      continue;
    }
    rest.push(error);
  }
  brazilianLocalizer(rest);
});

const PREFILLED_DATA = {
  create_initial_petition: {
    area: "civel",
    client: "João da Silva, nome completo conforme consta no RG nº 123456789 e no CPF 123.456.789-00.",
    case_breakdown:
      "Em 15/08/2022, no bairro Jardim Paulista, SP, houve o descumprimento contratual pela parte ré, Maria, que não entregou o imóvel na data acordada, causando prejuízos financeiros.",
    theses:
      "A parte ré, Maria, violou o art. 475 do Código Civil ao não cumprir a obrigação contratual, configurando direito à rescisão contratual e indenização.",
    evidence_text:
      "O autor dispõe de fotos e laudo técnico de vistoria datado de 05/09/2022, comprovando a não conformidade do imóvel entregue.",
    requests:
      "Requer a rescisão contratual, com a restituição de R$ 10.000,00 pagos, e indenização por danos materiais e morais, conforme previsto na legislação.",
  },
  create_contestation: {
    area: "civel",
    client: "SEGUROS ABC",
    case_breakdown:
      "Alega o requerente que em 12/08/2017 sofreu acidente com veículo automotor e consequentemente sequelas deste derivadas, o que teria ocasionado invalidez total e permanente. Em decorrência deste fato, requer o autor o recebimento do seguro obrigatório D(PVAT) em função de suposta invalidez permanente, no valor de R$13.500 de acordo com o artigo 3º, b da Lei11.482/2007. Contudo, em que pesem as alegações contidas na petição inicial, a pretensão do autor deverá ser julgada improcedente, pelas razões de fato e de direito a seguir expostas.",
    theses: `Necessário substituir a Seguros ABC pela Seguros DEF, pois A Seguros DEF é a responsável pelo pagamento das indenizações e tem um papel central na governança e transparência das operações do seguro. A solicitação de substituição visa agilizar o processo, melhorar a qualidade das informações e combater fraudes, sem causar atrasos.
As indenizações por danos pessoais do DPVAT podem ser solicitadas a qualquer seguradora do consórcio, conforme o parágrafo 7º do artigo 5º da Resolução CNSP nº 154/2006. Os pagamentos, no entanto, são feitos pela Seguros DEF, conforme o parágrafo 8º do mesmo artigo. A Seguros DEF é responsável por essas indenizações e, desde janeiro de 2008, organiza as operações do seguro DPVAT, conforme a reestruturação estabelecida pela Resolução nº 154 do CNSP, de 8 de dezembro de 2006.
O pedido de DPVAT já foi negado na esfera administrativa por falta de comprovação documental.
Falta de pressuposto processual em razão da ausência de documentos essenciais para a propositura da ação, os quais são: o boletim de ocorrência e laudo de exame de corpo delito, o que inviabiliza qualquer pedido realizado na inicial, pois não é possível demonstrar a verdade dos fatos alegados.
Não houve também a comprovação do nexo causal entre o acidente de trânsito e as lesões atuais. Assim, o pedido da parte autora deve ser rejeitado devido à ausência de cobertura para a indenização solicitada. Para a indenização por invalidez permanente do seguro DPVAT, é indispensável a prova de um acidente de trânsito. No caso em questão, não há comprovação da existência de tal acidente, pois não foram apresentados elementos que demonstrem o sinistro alegado.`,
    requests: `Requer-se a exclusão da Seguros ABC do polo passivo.
Requer-se seja o feito extinto sem resolução de mérito nos termos do Art. 485, inc. IV e VI do Código de Processo Civil.
Aguarda a requerida que a presente ação seja julgada totalmente improcedente.
Outrossim, requer a designação de audiência de instrução e julgamento, para depoimento para o completo deslinde da ação, de modo que é pertinente a produção de prova testemunhal.`,
  },
  create_appeal: {
    area: "civel",
    decision_type: DecisionType.TERMINATIVE_DECISIONS,
    client: "Turismo SA",
    case_breakdown:
      "A Turismo SA atua apenas como intermediária entre os compradores e as companhias aéreas, não sendo responsável pela operação dos voos.",
    theses: `A agência de turismo, Turismo SA, é ilegítima para atuar no polo passivo da demanda, uma vez que atua apenas como intermediadora na venda de passagens aéreas. Conforme jurisprudência.
Além disso, o cancelamento dos voos ocorreu devido à pandemia de COVID-19, o que caracteriza força maior e exclui sua responsabilidade, conforme a Lei 14.046/20 e o artigo 393 do Código Civil.
A responsabilidade pelo cancelamento e alteração de voos é exclusiva da companhia aérea, conforme a Resolução ANAC 400, artigo 20, e não há de ato ilícito e nexo causal para responsabilidade da agência Turismo SA, conforme o artigo 186 do Código Civil.`,
    requests: `Reforma da sentença para reconhecer a responsabilidade exclusiva da Companhia Aérea SA de reparar os danados experimentados pelas autoras, além de julgar improcedentes os pedidos em face da Turismo SA`,
  },
};

export const SkillStepsForm = ({ skill, skillForm, skillTitle, onSubmit, goBack }: SkillStepsFormProps) => {
  const { editor } = useEditor();
  const ref = useRef<HTMLDivElement>(null);

  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [finalFormData, setFinalFormData] = useState<FormData>({
    ...(skill.initialValue as FormData),
  });
  const [rawStepFormData, setRawStepFormData] = useState<FormData[]>([]);
  const [isSmallForm, setIsSmallForm] = useState<boolean>(() => !outsideOfficeClient());
  const { isOnboarding } = useDocumentOnboarding();

  const TEMPLATE_CONFIG = {
    create_contestation: { url: templateDocumentUrlForContestation, fieldName: "referencePiece" },
    create_appeal: { url: templateDocumentUrlForAppeal, fieldName: "reference_piece" },
  };

  const formRef = useRef(null);

  const onErrorHandler = (errors: RJSFValidationError[]) => {
    if (errors && errors.length > 0) {
      const firstError = errors[0];
      const errorProperty = firstError.property;

      const fieldId = errorProperty ? `root${errorProperty.replace(/\./g, "_")}` : "";

      setTimeout(() => {
        const errorElement = document.getElementById(fieldId);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          const input = errorElement.querySelector("input, select, textarea");
          if (input) {
            (input as HTMLElement).focus();
          }
        }
      }, 0);
    }
  };

  const handleFillTemplate = () => {
    if (!(skill.id in PREFILLED_DATA)) {
      throw new Error(`Invalid skill id on onboarding template. Skill: ${skill.id}`);
    }

    const preFilledData = PREFILLED_DATA[skill.id as keyof typeof PREFILLED_DATA];
    updateFormData(preFilledData);
  };

  const handleUploadTemplateDocument = async (): Promise<void> => {
    if (!(skill.id in TEMPLATE_CONFIG)) {
      throw new Error(`Invalid skill id on onboarding document upload. Skill: ${skill.id}`);
    }

    const { url, fieldName } = TEMPLATE_CONFIG[skill.id as keyof typeof TEMPLATE_CONFIG];

    try {
      const file = await fetchAndCreateFile(url);
      const base64data = await readFileAsBase64(file);
      updateFormData({ [fieldName]: base64data });
      if (skill.id === "create_appeal") {
        updateFormData({ decision_type: PREFILLED_DATA[skill.id].decision_type });
      }
    } catch (error) {
      logger.error("Error fetching template document:", error);
    }
  };

  const updateFormData = (newData: Partial<FormData>) => {
    setFinalFormData((prevData: FormData) => {
      const filteredNewData = Object.fromEntries(
        Object.entries(newData).filter(([, value]) => value !== undefined)
      ) as FormData;
      return { ...prevData, ...filteredNewData };
    });
    setRawStepFormData((prevRawStepFormData) => {
      const newRawStepFormData = [...prevRawStepFormData];
      const currentStepFields = Object.keys(currentStep?.schema?.properties || {});

      const newDataForCurrentStep = Object.entries(newData).reduce<FormData>((acc, [key, value]) => {
        if (currentStepFields.includes(key) && value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});

      newRawStepFormData[currentStepIndex] = {
        ...(newRawStepFormData[currentStepIndex] || {}),
        ...newDataForCurrentStep,
      };

      return newRawStepFormData;
    });
  };

  const fetchAndCreateFile = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "Peça de demonstração.pdf", { type: blob.type });
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result as string;
        const dataURLWithName = dataURL.replace(";base64", `;name=${encodeURIComponent(file.name)};base64`);
        resolve(dataURLWithName);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const { palette } = useTheme();
  const descriptionTextSx = { textWrap: "wrap", width: "100%", color: alpha(palette.text.secondary, 0.75) };

  useResizeObserver({
    ref,
    onResize: (size) => {
      setIsSmallForm(!outsideOfficeClient() || (size.width ?? 0) < 310);
    },
  });

  const steps = useMemo(() => {
    const stepsEvaluated = evaluateSteps({
      steps: skillForm?.steps,
      formData: getFormData({ rawStepFormData, initialValue: skill.initialValue as FormData }),
    });
    if (stepsEvaluated) {
      return stepsEvaluated;
    } else {
      return (
        (skill && [
          {
            schema: skill.inputSchema,
            uiSchema: undefined,
          } as SkillStep,
        ]) ??
        []
      );
    }
  }, [skillForm, currentStepIndex, rawStepFormData, skill]);

  const stepTitle = useMemo(() => {
    if (skillTitle && skillTitle !== "") return `Escrever ${skillTitle}`;
    return skillForm?.title ?? skill?.name;
  }, [skillForm, skill, skillTitle]);

  const stepSubtitle = useMemo(() => {
    return skillForm?.subtitle ?? "";
  }, [skillForm]);

  const currentStep = useMemo(() => {
    const step = steps?.[currentStepIndex];
    if (!step) return null;

    const formData = getFormData({
      initialValue: skill.initialValue as FormData,
      rawStepFormData,
    });

    const evaluatedStep = Array.isArray(step)
      ? evaluateStep({
          steps: step,
          formData,
        })
      : step;

    if (!evaluatedStep) return null;

    const description = evaluatedStep.schema?.description;
    const templateButton = evaluatedStep.schema?.onboarding?.templateButton;

    const evaluatedStepSchema = evaluatedStep;

    return {
      ...evaluatedStepSchema,
      description,
      templateButton,
    };
  }, [steps, currentStepIndex, rawStepFormData]);

  const formData = useMemo(() => {
    return getFormData({
      initialValue: {
        ...(currentStep ? buildStepDefaultData(currentStep) : {}),
        ...skill.initialValue,
      } as FormData,
      rawStepFormData,
    });
  }, [currentStep, currentStepIndex, rawStepFormData]);

  const onSubmitHandler = async (data: IChangeEvent) => {
    setRawStepFormData((rawStepFormData) => {
      rawStepFormData[currentStepIndex] = data.formData;
      return [...rawStepFormData];
    });
    const parsedFormData = await parseFormData({
      formData: data.formData,
      editor,
    });

    let formData = { ...parsedFormData };
    setFinalFormData((currentFormaData) => {
      formData = { ...currentFormaData, ...parsedFormData };

      return formData;
    });

    if (currentStepIndex < steps.length - 1) {
      onNextStep();
    } else {
      onSubmit({ ...finalFormData, ...parsedFormData });
    }
  };

  const onGoBackHandler = () => {
    if (currentStepIndex === 0) {
      return goBack();
    }
    setCurrentStepIndex((currentStepIndex) => currentStepIndex - 1);
  };

  const onNextStep = () => {
    setCurrentStepIndex((currentStepIndex) => currentStepIndex + 1);
  };

  const isDetailsStep = currentStep?.templateButton === "details";
  const isAttachmentStep = currentStep?.templateButton === "attachment";

  if (!currentStep) {
    logger.error(`Failed to load step: ${JSON.stringify({ skill, steps, currentStepIndex })}`);
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            pt: 6,
            width: "100%",
            boxSizing: "content-box",
          }}
        >
          <Box
            sx={{
              px: 6,
              maxWidth: SKILL_FORM_MAX_WIDTH,
              width: "100%",
              marginX: "auto",
              boxSizing: "border-box",
            }}
          >
            <Button startIcon={<ChevronLeftIcon />} onClick={onGoBackHandler} sx={{ ml: -1, b: 3, mb: 2, px: 1 }}>
              Voltar
            </Button>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography variant="h5">{stepTitle}</Typography>
              {stepSubtitle && (
                <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
                  {stepSubtitle}
                </Typography>
              )}
              {currentStep.description &&
                (currentStep.uiSchema?.["ui:enableMarkdownInDescription"] ? (
                  <Markdown
                    options={{
                      wrapper: (props) => <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }} {...props} />,
                      disableParsingRawHTML: true,
                      overrides: {
                        h1: (props) => <Typography variant="preTitle" sx={descriptionTextSx} {...props} />,
                        p: (props) => <Typography variant="body1" sx={descriptionTextSx} {...props} />,
                        span: (props) => <Typography variant="subtitle1" sx={descriptionTextSx} {...props} />,
                      },
                    }}
                  >
                    {currentStep.description ?? ""}
                  </Markdown>
                ) : (
                  <Typography variant="subtitle1" sx={{ textWrap: "wrap", width: "100%" }}>
                    {currentStep.description}
                  </Typography>
                ))}
            </Box>

            {steps.length > 1 && (
              <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", mt: 3, mb: 1 }}>
                <SkillStepper
                  steps={steps.filter((step): step is SkillStep => !Array.isArray(step))}
                  currentStepIndex={currentStepIndex}
                />
              </Box>
            )}

            {isOnboarding && isDetailsStep && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
                <Button type="button" variant="outlined" onClick={handleFillTemplate}>
                  Preencher com caso demonstração
                </Button>
              </Box>
            )}

            {isOnboarding && isAttachmentStep && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2, width: "100%" }}>
                <Button sx={{ width: "100%" }} type="button" variant="outlined" onClick={handleUploadTemplateDocument}>
                  Usar peça demonstração
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          component={Form}
          ref={formRef}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            justifyContent: "space-between",
            flexGrow: 1,
            marginTop: 3.5,
          }}
          schema={{
            title: stepTitle,
            subtitle: stepSubtitle,
            ...currentStep.schema,
            steps,
            currentStepIndex,
          }}
          uiSchema={currentStep.uiSchema}
          formData={formData}
          validator={validator}
          onSubmit={onSubmitHandler}
          onError={onErrorHandler}
          fields={{
            EditorContentField,
            SelectTextField,
          }}
          templates={{
            ObjectFieldTemplate,
            FieldTemplate,
            BaseInputTemplate,
          }}
          widgets={{
            SelectWidget,
            TextareaWidget,
            RadioWidget,
            FileWidget,
          }}
          formContext={{ isOnboarding }}
          showErrorList={false}
          transformErrors={transformErrors}
          noHtml5Validate
        >
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              borderTop: "1px solid",
              borderColor: "#0000001F",
              backgroundColor: "#FFFFFFCC",
            }}
          >
            <Box
              sx={{
                maxWidth: SKILL_FORM_MAX_WIDTH,
                width: "100%",
                margin: "auto",
                py: 3,
                px: 6,
                boxSizing: "border-box",
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              {!isSmallForm && (
                <Button variant="text" onClick={goBack}>
                  Cancelar
                </Button>
              )}
              <Button type="submit" variant="contained" fullWidth={isSmallForm}>
                {currentStep.submitButtonText ?? "Continuar"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const getFormData = ({ rawStepFormData, initialValue }: { initialValue?: FormData; rawStepFormData: FormData[] }) => {
  return {
    ...initialValue,
    ...rawStepFormData.reduce((acc, stepData) => ({ ...acc, ...stepData }), {}),
  };
};

const parseFormData = async ({ formData }: { formData: Record<string, string>; editor?: Editor }) => {
  const parsedFormData: Record<string, string> = {};
  for (const [key, value] of Object.entries(formData)) {
    if (key.includes("override:")) {
      const [, , keyWithoutFreeText] = key.split(":");
      parsedFormData[keyWithoutFreeText] = value;
      continue;
    }

    parsedFormData[key] = formData[key];
  }
  return parsedFormData;
};

const evaluateSteps = ({ steps, formData }: { steps?: (SkillStep | ConditionalSkillStep[])[]; formData: FormData }) => {
  if (!steps) return undefined;
  const result: (SkillStep | ConditionalSkillStep[])[] = [];

  for (const step of steps) {
    if (Array.isArray(step)) {
      const evaluatedStep = evaluateStep({ steps: step, formData });
      if (evaluatedStep) {
        result.push(evaluatedStep);
      }
    } else {
      result.push(step);
    }
  }
  return result;
};

const evaluateStep = ({ steps, formData }: { steps: ConditionalSkillStep[]; formData: FormData }) => {
  for (const step of steps) {
    const conditions = Array.isArray(step.condition) ? step.condition : [step.condition];

    const conditionApply = conditions.every(
      (condition) => condition.field in formData && formData[condition.field] === condition.value
    );
    if (conditionApply) {
      return step;
    }
  }
  return undefined;
};

const transformErrors = (errors: RJSFValidationError[]) => {
  return errors.map((error) => {
    return { ...error, message: error.message?.replace(/override:([\w:_]+)/g, "") };
  });
};

const buildStepDefaultData = (stepSchema: SkillStep): Record<string, unknown> => {
  return Object.entries(stepSchema.schema.properties ?? {}).reduce((obj, keyValue) => {
    const [key, value] = keyValue;
    if (typeof value !== "boolean") {
      return {
        ...obj,
        [key]: Array.isArray(value.type)
          ? value.type.includes("string")
            ? ""
            : undefined
          : value.type === "string"
            ? ""
            : undefined,
      };
    } else {
      return obj;
    }
  }, {});
};
