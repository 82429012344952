import React, { useState } from "react";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";
import { BaseField, fieldOption } from "../Field";
import { usePaymentContext } from "@/contexts/PaymentContext";

export const CardNumberField = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { onLoadingChange } = usePaymentContext();

  const handleCardNumberChange = (e: StripeCardNumberElementChangeEvent) => {
    setErrorMessage(null);
    if (e.error) {
      setErrorMessage(e.error.message);
    }
  };

  return (
    <>
      <BaseField errorMessage={errorMessage} label="Número do cartão">
        <CardNumberElement
          onChange={handleCardNumberChange}
          options={{
            placeholder: "Digite o número do cartão de crédito",
            ...fieldOption,
          }}
          className="credit-card-field-stripe"
          onReady={() => onLoadingChange(false)}
        />
      </BaseField>
    </>
  );
};
