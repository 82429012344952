import { buildSkillStep } from "@/core/skillForms/builder";

export const createReplySkillStep2 = buildSkillStep({
  title: "Anexo",
  description: "Anexe o arquivo abaixo para fornecer contexto à IA sobre o caso:",
  fields: [
    {
      name: "contestation_file",
      type: "file",
      ui: {
        label: "Anexe a contestação",
        description: "Para criar a réplica, é necessário anexar a contestação. Por favor, siga as instruções abaixo:",
      },
    },
  ],
});
