import { useAuthContext } from "@/contexts/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { userAcceptedTermsOfUse } from "@/utils/userAcceptedTermsOfUse";

export const useCheckTermsOfUse = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    if (!userAcceptedTermsOfUse(user)) {
      navigate(ROUTE_PATHS.TERMS_OF_USE, { replace: true });
    }
  }, []);
};
