import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import { PlanChip } from "@/components/PlanChip";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useCredits } from "@/hooks/credits/useCredits";
import { useOpenBilling } from "@/hooks/useOpenBilling";
import { MenuOption, useMenuOptions } from "./hooks/useMenuOptions";

export function ClientConfig() {
  const { menuOptions } = useMenuOptions();

  return (
    <PageLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "left",
          paddingBottom: "28px",
          alignSelf: "center",
        }}
      >
        <Typography variant="h3">Configurações</Typography>
      </Box>

      {menuOptions.map((menuOption, index) => {
        return <MenuOptions key={index} label={menuOption.label} options={menuOption.options} />;
      })}

      <Outlet />
    </PageLayout>
  );
}

function MenuOptions({ label, options }: MenuOption) {
  const navigate = useNavigate();
  const { data: credits } = useCredits();
  const openBilling = useOpenBilling();

  const planType = credits?.companyPlan?.plan;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "24px",
        alignSelf: "center",
      }}
    >
      <Paper
        sx={{
          p: "20px 20px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "info.main",
          color: "info.contrastText",
          borderRadius: "8px 8px 0 0",
          height: "52px",
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
      </Paper>
      {options.map((option, index) => {
        const isLastElement = index === options.length - 1;
        return (
          <Tooltip key={index} title={option.tooltip ? option.tooltip : ""} arrow placement="top">
            <Paper
              sx={{
                p: "20px 20px",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: "common.white",
                borderRadius: isLastElement ? "0 0 8px 8px" : "0",
                borderBottom: isLastElement ? "" : "1px solid #E5E5E5",
                height: "52px",
                ":hover": {
                  backgroundColor: "orange.light.5",
                },
              }}
              onClick={() => {
                if (option.openBilling) return openBilling();

                if (option.path) {
                  if (option.replaceRoute) {
                    return navigate(option.path, { replace: true });
                  }

                  return navigate(option.path);
                }
              }}
            >
              <Typography sx={{ color: "grey.900", display: "flex", gap: "8px", alignItems: "center" }} variant="body1">
                {option.label}
                {option.withPlanChip && !!planType && (
                  <PlanChip planType={planType} nextBillingDate={credits.companyPlan?.nextBillingDate} />
                )}
              </Typography>
              <KeyboardArrowRightIcon sx={{ color: "text.primary" }} />
            </Paper>
          </Tooltip>
        );
      })}
    </Box>
  );
}
