import { buildSkillStep } from "@/core/skillForms/builder";

export const legalAdviceSkillStep2 = buildSkillStep({
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Resumo dos fatos:",
        placeholder:
          "Exemplo: A Fazenda Santa Clara Ltda. precisa instituir uma servidão de passagem para acessar uma estrada pública. Solicita um parecer jurídico sobre a viabilidade, procedimentos, obstáculos e estratégias para obter a autorização necessária.",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Elaborar parecer jurídico",
});
