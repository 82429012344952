import { Box } from "@mui/material";
import React from "react";

import { MessageCardSkeleton } from "./components/MessageCard/skeleton";

export const MessageSkeleton = () => {
  const fromMe = Math.random() > 0.5;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: fromMe ? "flex-end" : "flex-start",
      }}
    >
      <MessageCardSkeleton fromMe={fromMe} />
    </Box>
  );
};
