import { Skills } from "@/core/skills";
import { useQuery } from "@tanstack/react-query";

export const useSkills = () => {
  return useQuery({
    queryKey: ["skills"],
    queryFn: async () => {
      return await Skills.getSkills();
    },
  });
};
