import { ChangeEvent, FocusEvent, useEffect } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  ariaDescribedByIds,
  BaseInputTemplateProps,
  examplesId,
  getInputProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
} from "@rjsf/utils";
import React from "react";
import { palette } from "@/theme/palette";
import { Box, Typography, FormLabel, Tooltip, IconButton, Button } from "@mui/material";
import { Add, DeleteOutline, InfoOutlined } from "@mui/icons-material";

const TYPES_THAT_SHRINK_LABEL = ["date", "datetime-local", "file", "time"];

/** The `BaseInputTemplate` is the template to use to render the basic `<input>` component for the `core` theme.
 * It is used as the template for rendering many of the <input> based widgets that differ by `type` and callbacks only.
 * It can be customized/overridden for other themes or individual implementations as needed.
 *
 * @param props - The `WidgetProps` for this template
 */
export function BaseInputTemplate<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(props: BaseInputTemplateProps<T, S, F>) {
  const {
    id,
    name, // remove this from textFieldProps
    placeholder,
    required,
    readonly,
    disabled,
    type,
    label,
    hideLabel,
    hideError,
    value,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    autofocus,
    hidden,
    options,
    schema,
    uiSchema,
    rawErrors = [],
    errorSchema,
    formContext,
    registry,
    InputLabelProps,
    accept,
    ...textFieldProps
  } = props;
  const inputProps = getInputProps<T, S, F>(schema, type, options);
  // Now we need to pull out the step, min, max into an inner `inputProps` for material-ui
  const { step, min, max, ...rest } = inputProps;
  const otherProps = {
    inputProps: {
      step,
      min,
      max,
      accept,
      ...(schema.examples ? { list: examplesId<T>(id) } : undefined),
    },
    ...rest,
  };
  const [showOptional, setShowOptional] = React.useState(required);
  const _onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target }: FocusEvent<HTMLInputElement>) => onBlur(id, target && target.value);
  const _onFocus = ({ target }: FocusEvent<HTMLInputElement>) => onFocus(id, target && target.value);
  const DisplayInputLabelProps = TYPES_THAT_SHRINK_LABEL.includes(type)
    ? {
        ...InputLabelProps,
        shrink: true,
      }
    : InputLabelProps;

  useEffect(() => {
    if (!showOptional) {
      onChange("");
    }
  }, [showOptional]);

  if (!showOptional) {
    return (
      <Button
        size="small"
        sx={{
          fontSize: "12px",
          maxHeight: "fit-content",
          minHeight: "fit-content",
          gap: 0.5,
          mb: 1,
        }}
        variant="outlined"
        onClick={() => setShowOptional(true)}
      >
        <Add
          fontSize="small"
          sx={{
            width: "16px",
          }}
        />
        {label}
      </Button>
    );
  }

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: hidden ? "none" : "flex",
        flexBasis: "100%",
      }}
    >
      {type !== "file" && (
        <Box
          sx={{
            display: "flex",
            mb: 1.5,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box flexGrow={1}>
            {label ? (
              <Typography
                variant="subtitle1"
                sx={{ whiteSpace: "normal", color: palette.text?.primary }}
                component={FormLabel}
              >
                {label}
              </Typography>
            ) : null}
            {uiSchema?.["ui:description"] ? (
              <Tooltip title={uiSchema?.["ui:description"]}>
                <IconButton>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
          {required && (
            <Typography
              sx={{
                bgcolor: "grey.100",
                borderRadius: "100px",
                px: 1,
                py: 0.5,
              }}
              variant="body2"
            >
              Preenchimento obrigatório
            </Typography>
          )}
          {!required && (
            <IconButton onClick={() => setShowOptional(false)}>
              <DeleteOutline fontSize="small" />
            </IconButton>
          )}
        </Box>
      )}

      <TextField
        id={id}
        name={id}
        placeholder={placeholder}
        multiline={inputProps.type === "text"}
        autoFocus={autofocus}
        required={required}
        disabled={disabled || readonly}
        {...otherProps}
        value={value || value === 0 ? value : ""}
        error={rawErrors.length > 0}
        onChange={onChangeOverride || _onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        InputLabelProps={DisplayInputLabelProps}
        {...(textFieldProps as TextFieldProps)}
        aria-describedby={ariaDescribedByIds<T>(id, !!schema.examples)}
        sx={hidden ? { display: "none" } : undefined}
      />
      {Array.isArray(schema.examples) && (
        <datalist id={examplesId<T>(id)}>
          {(schema.examples as string[])
            .concat(schema.default && !schema.examples.includes(schema.default) ? ([schema.default] as string[]) : [])
            .map((example: string) => {
              return <option key={example} value={example} />;
            })}
        </datalist>
      )}
    </Box>
  );
}
