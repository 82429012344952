import { escapeRegExp } from "@/utils/escapeRegExp";

export const findTextOccurrences = (text: string, search: string): number => {
  const searchValue = escapeRegExp(search.trim());

  if (!searchValue) {
    return 0;
  }

  return (text.match(new RegExp(searchValue, "gi")) || []).length;
};
