import * as skillGrups from "./implementations";
import { SkillGroup, SkillGroupId } from "./types";

const skillsMap = new Map<SkillGroupId, SkillGroup>(
  Object.values(skillGrups).map((skillGroup) => [skillGroup.id, skillGroup as unknown as SkillGroup])
);

export const SkillGroups = {
  async getSkillGroups() {
    return Array.from(skillsMap.values());
  },

  async getSkillGroup(id: SkillGroupId) {
    const skill = skillsMap.get(id);
    if (!skill) {
      return null;
    }
    return skill;
  },
};
