import { UploadingFile } from "@/contexts/MessagesContext";
import { v4 as uuidV4 } from "uuid";

export const fileToUploadingFile = (file: File): UploadingFile => {
  return {
    id: uuidV4(),
    type: "UPLOADING",
    file: file,
    name: file.name,
  };
};
