import { PaletteOptions } from "@mui/material";

const black = "#1E1818";
const white = "#FFFFFF";

export const palette: PaletteOptions = {
  mode: "light",
  common: {
    black,
    white,
    shade: "#4B4646",
    lightShade: "#DDDCDC",
    mediumShade: "#A5A3A3",
    dorian: "#F4F3F3",
    softBlue: "#FFE8E1",
    lightWarm: "#EEE5E3",
    dorianHover: "#d4d8df",
    backdrop: "rgba(30, 24, 24, 0.5)",
    yellow: "#FFC700",
    coral: "#FF7548",
    coralDark: "#D2623E",
  },
  primary: {
    main: "#FF7548",
    light: "#FFBAA3",
    dark: "#D2623E",
    contrastText: black,
  },
  secondary: {
    main: "#FFE3DA",
    light: "#FFE8E1",
    dark: "#B29E98",
    contrastText: black,
  },
  error: {
    main: "#D32F2F",
    light: "#EF5350",
    dark: "#C62828",
    contrastText: white,
  },
  warning: {
    main: "#F9A825",
    light: "#FFEB3B",
    dark: "#F57F17",
    contrastText: white,
  },
  info: {
    main: "#FFAC91",
    light: "#FFBCA7",
    dark: "#B27865",
    contrastText: black,
  },
  success: {
    main: "#2e7d32",
    light: "#4CAF50",
    dark: "#1B5E20",
    contrastText: white,
  },
  grey: {
    "50": "#F4F3F3",
    "100": "#E9E8E8",
    "200": "#D2D1D1",
    "300": "#BCBABA",
    "400": "#A5A3A3",
    "500": "#8E8B8B",
    "600": "#787474",
    "700": "#625D5D",
    "800": "#4B4646",
    "900": "#1E1818",
    A100: "#E9E8E8",
    A200: "#D2D1D1",
    A400: "#A5A3A3",
    A700: "#625D5D",
  },
  orange: {
    main: "#FF7548",
    light: {
      90: "#FF835A",
      85: "#FF8D68",
      80: "#FF916D",
      70: "#FF9E7F",
      60: "#FFAC91",
      50: "#FFBAA3",
      40: "#FFC8B6",
      30: "#FFD6C8",
      20: "#FFE3DA",
      15: "#FFEAE4",
      10: "#FFF1ED",
      5: "#FFF8F6",
    },
    dark: {
      90: "#E86C43",
      85: "#E56941",
      80: "#D2623E",
      70: "#BB593A",
      60: "#A55035",
      50: "#8E4630",
      40: "#783D2B",
      30: "#613426",
      20: "#4B2B22",
      15: "#40251D",
      10: "#34211D",
      5: "#1A100E",
    },
  },
  text: {
    primary: black,
    secondary: "#1E1818CC",
    disabled: "#1E181861",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: white,
    default: white,
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.04,
    selected: "rgba(0, 0, 0, 0.08)",
    selectedOpacity: 0.08,
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(0, 0, 0, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  plan: {
    trial: {
      background: "#D2623E",
      text: white,
    },
    free: {
      background: "#D2623E",
      text: white,
    },
    initial: {
      background: "#2E7D32",
      text: white,
    },
    basic: {
      background: "#FF7548",
      text: white,
    },
    intermediary: {
      background: "#FFE3DA",
      text: black,
    },
    advanced: {
      background: "#F9A825",
      text: white,
    },
    professional: {
      background: "#D2D1D1",
      text: black,
    },
    custom: {
      background: "#FFAC91",
      text: black,
    },
    unlimited: {
      background: "#F57F17",
      text: white,
    },
  },
  score: {
    high: {
      background: "#31D0AA",
      text: "#4B4646",
    },
    medium: {
      background: "#FFC700",
      text: "#4B4646",
    },
    low: {
      background: "#FDDBC7",
      text: "#4B4646",
    },
  },
  slider: {
    rail: "#DDE7EE",
  },
};
