import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";

export enum LoginType {
  FIREBASE = "firebase",
}

interface AuthState {
  token?: string;
  isLoggedIn: boolean;
  loginType?: LoginType;
  clearToken: () => void;
  setToken: ({ token, loginType }: { token: string; loginType: LoginType }) => void;
}

export const useAuthStore = create<AuthState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        token: undefined,
        isLoggedIn: false,
        loginType: undefined,
        clearToken: () => {
          set({ token: undefined, isLoggedIn: false, loginType: undefined });
        },
        setToken: async ({ token, loginType }) => {
          set({ isLoggedIn: true, token, loginType });
        },
      }),
      {
        name: "auth-store",
      }
    )
  )
);
