import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";

interface TimeSinceProps {
  timestamp: number;
}
export const TimeSince = ({ timestamp }: TimeSinceProps) => {
  const pluralize = (value: number, singular: string, plural: string) => {
    return value === 1 ? singular : plural;
  };

  const getTimeSince = (timestamp: number) => {
    const now = DateTime.now();
    const past = DateTime.fromMillis(timestamp);

    const diff = now.diff(past, ["years", "months", "days", "hours", "minutes", "seconds"]).toObject();

    if (diff) {
      if (diff.years && diff.years >= 1) {
        return `${Math.floor(diff.years)} ${pluralize(Math.floor(diff.years), "ano", "anos")}`;
      } else if (diff.months && diff.months >= 1) {
        return `${Math.floor(diff.months)} ${pluralize(Math.floor(diff.months), "mês", "meses")}`;
      } else if (diff.days && diff.days >= 1) {
        return `${Math.floor(diff.days)} ${pluralize(Math.floor(diff.days), "dia", "dias")}`;
      } else if (diff.hours && diff.hours >= 1) {
        return `${Math.floor(diff.hours)} ${pluralize(Math.floor(diff.hours), "hora", "horas")}`;
      } else if (diff.minutes && diff.minutes >= 1) {
        return `${Math.floor(diff.minutes)} ${pluralize(Math.floor(diff.minutes), "minuto", "minutos")}`;
      } else {
        return `alguns segundos`;
      }
    } else {
      return "";
    }
  };

  const [timeSince, setTimeSince] = useState(getTimeSince(timestamp));

  useEffect(() => {
    setTimeSince(getTimeSince(timestamp));

    const intervalId = setInterval(() => {
      setTimeSince(getTimeSince(timestamp));
    }, 60000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  return <>{timeSince}</>;
};
