import { env } from "@/constants/environment";

export const ASSISTANT_URL = env.IS_PRODUCTION
  ? "https://assistente.lexter.ai"
  : "https://assistente-staging.lexter.ai";

export const AUTHENTICATED_ROUTES_PREFIX = "/app";

export const EDITOR_ROUTES_PREFIX = `${AUTHENTICATED_ROUTES_PREFIX}/editor`;

export const ROUTE_PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_FAILURE: "/signup/failure",
  ACTIVATE_ACCOUNT: "/signup/activate",
  ADD_PHONE: "/app/add-phone",
  USE_WORD: `${AUTHENTICATED_ROUTES_PREFIX}/use-word`,

  CLIENT_CONFIG: `${AUTHENTICATED_ROUTES_PREFIX}/client-config`,
  CLIENT_CONFIG_MY_DATA: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/mydata`,
  CLIENT_CONFIG_CHANGE_PASSWORD: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/changepassword`,

  THREAD_HISTORY: `${EDITOR_ROUTES_PREFIX}/history`,
  CHAT: `${EDITOR_ROUTES_PREFIX}/chat`,
  THREAD: `${EDITOR_ROUTES_PREFIX}/chat/:threadId`,
  FEEDBACK: `${EDITOR_ROUTES_PREFIX}/feedback`,
  WHATSAPP_ASSISTANT: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant`,
  CASE_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/cases/:caseId`,
  CASE_DOCUMENT_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/cases/:caseId/documents/:documentId`,

  SESSION_SETTINGS: `${AUTHENTICATED_ROUTES_PREFIX}/session-settings`,
  CREDITS_HISTORY: `${AUTHENTICATED_ROUTES_PREFIX}/credits-history`,
  TERMS_OF_USE: `${AUTHENTICATED_ROUTES_PREFIX}/terms-of-use`,

  PLANS: `${AUTHENTICATED_ROUTES_PREFIX}/plans`,
  INTERNAL_PAYMENT: `${AUTHENTICATED_ROUTES_PREFIX}/payment`,
  HOME: `${AUTHENTICATED_ROUTES_PREFIX}/home`,
  REFERRAL: `${AUTHENTICATED_ROUTES_PREFIX}/referral`,
  WELCOME: `${AUTHENTICATED_ROUTES_PREFIX}/welcome`,
  ONBOARDING_QUESTIONS: `${AUTHENTICATED_ROUTES_PREFIX}/onboarding-questions`,
  CALL_WITH_SPECIALIST: `${AUTHENTICATED_ROUTES_PREFIX}/call-with-specialist`,

  WHATSAPP_USERS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/whatsapp-users`,
} as const;

export const EXTERNAL_URLS = {
  PRIVACY_POLICY: "https://lexter.ai/politicadeprivacidade",
  TERMS_OF_USE: "https://lexter.ai/termosdeuso",
  HELP_CENTER_URL: "https://ajuda.lexter.ai/",
  CONTACT: "https://lexter.ai/contato",
  CREDITS_INFO: "https://ajuda.lexter.ai/o-que-sao-e-como-funcionam-os-creditos",
  WHATSAPP_URL: "https://wa.me/5511914150170",
} as const;
