import { buildSkillStep } from "@/core/skillForms/builder";

export const createContractSkillStep2 = buildSkillStep({
  fields: [
    {
      name: "party_1",
      type: "string",
      ui: {
        label: "Qual é a parte contratante?",
        placeholder: "Exemplo: Maria Souza",
      },
    },
    {
      name: "party_2",
      type: "string",
      ui: {
        label: "Qual é a parte contratada?",
        placeholder: "Exemplo: João da Silva",
      },
    },
  ],
});
