import { Skill } from "@/core/skills/types";
import { createInitialPetitionSkill } from "@/core/skills/implementations";
import { Area } from "@/contexts/MessagesContext";

export const createPensionInitialPetitionSkill: Skill<typeof createInitialPetitionSkill.inputSchema> = {
  ...createInitialPetitionSkill,
  id: "create_pension_initial_petition",
  name: "Escrever petição inicial previdenciária",
  tags: [...createInitialPetitionSkill.tags, "mostUsed"],
  initialValue: {
    area: Area.SOCIAL_SECURITY,
  },
};
