import {
  BookmarkBorder as BookmarkBorderIcon,
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";
import { Collapse, IconButton, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { Button } from "@/components/Button";
import { DocumentSearchModal } from "@/components/DocumentSearchModal";
import { Precedent, PrecedentScore } from "@/contexts/WebSocketContext";
import { useApi } from "@/hooks/useApi";
import { useEditorUI } from "@/hooks/useEditorUI";

import { Chip } from "@/components/Chip";
import * as logger from "@/core/logger";
import { useEditor } from "@/hooks/useEditor";
import { ParagraphStyle } from "@/hooks/useEditor/types";
import { WebToast } from "@/taskpane/components/core/Toast";
import { formattedDateFromISO } from "@/utils/dates/formattedDateFromISO";
import { useQuery } from "@tanstack/react-query";
import { useThreadContext } from "@/contexts/ThreadContext";
import { HeapService } from "@/services/heap";

export const PrecedentCard = ({
  precedent: fullPrecedent,
  metadata,
}: {
  precedent: Precedent;
  metadata: { id: string; position: number; requestId: string };
}) => {
  const fullTextTemporarilyDisabled = true;

  const { headnote: headnoteUrl, ...precedent } = fullPrecedent;

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;
  const [loadingSave, setLoadingSave] = useState(false);
  const [saved, setSaved] = useState(false);
  const { openModal, closeModal } = useEditorUI();
  const { savePrecedent } = useApi();
  const { currentThread } = useThreadContext();
  const { editor } = useEditor();

  const { data: headnote, isLoading: isLoadingHeadnote } = useQuery({
    queryKey: ["precedent", precedent.cnj_unique_number, "headnote"],
    queryFn: async () => {
      const response = await axios.get<string>(headnoteUrl);
      return response.data;
    },
    enabled: isExpanded,
  });

  const {
    error: fullTextError,
    isLoading: isLoadingFullText,
    refetch: refetchFullText,
  } = useQuery({
    queryKey: ["precedent", precedent.cnj_unique_number, "fullText"],
    queryFn: async () => {
      const response = await axios.get<string>(precedent.full_text?.presigned_url_location || "");
      return response.data;
    },
    enabled: false,
  });

  const isLoading = isLoadingHeadnote || isLoadingFullText;

  const handleOpen = async () => {
    HeapService.track("Abrir acórdão", metadata);
    if (!precedent.full_text?.presigned_url_location) return;

    const { data: fullText } = await refetchFullText();
    openModal(<DocumentSearchModal title={precedent.title} documentText={fullText} onClose={closeModal} />);
  };

  const handleSave = async () => {
    HeapService.track("Salvar acórdão", metadata);
    if (saved || loadingSave) return;

    try {
      if (!currentThread) {
        throw new Error("Not connected to a thread");
      }

      if (!headnote) {
        throw new Error("Precedent headnote not loaded");
      }

      setLoadingSave(true);
      await savePrecedent({
        threadId: currentThread.id,
        title: precedent.title,
        courtCode: precedent.court_code,
        cnjUniqueNumber: precedent.cnj_unique_number,
        headnote: headnote,
        headnoteGen: undefined,
        fullTextLocation: precedent.full_text?.location,
        judgmentDate: precedent.judgment_date || undefined,
        publicationDate: precedent.publication_date || undefined,
        releaseDate: precedent.release_date || undefined,
        signatureDate: precedent.signature_date || undefined,
        score: precedent.score,
        scoreReason: precedent.score_reason,
        url: precedent.url,
      });
      setSaved(true);
    } catch (error) {
      logger.error("Error saving precedent", error);
      WebToast.error("Erro ao salvar acórdão");
    } finally {
      setLoadingSave(false);
    }
  };

  const handleWriteParagraph = async () => {
    HeapService.track("Redigir parágrafo", metadata);
    if (!editor || !headnote) return;

    const judgmentDate = precedent.judgment_date ? formattedDateFromISO(precedent.judgment_date) : "";
    const publicationDate = precedent.publication_date ? formattedDateFromISO(precedent.publication_date) : "";

    const judgmentInfo = judgmentDate ? `, j. ${judgmentDate}` : "";
    const publicationInfo = publicationDate ? `, DJE ${publicationDate}` : "";

    const precedentInfo = `${precedent.court_code}, Apelação n° ${precedent.cnj_unique_number}${judgmentInfo}${publicationInfo}`;

    const precedentStyle: ParagraphStyle = {
      alignment: "justify",
      italic: true,
      leftIndent: 90,
    };

    await editor.insertParagraphs([
      {
        text: "Nesse sentido, essa é a jurisprudência observada nos tribunais:",
      },
      {
        text: headnote,
        style: precedentStyle,
      },
      {
        text: `(${precedentInfo})`,
        style: precedentStyle,
      },
    ]);
  };

  useEffect(() => {
    if (fullTextError) {
      logger.error("Error fetching full_text precedent", fullTextError);
      WebToast.error("Erro ao buscar acórdão");
    }
  }, [fullTextError]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "minmax(0, 1fr) min-content",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Título:</strong>
            {" " + precedent.title}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Tribunal:</strong>
            {" " + precedent.court_code}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>ID:</strong>
            {" " + precedent.cnj_unique_number}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Data de Publicação:</strong>{" "}
            {`${precedent.publication_date ? formattedDateFromISO(precedent.publication_date) : "Não consta"}`}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Data de Julgamento:</strong>{" "}
            {`${precedent.judgment_date ? formattedDateFromISO(precedent.judgment_date) : "Não consta"}`}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "flex" }}>
            <Box sx={{ mr: 0.5 }}>
              <strong>Compatibilidade:</strong>
            </Box>
            <ScoreChip score={precedent.score} />
          </Typography>
        </Box>

        <IconButton onClick={() => setIsExpanded((prev) => !prev)} sx={{ m: -1 }}>
          <ExpandIcon fontSize="medium" />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
          <strong>Racional de Compatibilidade:</strong>
          <br />
          {precedent.score_reason}
        </Typography>

        <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
          <strong>Ementa:</strong>
          <br />
          {isLoadingHeadnote ? "Carregando..." : headnote}
        </Typography>

        {!fullTextTemporarilyDisabled && (
          <>
            {precedent.full_text?.presigned_url_location ? (
              <Button
                id={`button_open_precedent_${metadata.position}`}
                disabled={isLoading}
                onClick={handleOpen}
                variant="text"
                sx={{
                  textTransform: "none",
                  textDecoration: "underline",
                }}
              >
                {isLoading ? "Carregando acórdão..." : "Abrir acórdão na íntegra"}
              </Button>
            ) : (
              !!precedent.url_court && (
                <Link
                  id={`link_open_precedent_${metadata.position}`}
                  target="_blank"
                  href={precedent.url_court}
                  sx={{
                    fontWeight: 700,
                    color: "primary.main",
                  }}
                >
                  Abrir acórdão na íntegra
                </Link>
              )
            )}
          </>
        )}
      </Collapse>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          pt: 3,
        }}
      >
        <Button
          id={`button_write_paragraph_${metadata.position}`}
          onClick={handleWriteParagraph}
          borderVariant="rounded"
          variant="outlined"
          disabled={isLoadingHeadnote}
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          Redigir parágrafo complementar
        </Button>
        {!fullTextTemporarilyDisabled && (
          <Button
            id={`button_save_precedent_${metadata.position}`}
            disabled={loadingSave || !precedent.full_text?.location}
            onClick={handleSave}
            bgcolor={saved ? "common.shade" : undefined}
            startIcon={BookmarkBorderIcon}
            variant={saved ? "contained" : "outlined"}
            borderVariant="rounded"
            sx={{
              color: saved ? "common.white" : undefined,
              whiteSpace: "nowrap",
            }}
          >
            {loadingSave ? "Salvando..." : saved ? "Acórdão salvo" : "Salvar acórdão"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const ScoreChip = ({ score }: { score: PrecedentScore }) => {
  const { text, color, backgroundColor } = React.useMemo(() => {
    switch (score) {
      case PrecedentScore.ALTA: {
        return {
          backgroundColor: "score.high.background",
          color: "score.high.text",
          text: PrecedentScore.ALTA,
        };
      }
      case PrecedentScore.MEDIA: {
        return {
          backgroundColor: "score.medium.background",
          color: "score.medium.text",
          text: PrecedentScore.MEDIA,
        };
      }
      case PrecedentScore.BAIXA: {
        return {
          backgroundColor: "score.low.background",
          color: "score.low.text",
          text: PrecedentScore.BAIXA,
        };
      }
    }
  }, [score]);

  return (
    <Chip backgroundColor={backgroundColor} color={color}>
      {text}
    </Chip>
  );
};
