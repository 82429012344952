import { buildSkillStep } from "@/core/skillForms/builder";

export const hearingsScriptSkillStep4 = buildSkillStep({
  fields: [
    {
      name: "witness",
      type: "string",
      ui: {
        label: "Insira o nome das testemunhas e/ou partes a serem ouvidas:",
        placeholder: "Exemplo: João da Silva, Maria Souza, Daniel Santos",
        widget: "textarea",
      },
    },
  ],
});
