import { SkillForm } from "@/core/skillForms/types";
import { createReplySkillStep1 } from "./step1";
import { createReplySkillStep2 } from "./step2";
import { createReplySkillStep3 } from "./step3";

export const createReplySkillForm: SkillForm = {
  skillId: "create_reply",
  title: "Escrever Réplica",
  steps: [createReplySkillStep1, createReplySkillStep2, createReplySkillStep3],
};
