import { Case, CaseMessage, CaseMessageType, CaseStep } from "../types";

export const casesMock: (Omit<Case, "lastMessage"> & {
  messages: CaseMessage[];
})[] = [
  {
    id: "1",
    createdAt: "2024-10-22T19:49:06.19877",
    summary:
      "A cliente trabalhou como cozinheira no Restaurante Sabor e Amor Ltda., no Rio de Janeiro, de janeiro de 2022 até agosto de 2024. Durante esse período, cumpriu uma jornada de trabalho superior às oito horas diárias acordadas em contrato, frequentemente trabalhando até 12 horas por dia, sem receber pagamento de horas extras e sem concessão adequada de intervalos para descanso e alimentação. Em julho de 2024, questionou seu empregador sobre a regularização da jornada e o pagamento das horas extras. Após várias tentativas de diálogo, foi demitida sem justa causa no mês seguinte, sem que houvesse o pagamento de qualquer compensação adicional pelas horas extras acumuladas. O restaurante alega que o contrato de trabalho foi rescindido de forma regular e que a cliente sempre trabalhou dentro das condições acordadas. A cliente possui registros de folhas de ponto e trocas de e-mails com o gerente mencionando o excesso de horas, além de testemunhas que podem confirmar a sua jornada de trabalho e a falta de intervalos adequados. Recebeu aviso prévio e uma parte das verbas rescisórias, mas não houve pagamento referente às horas extras. O restaurante não forneceu justificativa clara para a demissão. A cliente ainda não procurou o sindicato da categoria. Recebeu corretamente os valores de férias e 13º salário, mas houve inconsistências com o FGTS. Após questionar sobre as horas extras, notou um tratamento diferenciado e exclusão de tarefas e eventos. Prefere tentar uma solução amigável, mas está disposta a seguir com uma ação judicial se necessário",
    step: CaseStep.SUMMARY_GENERATED,
    status: "ACTIVE",
    userId: "",
    companyId: "",
    chatId: "4",
    threadId: "",
    name: "Caso Maria de Souza X Restaurante Sabor e Amor Ltda.",
    applicantId: "987456",
    messages: [
      {
        id: "1",
        chatId: "1",
        from: "Maria de Souza",
        lawyerId: "",
        fromLawyer: false,
        to: "",
        body: "oi",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729519200000,
      },
      {
        id: "2",
        chatId: "1",
        from: "",
        lawyerId: "",
        fromLawyer: true,
        to: "Maria de Souza",
        body: "*Assistente*\n\nOlá! Sou o Assistente Digital do escritório de advocacia. Como posso te ajudar hoje? Para começar, poderia me informar seu nome e de qual cidade você é?",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729519320000,
      },
      {
        id: "3",
        chatId: "1",
        from: "Maria de Souza",
        lawyerId: "",
        fromLawyer: false,
        to: "",
        body: "Maria de Souza, do Rio de Janeiro. Trabalhei como cozinheira no Restaurante Sabor e Amor Ltda., no Rio de Janeiro, de janeiro de 2022 até agosto de 2024. Durante esse período, cumpri uma jornada de trabalho superior às oito horas diárias acordadas em contrato, frequentemente trabalhando até 12 horas por dia, sem receber pagamento de horas extras. Além disso, não tinha concessão adequada de intervalos para descanso e alimentação. Em julho de 2024 questionei meu empregador sobre a regularização de minha jornada e o pagamento das horas extras. Após várias tentativas de diálogo, fui demitida sem justa causa no mês seguinte, sem que houvesse o pagamento de qualquer compensação adicional pelas horas extras acumuladas. O restaurante alega que o contrato de trabalho foi rescindido de forma regular e que eu sempre trabalhei dentro das condições acordadas.",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729519500000,
      },
      {
        id: "4",
        chatId: "1",
        from: "",
        lawyerId: "",
        fromLawyer: true,
        to: "Maria de Souza",
        body: "*Assistente*\n\nPara entender melhor o seu caso, preciso fazer algumas perguntas. Vamos começar:\n1. Você possui algum registro, como folhas de ponto ou e-mails, que comprovem as horas extras trabalhadas?\n2. Houve testemunhas que possam confirmar a sua jornada de trabalho e a falta de intervalos adequados?\n3. Você recebeu algum tipo de aviso prévio ou indenização quando foi demitida?\n4. O restaurante forneceu algum documento ou justificativa por escrito sobre a sua demissão?\n5. Você já procurou o sindicato da categoria ou algum órgão de defesa do trabalhador para relatar essa situação?\n6. Você tem cópia do seu contrato de trabalho e dos contracheques recebidos durante o período de emprego?\nPor favor, responda essas perguntas para que eu possa entender melhor a situação e te orientar da melhor forma.",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729519800000,
      },
      {
        id: "5",
        chatId: "1",
        from: "Maria de Souza",
        lawyerId: "",
        fromLawyer: false,
        to: "",
        body: "Sim, tenho alguns registros de folhas de ponto e trocas de e-mails com o gerente onde menciono o excesso de horas.\nSim\nRecebi o aviso prévio e uma parte das verbas rescisórias, mas não houve pagamento referente às horas extras que trabalhei durante o período de contrato.\nO restaurante não forneceu nenhum documento com uma justificativa clara para a minha demissão, apenas recebi os documentos padrão de rescisão, sem qualquer explicação adicional sobre as minhas reivindicações por horas extras.\nAinda não procurei o sindicato da categoria\nSim",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729520520000,
      },
      {
        id: "6",
        chatId: "1",
        from: "",
        lawyerId: "",
        fromLawyer: true,
        to: "Maria de Souza",
        body: "*Assistente*\n\nObrigado pelas respostas. Preciso fazer mais algumas perguntas para entender melhor o caso:\n7. Você tem conhecimento de outros funcionários que passaram pela mesma situação de horas extras não pagas?\n8. Durante o período em que trabalhou no restaurante, houve algum tipo de fiscalização trabalhista ou auditoria?\n9. Você possui algum registro ou documento que comprove a comunicação das suas reivindicações ao empregador, como e-mails ou mensagens?\n10. Você recebeu corretamente os valores de férias, 13º salário e FGTS durante o período de trabalho?\n11. Houve algum tipo de retaliação ou tratamento diferenciado após você ter questionado sobre as horas extras?\n12. Você tem interesse em buscar uma solução amigável com o restaurante ou prefere seguir diretamente para uma ação judicial?\nPor favor, responda essas perguntas para que eu possa te orientar da melhor forma.",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729520820000,
      },
      {
        id: "7",
        chatId: "1",
        from: "Maria de Souza",
        lawyerId: "",
        fromLawyer: false,
        to: "",
        body: "Sim\nNão que eu saiba\nTenho, sim.\nRecebi os valores de férias e 13º salário, mas houve inconsistências com o FGTS. Nem todos os depósitos de FGTS foram feitos regularmente\nSim, depois que comecei a questionar sobre as horas extras, notei um tratamento diferente. Passei a ser excluída de algumas tarefas e eventos, e minha escala foi alterada sem aviso.\nMinha preferência é por tentar uma solução amigável primeiro, mas, se não houver acordo, estou disposta a seguir com uma ação judicial para garantir meus direitos e o pagamento das horas extras devidas.",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729521300000,
      },
      {
        id: "8",
        chatId: "1",
        from: "",
        lawyerId: "",
        fromLawyer: true,
        to: "Maria de Souza",
        body: "*Assistente*\n\nResumo do problema jurídico:\nVocê trabalhou como cozinheira no Restaurante Sabor e Amor Ltda., no Rio de Janeiro, de janeiro de 2022 até agosto de 2024. Durante esse período, cumpriu uma jornada de trabalho superior às oito horas diárias acordadas em contrato, frequentemente trabalhando até 12 horas por dia, sem receber pagamento de horas extras e sem concessão adequada de intervalos para descanso e alimentação. Em julho de 2024, questionou seu empregador sobre a regularização da jornada e o pagamento das horas extras. Após várias tentativas de diálogo, foi demitida sem justa causa no mês seguinte, sem que houvesse o pagamento de qualquer compensação adicional pelas horas extras acumuladas. O restaurante alega que o contrato de trabalho foi rescindido de forma regular e que você sempre trabalhou dentro das condições acordadas. Você possui registros de folhas de ponto e trocas de e-mails com o gerente mencionando o excesso de horas, além de testemunhas que podem confirmar a sua jornada de trabalho e a falta de intervalos adequados. Recebeu aviso prévio e uma parte das verbas rescisórias, mas não houve pagamento referente às horas extras. O restaurante não forneceu justificativa clara para a demissão. Você ainda não procurou o sindicato da categoria. Recebeu corretamente os valores de férias e 13º salário, mas houve inconsistências com o FGTS. Após questionar sobre as horas extras, notou um tratamento diferenciado e exclusão de tarefas e eventos. Prefere tentar uma solução amigável, mas está disposta a seguir com uma ação judicial se necessário.\nPor favor, confirme se o resumo está correto.",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729521600000,
      },
      {
        id: "9",
        chatId: "1",
        from: "Maria de Souza",
        lawyerId: "",
        fromLawyer: false,
        to: "",
        body: "sim",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729522020000,
      },
      {
        id: "10",
        chatId: "1",
        from: "",
        lawyerId: "",
        fromLawyer: true,
        to: "Maria de Souza",
        body: "*Assistente*\n\nSeu caso foi encaminhado para o advogado. Ele entrará em contato em breve.",
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: 1729522320000,
      },
    ],
    applicantName: "Maria de Souza (demo)",
    timeline: [
      {
        id: 1,
        createdAt: "2024-10-22T19:49:06.19877",
        status: "CASE_STARTED",
      },
      {
        id: 1,
        createdAt: "2024-10-22T19:49:16.19877",
        status: "AWAITING_APPLICANT_APPROVAL",
      },
      {
        id: 1,
        createdAt: "2024-10-22T19:49:36.19877",
        status: "SUMMARY_GENERATED",
      },
    ],
    description: "",
    facts:
      "A cliente trabalhou como cozinheira no Restaurante Sabor e Amor Ltda., no Rio de Janeiro, de janeiro de 2022 até agosto de 2024. Durante esse período, cumpriu uma jornada de trabalho superior às oito horas diárias acordadas em contrato, frequentemente trabalhando até 12 horas por dia, sem receber pagamento de horas extras e sem concessão adequada de intervalos para descanso e alimentação. Em julho de 2024, questionou seu empregador sobre a regularização da jornada e o pagamento das horas extras. Após várias tentativas de diálogo, foi demitida sem justa causa no mês seguinte, sem que houvesse o pagamento de qualquer compensação adicional pelas horas extras acumuladas. O restaurante alega que o contrato de trabalho foi rescindido de forma regular e que a cliente sempre trabalhou dentro das condições acordadas. A cliente possui registros de folhas de ponto e trocas de e-mails com o gerente mencionando o excesso de horas, além de testemunhas que podem confirmar a sua jornada de trabalho e a falta de intervalos adequados. Recebeu aviso prévio e uma parte das verbas rescisórias, mas não houve pagamento referente às horas extras. O restaurante não forneceu justificativa clara para a demissão. A cliente ainda não procurou o sindicato da categoria. Recebeu corretamente os valores de férias e 13º salário, mas houve inconsistências com o FGTS. Após questionar sobre as horas extras, notou um tratamento diferenciado e exclusão de tarefas e eventos. Prefere tentar uma solução amigável, mas está disposta a seguir com uma ação judicial se necessário",
    documentationAndEvidence:
      "- Registros de folhas de ponto\n\n- Trocas de e-mails com o gerente mencionando o excesso de horas\n\n- Testemunhas que podem confirmar a jornada de trabalho e a falta de intervalos adequados\n\n- Aviso prévio e documentos padrão de rescisão\n\n- Cópia do contrato de trabalho e dos contracheques\n\n- Registros de comunicação das reivindicações ao empregador\n\n- Documentação que comprova inconsistências nos depósitos de FGTS",
    legalQuestions:
      "- Não pagamento de horas extras trabalhadas\n\n- Falta de concessão adequada de intervalos para descanso e alimentação\n\n- Demissão sem justa causa sem pagamento de compensação adicional pelas horas extras acumuladas\n\n- Inconsistências nos depósitos de FGTS\n\n- Retaliação e tratamento diferenciado após questionamento sobre horas extras",
    recommendations:
      "- Reunir e organizar toda a documentação e provas mencionadas\n\n- Procurar o sindicato da categoria para relatar a situação e buscar apoio\n\n- Tentar uma solução amigável com o restaurante, possivelmente através de mediação\n\n- Caso não haja acordo, ingressar com uma ação judicial trabalhista para reivindicar o pagamento das horas extras, regularização dos depósitos de FGTS e compensação por danos morais devido à retaliação\n\n- Considerar a inclusão de testemunhas no processo judicial para fortalecer as alegações",
    conclusion:
      "O caso apresenta desafios significativos, especialmente no que diz respeito à comprovação das horas extras trabalhadas e à retaliação sofrida após o questionamento sobre os direitos trabalhistas. No entanto, a cliente possui uma quantidade substancial de documentação e provas, incluindo registros de folhas de ponto, e-mails e testemunhas, que podem fortalecer sua posição. A tentativa de uma solução amigável é recomendada inicialmente, mas a preparação para uma ação judicial deve ser feita de forma cuidadosa e detalhada, garantindo que todas as provas e testemunhas estejam prontas para serem apresentadas. A inconsistência nos depósitos de FGTS e a falta de justificativa clara para a demissão também são pontos que podem ser explorados juridicamente para reforçar a reivindicação da cliente.",
    nextSteps: [
      {
        description: "Gerar procuração",
        completed: false,
      },
      {
        description: "Gerar reclamação trabalhista",
        completed: false,
      },
      {
        description: "Contrato de honorários aceito pelo cliente",
        completed: true,
      },
    ],
    caseFiles: [
      {
        name: "Resumo do caso [Gerado pelo assistente]",
        createdAt: 1729535559022,
      },
      {
        name: "Cópia da CTPS",
        createdAt: 1729535559022,
      },
      {
        name: "Cópia do registro de folhas de ponto",
        createdAt: 1729535559022,
      },
      {
        name: "Cópia das trocas de e-mails com o gerente",
        createdAt: 1729535559022,
      },
    ],
  },
];
