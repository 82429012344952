import { FormControl, InputLabel, MenuItem as MuiMenuItem, Select } from "@mui/material";
import React from "react";

type OptionValue = string | number;

interface SelectOption {
  value: OptionValue;
  label: string;
}

interface FilterSelectProps {
  label: string;
  options: SelectOption[];
  defaultValue: OptionValue;
  onChange: (value: OptionValue) => void;
  disabled?: boolean;
}

export const FilterSelect = ({ label: selectLabel, options, defaultValue, onChange, disabled }: FilterSelectProps) => (
  <FormControl fullWidth>
    <InputLabel id={`${selectLabel}-select-label`}>{selectLabel}</InputLabel>
    <Select
      labelId={`${selectLabel}-select-label`}
      label={selectLabel}
      fullWidth
      disabled={disabled}
      variant="outlined"
      displayEmpty
      defaultValue={defaultValue}
      onChange={(event) => onChange(event.target.value as string)}
    >
      {options.map(({ value, label }) => (
        <MuiMenuItem key={value} value={value}>
          {label}
        </MuiMenuItem>
      ))}
    </Select>
  </FormControl>
);
