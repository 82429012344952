import { Paginated } from "@/core/api/types";
import { Thread } from "@/hooks/threads/types";
import { THREADS_QUERY_KEY } from "@/hooks/threads/usePaginatedThreads";
import { ThreadService } from "@/services/thread";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

export const useRenameThread = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["renameThread"],
    mutationFn: async ({ threadId, name }: { threadId: string; name: string }) => {
      return await ThreadService.renameThread({ threadId, name });
    },
    onMutate: async ({ threadId, name }: { threadId: string; name: string }) => {
      await queryClient.cancelQueries({ queryKey: THREADS_QUERY_KEY });
      const previousThreadsTuple = queryClient.getQueriesData({ queryKey: THREADS_QUERY_KEY });

      for (const [queryKey] of previousThreadsTuple) {
        queryClient.setQueryData(queryKey, (old: InfiniteData<Paginated<Thread>>) => {
          return {
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data.map((r) => {
                if (r.id === threadId) {
                  return {
                    ...r,
                    name,
                  };
                }
                return r;
              }),
            })),
            pageParams: old.pageParams,
          };
        });
      }

      return { previousThreadsTuple };
    },
    onError: (_error, _newFavoriteSkill, context) => {
      context?.previousThreadsTuple.forEach(([queryKey, queryData]) => {
        queryClient.setQueryData(queryKey, queryData);
      });
    },
  });
};
