import {
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";
import { Collapse, Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { ReactNode, useState } from "react";

interface FiltersContainerProps {
  children?: ReactNode;
}

export const FiltersContainer = ({ children }: FiltersContainerProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          my: 2,
        }}
      >
        <Typography variant="preTitle" sx={{ color: "common.shade" }}>
          Configurações avançadas
        </Typography>

        <IconButton onClick={() => setIsExpanded((prev) => !prev)} sx={{ m: -1 }}>
          <ExpandIcon fontSize="medium" />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 2,
            pb: 3,
            gap: 3,
          }}
        >
          <Typography variant="multiLineBody">Utilize os filtros abaixo para filtrar a busca de acórdãos:</Typography>

          {children}
        </Box>
      </Collapse>
      <Divider />
    </Box>
  );
};
