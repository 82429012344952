import { env } from "@/constants/environment";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface ReCaptchaProviderProps {
  children: React.ReactNode;
}
export const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => (
  <GoogleReCaptchaProvider reCaptchaKey={env.RECAPTCHA_KEY}>{children}</GoogleReCaptchaProvider>
);
