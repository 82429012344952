export const getGclid = () => {
  let url = window.location.href;
  if (Office.context.document?.url) {
    url = Office.context.document.url;
  }

  const documentUrl = new URL(url);
  const searchParams = documentUrl.searchParams;

  const gclid = searchParams.get("gclid");

  return gclid || undefined;
};
