import { SkillForm } from "@/core/skillForms/types";
import { intercurrentMotionSkillStep1 } from "./step1";
import { intercurrentMotionSkillStep2 } from "./step2";

export const intercurrentMotionSkillForm: SkillForm = {
  skillId: "intercurrent_motion",
  title: "Petição Intercorrente",
  subtitle: "Preencha a lista a seguir com detalhes essenciais para a criação da peça:",
  steps: [intercurrentMotionSkillStep1, intercurrentMotionSkillStep2],
};
