import { useEffect, useState } from "react";

import { User as FirebaseUser, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { LoggedInUserData, LoginType, UpdatePasswordErrors } from "@/contexts/AuthContext/types";
import * as logger from "@/core/logger";
import { SignUpSource } from "@/hooks/useApi/types";
import { FirebaseService } from "@/services/firebase";
import { getOfficeToken } from "./getOfficeToken";
import { getCustomTokenWithOfficeToken } from "./getUserDataWithSSOToken";
import { LoginWithEmailAndPassword, LoginWithGoogle, SignInGoogle } from "./types";
import { env } from "@/constants/environment";
import { Result, left, right } from "@/core/Result";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { LexterApiService } from "@/services/lexterApi";
import { useInterval } from "@/hooks/useInterval";
import { useAuthStore } from "@/stores/useAuthStore";
import { AuthService } from "@/services/auth";

const CHECK_TOKEN_INTERVAL = 1000 * 60 * 2; // 2 minutes

const firebaseAuth = FirebaseService.getAuth();

export const useLogin = () => {
  const userGuiding = useUserGuiding();

  const [firebaseUser, loadingFirebaseUser] = useAuthState(firebaseAuth);

  const [user, setUser] = useState<LoggedInUserData>();
  const [loading, setLoading] = useState(true);

  const { token, loginType } = useAuthStore();

  const redirectToAppDiligenceUsers = async (user: LoggedInUserData) => {
    const token = await FirebaseService.getToken();
    const companyDetails = await LexterApiService.getCompanyDetailsById(user.companyId, token);
    if (!companyDetails.products.includes("COPILOT") && outsideOfficeClient()) {
      window.location.replace(env.APP_LEXTER_URL as string);
    }
  };

  const setupFirebaseUser = async (firebaseUser?: FirebaseUser | null, loginConfig?: string) => {
    const { token } = await AuthService.setupFromFirebase();
    if (token) {
      logger.debug(`setupFirebaseUser started with firebaseUser email ${firebaseUser?.email}`);
      const userData = await LexterCopilotAuthService.getUserData({ loginConfig });
      setUser(userData);
      logger.debug(`setupFirebaseUser finished with firebaseUser email ${firebaseUser?.email}`);
    } else {
      logger.debug(`setupFirebaseUser failed with missing token`);
    }
  };

  const loginWithEmailAndPassword: LoginWithEmailAndPassword = async (
    email: string,
    password: string,
    recaptchaToken: string
  ) => {
    try {
      logger.debug(`loginWithEmailAndPassword with email ${email}`);
      const userCredential = await FirebaseService.signInWithEmailAndPassword(email.trim(), password);

      if (!userCredential.user?.emailVerified) {
        const { signUpSource } = await LexterCopilotAuthService.getSignupSource({ email, recaptchaToken });

        return {
          success: false,
          emailUnverified: true,
          requiresActivationCode: [
            SignUpSource.WORD_ADD_IN,
            SignUpSource.COPILOT_WEB,
            SignUpSource.COPILOT_WORD,
            SignUpSource.COPILOT_WORD_WEB,
          ].includes(signUpSource!),
        };
      }

      await setupFirebaseUser(userCredential.user as FirebaseUser);

      return { success: true };
    } catch (e) {
      const error = e as { message?: string; code?: string };
      logger.warn(
        `Error in loginWithEmailAndPassword ERROR ${error.message}, code ${error.code}, email ${email}`,
        error
      );

      const isCredentialsError = ["auth/wrong-password", "auth/invalid-email", "auth/user-not-found"].includes(
        error.code ?? ""
      );

      if (isCredentialsError) {
        return { success: false, invalidCredentials: true };
      }
      return { success: false, invalidCredentials: false };
    }
  };

  const loginWithGoogle: LoginWithGoogle = async () => {
    const signedIn = await signInGoogle();

    if (!signedIn.success || !signedIn.token) {
      return { success: false };
    }

    try {
      await setupFirebaseUser(signedIn.user, "EMAIL_AND_PASSWORD");

      return { success: true };
    } catch (e) {
      const error = e as { response?: { status: number }; message?: string };
      logger.warn(`Error in loginWithGoogle ERROR ${error.message}`, error);

      if (error.response) {
        return { success: false, needSignUp: { user: signedIn.user } };
      }

      return { success: false };
    }
  };

  const signInGoogle: SignInGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(firebaseAuth, googleProvider);
      const token = GoogleAuthProvider.credentialFromResult(result);
      return { success: true, user: result.user, token: token?.idToken };
    } catch (error) {
      if (error instanceof Object && "message" in error && "code" in error) {
        logger.warn(`Error in signInGoogle ERROR ${error.message}, code ${error.code}`, error);
      }

      return { success: false };
    }
  };

  const setupOfficeUser = async () => {
    const officeToken = await getOfficeToken();
    const customToken = await getCustomTokenWithOfficeToken(officeToken);
    const userCredential = await FirebaseService.signInWithCustomToken(customToken);

    await setupFirebaseUser(userCredential.user as FirebaseUser);
  };

  const loginWithOffice = async () => {
    try {
      await setupOfficeUser();

      return { success: true };
    } catch (e) {
      const error = e as { response?: { status: number }; message?: string };
      const status = error.response ? error.response.status : 500;
      logger.warn(`Error in loginWithOffice ERROR ${error.message}`, error);
      return { success: false, status };
    }
  };

  const updatePassword = async (currentPassWord: string, newPassword: string) => {
    try {
      const userEmail = firebaseAuth.currentUser?.email ?? user?.userEmail;

      if (userEmail) {
        const response = await FirebaseService.reauthenticateWithCredential(userEmail, currentPassWord)
          ?.then(() => {
            FirebaseService.updatePassword(newPassword);
          })
          .catch((e) => {
            return Result.fail(e);
          });

        if (response?.isFailure) {
          return left(UpdatePasswordErrors.INVALID_PASSWORD);
        }
        return right(undefined);
      }

      return left(UpdatePasswordErrors.GENERIC);
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.warn(`Error in updatePassword ERROR ${e.message}`, e);
      }
      return left(UpdatePasswordErrors.GENERIC);
    }
  };

  useEffect(() => {
    if (!loading) return;

    const checkLogin = async () => {
      try {
        switch (loginType) {
          case LoginType.FIREBASE: {
            if (loadingFirebaseUser) {
              return;
            }
            const hasToken = Boolean(token);
            if (firebaseUser || hasToken) {
              await setupFirebaseUser(firebaseUser);
              setLoading(false);
            } else {
              setLoading(false);
              void AuthService.logout({ redirectBack: true });
            }
            break;
          }
          default: {
            setLoading(false);
            void AuthService.logout({ redirectBack: true });
          }
        }
      } catch {
        void AuthService.logout({ redirectBack: true });
      }
    };

    void checkLogin();
  }, [loadingFirebaseUser, firebaseUser, loginType, loading, token]);

  useEffect(() => {
    if (user) {
      userGuiding.identify(user);
      logger.setProfile(user.userId, user.companyId, user.userName, user.userEmail);
      void redirectToAppDiligenceUsers(user);
    }
  }, [user]);

  useEffect(() => {
    if (!token) {
      setUser(undefined);
    }
  }, [token]);

  useInterval(() => AuthService.validateToken(), CHECK_TOKEN_INTERVAL);

  return {
    user,
    loginType,
    loading,
    loginWithEmailAndPassword,
    loginWithOffice,
    loginWithGoogle,
    updatePassword,
    setupFirebaseUser,
  };
};
