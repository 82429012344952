import { ActionId, LegalPieceMacroType, LegalPieceMicroType, UploadingFile } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  client: Type.String(),
  area: Type.String(),
  preliminary_objections: Type.Optional(Type.String()),
  case_breakdown: Type.Optional(Type.String()),
  theses: Type.Optional(Type.String()),
  evidence_text: Type.Optional(Type.String()),
  requests: Type.Optional(Type.String()),
  referencePiece: Type.String(),
});

const actionId = ActionId.CREATE_ONE_PIECE;

export const createDefenseSkill = {
  id: "create_contestation",
  name: "Escrever contestação",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  tags: ["litigation"],
  actionId,
  type: "message",
  getMessage: (input) => {
    const referencePieceFile = input.referencePiece?.startsWith("data:")
      ? fileToUploadingFile(base64ToFile(input.referencePiece))
      : undefined;
    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar Contestação",
      actions: [
        {
          id: actionId,
          text: "Criar Contestação",
          client: input.client,
          legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
          legal_piece_micro_type: LegalPieceMicroType.CONTESTATION,
          area: input.area,
          case_breakdown: input.case_breakdown
            ? {
                source: "TEXT",
                text: input.case_breakdown,
              }
            : undefined,
          preliminary_objections: input.preliminary_objections,
          theses: input.theses,
          evidence_text: input.evidence_text,
          requests: input.requests,
          reference_piece: {
            source: "FILE",
            file: referencePieceFile,
          },
        },
      ],
      files: referencePieceFile ? [referencePieceFile].filter((file): file is UploadingFile => Boolean(file)) : [],
    };
  },
} satisfies Skill<typeof inputSchema>;
