import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const EditorLoading = () => {
  return (
    <Box
      sx={{
        zIndex: 10,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "common.backdrop",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{
          color: "common.white",
        }}
      />
    </Box>
  );
};
