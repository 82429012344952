import React from "react";
import { ArrowOutward, DeleteForever, Edit } from "@mui/icons-material";
import { Divider, ListItemIcon, MenuItem, Popover, PopoverProps, Typography } from "@mui/material";

interface OptionsMenuProps extends PopoverProps {
  anchorEl: SVGSVGElement;
  onClose: () => void;
  openRename: () => void;
  openDelete: () => void;
  openThread: () => void;
}

export const ThreadOptionsMenu = ({
  anchorEl,
  onClose,
  openRename,
  openDelete,
  openThread,
  ...rest
}: OptionsMenuProps) => {
  return (
    <Popover
      onClose={onClose}
      anchorEl={anchorEl}
      {...rest}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
        ...rest.sx,
      }}
    >
      <MenuItem onClick={openThread}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <ArrowOutward fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">Abrir chat</Typography>
      </MenuItem>
      <Divider sx={{ margin: "0 !important" }} />
      <MenuItem onClick={openRename}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <Edit fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">Renomear</Typography>
      </MenuItem>
      <Divider sx={{ margin: "0 !important" }} />
      <MenuItem onClick={openDelete}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <DeleteForever fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">Deletar</Typography>
      </MenuItem>
    </Popover>
  );
};
