import { useContext } from "react";
import { WebEditorContext } from "./context";

export const useWebEditorContext = () => {
  const contextValue = useContext(WebEditorContext);
  if (!contextValue) {
    throw new Error("WebEditorContext not found");
  }
  return contextValue;
};
