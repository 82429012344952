import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { Case, CaseMessage, CaseMessageType } from "../types";
import { v4 as uuidV4 } from "uuid";
import { getCasesQueryKey } from "../useLexZapCases";
import { casesMock } from "../useLexZapCase/mocks";
import { SendLexZapMessageParams } from "@/hooks/useApi/types";

export const useSendLexZapMessage = () => {
  const queryClient = useQueryClient();
  const { sendLexZapMessage } = useApi();

  return useMutation({
    mutationKey: ["sendLexZapMessage"],
    mutationFn: async (params: SendLexZapMessageParams) => {
      const mockedCase = casesMock.find((caseItem) => caseItem.chatId === params.chatId);
      if (mockedCase) {
        return;
      }

      return sendLexZapMessage(params);
    },
    onMutate: async ({ chatId, message }) => {
      await queryClient.cancelQueries({ queryKey: getMessagesQueryKey({ chatId }) });

      const previousMessages = queryClient.getQueryData<CaseMessage[]>(getMessagesQueryKey({ chatId }));

      const tempId = uuidV4();

      const newMessage: CaseMessage = {
        chatId,
        from: "",
        lawyerId: "",
        fromLawyer: true,
        id: tempId,
        to: "",
        body: message.body,
        type: CaseMessageType.TEXT,
        isForwarded: false,
        timestamp: Date.now(),
        isSending: true,
        statusUpdateTimestamp: {},
      };

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ chatId }), (oldMessages = []) => [
        ...oldMessages,
        newMessage,
      ]);

      queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) =>
        oldCases.map((caseItem) => (caseItem.chatId === chatId ? { ...caseItem, lastMessage: newMessage } : caseItem))
      );

      return { previousMessages, newMessage };
    },
    onError: (_error, { chatId }, context) => {
      if (context?.previousMessages) {
        queryClient.setQueryData(getMessagesQueryKey({ chatId }), context.previousMessages);

        const previousLastMessage = context.previousMessages[context.previousMessages.length - 1];

        queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) =>
          oldCases.map((caseItem) =>
            caseItem.chatId === chatId ? { ...caseItem, lastMessage: previousLastMessage } : caseItem
          )
        );
      }
    },
    onSuccess: async (data, { chatId }, context) => {
      if (!data) return;

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ chatId }), (oldMessages = []) =>
        oldMessages.map((message) =>
          message.id === context.newMessage.id ? { ...message, id: data.message.id, isSending: false } : message
        )
      );

      queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) =>
        oldCases.map((caseItem) =>
          caseItem.chatId === chatId
            ? { ...caseItem, lastMessage: { ...context.newMessage, id: data.message.id, isSending: false } }
            : caseItem
        )
      );
    },
  });
};
