import { getCookie, removeCookie, setCookie } from "@/utils/cookie";

export const setInStorages = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
  setCookie(key, value);
};

export const getFromStorages = (key: string) => {
  const sessionItem = sessionStorage.getItem(key);
  if (sessionItem && sessionItem !== "undefined") {
    return sessionItem;
  }
  return getCookie(key);
};

export const removeFromStorages = (key: string) => {
  sessionStorage.removeItem(key);
  removeCookie(key);
};
