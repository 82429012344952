import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

export const HeaderGoBack = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowBack />}
        sx={{
          color: "text.primary",
          alignSelf: "center",
        }}
      >
        Voltar
      </Button>
    </Box>
  );
};
