import { useMemo } from "react";
import { CaseMessage, CaseMessageWithMedia } from "../types";
import { useLexZapMessages } from "../useLexZapMessages";
interface UseLexZapMessagesProps {
  chatId?: string;
}
export const useLexZapMessagesMedia = ({ chatId }: UseLexZapMessagesProps) => {
  const { data: messages, isLoading, isError } = useLexZapMessages({ chatId });

  function isCaseWithMedia(input: CaseMessageWithMedia | CaseMessage): input is CaseMessageWithMedia {
    return input?.media !== undefined;
  }

  const casesWithMedia = useMemo(() => messages?.filter(isCaseWithMedia), [messages]);

  return {
    data: casesWithMedia,
    isLoading,
    isError,
  };
};
