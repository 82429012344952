import { DialogModal } from "@/components/DialogModal";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { RecordType, ThreadRecord } from "@/hooks/records/types";
import { useDeleteRecord } from "@/hooks/records/useDeleteRecord";
import { WebToast } from "@/taskpane/components/core/Toast";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface RenameDocumentModalProps {
  threadId?: string;
  isOpen: boolean;
  onClose: () => void;
  documentName: string;
  currentEditingRecord: ThreadRecord | null;
}

export const ArchiveDocumentModal = ({
  threadId,
  isOpen,
  onClose,
  documentName,
  currentEditingRecord,
}: RenameDocumentModalProps) => {
  const { closeTabByDocumentId } = useWebEditorContext();

  const { mutateAsync: deleteRecord, isPending: isDeleting } = useDeleteRecord({
    threadId: threadId,
  });

  const handleArchiveDocument = async () => {
    if (!currentEditingRecord) return WebToast.error("Erro ao excluir documento");

    await deleteRecord(currentEditingRecord);

    onClose();

    if (currentEditingRecord.type === RecordType.DOCUMENT) {
      closeTabByDocumentId(currentEditingRecord.id);
    }
  };

  return (
    <DialogModal
      open={isOpen}
      onClose={onClose}
      title="Excluir documento?"
      description={
        <Box display="flex" alignItems={"center"} justifyContent={"center"} width="100%" px={1}>
          {isDeleting ? (
            <Box display="flex" color="secondary" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              Tem certeza que deseja excluir o documento <strong>{documentName}</strong>?
            </Typography>
          )}
        </Box>
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Voltar",
          onClick: isDeleting ? undefined : onClose,
          variant: "text",
        },
        {
          label: "Excluir",
          onClick: handleArchiveDocument,
          color: "error",
          disabled: isDeleting,
        },
      ]}
      variant="error"
    />
  );
};
