import { Box, Button, TextField } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { UserProfile } from "@/hooks/useApi/types";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@/taskpane/components/core/Toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MyDataInfoSchema, myDataInfoSchema } from "./types";

export function ClientConfigMyDataModal() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { updateUserProfile } = useApi();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<MyDataInfoSchema>({
    defaultValues: {
      name: user?.userName || "",
      email: user?.userEmail || "",
    },
    mode: "onChange",
    resolver: zodResolver(myDataInfoSchema),
  });

  const getUserData = React.useCallback(() => {
    setIsLoading(true);

    if (!user || !user.userEmail) {
      return;
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getUserData();
    if (user) {
      reset({
        name: user.userName,
        email: user.userEmail,
      });
    }
  }, [getUserData, user, reset]);

  const updateUser = async (userProfile: UserProfile) => {
    setIsLoading(true);
    try {
      await updateUserProfile(userProfile);

      setIsLoading(false);

      goBackToSettings();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao alterar o perfil do usuário");
    }
  };

  const goBackToSettings = () => {
    navigate(ROUTE_PATHS.CLIENT_CONFIG, { replace: true });
  };

  const handleUpdateUserProfile = handleSubmit((data) => {
    try {
      if (!user || !user.userEmail) {
        return;
      }

      updateUser({
        userId: user.userId,
        name: data.name,
        email: data.email,
        companies: [],
        reviewConfig: {
          includeDefaultText: true,
        },
      });
    } catch (e) {
      WebToast.error("Não foi possível alterar as informações do usuário");
    }
  });

  const saveButtonDisabled = !isDirty || !isValid || isLoading;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Meus Dados"}
        size={"SMALL"}
        onClose={goBackToSettings}
        footer={
          user &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"Salvar alterações"}
              onConfirm={handleUpdateUserProfile}
              cancelLabel="Cancelar"
              onCancel={goBackToSettings}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={true}
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {user && (
                <>
                  <TextField
                    placeholder="Nome Completo"
                    {...register("name", { required: true })}
                    label="Nome"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                  <TextField
                    placeholder="E-mail de trabalho"
                    {...register("email")}
                    label="E-mail"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled={true}
                  />
                </>
              )}
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm} size="small">
        {confirmLabel}
      </Button>
      <Button size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Box>
  );
};
