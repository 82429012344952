import { ActionId, Area, LegalPieceMacroType, LegalPieceMicroType, RepliedMessage } from "../types";

export const shouldSuggestCreateEvidence = (repliedMessage: RepliedMessage): boolean => {
  try {
    const { actionId, payload } = repliedMessage;
    const { legal_piece_macro_type, area, legal_piece_micro_type } = payload;

    if (actionId !== ActionId.CREATE_ONE_PIECE) return false;

    const allowedPieces: Array<[LegalPieceMacroType, Area, LegalPieceMicroType]> = [
      [LegalPieceMacroType.INAUGURAL_DOCUMENT, Area.CIVIL, LegalPieceMicroType.INITIAL],
      [LegalPieceMacroType.INAUGURAL_DOCUMENT, Area.LABOR, LegalPieceMicroType.INITIAL],
      [LegalPieceMacroType.RESPONSE, Area.CIVIL, LegalPieceMicroType.CONTESTATION],
      [LegalPieceMacroType.RESPONSE, Area.CIVIL, LegalPieceMicroType.REPLICATION],
      [LegalPieceMacroType.RESPONSE, Area.LABOR, LegalPieceMicroType.CONTESTATION],
      [LegalPieceMacroType.RESPONSE, Area.LABOR, LegalPieceMicroType.REPLICATION],
    ];

    return allowedPieces.some(
      ([allowedMacroType, allowedArea, allowedMicroType]) =>
        allowedMacroType === legal_piece_macro_type &&
        allowedArea === area &&
        allowedMicroType === legal_piece_micro_type
    );
  } catch {
    return false;
  }
};
