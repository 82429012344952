import { SkillForm } from "@/core/skillForms/types";
import { createStrategySkillStep1 } from "./step1";
import { createStrategySkillStep2 } from "./step2";
import { createStrategySkillStep3 } from "./step3";

export const createStrategySkillForm: SkillForm = {
  skillId: "create_case_strategy",
  title: "Estratégia Jurídica",
  subtitle: "Preencha a lista a seguir com detalhes essenciais para a criação da estratégia:",
  steps: [createStrategySkillStep1, createStrategySkillStep2, createStrategySkillStep3],
};
