import { Skills } from "@/core/skills";
import { SkillId } from "@/core/skills/types";
import { useQuery } from "@tanstack/react-query";

export const useSkill = (skillId?: string) => {
  return useQuery({
    queryKey: ["skill", skillId],
    queryFn: async () => {
      const skill = await Skills.getSkill(skillId as SkillId);

      if (skill) {
        return skill;
      }

      throw new Error("Skill not found");
    },
    enabled: Boolean(skillId),
  });
};
