import { Box, SxProps, useTheme } from "@mui/material";
import React from "react";

interface LoadingProps {
  isLoading: boolean;
  children?: React.ReactNode;
  label?: React.ReactNode;
  size?: "SMALL" | "MEDIUM" | "LARGE";
  color?: "PRIMARY" | "SECONDARY" | "TERTIARY";
  alignment?: "START" | "CENTER" | "END";
  sx?: SxProps;
}

export function Loading({ isLoading, children, size, color, alignment, label, sx }: LoadingProps) {
  const theme = useTheme();

  let loadingColor: string;
  let loadingSize: string;
  let loadingAlignment: string;

  switch (color) {
    case "PRIMARY":
      loadingColor = theme.palette.primary.main;
      break;
    case "SECONDARY":
      loadingColor = theme.palette.secondary.main;
      break;
    case "TERTIARY":
      loadingColor = theme.palette.warning.main;
      break;
    default:
      loadingColor = theme.palette.primary.main;
  }

  switch (size) {
    case "SMALL":
      loadingSize = "10px";
      break;
    case "MEDIUM":
      loadingSize = "34px";
      break;
    case "LARGE":
      loadingSize = "64px";
      break;
    default:
      loadingSize = "34px";
  }

  switch (alignment) {
    case "START":
      loadingAlignment = "flex-start";
      break;
    case "CENTER":
      loadingAlignment = "center";
      break;
    case "END":
      loadingAlignment = "flex-end";
      break;
    default:
      loadingAlignment = "center";
  }

  const loadingCircleStyle = {
    width: loadingSize,
    height: loadingSize,
    border: `${loadingSize === "10px" ? "2px" : "5px"} solid ${loadingColor}`,
    borderRadius: "50%",
    borderRightColor: "transparent",
    borderBottomColor: "transparent",
    transition: "all 0.5s ease-in",
    animation: "rotate 1s infinite linear",
    ...sx,
  };

  const wrapperStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "32px",
    alignItems: loadingAlignment,
  };

  if (isLoading) {
    return (
      <Box sx={wrapperStyle}>
        <Box sx={loadingCircleStyle} />
        {label}
      </Box>
    );
  }
  return <>{children}</>;
}
