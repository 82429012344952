import { SkillForm } from "@/core/skillForms/types";
import { createOrdinaryAppealSkillStep3 } from "./step3";
import { createBaseAppealSkillStep2 } from "../createBaseAppealSkill/step2";

export const createOrdinaryAppealSkillForm: SkillForm = {
  skillId: "create_ordinary_appeal",
  title: "Recurso ordinário trabalhista",
  subtitle: "Preencha a lista a seguir com detalhes essenciais para a criação da peça:",
  steps: [createBaseAppealSkillStep2, createOrdinaryAppealSkillStep3],
};
