import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  area: Type.String(),
  client: Type.String(),
  case_breakdown: Type.String(),
  theses: Type.Optional(Type.String()),
  evidence_text: Type.Optional(Type.String()),
  requests: Type.Optional(Type.String()),
});

const actionId = ActionId.INTERCURRENT_MOTION;

export const intercurrentMotionSkill = {
  id: "intercurrent_motion",
  name: "Petição intercorrente",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["litigation", "new"],
  actionId,
  type: "payload",
  getPayload: (input) => ({
    skillId: ActionId.INTERCURRENT_MOTION,
    requestId: uuidV4(),
    payload: {
      case_breakdown: input.case_breakdown,
      theses: input.theses,
      evidence_text: input.evidence_text,
      requests: input.requests,
      client: input.client,
      area: input.area,
    },
    messageToSave: "Elaborar petição intercorrente",
  }),
} satisfies Skill<typeof inputSchema>;
