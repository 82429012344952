import { z } from "zod";

export const myDataInfoSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." }),
  email: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." })
    .email({ message: "E-mail inválido." }),
});

export type MyDataInfoSchema = z.infer<typeof myDataInfoSchema>;
