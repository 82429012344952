import { Box } from "@mui/material";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import React from "react";
import { SKILL_FORM_MAX_WIDTH } from "@/constants/layout";

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 0.8,
        maxWidth: SKILL_FORM_MAX_WIDTH,
        width: "100%",
        margin: "auto",
        px: 6,
        boxSizing: "border-box",
      }}
    >
      {props.properties.map((element) => (
        <Box
          sx={{
            "&:has(div > div)": {
              flexGrow: 1,
              width: "100%",
              mb: 3,
            },
          }}
          key={element.name}
        >
          {element.content}
        </Box>
      ))}
    </Box>
  );
};
