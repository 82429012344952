import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { numberOfMotionsPerWeekOptions } from "../defaultValues";

interface UserNumberOfMotionsPerWeekSelectProps {
  numberOfMotionsPerWeek: string;
  onNumberOfMotionsPerWeekChange: (value: string) => void;
  show: boolean;
}

export const UserNumberOfMotionsPerWeekSelect = ({
  numberOfMotionsPerWeek,
  onNumberOfMotionsPerWeekChange,
  show,
}: UserNumberOfMotionsPerWeekSelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNumberOfMotionsPerWeekChange(event.target.value);
  };

  if (!show) return null;

  return (
    <Box>
      <FormControl>
        <Typography variant="subtitle2" sx={{ color: "common.shade", mb: 2, fontWeight: "700" }}>
          Qual o volume de peças que escreve semanalmente?
        </Typography>
        <RadioGroup value={numberOfMotionsPerWeek} onChange={handleChange}>
          {numberOfMotionsPerWeekOptions.map((motion, i) => (
            <FormControlLabel key={i} value={motion.value} control={<Radio />} label={motion.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
