import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@/components/Button";

import React, { useMemo } from "react";

import SaleSvg from "@/assets/svgs/sale.svg?react";
import { useApi } from "@/hooks/useApi";
import { useAuthContext } from "@/contexts/AuthContext";
import { Toast } from "@/taskpane/components/core/Toast";
import { logger } from "@/core/logger";
import { useCredits } from "@/hooks/credits/useCredits";
import { DateTime } from "luxon";

export const RestorePlanBanner = () => {
  const theme = useTheme();
  const { data: credits, refetch: refetchCredits } = useCredits();
  const { user } = useAuthContext();
  const { restorePlan } = useApi();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const cancellationAtFormatted = useMemo(() => {
    if (!credits?.companyPlan?.cancellingAt) return undefined;

    return DateTime.fromISO(credits.companyPlan.cancellingAt).toFormat("dd 'de' MMMM 'de' yyyy");
  }, [credits?.companyPlan?.cancellingAt]);

  const handleEndCancellation = async () => {
    setLoading(true);
    try {
      if (!user?.companyId) throw new Error("Company not found");

      await restorePlan({
        companyId: user.companyId,
        couponCode: "CANCELAMENTO10",
      });

      setLoading(false);
      refetchCredits();
      setSuccess(true);
      Toast.success("Plano renovado com sucesso!");
    } catch (err) {
      logger.error("handleEndCancellation", err);
      Toast.error("Ocorreu um erro ao encerrar o cancelamento. Tente novamente mais tarde.");
      setLoading(false);
    }
  };

  if (!cancellationAtFormatted || success) return null;

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        padding: "8px 16px 8px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 1,
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      }}
    >
      <Typography
        color="common.black"
        sx={{
          display: "flex",
          gap: 1,
          py: 0.25,
        }}
      >
        <SaleSvg
          style={{
            margin: "auto 0 auto 0",
          }}
        />
        <Typography
          sx={{
            fontWeight: 700,
            height: "20px",
            "@media (max-width: 960px)": {
              height: "auto",
            },
          }}
          color="common.black"
        >
          Continue utilizando o melhor da inteligência artificial no seu dia a dia e ganhe 10% de desconto nos próximos
          dois meses de assinatura
        </Typography>
      </Typography>
      <Button
        sx={{
          width: "max-content",
          borderRadius: "20px",
          lineHeight: "22px",
          paddingY: 0.5,
          alignSelf: "center",
        }}
        variant="contained"
        onClick={handleEndCancellation}
        isLoading={loading}
        loaderStyle={{ maxWidth: "233px", borderRadius: "20px", height: "30px" }}
      >
        Encerrar cancelamento
      </Button>
    </Box>
  );
};
