import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";
import { skillInputToUserInput } from "@/utils/skillInputToUserInput";

const inputSchema = Type.Object({
  reference_piece_complaint: Type.String(),
  reference_piece_answer: Type.String(),
  client: Type.String(),
  witness: Type.String(),
  case_breakdown: Type.String(),
});

const actionId = ActionId.HEARINGS_SCRIPT;

export const hearingsScriptSkill: Skill<typeof inputSchema> = {
  id: "hearings_script",
  name: "Roteiro para audiências",
  credits: CreditPerSkill.ESSENTIAL_SKILL,
  inputSchema,
  tags: ["litigation", "new"],
  actionId,
  type: "payload",
  description: "Elabore um roteiro de perguntas para preparar seu cliente e testemunhas para a audiência judicial.",
  getPayload: (input) => ({
    skillId: ActionId.HEARINGS_SCRIPT,
    requestId: uuidV4(),
    payload: {
      reference_piece_complaint: skillInputToUserInput(input.reference_piece_complaint),
      reference_piece_answer: skillInputToUserInput(input.reference_piece_answer),
      client: input.client,
      witness: input.witness,
      case_breakdown: input.case_breakdown,
    },
    messageToSave: "Elaborar roteiro para audiência",
  }),
};
