import { Box, Link, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { Layout } from "@/pages/Signup/Layout";
import React from "react";
import { EXTERNAL_URLS } from "@/routes/routePaths";

interface SignupMessageScreenProps {
  imageSrc: string;
  title: string;
  message: string;
  button: {
    label: string;
    onClick: () => void;
    isLoading?: boolean;
  };
}

export const SignupMessageScreen = ({ imageSrc, title, message, button }: SignupMessageScreenProps) => (
  <Layout>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          marginY: "auto",
          maxWidth: "320px",
        }}
      >
        <img src={imageSrc} width={72} height={72} />
        <Typography variant="h3" align="center">
          {title}
        </Typography>
        <Typography variant="multiLineBody" align="center">
          {message}
        </Typography>
        <Button variant="contained" fullWidth onClick={button.onClick} isLoading={button.isLoading}>
          {button.label}
        </Button>
        <Typography variant="multiLineBody" align="center">
          Em caso de dúvidas, acesse nossa <Link href={EXTERNAL_URLS.HELP_CENTER_URL}>Central de Ajuda</Link>.
        </Typography>
      </Box>
    </Box>
  </Layout>
);
