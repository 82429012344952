import { Box, Button } from "@mui/material";
import React from "react";

export const CaseFooter = ({
  onDelete,
  onArchive,
  onUnarchive,
  archived,
}: {
  onDelete: () => void;
  onArchive: () => void;
  onUnarchive: () => void;
  archived: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2.5,
      }}
    >
      <Button variant="contained" color="error" onClick={onDelete}>
        Deletar caso
      </Button>

      {archived ? (
        <Button variant="outlined" onClick={onUnarchive}>
          Desarquivar caso
        </Button>
      ) : (
        <Button variant="outlined" onClick={onArchive}>
          Arquivar caso
        </Button>
      )}
    </Box>
  );
};
