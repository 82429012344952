import { FormattedText, Style } from "@/hooks/useEditor/types";

export const formattedTextToHtml = ({ lines, stylesMap }: FormattedText) => {
  let html = "";

  for (const line of lines) {
    if (line.type === "PageBreak") {
      html += `<div class="page-break" style=" page-break-after: always; position: absolute; background-color: #f1f1f1; width: 100%; height: 10px; left: 0; user-select: none; pointer-events: none;"></div><br></br>`;
    } else {
      const style = stylesMap[line.type] || ({} as Partial<Style>);
      const { alignment, font_size, font_name, underline, bold, lines_after } = style;

      const textAlign = alignment ? `text-align: ${alignment.toLowerCase()};` : "";
      const fontSize = font_size ? `font-size: ${font_size}pt;` : "";
      const fontFamily = font_name ? `font-family: '${font_name}';` : "";

      let text = line.text || "";
      if (underline) text = `<u>${text}</u>`;
      if (bold) text = `<strong>${text}</strong>`;

      const lineBreaks = lines_after ? "<p></p>".repeat(lines_after) : "";

      html += `<p style="${fontFamily} ${fontSize} ${textAlign}">${text}</p>${lineBreaks}`;
    }
  }

  return html;
};
