import React from "react";
import { motion, MotionStyle, MotionValue, PanInfo } from "framer-motion";

type SliderProps = {
  x: MotionValue<number>;
  i: number;
  children: React.ReactNode;
  onDragEnd: (e: Event, dragProps: PanInfo) => void;
  totalSliders: number;
};

const pageStyle: MotionStyle = {
  width: "100%",
  height: "100%",
  display: "inline-block",
  flex: "none",
};

export const Slider = ({ x, i, onDragEnd, children, totalSliders }: SliderProps) => (
  <motion.div
    style={{
      ...pageStyle,
      x,
      left: `${i * 100}%`,
      right: `${i * 100}%`,
    }}
    drag={totalSliders > 1 && "x"}
    dragElastic={0.3}
    onDragEnd={onDragEnd}
  >
    {children}
  </motion.div>
);
