import { z } from "zod";

export const phoneSchema = z.object({
  phone: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .regex(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[0-9])[0-9]{3}-?[0-9]{4}$/, {
      message: "Telefone inválido",
    }),
});

export type PhoneFormData = z.infer<typeof phoneSchema>;
