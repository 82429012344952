import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Input, useTheme } from "@mui/material";
import { useRenameRecord } from "@/hooks/records/useRenameRecord";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { RecordType, ThreadRecord } from "@/hooks/records/types";
import { WebToast } from "@/taskpane/components/core/Toast";

interface RenameDocumentModalProps {
  threadId?: string;
  isOpen: boolean;
  onClose: () => void;
  currentEditingRecord: ThreadRecord | null;
}

export const RenameDocumentModal = ({ threadId, isOpen, onClose, currentEditingRecord }: RenameDocumentModalProps) => {
  const theme = useTheme();
  const { renameTabByDocumentId } = useWebEditorContext();

  const [documentName, setDocumentName] = React.useState("");

  const { mutateAsync: renameRecord, isPending: isRenaming } = useRenameRecord({
    threadId: threadId,
  });

  const handleRenameRecord = async (name: string) => {
    if (!currentEditingRecord) return WebToast.error("Erro ao renomear documento");

    await renameRecord({ record: currentEditingRecord, name });

    onClose();

    if (currentEditingRecord.type === RecordType.DOCUMENT) {
      renameTabByDocumentId(currentEditingRecord.id, name);
    }
  };

  return (
    <DialogModal
      open={isOpen}
      onClose={onClose}
      title="Renomear documento"
      description={
        <Box display="flex" alignItems={"center"} justifyContent={"center"} width="100%" px={1} py={3}>
          {isRenaming ? (
            <Box display="flex" color="secondary" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Input
              sx={{
                width: "100%",
                fontFamily: `${theme.typography.fontFamily} !important`,
              }}
              onChange={(e) => setDocumentName(e.target.value)}
              placeholder="Nome do documento"
            />
          )}
        </Box>
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Cancelar",
          onClick: isRenaming ? undefined : onClose,
          variant: "text",
        },
        {
          label: "Renomear",
          onClick: () => handleRenameRecord(documentName),
          color: "primary",
          disabled: isRenaming || documentName.length === 0,
        },
      ]}
      variant="primary"
    />
  );
};
