import { Box } from "@mui/material";
import React from "react";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "background.default",
        color: "common.black",
        position: "relative",
        display: "flex",
        overflow: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Content>{children}</Content>
    </Box>
  );
};

const Content = ({ children }: LayoutProps) => (
  <Box
    sx={{
      margin: "auto",
      overflow: "auto",
      width: "100%",
      maxWidth: "320px",
      maxHeight: "100%",
      my: 4,
      mx: 3,
    }}
  >
    {children}
  </Box>
);
