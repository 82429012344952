const isTrident = navigator.userAgent.indexOf("Trident") !== -1;
const isEdgeHTML = navigator.userAgent.indexOf("Edge") !== -1;

const isOldWebview = isTrident || isEdgeHTML;

function load(url, element) {
  const req = new XMLHttpRequest();
  req.open("GET", url, false);
  req.send(null);

  element.innerHTML = req.responseText;
}

if (isOldWebview) {
  load("./unsupported.html", document.getElementById("container"));
}
