import { logger } from "@/core/logger";

function download(url: string, name: string) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.onload = function () {
    saveFileAs(xhr.response, name);
  };
  xhr.onerror = function () {
    logger.error("could not download file");
  };
  xhr.send();
}

function corsEnabled(url: string) {
  const xhr = new XMLHttpRequest();
  // use sync to avoid popup blocker
  xhr.open("HEAD", url, false);
  try {
    xhr.send();
  } catch (e) {
    /* empty */
  }
  return xhr.status >= 200 && xhr.status <= 299;
}

// `a.click()` doesn't work for all browsers
function click(node: EventTarget) {
  try {
    node.dispatchEvent(new MouseEvent("click"));
  } catch (e) {
    const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
      screenX: 80,
      screenY: 20,
      clientX: 0,
      clientY: 0,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      button: 0,
      relatedTarget: null,
    });
    node.dispatchEvent(evt);
  }
}

export function saveFileAs(blob: string | Blob | MediaSource, name?: string) {
  // Namespace is used to prevent conflict w/ Chrome Poper Blocker extension
  const a = document.createElementNS("http://www.w3.org/1999/xhtml", "a") as HTMLAnchorElement;
  name = name || "download";

  a.download = name;
  a.rel = "noopener"; // tabnabbing

  if (typeof blob === "string") {
    // Support regular links
    a.href = blob;
    if (a.origin !== location.origin) {
      corsEnabled(a.href) ? download(blob, name) : click(a);
    } else {
      click(a);
    }
  } else {
    // Support blobs
    a.href = URL.createObjectURL(blob);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 4e4); // 40s
    setTimeout(function () {
      click(a);
    }, 0);
  }
}
