import { Box, Divider, Link, TextField, Typography } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import React, { useEffect, useState } from "react";
import {
  CampaignOutlined,
  EmojiEventsOutlined,
  HighlightOffOutlined,
  MonetizationOnOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import { Button, LinkButton } from "@/components/Button";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { useApi } from "@/hooks/useApi";
import { useAuthContext } from "@/contexts/AuthContext";
import { ReferralApiResponseItem } from "@/hooks/useApi/types";
import { Toast } from "@/taskpane/components/core/Toast";
import { Chip } from "@/components/Chip";
import { Email } from "@/core/Email";
import { Loading } from "@/components/Loading";
import { ASSISTANT_URL, EXTERNAL_URLS } from "@/routes/routePaths";
import * as logger from "../../core/logger";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { Paginated, PaginationPayload } from "@/core/api/types";

const INITIAL_PAGINATION_MODEL = {
  page: 0,
  pageSize: 5,
};

export function Referral() {
  const { sendReferrals } = useApi();
  const { user } = useAuthContext();
  const [emails, setEmails] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION_MODEL);
  const [sendReferralsState, setSendReferralsState] = useState<"WAITING" | "SENDING" | "SUCCESS" | "ERROR">("WAITING");
  const [loading, setLoading] = useState<boolean>(false);
  const [referralsList, setReferrasList] = useState<Paginated<ReferralApiResponseItem>>();
  const { listReferrals } = useApi();

  useEffect(() => {
    refetchReferrals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  const handleRetry = () => {
    setSendReferralsState("WAITING");
    setErrorMessage("");
  };

  const fetchReferrals = async (pagination: PaginationPayload) => {
    setLoading(true);

    try {
      if (!user) {
        throw new Error("Usuário não encontrado");
      }

      const referrals = await listReferrals({
        companyId: user.companyId,
        pagination,
      });

      setReferrasList(referrals);
      setEmails("");
    } catch (e) {
      Toast.error("Não foi possível buscar os indicados");
    }
    setLoading(false);
  };

  const refetchReferrals = () => {
    fetchReferrals({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });
  };

  const handleSendReferral = async () => {
    setSendReferralsState("SENDING");
    try {
      let hasError = false;

      const emailsArray = emails.split(",").map((email) => email.trim());
      const regex = /^[a-zA-Z0-9._%+-]+(\+|\.)[0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      emailsArray.forEach((email) => {
        if (regex.test(email) || !Email.isValidEmail(email)) {
          hasError = true;
          setSendReferralsState("ERROR");
          setErrorMessage("Ops! Os emails estão invalidos.");
        }
      });

      if (hasError || !user) {
        return;
      }

      const sendResult = await sendReferrals({
        companyId: user.companyId,
        emails: emails.split(",").map((email) => email.trim()),
      });

      if (sendResult.length !== emailsArray.length) {
        const notSentEmails = emailsArray.length - sendResult.length;
        const message =
          notSentEmails === 1
            ? "O convite não foi enviado, pois o destinatários já está cadastrado"
            : `${notSentEmails} convites não foram enviados, pois os destinatários já estão cadastrados.`;
        Toast.error(message);
      }

      setSendReferralsState("SUCCESS");
      if (paginationModel.page === 0) {
        refetchReferrals();
      } else {
        setPaginationModel(INITIAL_PAGINATION_MODEL);
      }
    } catch (e) {
      setErrorMessage("Ops! houve um erro em enviar seus convites.");
      setSendReferralsState("ERROR");
    }
  };

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(`${ASSISTANT_URL}/signup?referralCode=${user?.userId}`);
    Toast.success("Link de indicação copiado para a área de transferência");
  };

  return (
    <PageLayout>
      <Typography variant="h3" mb={4}>
        Indique e Ganhe
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Typography variant="body3" color="common.shade">
          Compartilhe o seu <Link onClick={handleCopyReferralLink}>link de indicação</Link> e toda vez que alguém se
          cadastrar e utilizar o Assistente Lexter, você ganha <strong>200 créditos</strong> para utilizar o melhor da
          inteligência artificial no seu dia a dia.
        </Typography>
        <Typography variant="label" textTransform="uppercase" color="common.shade">
          Compartilhe agora por e-mail
        </Typography>

        {sendReferralsState === "WAITING" && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              sx={{
                flexGrow: 1,
                bgcolor: "common.white",
              }}
              multiline
              variant="outlined"
              placeholder="Insira os e-mails, separe-os usando vírgula"
              onChange={(e) => setEmails(e.target.value)}
              minRows={1}
              maxRows={4}
            />
            <Button disabled={emails === ""} onClick={handleSendReferral} variant="contained">
              Enviar
            </Button>
          </Box>
        )}
        {sendReferralsState === "SENDING" && <Loading isLoading={true} size="LARGE" />}
        {sendReferralsState === "SUCCESS" && <SuccessMessage />}
        {sendReferralsState === "ERROR" && <ErrorMessage handleRetry={handleRetry} errorMessage={errorMessage} />}
        <Typography variant="body2">
          Ao compartilhar seu código de indicação, você está de acordo com as regras estipuladas nos{" "}
          <Link href={EXTERNAL_URLS.TERMS_OF_USE} target="_blank">
            Termos de Uso
          </Link>{" "}
          e{" "}
          <Link href={EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">
            Política de Privacidade
          </Link>{" "}
          da Lexter.Ai. Em caso de dúvidas, acesse nossa{" "}
          <Link href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank">
            Central de Ajuda
          </Link>
          .
        </Typography>
        <Divider />
        <HowItWorks />
        <Divider />
        <Typography fontWeight={700} variant="body3">
          Convidados
        </Typography>
        <ReferralTable
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          loading={loading}
          referralsList={referralsList}
        />
      </Box>
    </PageLayout>
  );
}

const HowItWorks = () => {
  return (
    <>
      <Typography fontWeight={700} variant="body3">
        Como funciona?
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
        color="common.shade"
      >
        <CampaignOutlined />
        <div>
          <Typography fontWeight={700} variant="body2">
            INDIQUE O ASSISTENTE LEXTER
          </Typography>
          <Typography variant="body2">Compartilhe o seu link de indicação por e-mail com outras pessoas.</Typography>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
        color="common.shade"
      >
        <MonetizationOnOutlined />
        <div>
          <Typography fontWeight={700} variant="body2">
            RECEBA CRÉDITOS
          </Typography>
          <Typography variant="body2">
            Ganhe 200 créditos quando alguém se cadastrar e utilizar o Assistente Lexter com o seu link.
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
        color="common.shade"
      >
        <EmojiEventsOutlined />
        <div>
          <Typography fontWeight={700} variant="body2">
            GANHE AINDA MAIS
          </Typography>
          <Typography variant="body2">
            Receba mais 2.000 créditos quando quem se cadastrou fizer uma assinatura.
          </Typography>
        </div>
      </Box>
    </>
  );
};

interface ReferralTableProps {
  paginationModel: GridPaginationModel;
  setPaginationModel: (paginationModel: GridPaginationModel) => void;
  loading: boolean;
  referralsList: Paginated<ReferralApiResponseItem> | undefined;
}

const ReferralTable = ({ paginationModel, setPaginationModel, loading, referralsList }: ReferralTableProps) => {
  const { sendReferrals } = useApi();
  const { user } = useAuthContext();
  const { resendReferralEnabled } = useFeatureFlags();

  const handleResend = async (referral: ReferralApiResponseItem) => {
    try {
      if (!user) {
        throw new Error("Usuário não encontrado");
      }

      await sendReferrals({
        companyId: user.companyId,
        emails: [referral.refereeEmail],
        resendReferral: true,
      });
    } catch (e) {
      logger.error("Error on handle resend referral button");
      Toast.error("Erro ao tentar reenviar o convite");
    }
  };

  const resendButtonEnable = (referral: ReferralApiResponseItem) => {
    return referral.refereeStatus === "PENDING" && resendReferralEnabled && !referral.resentAt;
  };

  return (
    <DataGrid
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={(paginationModel) => !loading && setPaginationModel(paginationModel)}
      rowSelection={false}
      getRowId={(row: ReferralApiResponseItem) => row.refereeEmail}
      filterMode="server"
      loading={loading}
      localeText={{
        ...ptBR.components.MuiDataGrid.defaultProps.localeText,
        noResultsOverlayLabel: "No result found !!!",
      }}
      pageSizeOptions={[5, 10, 25]}
      columns={[
        {
          field: "email",
          headerName: "E-mail",
          flex: 1,
          renderCell: ({ row }) => <Typography variant={"body"}>{row.refereeEmail}</Typography>,
          sortable: false,
          disableColumnMenu: true,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }) => (
            <>
              <Chip
                sx={{
                  bgcolor: referralStatusMap[row.refereeStatus].bgColor,
                  color: referralStatusMap[row.refereeStatus].color,
                  fontWeight: "normal",
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                {referralStatusMap[row.refereeStatus].label}
              </Chip>
              {resendButtonEnable(row) && <ResendButton handleResend={() => handleResend(row)} />}
            </>
          ),
          sortable: false,
          disableColumnMenu: true,
        },
      ]}
      rows={referralsList?.data || undefined}
      rowCount={referralsList?.totalResults || 0}
      hideFooterPagination={loading}
      sx={{
        "& .MuiDataGrid-row": {
          backgroundColor: "transparent",
        },
        "& .MuiDataGrid-main": {
          overflow: "hidden",
        },
        "& .MuiDataGrid-cell": {
          display: "flex",
          alignItems: "center",
        },
      }}
    ></DataGrid>
  );
};

interface ReferralStatusItem {
  label: string;
  bgColor: string;
  color: string;
}

const referralStatusMap: Record<string, ReferralStatusItem> = {
  PENDING: {
    label: "Pendente",
    bgColor: "common.shade",
    color: "common.white",
  },
  TESTER: {
    label: "Testando",
    bgColor: "common.yellow",
    color: "common.shade",
  },
  SUBSCRIBED: {
    label: "Assinante",
    bgColor: "success.main",
    color: "common.shade",
  },
};

const ResendButton = (props: { handleResend: () => Promise<void> }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleButton = async () => {
    setSuccess(false);
    setLoading(true);
    try {
      await props.handleResend();
    } catch (e) {
      logger.error("Error on handle resend referral button", e);
    }
    setLoading(false);
    setSuccess(true);
  };

  if (success) {
    return (
      <Typography
        variant="body3"
        sx={{
          ml: 2,
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <VerifiedOutlined />
        Convite reenviado!
      </Typography>
    );
  }

  if (loading) {
    return <Loading isLoading={true} size="MEDIUM" />;
  }

  return (
    <LinkButton sx={{ p: 0, ml: 2, textTransform: "none!important" }} onClick={handleButton}>
      Reenviar convite?
    </LinkButton>
  );
};

const ErrorMessage = (props: { errorMessage: string; handleRetry: () => void }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <HighlightOffOutlined color="error" />
        <Typography
          width="fit-content"
          variant="body2"
          component="span"
          color="common.shade"
          fontWeight="700"
          textTransform="uppercase"
        >
          {props.errorMessage}
        </Typography>
      </Box>
      <LinkButton
        capitalize
        onClick={props.handleRetry}
        sx={{
          width: "fit-content",
          p: 0,
          alignSelf: "center",
        }}
      >
        Tente novamente
      </LinkButton>
    </Box>
  );
};

const SuccessMessage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <VerifiedOutlined />
      <Typography
        width="fit-content"
        variant="body2"
        component="span"
        color="common.shade"
        fontWeight="700"
        textTransform="uppercase"
      >
        Seu convite foi enviado com sucesso!
      </Typography>
    </Box>
  );
};
