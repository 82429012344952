import { PaginationPayload } from "@/core/api/types";

export const getPaginationHeaders = (pagination?: PaginationPayload) => {
  if (!pagination) {
    return {};
  }

  return {
    "x-pagination-page": pagination.page,
    "x-pagination-page-size": pagination.pageSize,
  };
};
