import React from "react";

import {
  Edit,
  MoreHoriz as MoreHorizIcon,
  StarBorder as StarBorderIcon,
  Star as StarFillIcon,
} from "@mui/icons-material";
import { Box, CircularProgress, Divider, Input, Popover, Typography, useTheme } from "@mui/material";

import { useMessagesContext } from "@/contexts/MessagesContext";
import { ExitConfirmDialog } from "@/components/ExitConfirmDialog";
import { Loading } from "@/components/Loading";
import { ModalLeft } from "@/components/Modal/ModalLeft";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@/taskpane/components/core/Toast";
import { usePaginatedThreads } from "@/hooks/threads/usePaginatedThreads";
import { useDeleteThread } from "@/hooks/threads/useDeleteThread";
import { useRenameThread } from "@/hooks/threads/useRenameThread";
import { OrderThreadsBy, Thread } from "@/hooks/threads/types";
import { useToggleFavoriteThread } from "@/hooks/threads/useToggleFavoriteThread";
import { formatDateShortMonth } from "@/utils/formatDateShortMonth";
import { ThreadsScreen } from "@/components/ThreadsScreen";
import { ThreadsHeader } from "@/components/ThreadsHeader";
import { DeleteThreadModal } from "@/components/DeleteThreadModal";
import { ThreadOptionsMenu } from "@/components/ThreadOptionsMenu";
import { logger } from "@/core/logger";

const MAX_SCROLL_POSITION = 56;

export const Threads = ({ onClose, component }: { onClose: () => void; component: "MODAL" | "SCREEN" }) => {
  const navigate = useNavigate();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const [currentEditingEditingThreadName, setCurrentEditingThreadName] = React.useState("");
  const [currentEditingEditingThreadId, setCurrentEditingThreadId] = React.useState<string | null>(null);
  const [currentListOrder, setCurrentListOrder] = React.useState<OrderThreadsBy>("UPDATED_AT");
  const [newChatModalOpen, setNewChatModalOpen] = React.useState(false);
  const [openThreadsModalOpen, setOpenThreadsModalOpen] = React.useState(false);
  const [querySearch, setQuerySearch] = React.useState<string>("");
  const { waitingForResponse } = useMessagesContext();
  const shouldDisplayExitConfirmation = !!waitingForResponse;

  const {
    data: paginatedThreads,
    isLoading: isLoadingThreads,
    fetchNextPage,
    hasNextPage,
  } = usePaginatedThreads({
    search: querySearch,
    order: currentListOrder,
  });

  const { mutateAsync: deleteThread, isPending: isDeletingThread } = useDeleteThread();
  const { mutateAsync: renameThread } = useRenameThread();
  const { mutateAsync: toggleFavoriteThread } = useToggleFavoriteThread();

  const threads = paginatedThreads?.pages.flatMap((page) => page.data);

  const hasFullList = !hasNextPage;

  const fetchThreads = async () => {
    fetchNextPage();
  };

  const goToNewChat = async () => {
    onClose();
    navigate(ROUTE_PATHS.CHAT, { replace: true });
  };

  const handleOpenNewChatModal = () => {
    if (location.pathname.startsWith(ROUTE_PATHS.CHAT) && shouldDisplayExitConfirmation) {
      setNewChatModalOpen(true);
    } else {
      goToNewChat();
    }
  };

  const handleRenameThread = async (threadName: string) => {
    if (!currentEditingEditingThreadId) return WebToast.error("Erro ao renomear chat");

    try {
      await renameThread({ threadId: currentEditingEditingThreadId, name: threadName });
      WebToast.success("Sessão renomeada com sucesso");
    } catch (e) {
      logger.error("Error renaming thread", e);
      WebToast.error("Erro ao renomear Sessão");
    }
    handleCloseRenameModal();
  };

  const handleOpenRenameModal = (threadId: string, name: string) => {
    setCurrentEditingThreadName(name);
    setCurrentEditingThreadId(threadId);
  };

  const handleCloseRenameModal = () => {
    setCurrentEditingThreadId(null);
  };

  const handleDeleteThread = async () => {
    if (!currentEditingEditingThreadId) return WebToast.error("Erro ao excluir sessão");

    try {
      await deleteThread({ threadId: currentEditingEditingThreadId });
      WebToast.success("Sessão deletada com sucesso");
    } catch (e) {
      logger.error("Error deleting thread", e);
      WebToast.error("Erro ao deletar Sessão");
    }
    handleCloseDeleteModal();
  };

  const handleOpenDeleteModal = (threadId: string, name: string) => {
    setIsOpenDeleteModal(true);
    setCurrentEditingThreadName(name);
    setCurrentEditingThreadId(threadId);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setCurrentEditingThreadId(null);
  };

  const handleFavorite = async (threadId: string) => {
    setCurrentEditingThreadId(threadId);
    await toggleFavoriteThread({ threadId });
    setCurrentEditingThreadId(null);
  };

  const handleUpdateOrder = async (order: OrderThreadsBy) => {
    setCurrentListOrder(order);
  };

  const openThread = async (threadId: string) => {
    handleCloseThreadModal();
    onClose();
    navigate(ROUTE_PATHS.THREAD.replace(":threadId", threadId));
  };

  const handleOpenThreadModal = (threadId: string, name: string) => {
    setCurrentEditingThreadName(name);
    setCurrentEditingThreadId(threadId);
    if (location.pathname.startsWith(ROUTE_PATHS.CHAT) && shouldDisplayExitConfirmation) {
      setOpenThreadsModalOpen(true);
    } else {
      openThread(threadId);
    }
  };

  const handleCloseThreadModal = () => {
    setCurrentEditingThreadName("");
    setCurrentEditingThreadId(null);
    setOpenThreadsModalOpen(false);
  };

  const ThreadsComponent = component === "MODAL" ? ModalLeft : ThreadsScreen;
  const handleNewChat = component === "MODAL" ? handleOpenNewChatModal : goToNewChat;
  const listComponentId = component === "MODAL" ? "ThreadsList" : "ThreadsScreenList";

  return (
    <>
      <ThreadsComponent
        title="Histórico"
        header={
          <ThreadsHeader
            goToNewChat={handleNewChat}
            currentListOrder={currentListOrder}
            updateOrder={handleUpdateOrder}
            currentQuerySearch={querySearch}
            setCurrentQuerySearch={setQuerySearch}
          />
        }
        open
        onClose={onClose}
        onScroll={() => {
          const listComponent = document.getElementById(listComponentId);
          if (!listComponent) return;

          const scrollPosition = listComponent?.scrollHeight - listComponent?.offsetHeight - listComponent?.scrollTop;

          if (scrollPosition < MAX_SCROLL_POSITION) {
            fetchThreads();
          }
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: "16px",
          }}
        >
          <Loading isLoading={isLoadingThreads}>
            <Box
              sx={{
                paddingBottom: "16px",
              }}
            >
              {threads?.length ? (
                threads.map((thread, index) => {
                  return (
                    <Box key={index}>
                      <ThreadItem
                        thread={thread}
                        handleOpenRenameModal={handleOpenRenameModal}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                        handleRenameThread={handleRenameThread}
                        toggleFavorite={handleFavorite}
                        openThread={handleOpenThreadModal}
                        menuPosition={component === "MODAL" ? "left" : "right"}
                      />
                      <Divider />
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{ minHeight: 200, padding: "20px" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="multiLineBody" sx={{ textAlign: "center" }}>
                    {Boolean(querySearch)
                      ? "Nenhuma sessão encontrada com os termos buscados."
                      : "Você ainda não possui sessões salvas.\n Realize o primeiro uso do Assistente Lexter para criar sua primeira sessão."}
                  </Typography>
                </Box>
              )}
            </Box>
          </Loading>
          <Box
            sx={{
              display: isLoadingThreads ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "48px !important",
            }}
          >
            <Loading isLoading={isLoadingThreads}>
              {hasFullList ? <Typography variant="body2">Fim da lista</Typography> : <CircularProgress />}
            </Loading>
          </Box>
        </Box>
      </ThreadsComponent>
      <DeleteThreadModal
        isOpen={isOpenDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleDeleteThread}
        chatName={currentEditingEditingThreadName}
        isLoading={isDeletingThread}
      />
      <ExitConfirmDialog
        open={newChatModalOpen}
        title="Abrir nova sessão"
        action="NEW"
        onConfirm={goToNewChat}
        onCancel={() => setNewChatModalOpen(false)}
      />
      <ExitConfirmDialog
        open={openThreadsModalOpen}
        title={"Abrir sessão já existente"}
        chatName={currentEditingEditingThreadName}
        action="EXISTING"
        onConfirm={() => openThread(currentEditingEditingThreadId!)}
        onCancel={handleCloseThreadModal}
      />
    </>
  );
};

const ThreadItem = ({
  thread,
  handleOpenDeleteModal,
  handleOpenRenameModal,
  handleRenameThread,
  toggleFavorite,
  openThread,
  menuPosition,
}: {
  thread: Thread;
  handleOpenDeleteModal: (id: string, name: string) => void;
  handleOpenRenameModal: (id: string, name: string) => void;
  handleRenameThread: (name: string) => void;
  toggleFavorite: (id: string) => void;
  openThread: (id: string, name: string) => void;
  menuPosition: "left" | "right";
}) => {
  const { palette } = useTheme();

  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const [renameAnchorEl, setRenameAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newThreadName, setNewThreadName] = React.useState(thread.name);
  const isOptionsOpen = Boolean(optionsMenuAnchorEl);
  const isRenameOpen = Boolean(renameAnchorEl);

  const openOptionsMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  const closeOptionsMenu = () => {
    setOptionsMenuAnchorEl(null);
  };

  const closeRename = () => {
    setRenameAnchorEl(null);
  };

  const openDelete = () => {
    handleOpenDeleteModal(thread.id, thread.name);
    closeOptionsMenu();
  };

  const openRename = () => {
    const newRenameAnchorEl = document.getElementById(`thread-item-rename-el-${thread.id}`);
    setRenameAnchorEl(newRenameAnchorEl);
    handleOpenRenameModal(thread.id, thread.name);
    closeOptionsMenu();
  };

  const renameThread = () => {
    if (newThreadName === thread.name) {
      closeRename();
      return;
    }
    handleRenameThread(newThreadName);
    closeRename();
  };

  const handleFavorite = () => {
    toggleFavorite(thread.id);
  };

  const handleOpenThread = () => {
    if (isRenameOpen) return;
    openThread(thread.id, thread.name);
  };

  const formattedDate = formatDateShortMonth(thread.lastEventDate ?? thread.createdAt);

  const FavoriteIcon = () => {
    if (thread.favorite) {
      return <StarFillIcon onClick={handleFavorite} sx={{ cursor: "pointer", fill: palette.common?.yellow }} />;
    }
    return <StarBorderIcon onClick={handleFavorite} sx={{ cursor: "pointer" }} />;
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        ":hover": {
          backgroundColor: "common.softBlue",
        },
        "& .hidden-button": {
          visibility: "hidden",
        },
        "&:hover .hidden-button": {
          visibility: "visible",
        },
      }}
    >
      <Box
        onClick={handleOpenThread}
        sx={{
          padding: "24px 0px 24px 24px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "calc(100% - 48px)",
        }}
      >
        <Box sx={{ height: "48px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Typography id={`thread-item-rename-el-${thread.id}`} variant="title">
            {thread.name}
          </Typography>
          <Popover
            open={isRenameOpen}
            onClose={() => {
              renameThread();
            }}
            anchorEl={renameAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              top: "-8px !important",
              "& .MuiPaper-root": {
                boxShadow: "none",
                borderRadius: "0",
              },
              "& .MuiModal-backdrop": {
                backgroundColor: "transparent",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Input
              sx={{
                width: "332px",
                padding: "0",
                backgroundColor: "common.softBlue",
              }}
              onChange={(e) => {
                setNewThreadName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  renameThread();
                }
              }}
              value={newThreadName}
              endAdornment={<Edit fontSize="small" />}
            />
          </Popover>
          <Typography sx={{ justifySelf: "baseline" }} variant="body2">
            <strong>ÚLTIMA ATUALIZAÇÃO:</strong> {formattedDate}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "24px 16px 24px 0px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MoreHorizIcon
          sx={{
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={openOptionsMenu}
          className="hidden-button"
        />
        <FavoriteIcon />
      </Box>
      <ThreadOptionsMenu
        open={isOptionsOpen}
        anchorEl={optionsMenuAnchorEl!}
        onClose={closeOptionsMenu}
        openDelete={openDelete}
        openRename={openRename}
        openThread={handleOpenThread}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuPosition,
        }}
        sx={{ left: menuPosition === "left" ? "24px !important" : undefined }}
      />
    </Box>
  );
};
