import { FavoriteSkill } from "@/hooks/skills/types";
import { FAVORITE_SKILLS_QUERY_KEY } from "@/hooks/skills/useFavoriteSkills";
import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteFavoriteSkill = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["deleteFavoriteSkill"],
    mutationFn: ({ skillId }: { skillId: string }) => api.deleteFavoriteSkill({ skillId }),
    onMutate: async ({ skillId }) => {
      await queryClient.cancelQueries({ queryKey: [FAVORITE_SKILLS_QUERY_KEY] });
      const previousFavoriteSkills = queryClient.getQueryData<string[]>([FAVORITE_SKILLS_QUERY_KEY]);
      queryClient.setQueryData([FAVORITE_SKILLS_QUERY_KEY], (old) => [
        ...(old as FavoriteSkill[]).filter((favoriteSkill) => favoriteSkill.skillId !== skillId),
      ]);
      return { previousFavoriteSkills };
    },
    onError: (_error, _newFavoriteSkill, context) => {
      queryClient.setQueryData([FAVORITE_SKILLS_QUERY_KEY], context?.previousFavoriteSkills);
    },
  });
};
