import { RecordType, ThreadRecord } from "../types";
import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getRecordsQueryKey } from "../useRecords";
import { Paginated } from "@/core/api/types";

export const useDeleteRecord = ({ threadId }: { threadId?: string }) => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["thread", threadId, "deleteRecord"],
    mutationFn: async (record: ThreadRecord) => {
      switch (record.type) {
        case RecordType.PRECEDENT:
          api.deletePrecedent({ id: record.id });
          break;
        case RecordType.DOCUMENT:
          api.archiveDocument(record.id);
          break;
      }
    },
    onMutate: async (record: ThreadRecord) => {
      await queryClient.invalidateQueries({ queryKey: getRecordsQueryKey({ threadId }) });
      const previousRecordsTuple = queryClient.getQueriesData({ queryKey: getRecordsQueryKey({ threadId }) });

      for (const [queryKey] of previousRecordsTuple) {
        queryClient.setQueryData(queryKey, (old: Paginated<ThreadRecord>) => {
          return {
            ...old,
            data: old.data.filter((r) => r.id !== record.id),
          };
        });
      }
      return { previousRecordsTuple };
    },
    onError: (_error, _newFavoriteSkill, context) => {
      context?.previousRecordsTuple.forEach(([queryKey, queryData]) => {
        queryClient.setQueryData(queryKey, queryData);
      });
    },
  });
};
