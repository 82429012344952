import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { useEffect, useMemo, useState } from "react";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { WebEditor } from "./WebEditor";
import { WordEditor } from "./WordEditor";
import { Editor } from "./types";
import { logger } from "@/core/logger";

export const useEditor = () => {
  const {
    editor: webEditor,
    currentTabIsEmpty: webEditorCurrentTabIsEmpty,
    allTabsAreEmpty: webEditorAllTabsAreEmpty,
    currentTabContent: webEditorCurrentTabContent,
  } = useWebEditorContext();
  const [officeContent, setOfficeContent] = useState<string>();
  const [officeIsEmpty, setOfficeIsEmpty] = useState(true);

  const currentTabContent = useMemo(() => {
    if (outsideOfficeClient()) {
      return webEditorCurrentTabContent;
    }
    return officeContent;
  }, [webEditorCurrentTabContent, officeContent]);

  const currentTabIsEmpty = useMemo(() => {
    if (outsideOfficeClient()) {
      return webEditorCurrentTabIsEmpty;
    }
    return officeIsEmpty;
  }, [webEditorCurrentTabIsEmpty, officeIsEmpty]);

  const allTabsAreEmpty = useMemo(() => {
    if (outsideOfficeClient()) {
      return webEditorAllTabsAreEmpty;
    }
    return officeIsEmpty;
  }, [webEditorAllTabsAreEmpty, officeIsEmpty]);

  const editor: Editor | undefined = useMemo(() => {
    if (outsideOfficeClient()) {
      if (!webEditor) {
        return undefined;
      }
      return new WebEditor(webEditor);
    }
    return new WordEditor();
  }, [outsideOfficeClient, webEditor]);

  useEffect(() => {
    if (outsideOfficeClient()) return;

    Office.context.document?.addHandlerAsync(Office.EventType.DocumentSelectionChanged, checkIfDocumentIsEmpty);
    checkIfDocumentIsEmpty();
  }, []);

  const checkIfDocumentIsEmpty = async () => {
    try {
      await Word.run(async (context) => {
        const body = context.document.body;
        body.load("text");

        await context.sync();
        setOfficeContent(body.text.trim());
        setOfficeIsEmpty(body.text.trim().length === 0);
      });
    } catch (error) {
      logger.error("Error checking if document is empty: ", error);
    }
  };

  return {
    editor,
    currentTabContent,
    currentTabIsEmpty,
    allTabsAreEmpty,
  };
};
