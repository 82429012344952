import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Typography } from "@mui/material";

interface ConfirmCancelModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  cancelModalDescription: string;
}

export const ConfirmCancelModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isLoading,
  cancelModalDescription,
}: ConfirmCancelModalProps) => {
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title="Deseja cancelar?"
      description={
        <Box>
          <Typography>{cancelModalDescription}</Typography>
          {isLoading && (
            <Box display="flex" color="secondary" justifyContent="center" mt={1}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      descriptionTextAlign="left"
      buttonsDirection="column"
      buttons={[
        {
          label: "SIM, CANCELAR",
          onClick: onConfirm,
          color: "error",
          disabled: isLoading,
        },
        {
          label: "NÃO, CONTINUAR",
          onClick: onCancel,
          sx: {
            color: "text.primary",
            backgroundColor: "transparent",
            textDecoration: "underline",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
      ]}
      variant="error"
    />
  );
};
