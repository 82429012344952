import { applyMaskFunctionFactory } from "./applyMaskFunctionFactory";

const phoneMask = (phone: string) => {
  if (!phone) return "";
  return phone
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");
};

export const applyPhoneMask = applyMaskFunctionFactory(phoneMask);
