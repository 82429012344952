import { FieldProps, FormContextType, RJSFSchema, StrictRJSFSchema } from "@rjsf/utils";
import React, { useEffect } from "react";
import { useMessagesContext } from "@/contexts/MessagesContext";
import { Box, Typography } from "@mui/material";

const MAX_CHARACTERS = 10000;
const ERROR_MESSAGE = "O trecho selecionado é muito longo. Selecione no máximo 10 mil caracteres.";

export const SelectTextField = <
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(
  props: FieldProps<T, S, F>
) => {
  const { onChange, label } = props;

  const { inputContext } = useMessagesContext();

  const isTextLengthValid = (text: string) => text.length <= MAX_CHARACTERS;

  const textToDisplay = !isTextLengthValid(inputContext) ? ERROR_MESSAGE : inputContext;

  useEffect(() => {
    if (isTextLengthValid(inputContext)) {
      onChange(inputContext as T);
    } else {
      onChange(undefined as T);
    }
  }, [inputContext, onChange]);

  return (
    <Box>
      {label && (
        <Typography variant="subtitle1" sx={{ textWrap: "wrap" }}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          backgroundColor: "common.lightShade",
          mt: 3,
          p: 1,
          borderRadius: 1,
          minHeight: "38px",
          maxHeight: "150px",
          flexGrow: 1,
          overflowY: "auto",
          px: 2,
        }}
      >
        <Typography variant="multiLineBody" color={!isTextLengthValid(inputContext) ? "error.main" : "text.secondary"}>
          {textToDisplay}
        </Typography>
      </Box>
    </Box>
  );
};
