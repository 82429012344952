import { ActionId } from "@/contexts/MessagesContext";
import { UploadingFile } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";

const inputSchema = Type.Object({
  client: Type.String(),
  case_breakdown: Type.String(),
});

const actionId = ActionId.CREATE_INTERVIEW_SCRIPT_ACTION;

export const interviewScriptSkill: Skill<typeof inputSchema> = {
  id: "create_interview_script",
  name: "Elaborar roteiro de entrevista",
  credits: CreditPerSkill.ESSENTIAL_SKILL,
  inputSchema,
  tags: ["litigation", "consultative"],
  actionId,
  type: "message",
  description: "Crie roteiros de entrevista jurídica em instantes e entenda melhor seus clientes.",
  getMessage: (input) => {
    const caseBreakdownFile = input.case_breakdown?.startsWith("data:")
      ? fileToUploadingFile(base64ToFile(input.case_breakdown))
      : undefined;

    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar roteiro de entrevista",
      actions: [
        {
          id: ActionId.CREATE_INTERVIEW_SCRIPT_ACTION,
          client: input.client,
          case_breakdown: caseBreakdownFile
            ? {
                source: "FILE",
                file: caseBreakdownFile,
              }
            : {
                source: "TEXT",
                text: input.case_breakdown,
              },
        },
      ],
      files: [caseBreakdownFile].filter((file): file is UploadingFile => Boolean(file)),
    };
  },
};
