import { DateTime } from "luxon";

export interface Court {
  code: string;
}

export interface CourtGroup {
  name: string;
  courts: Court[];
}

export const isCourtGroup = (court: Court | CourtGroup): court is CourtGroup => {
  return (court as Court).code === undefined;
};

export enum PeriodValue {
  ANY = "ANY",
  LAST_YEAR = "LAST_YEAR",
  LAST_2_YEARS = "LAST_2_YEARS",
  LAST_3_YEARS = "LAST_3_YEARS",
  LAST_5_YEARS = "LAST_5_YEARS",
}

export interface FiltersState {
  courts?: Court[];
  minDate?: DateTime;
}
