import { RecordType } from "../records/types";

export interface LexZapUser {
  userId: string;
  companyId: string;
  email: string;
  name: string;
  phoneNumber: string;
  notificationNumber?: string;
  isActive?: boolean;
  apiKey: string;
  createdAt: number;
}

export enum CaseStep {
  CASE_STARTED = "CASE_STARTED",
  SUMMARY_GENERATED = "SUMMARY_GENERATED",
  AWAITING_APPLICANT_APPROVAL = "AWAITING_APPLICANT_APPROVAL",
  CASE_FAILED = "CASE_FAILED",
  LAWYER_INVOKED_BY_APPLICANT = "LAWYER_INVOKED_BY_APPLICANT",
}
export interface Case {
  id: string;
  summary: string;
  step: CaseStep;
  status: "ACTIVE" | "ARCHIVED";
  userId: string;
  companyId: string;
  chatId?: string;
  threadId: string;
  name: string;
  lastMessage?: CaseMessage;
  applicantName: string;
  applicantPictureUrl?: string;
  partyIdentification?: string;
  facts?: string;
  legalQuestions?: string;
  recommendations?: string;
  documentationAndEvidence?: string;
  conclusion?: string;
  timeline: CaseTimeline[];
  applicantId: string;
  lastModifiedAt?: string;
  createdAt: string;

  // campos para os mocks
  description?: string;
  nextSteps?: Array<{
    completed: boolean;
    description: string;
  }>;
  caseFiles?: Array<{
    createdAt: number;
    name: string;
  }>;
}

export interface CaseWithChat extends Case {
  lastMessage: CaseMessage;
  chatId: string;
}

export interface CaseTimeline {
  id: number;
  createdAt: string;
  status:
    | "CASE_STARTED"
    | "SUMMARY_GENERATED"
    | "AWAITING_APPLICANT_APPROVAL"
    | "CASE_FAILED"
    | "LAWYER_INVOKED_BY_APPLICANT"
    | "MANUALLY_CREATED";
}

export enum CaseMessageType {
  TEXT = "text",
  AUDIO = "audio",
  IMAGE = "image",
  DOCUMENT = "document",
}

export interface CaseMessage {
  chatId: string;
  from: string;
  lawyerId: string;
  fromLawyer: boolean;
  id: string;
  to: string;
  body: string;
  type: CaseMessageType;
  isForwarded: boolean;
  timestamp: number;
  media?: {
    filename: string;
    mimeType: string;
    url: string;
    error?: boolean;
  };
  transcription?: string;
  applicantName?: string;
  statusUpdateTimestamp?: Partial<Record<MessageStatus, number>>;

  isSending?: boolean;
}

export enum MessageStatus {
  Sent = "sent",
  Delivered = "delivered",
  Read = "read",
}

export interface CaseMessageWithMedia extends CaseMessage {
  media: {
    filename: string;
    mimeType: string;
    url: string;
    error?: boolean;
  };
}

export interface CaseMessageToSend {
  body: string;
}

export interface ConnectionStatusBody {
  phoneNumber?: string;
}

export enum ConnectionStatus {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}

export type CaseFileType =
  | {
      type: "RECORD";
      name: string;
      createdAt: number;
      recordType: RecordType;
      id: number;
    }
  | {
      type: "MEDIA";
      name: string;
      url: string;
      mimeType: string;
      createdAt: number;
      id: string;
    };
