import { Case, CaseMessage } from "@/hooks/lexZap/types";

export type WhatsappUpdate = MessageReceivedUpdate | MessageChangedUpdate | CaseUpdate;

export enum WhatsappUpdateType {
  MESSAGE_RECEIVED = "MESSAGE_RECEIVED",
  MESSAGE_CHANGED = "MESSAGE_CHANGED",
  CASE_UPDATE = "CASE_UPDATE",
}

interface WhatsappUpdateBase {
  userId: string;
  type: WhatsappUpdateType;
}

interface MessageReceivedUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.MESSAGE_RECEIVED;
  message: CaseMessage;
}

interface MessageChangedUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.MESSAGE_CHANGED;
  message: CaseMessage;
}

interface CaseUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.CASE_UPDATE;
  caseData: Case;
}
