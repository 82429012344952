import { Box, Typography } from "@mui/material";
import React from "react";

export const HeaderWelcome = () => (
  <Box sx={{ flexGrow: 1 }}>
    <Typography variant="body1" sx={{ fontSize: "14px" }}>
      Bem-vindo ao Assistente Lexter
    </Typography>
  </Box>
);
