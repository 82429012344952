import { env } from "@/constants/environment";
import {
  AcceptTermsParams,
  AddActionOfInterestPayload,
  CancelExecutionPayload,
  CheckCreditsParams,
  CheckCreditsResponse,
  CreditsApiResponse,
  CreditsHistoryApiResponseItem,
  DeletePrecedentPayload,
  GetDocumentResponseType,
  GetMessagesByThreadPayload,
  GetMessagesByThreadResponse,
  GetRecordsPayload,
  GetThreadsPayload,
  GetThreadsResponse,
  PrecedentPresignedUrlPayload,
  PrecedentPresignedUrlResponse,
  RenamePrecedentPayload,
  HandlePaymentPayload,
  SavePrecedentPayload,
  UserProfile,
  CheckCouponPayload,
  GetPaymentUserPortalPayload,
  RestorePlanPayload,
  SendReferralsPayload,
  ListReferralsPayload,
  ReferralApiResponseItem,
  GetLexZapMessagesParams,
  SendLexZapMessageParams,
  GetLexZapUsersPayload,
  SetupLexZapUserParams,
  UpdateLexZapUserParams,
} from "./types";
import { getPaginationHeaders } from "@/core/api/utils/getPaginationHeaders";
import { authenticatedCopilotServer, authenticatedLexterApi } from "@/core/api";
import { Evaluation, SkillsPayload, UserInputDTO } from "@/contexts/WebSocketContext";
import { Notification } from "@/contexts/NotificationsContext";
import { FlowMessageAction } from "@/contexts/MessagesContext";
import axios from "axios";
import { logger } from "@/core/logger";
import { ThreadRecord } from "../records/types";
import { Thread } from "@/hooks/threads/types";
import { Case, CaseMessage, ConnectionStatusBody, LexZapUser } from "../lexZap/types";
import { Paginated } from "@/core/api/types";
import { Applicant } from "../applicants/types";

export const useApi = () => {
  const getCredits = async () => {
    const response = await authenticatedCopilotServer.get<CreditsApiResponse>("/credits");
    return response.data;
  };

  const checkCredits = async ({ action }: CheckCreditsParams) => {
    const response = await authenticatedCopilotServer.post<CheckCreditsResponse>("/credits/check", { action });
    return response.data;
  };

  const getCreditsHistory = async (): Promise<CreditsHistoryApiResponseItem[]> => {
    const response = await authenticatedCopilotServer.get<CreditsHistoryApiResponseItem[]>("/credits/history");
    return response.data;
  };

  const acceptTerms = async ({ version }: AcceptTermsParams): Promise<void> => {
    await authenticatedCopilotServer.put("/acceptTerms", { version });
  };

  const getSignedUrl = async ({ name }: { name: string }): Promise<{ url: string; location: string }> => {
    const response = await authenticatedCopilotServer.post(`${env.API}/url`, { name });
    return response.data;
  };

  const createDocument = async ({ name, location }: { name: string; location: string }): Promise<string> => {
    const response = await authenticatedCopilotServer.post(`${env.API}/files`, { name, location });
    return response.data.toString();
  };

  const addActionOfInterest = async ({ userId, companyId, token, ...payload }: AddActionOfInterestPayload) => {
    const customHeader = token ? { Authorization: `bearer ${token}` } : {};

    await authenticatedLexterApi.put(
      `/users/${userId}/actionsOfInterest`,
      {
        ...payload,
      },
      {
        headers: { ...customHeader, "x-company-id": companyId },
      }
    );
  };

  const handlePayment = async ({ ...payload }: HandlePaymentPayload) => {
    const response = await authenticatedLexterApi.post(
      `/payments`,
      {
        ...payload,
      },
      {
        headers: { "x-company-id": payload.companyId },
      }
    );

    return response.data;
  };

  const sendReferrals = async ({
    companyId,
    emails,
    resendReferral,
  }: SendReferralsPayload): Promise<{ id: string }[]> => {
    const response = await authenticatedLexterApi.post(
      `/referrals`,
      {
        emails,
        resendReferral,
      },
      {
        headers: { "x-company-id": companyId },
      }
    );

    return response.data;
  };

  const listReferrals = async ({
    companyId,
    pagination,
  }: ListReferralsPayload): Promise<Paginated<ReferralApiResponseItem>> => {
    const response = await authenticatedLexterApi.get(`/referrals`, {
      headers: { "x-company-id": companyId, ...getPaginationHeaders(pagination) },
    });

    return {
      data: response.data,
      totalResults: Number(response.headers["x-pagination-total-results"]),
      pageSize: Number(response.headers["x-pagination-page-size"]),
      page: Number(response.headers["x-pagination-page"]),
    };
  };

  const checkCoupon = async ({ couponCode, planName, companyId }: CheckCouponPayload) => {
    const response = await authenticatedLexterApi.get(`/payments/coupons/${couponCode}?planName=${planName}`, {
      headers: { "x-company-id": companyId },
    });
    return response.data;
  };

  const restorePlan = async ({ companyId, couponCode }: RestorePlanPayload) => {
    const response = await authenticatedLexterApi.put(
      `/companiesPlan/${companyId}/restore`,
      {
        couponCode,
      },
      { headers: { "x-company-id": companyId } }
    );
    return response.data;
  };

  const getPaymentUserPortal = async ({ companyId }: GetPaymentUserPortalPayload) => {
    const response = await authenticatedLexterApi.get(`/payments/userPortal/${companyId}`, {
      headers: { "x-company-id": companyId },
    });
    return response.data;
  };

  const updateUserProfile = async (userProfile: UserProfile) => {
    const response = await authenticatedLexterApi.put(`/users/${userProfile.userId}`, userProfile);
    return response.data;
  };

  const getDocumentById = async (documentId: number) => {
    const response = await authenticatedCopilotServer.get<GetDocumentResponseType>(`/documents/${documentId}/content`);
    return response.data;
  };

  const saveEditedDocument = async (documentId: number, content: string) => {
    await authenticatedCopilotServer.post(`/documents/${documentId}/content`, { content });
  };

  const getRecords = async (props?: GetRecordsPayload) => {
    const { sortBy = "date", sortDirection = "desc", threadId, pagination, search } = props || {};

    const params = new URLSearchParams({
      sortDirection: sortDirection,
      sortBy: sortBy,
    });

    if (search) {
      params.append("search", search);
    }

    const query = params.toString();

    const url = `/threads/${threadId}/records?${query}`;

    try {
      const response = await authenticatedCopilotServer.get<Paginated<ThreadRecord>>(url, {
        headers: {
          ...getPaginationHeaders(pagination),
        },
      });
      return response.data;
    } catch (e) {
      logger.error("Error fetching documents", e);
    }

    return undefined;
  };

  const updateDocumentName = async ({ documentId, documentName }: { documentId: number; documentName: string }) => {
    await authenticatedCopilotServer.put<GetDocumentResponseType>(`/documents/${documentId}/name`, {
      name: documentName,
    });
  };

  const archiveDocument = async (documentId: number) => {
    await authenticatedCopilotServer.post(`/documents/${documentId}/archive`);
  };

  const removeDocumentThread = async (documentId: number) => {
    await authenticatedCopilotServer.delete(`/documents/${documentId}/thread`);
  };

  const saveNewDocument = async ({ name, content, threadId }: { name: string; content: string; threadId: string }) => {
    const response = await authenticatedCopilotServer.post<{ id: number }>(`/documents`, { name, content, threadId });
    return { id: response.data.id };
  };

  const saveOpenDocument = async ({ threadId, documentId }: { documentId: number; threadId: string }) => {
    await authenticatedCopilotServer.put(`/threads/${threadId}/documents/open/${documentId}`);
  };

  const deleteOpenDocument = async ({ threadId, documentId }: { documentId: number; threadId: string }) => {
    await authenticatedCopilotServer.delete(`/threads/${threadId}/documents/open/${documentId}`);
  };

  const getThreads = async (props?: GetThreadsPayload) => {
    const { sortBy = "lastEventDate", sortDirection = "desc", search, pagination } = props || {};
    let query = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    const url = `/threads?${query}`;
    const response = await authenticatedCopilotServer.get<GetThreadsResponse>(url, {
      headers: {
        ...getPaginationHeaders(pagination),
      },
    });
    return {
      data: response.data.threads,
      totalResults: response.data.total,
      pageSize: pagination?.pageSize ?? response.data.threads.length,
      page: pagination?.page ?? 1,
    } as Paginated<Thread>;
  };

  const toggleFavoriteThread = async (threadId: string) => {
    const response = await authenticatedCopilotServer.put(`/threads/${threadId}/favorite`);
    return response.data;
  };

  const savePrecedent = async (payload: SavePrecedentPayload) => {
    await authenticatedCopilotServer.post("/precedents", payload);
  };

  const getPrecedentPresignedUrl = async ({ id }: PrecedentPresignedUrlPayload) => {
    const response = await authenticatedCopilotServer.get<PrecedentPresignedUrlResponse>(
      `/precedents/${id}/presignedUrl`
    );
    return response.data;
  };

  const renamePrecedent = async ({ id, name }: RenamePrecedentPayload) => {
    await authenticatedCopilotServer.put(`/precedents/${id}/name`, { name });
  };

  const deletePrecedent = async ({ id }: DeletePrecedentPayload) => {
    await authenticatedCopilotServer.delete(`/precedents/${id}`);
  };

  const addFavoriteSkill = async ({ skillId }: { skillId: string }) => {
    try {
      const response = await authenticatedCopilotServer.put(`/skills/favorites/${skillId}`);
      return response.data;
    } catch (e) {
      logger.error("addFavoriteSkill", e);
      throw e;
    }
  };

  const deleteFavoriteSkill = async ({ skillId }: { skillId: string }) => {
    try {
      const response = await authenticatedCopilotServer.delete(`/skills/favorites/${skillId}`);
      return response.data;
    } catch (e) {
      logger.error("deleteFavoriteSkill", e);
      throw e;
    }
  };

  const getFavoriteSkills = async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/skills/favorites`);
      return response.data;
    } catch (e) {
      logger.error("getFavoriteSkills", e);
      throw e;
    }
  };

  const getMessages = async ({ threadId, pagination }: GetMessagesByThreadPayload) => {
    const response = await authenticatedCopilotServer.get<GetMessagesByThreadResponse>(
      `/threads/${threadId}/messages`,
      {
        headers: {
          ...getPaginationHeaders(pagination),
        },
      }
    );
    return {
      data: response.data.data,
      totalResults: response.data.totalResults,
    };
  };

  const getNotifications = async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/notifications`);
      return response.data;
    } catch (e) {
      logger.error("getNotifications", e);
      throw e;
    }
  };

  const getNotificationById = async (id: number) => {
    try {
      const response = await authenticatedCopilotServer.get<Notification>(`/notifications/${id}`);
      return response.data;
    } catch (e) {
      logger.error("getNotificationById", e);
      throw e;
    }
  };

  const setNotificationOpen = async (id: number) => {
    try {
      const response = await authenticatedCopilotServer.put(`/notifications/visualize/${id}`);
      return response.data;
    } catch (e) {
      logger.error("setNotificationOpen", e);
      throw e;
    }
  };

  const evaluateMessage = async ({ messageId, evaluation }: { messageId: string; evaluation?: Evaluation }) => {
    const response = await authenticatedCopilotServer.post(`/messages/${messageId}/evaluate`, {
      evaluation: evaluation ?? null,
    });
    return response.data;
  };

  const generateThreadName = async ({
    threadId,
    data,
  }: {
    threadId: string;
    data: FlowMessageAction | SkillsPayload<UserInputDTO>;
  }) => {
    const response = await authenticatedCopilotServer.post<{ name: string }>(`/threads/${threadId}/name/generate`, {
      data,
    });
    return response.data;
  };

  const getLexZapUserStatus = async () => {
    const response = await authenticatedCopilotServer.get<ConnectionStatusBody>(`/whatsapp-assistant/connection`);
    return response.data;
  };

  const getLexZapUsers = async ({ pagination }: GetLexZapUsersPayload) => {
    const response = await authenticatedCopilotServer.get<Paginated<LexZapUser>>("whatsapp-assistant/users", {
      headers: getPaginationHeaders(pagination),
    });
    return response.data;
  };

  const setupLexZapUser = async (params: SetupLexZapUserParams) => {
    await authenticatedCopilotServer.post("whatsapp-assistant/users", params);
  };

  const getLexZapUserById = async (userId: string) => {
    const response = await authenticatedCopilotServer.get<LexZapUser>(`whatsapp-assistant/users/${userId}`);
    return response.data;
  };

  const updateLexZapUser = async ({ userId, updates }: UpdateLexZapUserParams) => {
    await authenticatedCopilotServer.put(`whatsapp-assistant/users/${userId}`, {
      ...updates,
      apiKey: updates.apiKey || undefined,
    });
  };

  const deleteLexZapUser = async (userId: string) => {
    await authenticatedCopilotServer.delete(`whatsapp-assistant/users/${userId}`);
  };

  const getLexZapCases = async () => {
    const response = await authenticatedCopilotServer.get<Case[]>(`/cases`);
    return response.data;
  };

  const getLexZapMessages = async ({ chatId }: GetLexZapMessagesParams) => {
    const response = await authenticatedCopilotServer.get<{ messages: CaseMessage[] }>(
      `/cases/chats/${chatId}/messages`
    );
    return response.data;
  };

  const sendLexZapMessage = async ({ chatId, message }: SendLexZapMessageParams) => {
    const response = await authenticatedCopilotServer.post<{
      message: {
        id: string;
        text: string;
      };
    }>(`/cases/chats/${chatId}/messages`, message);
    return response.data;
  };

  const archiveCase = async ({ caseId }: { caseId: string }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}/archive`);
    } catch (e) {
      logger.error("archiveCase", e);
      throw e;
    }
  };

  const unarchiveCase = async ({ caseId }: { caseId: string }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}/unarchive`);
    } catch (e) {
      logger.error("unarchiveCase", e);
      throw e;
    }
  };

  const deleteCase = async ({ caseId }: { caseId: string }) => {
    try {
      await authenticatedCopilotServer.delete(`/cases/${caseId}`);
    } catch (e) {
      logger.error("deleteCase", e);
      throw e;
    }
  };

  const createCase = async ({ name, applicantId }: { name: string; applicantId: string }) => {
    try {
      const response = await authenticatedCopilotServer.post(`/cases`, { name, applicantId });
      return response.data;
    } catch (e) {
      logger.error("createCase", e);
      throw e;
    }
  };

  const updateCase = async ({
    caseId,
    data,
  }: {
    caseId: string;
    data: {
      name: string;
      applicantId: string;
    };
  }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}`, data);
    } catch (e) {
      logger.error("updateCase", e);
      throw e;
    }
  };

  const getApplicants = async () => {
    try {
      const response = await authenticatedCopilotServer.get<Applicant[]>(`/applicants`);
      return response.data;
    } catch (e) {
      logger.error("getApplicants", e);
      throw e;
    }
  };

  const updateApplicant = async ({
    applicantId,
    data,
  }: {
    applicantId: string;
    data: {
      name: string;
      city: string | null;
      cpf: string | null;
      email: string | null;
      houseNumber: string | null;
      maritalStatus: string | null;
      occupation: string | null;
      state: string | null;
      streetName: string | null;
      zipCode: string | null;
    };
  }) => {
    try {
      await authenticatedCopilotServer.post(`/applicants/${applicantId}`, data);
    } catch (e) {
      logger.error("updateApplicant", e);
      throw e;
    }
  };

  const cancelExecution = async ({ executionId }: CancelExecutionPayload) => {
    logger.info("Canceling execution", { executionId });
    try {
      logger.info("Canceling execution2");
      const { status, data } = await authenticatedCopilotServer.delete(`/executions/${executionId}`);
      logger.info("Canceling execution response", { status, data });
      return { status };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        logger.error("Error canceling execution", error.response);
        return {
          status: error.response.status,
          message: error.response.data.message || "Erro ao cancelar a execução",
        };
      }
      throw error;
    }
  };

  return {
    getCredits,
    checkCredits,
    getCreditsHistory,
    acceptTerms,
    getSignedUrl,
    createDocument,
    cancelExecution,
    updateUserProfile,
    getDocumentById,
    saveEditedDocument,
    getRecords,
    updateDocumentName,
    archiveDocument,
    saveNewDocument,
    getThreads,
    toggleFavoriteThread,
    saveOpenDocument,
    deleteOpenDocument,
    addActionOfInterest,
    savePrecedent,
    getPrecedentPresignedUrl,
    renamePrecedent,
    deletePrecedent,
    addFavoriteSkill,
    getFavoriteSkills,
    deleteFavoriteSkill,
    handlePayment,
    checkCoupon,
    getPaymentUserPortal,
    getMessages,
    getNotifications,
    setNotificationOpen,
    evaluateMessage,
    getNotificationById,
    generateThreadName,
    restorePlan,
    sendReferrals,
    listReferrals,
    getLexZapUsers,
    setupLexZapUser,
    getLexZapUserById,
    updateLexZapUser,
    deleteLexZapUser,
    getLexZapUserStatus,
    getLexZapCases,
    getLexZapMessages,
    sendLexZapMessage,
    archiveCase,
    deleteCase,
    unarchiveCase,
    updateCase,
    createCase,
    getApplicants,
    updateApplicant,
    removeDocumentThread,
  };
};
