import { Box, Typography } from "@mui/material";
import { MultiSelectChip, MultiSelectChipItem } from "@/components/MultiSelectChip";
import React from "react";

interface UserActivityAreaSelectProps {
  activityAreas: MultiSelectChipItem[];
  onActivityAreasChange: (options: MultiSelectChipItem[]) => void;
  show: boolean;
}

export const UserActivityAreaSelect = ({ activityAreas, onActivityAreasChange, show }: UserActivityAreaSelectProps) => {
  if (!show) return null;

  return (
    <Box>
      <Typography sx={{ color: "common.shade", mb: 2, fontWeight: "700" }} variant="subtitle2">
        Quais áreas jurídicas que mais atua? Selecione até 5 opções da lista abaixo:
      </Typography>
      <MultiSelectChip max={5} options={activityAreas} onMultiSelectChipChange={onActivityAreasChange} />
    </Box>
  );
};
