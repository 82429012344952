import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface MessageCardProps {
  children: ReactNode;
  fromMe: boolean;
  timestamp: number;
  bottomContent?: ReactNode;
}

export const MessageCard = ({ children, fromMe, bottomContent }: MessageCardProps) => {
  return (
    <Box
      sx={{
        maxWidth: "65%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "16px",
          borderBottomRightRadius: fromMe ? 0 : "16px",
          backgroundColor: fromMe ? "common.lightWarm" : "common.white",
          border: fromMe ? undefined : "1px solid",
          borderColor: fromMe ? undefined : "common.lightWarm",
          paddingX: 3,
          paddingY: 2,
        }}
      >
        {children}
      </Box>

      {bottomContent}
    </Box>
  );
};
