/**
 * Represents a transaction that can fail
 * @typeParam T is the type that will be contained inside the result
 */
export class Result<T = unknown> {
  public isSuccess: boolean;
  public isFailure: boolean;
  private error: string;
  private _value: T;

  private constructor(isSuccess: boolean, error?: string, value?: T) {
    this.isSuccess = isSuccess;
    this.isFailure = !isSuccess;
    this.error = error || "";
    this._value = value as T;

    Object.freeze(this);
  }

  public getValue(): T {
    if (!this.isSuccess) {
      throw new Error("Can't get the value of an error result.");
    }

    return this._value;
  }

  public getError(): string {
    if (!this.isFailure) {
      throw new Error("Can't get the error of a successful result.");
    }
    return this.error;
  }

  public static void(): Result<void> {
    return new Result<void>(true, undefined);
  }

  public static ok<U>(value?: U): Result<U> {
    return new Result<U>(true, undefined, value);
  }

  public static fail<U>(error: string): Result<U> {
    if (!error) {
      throw new Error("An error message is mandatory");
    }
    return new Result<U>(false, error);
  }

  public static combineFailures<U>(error: string, result: Result): Result<U> {
    if (!error || typeof error !== "string") {
      throw new Error("An error message is mandatory");
    }
    if (result.isSuccess) {
      throw new Error("Can only concatenate failures");
    }

    const appendedError = `${error}: ${result.getError()}`;

    return new Result<U>(false, appendedError);
  }

  public static combine<T = unknown>(results: Result<T>[]): Result<T> {
    for (const result of results) {
      if (result.isFailure) return result;
    }
    return Result.ok();
  }

  public static flat<U>(results: Result<U>[]): Result<U[]> {
    for (const result of results) {
      if (result.isFailure) return Result.fail<U[]>(result.error);
    }
    return Result.ok<U[]>(results.map((r) => r.getValue()));
  }
}

export type Either<L, A> = Left<L, A> | Right<L, A>;

class Left<L, A> {
  readonly value: L;

  constructor(value: L) {
    this.value = value;
  }

  isLeft(): this is Left<L, A> {
    return true;
  }

  isRight(): this is Right<L, A> {
    return false;
  }
}

class Right<L, A> {
  readonly value: A;

  constructor(value: A) {
    this.value = value;
  }

  isLeft(): this is Left<L, A> {
    return false;
  }

  isRight(): this is Right<L, A> {
    return true;
  }
}

export const left = <L, A>(l: L): Either<L, A> => {
  return new Left(l);
};

export const right = <L, A>(a: A): Either<L, A> => {
  return new Right<L, A>(a);
};
