import { applyMaskFunctionFactory } from "./applyMaskFunctionFactory";

const maskCpfCnpj = (input: string) => {
  if (!input) return "";
  const cleanInput = input.replace(/\D/g, "");

  if (cleanInput.length <= 11) {
    return cleanInput
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } else {
    return cleanInput
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{4})/, "$1/$2")
      .replace(/(\d{4})(\d{2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
};

export const applyCpfCnpjMask = applyMaskFunctionFactory(maskCpfCnpj);
