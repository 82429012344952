import React, { useEffect } from "react";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { Box, Typography, Divider as MUIDivider } from "@mui/material";
import { transformToBRL } from "@/utils/transformToBRL";

export const PaymentResume = () => {
  const { selectedPlan, couponSelected } = usePaymentContext();
  const [discount, setDiscount] = React.useState(0);

  useEffect(() => {
    if (couponSelected) {
      if (couponSelected.percentOff) {
        setDiscount((selectedPlan.price * couponSelected.percentOff) / 100);
      } else if (couponSelected.amountOff) {
        setDiscount(couponSelected.amountOff / 100);
      }
    } else {
      setDiscount(0);
    }
  }, [couponSelected, selectedPlan]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column",
      }}
    >
      <Typography sx={{ mb: 2, color: "common.black" }} variant="body">
        <strong>Resumo da contratação</strong>
      </Typography>
      <Typography sx={{ display: "flex", justifyContent: "space-between" }} variant="body">
        Plano a ser contratado
        <Typography variant="body">Plano {selectedPlan.title}</Typography>
      </Typography>
      <Typography sx={{ display: "flex", justifyContent: "space-between" }} variant="body">
        Valor do plano
        <Typography variant="body">{transformToBRL(selectedPlan.price)} / mês</Typography>
      </Typography>
      <Typography sx={{ display: "flex", justifyContent: "space-between" }} variant="body">
        Desconto
        <Typography variant="body">{transformToBRL(discount ?? 0)}</Typography>
      </Typography>
      <MUIDivider color="grey.30" />
      <Typography sx={{ display: "flex", justifyContent: "space-between", color: "common.black" }} variant="subtitle1">
        <strong>Total</strong>
        {couponSelected?.durationType === "ONCE" && (
          <Box sx={{ textAlign: "end" }}>
            <Typography sx={{ fontWeight: 700, color: "common.black", mb: 0.5 }} variant="subtitle1">
              {transformToBRL(selectedPlan.price - discount)} no primeiro mês
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "common.black" }} variant="body">
              {transformToBRL(selectedPlan.price)} nos próximos meses
            </Typography>
          </Box>
        )}
        {couponSelected?.durationType === "REPEATING" && (
          <Box sx={{ textAlign: "end" }}>
            <Typography sx={{ fontWeight: 700, color: "common.black", mb: 0.5 }} variant="subtitle1">
              {transformToBRL(selectedPlan.price - discount)} nos {couponSelected.durationInMonths} primeiros meses
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "common.black" }} variant="body">
              {transformToBRL(selectedPlan.price)} nos meses seguintes
            </Typography>
          </Box>
        )}
        {(!couponSelected || couponSelected.durationType === "FOREVER") && (
          <Typography sx={{ fontWeight: 700, color: "common.black" }} variant="subtitle1">
            {transformToBRL(selectedPlan.price - discount)} / mês
          </Typography>
        )}
      </Typography>
    </Box>
  );
};
