import { useContext } from "react";
import { ThreadContext } from "./context";

export const useThreadContext = () => {
  const contextValue = useContext(ThreadContext);
  if (!contextValue) {
    throw new Error("ThreadsContext not found");
  }
  return contextValue;
};
