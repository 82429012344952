import { DialogModal } from "@/components/DialogModal";
import React from "react";

interface OfficeSignUpModalProps {
  open: boolean;
  onClose: () => void;
  onSignUpWithOffice: () => void;
  onRetry: () => void;
}

export const OfficeSignUpModal = ({ open, onClose, onSignUpWithOffice, onRetry }: OfficeSignUpModalProps) => {
  return (
    <DialogModal
      title="Entrar com Office 365"
      description={"Não encontramos nenhuma conta com os dados inseridos."}
      buttonsDirection="column"
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: "Criar Nova Conta",
          onClick: onSignUpWithOffice,
        },
        {
          label: "Tentar Novamente",
          variant: "text",
          sx: { color: "black" },
          onClick: onRetry,
        },
      ]}
    />
  );
};
