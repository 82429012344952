import React from "react";

import "firebase/compat/auth";

import { AuthContext } from "./context";
import { useLogin } from "./hooks/useLogin";

export function AuthProvider({ children }: React.PropsWithChildren) {
  const { user, loginType, loading, loginWithEmailAndPassword, loginWithOffice, loginWithGoogle, setupFirebaseUser } =
    useLogin();

  return (
    <AuthContext.Provider
      value={{
        user,
        loginType,
        loading,
        loginWithEmailAndPassword,
        loginWithOffice,
        loginWithGoogle,
        setupFirebaseUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
