/* istanbul ignore file */

import { SessionSettings } from "@/core/SessionSettings";
import { ConfigInterface } from "./ConfigInterface";
import { Result } from "@/core/Result";
import { env } from "@/constants/environment";
/**
 * Handle the parsing and processing of environment settings
 * This file will use the .env and the process.env definitions to configure the
 * api runtime, as expected by the third step in a (12 factor app)[https://12factor.net/].
 *
 * Config is a singleton class, users should always use Config.getConfig() to get
 * the api settings.
 */
export class Config implements ConfigInterface {
  private static instance: Config;
  /**
   * The env may be configure to have one of the values:
   *
   *  - dev or development
   *  - staging
   *  - prod or production
   *
   *  This flag will change the API runtime in many ways.
   */
  private env: string;

  /**
   * Return the singleton instance
   */
  static getConfig(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }

    return Config.instance;
  }

  /**
   * Validate environment variables and init readonly properties
   */
  private constructor() {
    this.env = env.ENV as string;
  }

  /**
   * Return true if we are in a production environment
   */
  get isProd(): boolean {
    return Config.isProd(this.env);
  }

  /**
   * Return true if we are in a development (sandbox) environment
   */
  get isDev(): boolean {
    return Config.isDev(this.env);
  }

  /**
   * Return true if we are in a staging environment
   */
  get isStaging(): boolean {
    return Config.isStaging(this.env);
  }

  /**
   * Return the sessionSettings
   */
  get sessionSettings(): SessionSettings {
    return Config.getSessionSettings(this.env);
  }

  get hasSessionSettings(): boolean {
    return Object.keys(this.sessionSettings.toDTO()).length > 0;
  }

  public setSessionSettings(settings: { [key: string]: string }): Result<void> {
    if (!settings || Config.isProd(env.ENV as string)) {
      return Result.fail("Não foi possível salvar as configurações de sessão");
    }

    try {
      const newSessionSettings = SessionSettings.fromJSON(settings);

      if (newSessionSettings.isFailure) {
        return Result.fail("Configurações de sessão inválidas");
      }

      sessionStorage.setItem("sessionSettings", newSessionSettings.getValue().toPersistence());

      return Result.ok();
    } catch (e) {
      return Result.fail("Não foi possível salvar as configurações de sessão");
    }
  }

  private static isProd(env: string): boolean {
    return env === "prod" || env === "production";
  }

  private static isDev(env: string): boolean {
    return env === "dev" || env === "development";
  }

  private static isStaging(env: string): boolean {
    return env === "staging";
  }

  private static getSessionSettings(env: string): SessionSettings {
    const sessionSettingsString = sessionStorage.getItem("sessionSettings");

    if (!sessionSettingsString || Config.isProd(env)) {
      return SessionSettings.empty();
    }

    const sessionSettings = SessionSettings.fromString(sessionSettingsString);

    if (sessionSettings.isFailure) {
      return SessionSettings.empty();
    }

    return sessionSettings.getValue();
  }
}
