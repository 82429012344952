import { useFlags, useLDClientError } from "launchdarkly-react-client-sdk";
import { defaultFlagValues } from "./defaultFlagValues";
import { Flags } from "./types";

export const useFeatureFlags = () => {
  const ldFlags = useFlags<Flags>();
  const ldClientError = useLDClientError();

  return ldClientError ? defaultFlagValues : ldFlags;
};
