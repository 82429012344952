import { Box, Divider, Modal, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { EditorUIProvider } from "@/components/EditorPageLayout/contexts/EditorUIContext/provider";
import { EditorModal } from "@/components/EditorPageLayout/components/EditorModal";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useRecord } from "@/hooks/records/useRecord";

export function DocumentEditorModal() {
  const navigate = useNavigate();
  const { caseId, documentId } = useParams<{ caseId: string; documentId: string }>();
  const { data: document, isLoading: isLoadingRecord } = useRecord({ recordId: documentId });

  const onClose = () => {
    if (!caseId) {
      navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT);
      return;
    }
    navigate(ROUTE_PATHS.CASE_DETAILS.replace(":caseId", caseId));
  };

  if (outsideOfficeClient()) {
    return (
      <EditorUIProvider>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "min-content min-content",
            gridTemplateRows: "minmax(0, 1fr)",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            bgcolor: "background.paper",
            boxShadow: 24,
            zIndex: "modal",
          }}
        >
          <Modal open={true} onClose={onClose}>
            <Box
              sx={{
                width: "640px",
                backgroundColor: "background.paper",
                right: 0,
                position: "fixed",
                height: "100vh",
                display: "grid",
                cursor: "default",
                gridTemplateColumns: "1fr",
                paddingTop: "20px",
                gridTemplateRows: "40px 1px 1fr",
                gridTemplateAreas: `'lateral-modal-header' 'divider' 'lateral-modal-content'`,
                "&:focus-visible": {
                  outline: "none",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  padding: "0 16px 16px 16px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                  {isLoadingRecord ? (
                    <TitleSkeleton />
                  ) : (
                    <Typography variant="h3" sx={{ fontWeight: "600", color: "legalBlack" }}>
                      {document?.name}
                    </Typography>
                  )}
                </Box>
                <CloseIcon sx={{ width: "32px", height: "32px", cursor: "pointer" }} onClick={onClose} />
              </Box>

              <Divider sx={{ gridArea: "divider" }} />

              <Box
                sx={{
                  gridArea: "lateral-modal-content",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <WebEditor />
                  <EditorModal />
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </EditorUIProvider>
    );
  }

  return <Outlet />;
}

const WebEditor = () => {
  const { editorElement } = useWebEditorContext();

  return editorElement;
};

const TitleSkeleton = () => {
  return (
    <Skeleton
      sx={{
        transformOrigin: "0 0",
      }}
      variant="text"
      width="400px"
      height="52px"
    />
  );
};
