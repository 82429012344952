import { SvgIconComponent } from "@mui/icons-material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ConfigMenu } from "@/components/ConfigMenu";
import * as React from "react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import GearIcon from "@/assets/svgs/gear.svg?react";

interface HeaderProps {
  activeTab: number;
  tabs: Array<{
    id: number;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  }>;
  onTabChange: (tabId: number) => void;
}

export function Header({ activeTab, tabs, onTabChange }: HeaderProps) {
  const [openSettings, setOpenSettings] = React.useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  return (
    <Box
      sx={{
        height: 48,
        backgroundColor: "secondary.light",
        color: "secondary.contrastText",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 2,
        pr: 2,
        zIndex: 1,
      }}
    >
      <Box
        component={LexterLogoIcon}
        height={32}
        sx={{
          color: "primary.main",
        }}
      />

      <Box
        sx={{
          display: "flex",
          gap: 1,
          height: "100%",
        }}
      >
        {tabs.map((tab) => (
          <TabButton key={tab.id} active={activeTab === tab.id} icon={tab.icon} onClick={() => onTabChange(tab.id)} />
        ))}
      </Box>

      <IconButton sx={{ color: "secondary.contrastText" }} onClick={handleOpenSettings}>
        <GearIcon />
      </IconButton>

      <ConfigMenu
        open={openSettings}
        onClose={handleCloseSettings}
        sx={{
          top: 56,
          right: 16,
        }}
      />
    </Box>
  );
}

interface TabButtonProps {
  active: boolean;
  onClick?: () => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
}

const TabButton = ({ active, onClick, icon: Icon }: TabButtonProps) => {
  return (
    <IconButton
      sx={{
        color: active ? "primary.main" : "common.black",
        borderBottomWidth: "4px",
        borderBottomStyle: "solid",
        borderBottomColor: active ? "primary.main" : "transparent",
        borderRadius: 0,
        px: 1,
      }}
      onClick={onClick}
    >
      <Box
        component={Icon}
        height={24}
        sx={{
          cursor: "pointer",
        }}
      />
    </IconButton>
  );
};
