import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { UpdateLexZapUserParams } from "@/hooks/useApi/types";
import { lexZapUsersQueryKey } from "../useLexZapUsers";
import { Paginated } from "@/core/api/types";
import { LexZapUser } from "../../types";

export const useUpdateLexZapUser = () => {
  const { updateLexZapUser } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateLexZapUser"],
    mutationFn: async ({ userId, updates }: UpdateLexZapUserParams) => {
      updateLexZapUser({ userId, updates });
      return { userId, updates };
    },
    onMutate: ({ userId, updates }: UpdateLexZapUserParams) => {
      queryClient.invalidateQueries({ queryKey: lexZapUsersQueryKey });

      const previousUsersTuple = queryClient.getQueriesData({ queryKey: lexZapUsersQueryKey });

      for (const [queryKey] of previousUsersTuple) {
        queryClient.setQueryData(queryKey, (old: Paginated<LexZapUser>) => {
          return {
            ...old,
            data: old.data.map((u) => {
              if (u.userId === userId) {
                return {
                  ...u,
                  ...updates,
                };
              }
              return u;
            }),
          };
        });
      }

      return { previousUsersTuple };
    },
    onError: (_error, _updatedUser, context) => {
      context?.previousUsersTuple.forEach(([queryKey, queryData]) => {
        queryClient.setQueryData(queryKey, queryData);
      });
    },
  });
};
