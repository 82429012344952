import { env } from "@/constants/environment";
import { withAuthorization } from "@/core/api/utils/withAuthorization";
import { Config } from "@/config/Config";
import axios from "axios";

const config = Config.getConfig();

export const copilotServer = axios.create({
  baseURL: env.API,
  headers: { ...(config.sessionSettings?.toHeaders() || {}) },
});

export const authenticatedCopilotServer = withAuthorization({
  instance: axios.create({
    baseURL: env.API,
    headers: { ...(config.sessionSettings?.toHeaders() || {}) },
  }),
});

export const authenticationCopilotServer = withAuthorization({
  instance: axios.create({
    baseURL: env.API,
    headers: { ...(config.sessionSettings?.toHeaders() || {}) },
  }),
  withLoginType: false,
  logoutOnUnauthorized: false,
});

export const authenticatedLexterApi = withAuthorization({
  instance: axios.create({
    baseURL: env.API_HOST,
    headers: {
      "x-lexter-origin": "copilot-app",
    },
  }),
  withLoginType: false,
});
