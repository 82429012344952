import { Box } from "@mui/material";
import React from "react";
import { ChatMessages } from "./components/ChatMessages";
import { ChatHeader } from "./components/ChatHeader";
import { ChatInputs } from "./components/ChatInputs";
import { EmptyChat } from "./components/EmptyChat";
import { Case, CaseMessage } from "@/hooks/lexZap/types";

interface ChatProps {
  messages?: CaseMessage[];
  lexZapCase?: Case;
  isLoadingCases?: boolean;
  isLoadingMessages?: boolean;
}

export const Chat = ({ messages, lexZapCase, isLoadingCases, isLoadingMessages }: ChatProps) => {
  if (!isLoadingCases && !isLoadingMessages && !lexZapCase) {
    return <EmptyChat />;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "min-content minmax(0, 1fr) min-content",
        backgroundColor: "#F9F9F9",
        height: "100%",
      }}
    >
      <ChatHeader lexZapCase={lexZapCase} isLoading={isLoadingCases} />

      <ChatMessages messages={messages} isLoading={isLoadingMessages} caseData={lexZapCase} />

      <ChatInputs chatId={lexZapCase?.chatId} />
    </Box>
  );
};
