import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { useTawk } from "@/hooks/useTawk";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { useQuery } from "@tanstack/react-query";
import { PlanType } from "../types";
import { HeapService } from "@/services/heap";
import { logger } from "@/core/logger";

export const useCredits = () => {
  const api = useApi();
  const userGuiding = useUserGuiding();
  const tawk = useTawk();
  const { user } = useAuthContext();
  return useQuery({
    queryKey: ["credits"],
    queryFn: async () => {
      const credits = await api.getCredits();
      const planType = credits.companyPlan?.plan.toString();
      const isFreemium = credits?.companyPlan?.plan === PlanType.FREEMIUM;
      const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
      userGuiding.identify(user, { credits: credits.availableCredit, plan: planType });
      HeapService.addUserProperties({ CurrentPlan: planType });
      if (user) {
        try {
          tawk.loadTawk(user, isFreemium || isTrial);
          tawk.addProperties({ userid: user?.userId, companyid: user?.companyId, currentplan: planType });
          tawk.addTags(planType ? [planType] : []);
        } catch (e) {
          logger.debug(`Tawk error: ${JSON.stringify(e)}`);
        }
      }
      return credits;
    },
    enabled: !!user?.userId,
  });
};
