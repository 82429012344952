import { buildSkillStep } from "@/core/skillForms/builder";

export const settlementOfferSkillStep2 = buildSkillStep({
  fields: [
    {
      name: "area",
      type: "string",
      options: [
        { value: "Cível" },
        { value: "Trabalhista" },
        { value: "Previdenciário" },
        { value: "Tributário" },
        { value: "Criminal" },
        {
          value: "Outros",
        },
      ],
      ui: {
        label: "Área jurídica",
        placeholder: "Selecione a área jurídica",
      },
    },
    {
      name: "custom_area",
      type: "string",
      overrideField: "area",
      ui: {
        label: "Digite a área jurídica:",
        placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
        widget: "textarea",
      },
      condition: {
        field: "area",
        value: "Outros",
      },
    },
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o Nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
