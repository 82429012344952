import { Editor as TinyMCEEditor } from "tinymce";
// @ts-expect-error No types available
import mammoth from "mammoth-style";

export const importDocx = async (editor: TinyMCEEditor) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "application/vnd.openxmlformats-officedocument.wordprocessingml.document");

  input.addEventListener("change", (e) => {
    editor.mode.set("readonly");

    const file = (e.target as HTMLInputElement).files?.[0];
    const reader = new FileReader();

    reader.addEventListener("load", async () => {
      const arrayBuffer = reader.result as ArrayBuffer;

      mammoth.convertToHtml({ arrayBuffer: arrayBuffer }).then(function (resultObject: { value: string }) {
        editor.setContent(resultObject.value);
      });

      editor.mode.set("design");
    });
    if (file) reader.readAsArrayBuffer(file);
  });

  input.click();
};
