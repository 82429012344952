import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { Box, Button, ButtonProps, Modal, SxProps, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import React, { ReactNode } from "react";

export interface DialogModalButtonProps extends ButtonProps {
  label: string;
}

interface DialogModalProps {
  title?: string;
  description: string | ReactNode;
  descriptionTextAlign?: "center" | "left" | "right";
  buttons: DialogModalButtonProps[];
  open: boolean;
  onClose?: () => void;
  variant?: "primary" | "error";
  subTitle?: string;
  icon?: ReactNode;
  buttonsDirection?: "row" | "column";
  isLoading?: boolean;
  heightLoading?: number;
  sx?: SxProps;
}

export const DialogModal = ({
  title,
  description,
  descriptionTextAlign = "center",
  open,
  onClose,
  buttons,
  variant = "primary",
  subTitle,
  buttonsDirection = "row",
  icon,
  isLoading,
  heightLoading = 150,
  sx,
}: DialogModalProps) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "calc(100% - 32px)",
        maxWidth: "400px",
        bgcolor: "common.white",
        borderRadius: 1,
        overflow: "hidden",
        ...sx,
      }}
    >
      {(title || onClose) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="h6">{title}</Typography>
          {onClose && (
            <CloseOutlinedIcon
              onClick={onClose}
              fontSize="medium"
              sx={{
                cursor: "pointer",
                color: "common.black",
              }}
            />
          )}
        </Box>
      )}
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            p: 2,
            minHeight: heightLoading,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading isLoading={true} />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            p: 2,
            textAlign: "center",
          }}
        >
          {icon && <Box mb={2}>{icon}</Box>}

          {subTitle && (
            <Typography mb={2}>
              <b>{subTitle}</b>
            </Typography>
          )}

          <Typography variant="body1" sx={{ textAlign: descriptionTextAlign }}>
            {description}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: buttonsDirection,
          gap: 2,
          justifyContent: buttonsDirection === "column" ? undefined : "end",
          px: 2,
          py: 2,
        }}
      >
        {buttons.map(({ label, ...buttonProps }, index) => (
          <Button
            key={index}
            color={variant === "error" ? "error" : undefined}
            variant="contained"
            disabled={isLoading}
            fullWidth={buttonsDirection === "column"}
            {...buttonProps}
          >
            {label}
          </Button>
        ))}
      </Box>
    </Box>
  </Modal>
);
