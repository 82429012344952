import React, { useEffect, useRef, useState } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Action } from "@/contexts/MessagesContext";
import { ActionButton } from "./ActionComponents/ActionButton";
import { SelectSkill } from "../SelectSkill";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { Popper } from "@mui/material";
import { ActionsMenu } from "./ActionComponents/ActionsMenu";

export function Footer(props: { actions: Action[]; loading: boolean; threadId: string }) {
  const { actions, loading } = props;

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const isOutsideOfficeClient = outsideOfficeClient();

  const handleClickActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      setOpenActionsMenu((prev) => !prev);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseActionsMenu = () => {
    setOpenActionsMenu(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        handleCloseActionsMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      {openActionsMenu && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1,
          pb: "10px",
          pl: 3,
          pr: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <WarningAmberRoundedIcon sx={{ color: "text.secondary" }} />

          <Typography
            variant="multiLineBody"
            color={"text.primary"}
            sx={{ ml: 1, color: "text.secondary", fontSize: "10px" }}
          >
            Conteúdos gerados por IA podem conter dados incorretos.
          </Typography>
        </Box>

        <ActionButton
          id="button_new_action"
          isOpen={openActionsMenu}
          disabled={loading}
          onClick={handleClickActionsMenu}
          buttonRef={buttonRef}
        />
        {isOutsideOfficeClient ? (
          <SelectSkill isOpen={openActionsMenu} onClose={() => setOpenActionsMenu(false)} threadId={props.threadId} />
        ) : (
          <Popper
            open={openActionsMenu}
            disablePortal={true}
            anchorEl={anchorEl}
            placement="top"
            modifiers={[
              {
                name: "preventOverflow",
                options: {
                  boundariesElement: "viewport",
                },
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: ["top", "right", "bottom"],
                },
              },
            ]}
            sx={{
              zIndex: 4,
              maxHeight: "calc(100vh - 140px)",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                maxWidth: "100%",
              }}
            >
              <div ref={menuRef}>
                <ActionsMenu actions={actions} onClose={() => setOpenActionsMenu(false)} />
              </div>
            </Box>
          </Popper>
        )}
      </Box>
    </>
  );
}
