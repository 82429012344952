import { buildSkillStep } from "@/core/skillForms/builder";

export const createStrategySkillStep1 = buildSkillStep({
  description:
    "O Assistente Lexter pode te ajudar a identificar e selecionar as estratégias mais relevantes para o caso.",
  title: "Detalhes do caso",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Enviar detalhamento do caso:",
        placeholder:
          "Exemplo: A influenciadora digital sofreu lesões graves após um procedimento estético mal realizado. A clínica não conseguiu tratar os ferimentos. O atendimento inadequado resultou em dor intensa e impacto psicológico, afetando seu trabalho.",
        widget: "textarea",
      },
    },
  ],
});
