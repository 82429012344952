import { useEffect, useMemo, useState } from "react";
import { EditorState, EditorTab } from "@/contexts/WebEditorContext/types";
import { ROUTE_PATHS } from "@/routes/routePaths";

const CURRENT_TAB_ID = Symbol("current_tab");

export const useEditorState = (initialEditorState: EditorState) => {
  const [editorState, setEditorState] = useState<EditorState>(initialEditorState);

  const currentTab = useMemo(() => editorState.tabs.find((tab) => tab.id === editorState.activeTab), [editorState]);

  const areTabsEmpty = useMemo(() => {
    return editorState.tabs.every((tab) => !tab.content);
  }, [editorState.tabs]);

  const resetEditorState = () => {
    setEditorState(initialEditorState);
  };

  const addEditorTab = (tab: EditorTab, { selected = true }: { selected?: boolean } = {}) => {
    setEditorState((prev) => ({
      ...prev,
      activeTab: selected ? tab.id : prev.activeTab,
      tabs: uniqueTabs([...prev.tabs, tab]),
    }));
  };

  const selectEditorTab = (tabId: EditorTab["id"]) => {
    setEditorState((prev) => ({
      ...prev,
      activeTab: tabId,
    }));
  };

  const removeEditorTab = (tabId: EditorTab["id"]) => {
    setEditorState((prev) => ({
      ...prev,
      tabs: prev.tabs.filter((tab) => tab.id !== tabId),
    }));
  };

  const updateEditorTab = ({
    id,
    ...newTab
  }: Partial<Omit<EditorTab, "id">> & { id: EditorTab["id"] | typeof CURRENT_TAB_ID }) => {
    setEditorState((prev) => {
      const tabID = id === CURRENT_TAB_ID ? prev.activeTab : id;
      return {
        ...prev,
        tabs: prev.tabs.map((tab) => {
          if (tab.id === tabID) {
            return {
              ...tab,
              ...newTab,
            };
          }
          return tab;
        }),
      };
    });
  };

  const uniqueTabs = (tabs: EditorTab[]) => {
    const seenDocumentIds = new Set();
    return tabs.filter((tab) => {
      if (tab.documentId && seenDocumentIds.has(tab.documentId)) {
        return false;
      }
      seenDocumentIds.add(tab.documentId);
      return true;
    });
  };

  const updateCurrentEditorTab = (newTab: Partial<Omit<EditorTab, "id">>) => {
    updateEditorTab({ id: CURRENT_TAB_ID, ...newTab });
  };

  const setEditorTabs = (tabs: EditorTab[]) => {
    setEditorState((prev) => ({ ...prev, tabs: uniqueTabs(tabs) }));
  };

  useEffect(() => {
    const shouldClearEditor = location.pathname === ROUTE_PATHS.HOME;
    if (shouldClearEditor) {
      resetEditorState();
    }
  }, [location.pathname]);

  return {
    editorState,
    resetEditorState,
    areTabsEmpty,
    currentTab,
    addEditorTab,
    selectEditorTab,
    removeEditorTab,
    updateEditorTab,
    updateCurrentEditorTab,
    setEditorTabs,
  };
};
