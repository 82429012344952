import { ROUTE_PATHS } from "@/routes/routePaths";
import { formattedDateTimeFromISO } from "@/utils/dates/formattedDateTimeFromISO";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "@/utils/formattedDate";
import { saveFileAs } from "@/utils/saveFileAs";
import juice from "juice";
import { useApi } from "@/hooks/useApi";
// @ts-expect-error No types available
import htmlToDocx from "html-to-docx";
import { DateTime } from "luxon";
import axios from "axios";
import { RemoveDocumentCaseModal } from "../RemoveDocumenteCaseModal";
import { useDeleteRecord } from "@/hooks/records/useDeleteRecord";
import { CaseFileType } from "@/hooks/lexZap/types";
interface CaseFileProps {
  caseId?: string;
  data: CaseFileType;
  threadId?: string;
}

export const CaseFile = ({ data, caseId, threadId }: CaseFileProps) => {
  const navigate = useNavigate();
  const { getDocumentById } = useApi();
  const [isRemoveDocumentModalOpen, setIsRemoveDocumentModalOpen] = React.useState(false);
  const { mutateAsync: deleteRecord, isPending: isDeleting } = useDeleteRecord({ threadId });

  const openDocument = () => {
    if (!caseId) return;
    navigate(ROUTE_PATHS.CASE_DOCUMENT_DETAILS.replace(":documentId", data.id.toString()).replace(":caseId", caseId));
  };

  const exportDocumentContent = async () => {
    if (data.type !== "RECORD") return;
    const document = await getDocumentById(data.id);
    // Código necessario para negrito ser exportado corretamente pro word
    const htmlWithInlineContent = juice.inlineContent(document.content, "p:has(strong) { font-weight: bold;}");
    const docx = await htmlToDocx(htmlWithInlineContent, "", {
      orientation: "portrait",
      creator: "Lexter",
      lang: "pt_BR",
      font: "Arial",
      fontSize: 24, // 12pt
    });

    saveFileAs(docx, `${document.name}_${getFormattedDate()}.docx`);
  };

  const exportFileContent = async () => {
    if (data.type !== "MEDIA") return;

    const response = await axios.get(data.url, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": data.mimeType,
      },
    });

    const blob = new Blob([response.data], {
      type: data.mimeType,
    });

    const url = URL.createObjectURL(blob);

    saveFileAs(url, data.name);
  };

  const openRemoveDocument = () => {
    setIsRemoveDocumentModalOpen(true);
  };

  const handleRemove = async () => {
    if (!caseId || data.type === "MEDIA") return;

    await deleteRecord({
      id: Number(data.id),
      type: data.recordType,
      name: data.name,
      createdAt: DateTime.fromMillis(data.createdAt).toISO()!,
    });
    setIsRemoveDocumentModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        paddingY: 1.5,
        borderBottomColor: "common.lightShade",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        "&:last-child": {
          borderBottom: "none",
        },
      }}
    >
      <Box>
        <Typography variant="multiLineBody" fontWeight={700} fontSize="10px" color="text.primary" mb={0.5}>
          {formattedDateTimeFromISO(DateTime.fromMillis(data.createdAt).toISO()!)}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {data.name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {data.type === "RECORD" ? (
          <DocumentButtons
            openDocument={openDocument}
            downloadDocument={exportDocumentContent}
            removeDocument={openRemoveDocument}
          />
        ) : (
          <FileButtons downloadFile={exportFileContent} />
        )}
      </Box>
      <RemoveDocumentCaseModal
        isOpen={isRemoveDocumentModalOpen}
        onCancel={() => setIsRemoveDocumentModalOpen(false)}
        onConfirm={handleRemove}
        isLoading={isDeleting}
        name={data.name}
      />
    </Box>
  );
};

const DocumentButtons = ({
  removeDocument,
  openDocument,
  downloadDocument,
}: {
  removeDocument: () => void;
  openDocument: () => void;
  downloadDocument: () => void;
}) => {
  return (
    <>
      <Button variant="text" onClick={removeDocument}>
        Remover
      </Button>
      <Button variant="text" onClick={openDocument}>
        Acessar
      </Button>
      <Button variant="text" onClick={downloadDocument}>
        Baixar
      </Button>
    </>
  );
};

const FileButtons = ({ downloadFile }: { downloadFile: () => void }) => {
  return (
    <>
      <Button variant="text" onClick={downloadFile}>
        Baixar
      </Button>
    </>
  );
};
