import { ActionId, UploadingFile } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  client: Type.String(),
  case_breakdown: Type.String(),
  reference_piece: Type.Optional(Type.String()),
});

const actionId = ActionId.CREATE_NOTICE_ACTION;

export const createNoticeSkill: Skill<typeof inputSchema> = {
  id: "create_notice",
  name: "Elaborar notificação extrajudicial",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["consultative"],
  actionId,
  type: "message",
  description: "Crie notificações extrajudiciais em poucos minutos utilizando as informações do caso.",
  getMessage: (input) => {
    const referencePieceFile = input.reference_piece?.startsWith("data:")
      ? fileToUploadingFile(base64ToFile(input.reference_piece))
      : undefined;
    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Elaborar Notificação Extrajudicial",
      actions: [
        {
          id: ActionId.CREATE_NOTICE_ACTION,
          client: input.client,
          case_breakdown: input.case_breakdown,
          reference_piece: referencePieceFile
            ? {
                source: "FILE",
                file: referencePieceFile,
              }
            : input.reference_piece
              ? {
                  source: "TEXT",
                  text: input.reference_piece,
                }
              : undefined,
        },
      ],
      files: referencePieceFile ? [referencePieceFile].filter((file): file is UploadingFile => Boolean(file)) : [],
    };
  },
};
