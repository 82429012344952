import { buildSkillStep } from "@/core/skillForms/builder";

export const hearingsScriptSkillStep1 = buildSkillStep({
  description:
    "O Assistente Lexter pode te ajudar a elaborar questões para preparar seu cliente ou suas testemunhas durante a audiência judicial, baseando-se no contexto do caso.",
  fields: [
    {
      name: "reference_piece_complaint",
      type: "file",
      ui: {
        label: "Enviar petição inicial do caso:",
      },
    },
  ],
});
