import { PlanType } from "@/hooks/credits/types";
import { CreditsApiResponse } from "@/hooks/useApi/types";
import { DateTime } from "luxon";

export const isPaidUser = (credits?: CreditsApiResponse) => {
  const plan = credits?.companyPlan?.plan;
  return plan !== PlanType.TRIAL && plan !== PlanType.FREEMIUM;
};

export const isFreeUser = (credits?: CreditsApiResponse) => {
  const plan = credits?.companyPlan?.plan;
  return plan === PlanType.TRIAL || plan === PlanType.FREEMIUM;
};

export const notShowCredits = (credits?: CreditsApiResponse) => {
  const plan = credits?.companyPlan?.plan;
  return (
    plan === PlanType.TRIAL ||
    plan === PlanType.UNLIMITED ||
    plan === PlanType.PROFESSIONAL_WITH_WPP ||
    plan === PlanType.PERFORMANCE
  );
};

export const calculateDaysToEndPlan = (nextBillingDate?: string) => {
  if (nextBillingDate) {
    const now = DateTime.now();
    const diffDays = DateTime.fromISO(nextBillingDate).diff(now, "days");
    if (diffDays.days > 0 && diffDays.days < 1) {
      return 1;
    } else if (diffDays.days >= 1) {
      return Number(diffDays.days.toFixed());
    }
  }

  return 0;
};
