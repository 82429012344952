import { SkillForm } from "@/core/skillForms/types";
import { createDefenseSkillStep1 } from "./step1";
import { createDefenseSkillStep2 } from "./step2";
import { createDefenseSkillStep3 } from "./step3";

export const createDefenseSkillForm: SkillForm = {
  skillId: "create_contestation",
  title: "Contestação",
  steps: [createDefenseSkillStep1, createDefenseSkillStep2, createDefenseSkillStep3],
};
