import { Paragraph } from "@/hooks/useEditor/types";

interface ParagraphsToHtmlProps {
  paragraphs: Paragraph[];
}

export const paragraphsToHtml = ({ paragraphs }: ParagraphsToHtmlProps) => {
  let html = "";

  for (const paragraph of paragraphs) {
    const { leftIndent, italic, alignment } = paragraph.style || {};

    const styles: string[] = [];
    if (leftIndent) styles.push(`margin-left: ${leftIndent}px;`);
    if (alignment) styles.push(`text-align: ${alignment};`);

    let children = paragraph.text;
    if (italic) children = `<i>${children}</i>`;

    html += `<p style="${styles.join("; ")}">${children}</p>`;
  }

  return html;
};
