import LogoWindowsSvg from "@/assets/svgs/logo-windows.svg?react";
import { Button, ButtonProps } from "@/components/Button";
import React from "react";

interface MicrosoftButtonProps extends ButtonProps {
  submit: () => void;
}

const microsoftButtonCommonStyle = {
  width: "220px",
  borderRadius: "100px",
  border: "solid 1px #747775",
  padding: "6px 10px 6px 10px",
  alignSelf: "center",
};

export const MicrosoftButton = ({ children, submit, isLoading, ...rest }: MicrosoftButtonProps) => (
  <Button
    variant="contained"
    fullWidth
    isLoading={isLoading}
    {...rest}
    bgcolor="#131314"
    loadingColor="#1c1c1c"
    sx={{
      textTransform: "none",
      fontWeight: "600",
      gap: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "common.white",
      "&:focus": {
        outline: "2px solid #4285f454",
      },
      "&:hover": {
        border: "solid 1px #C1D5F6",
      },
      ...microsoftButtonCommonStyle,
      ...rest.sx,
    }}
    onClick={submit}
    loaderStyle={microsoftButtonCommonStyle}
  >
    <LogoWindowsSvg fontSize="medium" height={24} />
    {children}
  </Button>
);
