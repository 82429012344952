import { Box, Button, FormControl, InputLabel, Select, TextField } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { ChangeEvent } from "react";
import { WebToast } from "@/taskpane/components/core/Toast";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { useEditCase } from "@/hooks/lexZap/useEditCase";

type Form = {
  name: string;
  applicantId: string;
  step: string;
};

export function EditCaseModal({ isOpen, onClose, caseId }: { isOpen: boolean; onClose: () => void; caseId: string }) {
  const { caseData, isLoading: isLoadingCaseData } = useLexZapCase({ caseId });
  const { mutateAsync: updateCase, isPending: isUpdatingCase } = useEditCase();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<Form>({
    name: "",
    applicantId: "",
    step: "",
  });

  React.useEffect(() => {
    if (caseData) {
      setForm({
        name: caseData.name,
        applicantId: caseData.applicantId,
        step: caseData.step,
      });
    }
  }, [caseData]);

  const handleUpdateCase = async () => {
    if (!caseData || !caseId || form.applicantId === "" || form.step === "" || form.name === "") {
      return;
    }

    try {
      setIsLoading(true);
      await updateCase({ caseId, data: { ...form } });

      setIsLoading(false);

      onClose();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      setIsLoading(false);
      return WebToast.error("Falha ao alterar o caso");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
  };

  const saveButtonDisabled = form?.name === "" || isLoading;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Editar caso"}
        size={"SMALL"}
        onClose={onClose}
        footer={
          caseData &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"Atualizar"}
              onConfirm={handleUpdateCase}
              cancelLabel="Cancelar"
              onCancel={onClose}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={isOpen}
      >
        <Loading isLoading={isLoading || isLoadingCaseData || isUpdatingCase}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {caseData && (
                <>
                  <TextField
                    placeholder="Nome do caso"
                    onChange={handleChangeForm("name")}
                    value={form.name}
                    label="Nome do caso"
                  />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-name-native-simple">Cliente</InputLabel>
                    <Select
                      native
                      value={caseData.applicantName}
                      label="Cliente"
                      inputProps={{
                        name: "cliente",
                        id: "outlined-name-native-simple",
                      }}
                      disabled={true}
                    >
                      <option value={caseData.applicantId}>{caseData.applicantName}</option>
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm} size="small">
        {confirmLabel}
      </Button>
      <Button size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Box>
  );
};
