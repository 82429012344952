import { Box, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, Typography } from "@mui/material";
import React, { forwardRef } from "react";

interface CheckboxProps extends Omit<MuiCheckboxProps, "color"> {
  label: string | React.ReactNode;
  error?: boolean;
  helperText?: string;
  color?: string;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ label, error, helperText, color, sx, ...rest }, ref) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "0.5rem",
        }}
        component="label"
      >
        <MuiCheckbox
          {...rest}
          ref={ref}
          sx={{
            p: 0,
          }}
        />
        {typeof label === "string" ? (
          <Typography color={color} variant="body1">
            {label}
          </Typography>
        ) : (
          label
        )}
      </Box>

      {helperText && (
        <Typography variant="body1" color={error ? "error.main" : "text.secondary"} sx={{ mt: "8px" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  )
);
Checkbox.displayName = "Checkbox";
