import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  Pending as PendingIcon,
  RemoveCircleOutlined as RemoveCircleOutlinedIcon,
} from "@mui/icons-material";
import { Box, Collapse, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { Chip } from "@/components/Chip";
import { CreditTransactionStatus, CreditTransactionType, CreditsHistoryApiResponseItem } from "@/hooks/useApi/types";
import { DateTime } from "luxon";
import React from "react";
import { getDescription } from "./getDescription";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useCredits } from "@/hooks/credits/useCredits";
import { notShowCredits } from "@/utils/plans";

interface CreditsHistoryItemProps {
  item: CreditsHistoryApiResponseItem;
  onClick?: () => void;
  collapseOpen: boolean;
  isLoading: boolean;
  onDisputeItem: () => void;
}

export const CreditsHistoryItem = ({
  item,
  onClick,
  collapseOpen,
  isLoading,
  onDisputeItem,
}: CreditsHistoryItemProps) => {
  const isOutsideOfficeClient = outsideOfficeClient();
  const { data: credits } = useCredits();
  const showCredits = !notShowCredits(credits);
  const hoverColor = isOutsideOfficeClient ? "common.dorianHover" : "common.dorian";

  const pending = item.status === CreditTransactionStatus.PENDING;

  const getIcon = () => {
    if (pending) {
      return <PendingIcon fontSize="medium" sx={{ color: "common.lightShade" }} />;
    }
    if ([CreditTransactionType.EXPENSE, CreditTransactionType.EXPIRE].includes(item.type)) {
      return <RemoveCircleOutlinedIcon fontSize="medium" sx={{ color: "error.light" }} />;
    }

    return <AddCircleOutlinedIcon fontSize="medium" sx={{ color: "success.main" }} />;
  };

  if (item.type === CreditTransactionType.RECHARGE && !showCredits) {
    return <></>;
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        py: "16px",
        display: "grid",
        gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
        gap: "16px",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "common.lightShade",
        "&:first-of-type": {
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          borderTopColor: "common.lightShade",
        },
        ":hover": {
          backgroundColor: hoverColor,
          borderColor: hoverColor,
          cursor: "pointer",
          marginLeft: "-16px",
          marginRight: "-16px",
          paddingLeft: "16px",
          paddingRight: "16px",
          marginTop: "-1px",
          paddingTop: "17px",
        },
      }}
    >
      {getIcon()}

      <Box>
        <Typography
          variant="bold"
          mb="4px"
          sx={{
            color: "text.primary",
            display: "block",
          }}
        >
          {getDescription(item)}
        </Typography>
        <Typography variant="body2">
          {DateTime.fromISO(item.creationDate).toFormat("dd LLL yyyy - T").toUpperCase().replace(".", "")}
        </Typography>

        {pending && (
          <Chip
            backgroundColor="common.lightShade"
            color="text.primary"
            sx={{
              mt: "4px",
            }}
          >
            Pendente
          </Chip>
        )}

        <Collapse in={collapseOpen}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }} mt="8px" mb="4px">
            {item.description && <Typography variant="body2">{item.description}</Typography>}{" "}
            <Typography variant="body2">{item.authorName}</Typography>
            <Typography variant="body2">ID: {item.creditTransactionId}</Typography>
          </Box>

          {item.type === CreditTransactionType.EXPENSE && (
            <Button
              isLoading={isLoading}
              variant="contained"
              fullWidth
              onClick={onDisputeItem}
              sx={{
                mt: "20px",
                // TODO falta implementação na api
                display: "none",
              }}
            >
              Contestar Item
            </Button>
          )}
        </Collapse>
      </Box>

      {showCredits && (
        <Typography variant="body">
          {[CreditTransactionType.EXPENSE, CreditTransactionType.EXPIRE].includes(item.type)
            ? `-${item.value.toLocaleString("pt-BR")}`
            : item.value.toLocaleString("pt-BR")}{" "}
          Cr.
        </Typography>
      )}
    </Box>
  );
};
