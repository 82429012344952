import { ConnectionState, Evaluation, SkillsPayload } from "@/contexts/WebSocketContext";
import { Line, StylesMap } from "@/hooks/useEditor/types";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction } from "react";
import { CreditPerSkill, SkillId } from "@/core/skills/types";
import { PaginationPayload } from "@/core/api/types";

export interface MessagesContextData {
  messages: Message[];
  error: string;
  clearError: () => void;
  skillExecutionStatus: SkillExecutionStatus;
  loading: boolean;
  sendMessage: (message: Message) => void;
  addMessage: (message: Message) => void;
  actions: Action[];
  waitingForResponse?: WaitingForResponse;
  uploadingFiles: { [fileId: string]: number };
  clearContext: () => Promise<void>;
  inputContext: string;
  inputContextFormatted: string;
  clearChatAndStartNewSession: () => void;
  cancelOngoingExecution: (executionId?: string) => Promise<void>;
  connectionState: ConnectionState;
  inputVisible: boolean;
  setInputVisible: Dispatch<SetStateAction<boolean>>;
  startSkillProcess: (skillPayload: SkillsPayload) => void;
  loadingState: LoadingState;
  totalResults: number;
  refetchMessages: ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => Promise<void>;
  paginationModel: PaginationPayload;
  setPaginationModel: Dispatch<SetStateAction<PaginationPayload>>;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
  startSkill: ({ skillId, actionId }: { skillId: string; actionId: ActionId }) => Promise<void>;
  documentCreationText: string;
}

export type HandleEvaluation = {
  messageId: string;
  newEvaluation?: Evaluation;
  currentEvaluation?: Evaluation;
};

type MessageDirection = "SENT" | "RECEIVED";

type MessageStatus = "PENDING" | "SENT" | "DELIVERED" | "READ";

export type Action = {
  id?: SkillId;
  text: string;
  onClick: (message: Message) => unknown;
  disabled?: boolean;
  hidden?: boolean;
};

export type TextMessage = {
  id: string;
  type: "TEXT";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  copyable?: boolean;
  evaluation?: Evaluation;
};

export type UploadingFile = {
  id: string;
  type: "UPLOADING";
  file: File;
  name: string; //Nome do documento original
};

export type UploadedFile = {
  id: string; //CortexId
  cortexId: string; //CortexId
  type: "UPLOADED";
  url: string; //URL do S3
  name: string; //Nome do documento original
};

export type ErrorFile = {
  id: string; //CortexId
  type: "ERROR";
  name: string; //Nome do documento original
  error: string;
};

export type InMessageFile = UploadingFile | UploadedFile | ErrorFile;

export type FileMessage = {
  id: string;
  type: "FILE";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  files: InMessageFile[];
};

export type ActionMessage = {
  id: string;
  type: "ACTION" | "INITIAL";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string | React.JSX.Element;
  context?: string;
  actions: Action[];
};

export type ErrorMessage = {
  id: string;
  type: "ERROR";
  direction: "RECEIVED";
  author: "Lexter.ai";
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  actions: Action[];
  retry?: () => void;
  cancel?: () => void;
};

export type InputType = "CONTENT" | "FILE" | "TEXT" | "NONE";

export type FlowOutput = {
  source: InputType;
  text?: string;
  file?: {
    id: string;
    name: string;
    file: File;
  };
};

export enum ActionId {
  CREATE_NEW_DOCUMENT = "CREATE_NEW_DOCUMENT",
  CREATE_PETITION_SUMMARY = "CREATE_PETITION_SUMMARY",
  CREATE_ONE_PIECE = "CREATE_ONE_PIECE",
  CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT = "CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT",
  CREATE_INTERVIEW_SCRIPT_ACTION = "CREATE_INTERVIEW_SCRIPT_ACTION",
  CREATE_CONTRACT = "CREATE_CONTRACTS_ACTION",
  CREATE_NOTICE_ACTION = "CREATE_NOTICE_ACTION",
  SEARCH_PRECEDENT = "precedent",
  EDIT_DOCUMENT = "edit_document",
  LEGAL_QUESTION = "legal_questions",
  CREATE_STRATEGY = "create_case_strategy",
  SUMMARIZE_DOCUMENT = "summary_procedural_documents",
  LEGAL_ADVICE = "legal_advice",
  INTERCURRENT_MOTION = "intercurrent_motion",
  FEE_AGREEMENT = "fee_agreement",
  SETTLEMENT_OFFER = "settlement_offer",
  HEARINGS_SCRIPT = "hearings_script",
  UPDATE_DOCUMENTS = "update_documents",
}

export const actionCreditMap: Record<SkillId, CreditPerSkill> = {
  create_new_document: CreditPerSkill.ADVANCED_SKILL,
  create_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_initial_petition: CreditPerSkill.ADVANCED_SKILL,
  create_contestation: CreditPerSkill.ADVANCED_SKILL,
  create_reply: CreditPerSkill.ADVANCED_SKILL,
  create_ordinary_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_extraordinary_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_special_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_unnamed_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_review_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_interlocutory_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_internal_appeal: CreditPerSkill.ADVANCED_SKILL,
  create_appeal_in_strict_sense: CreditPerSkill.ADVANCED_SKILL,
  create_interview_script: CreditPerSkill.ESSENTIAL_SKILL,
  create_contract: CreditPerSkill.ADVANCED_SKILL,
  create_notice: CreditPerSkill.INTERMEDIARY_SKILL,
  create_evidence_and_required_documents: CreditPerSkill.ESSENTIAL_SKILL,
  create_labor_initial_petition: CreditPerSkill.ADVANCED_SKILL,
  create_labor_complaint: CreditPerSkill.ADVANCED_SKILL,
  create_civil_initial_petition: CreditPerSkill.ADVANCED_SKILL,
  create_criminal_initial_petition: CreditPerSkill.ADVANCED_SKILL,
  create_pension_initial_petition: CreditPerSkill.ADVANCED_SKILL,
  legal_questions: CreditPerSkill.ESSENTIAL_SKILL,
  create_case_strategy: CreditPerSkill.INTERMEDIARY_SKILL,
  summary_document: CreditPerSkill.INTERMEDIARY_SKILL,
  intercurrent_motion: CreditPerSkill.INTERMEDIARY_SKILL,
  search_precedent: CreditPerSkill.BASIC_SKILL,
  legal_advice: CreditPerSkill.INTERMEDIARY_SKILL,
  fee_agreement: CreditPerSkill.INTERMEDIARY_SKILL,
  settlement_offer: CreditPerSkill.BASIC_SKILL,
  hearings_script: CreditPerSkill.ESSENTIAL_SKILL,
  edit_document: CreditPerSkill.ESSENTIAL_SKILL,
  create_other_type_of_piece: CreditPerSkill.ADVANCED_SKILL,
  adapt_document: CreditPerSkill.ESSENTIAL_SKILL,
};

type UploadedFlowOutput = {
  source: InputType;
  text?: string;
  file?: UploadedFile;
};

type CreatePetitionSummaryAction = {
  id: ActionId.CREATE_PETITION_SUMMARY;
  petition: FlowOutput;
  text: string;
};

export type FlowMessageAction =
  | CreatePetitionSummaryAction
  | CreatePieceAction
  | CreateEvidenceAndRequiredDocumentAction
  | CreateInterviewScriptAction
  | CreateContractsAction
  | SearchPrecedentAction
  | CreateExtrajudicialNoticeAction;

export type FlowMessage = {
  id: string;
  type: "FLOW";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  actions: FlowMessageAction[];
  files: InMessageFile[];
  hideFiles?: boolean;
  actionId?: ActionId | null;
  evaluation?: Evaluation;
};

export type FeedbackMessage = {
  id: string;
  type: "FEEDBACK";
  direction: "RECEIVED";
  author: string;
  date: DateTime;
  status: "READ";
  text: string;
  //Context will never be used here, we only defined it to make the type
  ///compatible with the Message type
  context?: string;
};

export type Message = TextMessage | FileMessage | ActionMessage | ErrorMessage | FlowMessage | FeedbackMessage;

//Os IDs definem qual função o chat vai executar ao realizar essa mensagem. Isso tem que ser previamente combinado
export type ActionDTO =
  | {
      id: "REPLACE_ENTIRE_DOCUMENT" | "REPLACE_SELECTION";
      type: "TEXT";
      text?: string;
    }
  | {
      id: "REPLACE_ENTIRE_DOCUMENT" | "REPLACE_SELECTION" | "DOWNLOAD_ASSISTANT_ANSWER";
      type: "FORMATTED";
      lines: Line[];
      stylesMap: StylesMap;
    };

export type ChatFile = {
  id: string;
  cortexId: string; //CortexId
  url: string; //URL do S3
  name: string; //Nome do documento original
};

export interface MessageCortex {
  id: string;
  author: "Lexter.ai" | string;
  date: string;
  text: string | React.JSX.Element;
  context?: string; //Texto grifado atualmente
  files?: ChatFile[];
  actions?: ActionDTO[];
}

export interface MessageDTO extends Omit<MessageCortex, "actions"> {
  actions?: FlowMessageAction[];
}

interface SendingErrorMessage {
  text: string;
  insufficientCredits?: boolean;
}

export const isCortexResponseMessage = (response: ResponseMessage): response is CortexResponseMessage => {
  return (response as CortexResponseMessage).replyTo !== undefined;
};

export interface RepliedMessage {
  id: string;
  actionId: ActionId;
  payload: CreatePieceAction;
  editorContent?: string;
}

interface BaseCortexResponse {
  document?: {
    id?: number;
    name: string;
  };
  replyTo: RepliedMessage;
}

export interface CortexSuccessResponse extends BaseCortexResponse {
  success: true;
  messages: MessageCortex[];
  requestId?: string;
}

interface CortexErrorResponse extends BaseCortexResponse {
  success: false;
}

type CortexResponseMessage = CortexSuccessResponse | CortexErrorResponse;

export type ResponseMessage = CortexResponseMessage | SendingErrorMessage;

export interface ChatOption {
  id?: SkillId;
  label: string;
  onClick?: () => void;
  response?: ChatFlow;
  hidden?: boolean;
  disabled?: boolean;
}

export interface ChatFlow {
  message: string | React.JSX.Element;
  options?: ChatOption[];
}

export type SuggestedSkillsMap = Record<ActionId, ChatFlow>;

export interface WaitingForResponse {
  type: WaitingForResponseType;
  executionId?: string;
}

export enum WaitingForResponseType {
  GENERIC = 1,
  INTERACTION_WITH_CHAT,
  CREATE_MOTION,
  CREATE_DOCUMENT,
  CREATE_SUMMARY,
  LEGAL_QUESTION,
  DOCUMENT_UPLOAD,
  CONTRACT,
}

export enum Area {
  CIVIL = "civel",
  LABOR = "trabalhista",
  SOCIAL_SECURITY = "previdenciario",
  TAX = "tributario",
  CRIMINAL = "criminal",
  OTHER = "outros",
}

export enum LegalPieceMacroType {
  INAUGURAL_DOCUMENT = "peca_inaugural",
  RESPONSE = "resposta",
  APPEAL = "recurso",
}

export enum LegalPieceMicroType {
  INITIAL = "inicial",
  CONTESTATION = "contestacao",
  REPLICATION = "replica",
  APPEAL = "apelacao",
  ORDINARY_APPEAL = "recurso_ordinario",
  EXTRAORDINARY_APPEAL = "recurso_extraordinario",
  SPECIAL_APPEAL = "recurso_especial",
  UNNAMED_APPEAL = "recurso_inominado",
  REVIEW_APPEAL = "recurso_de_revista",
  INTERLOCUTORY_APPEAL = "agravo_de_instrumento",
  INTERNAL_APPEAL = "agravo_interno",
  APPEAL_IN_STRICT_SENSE = "recurso_em_sentido_estrito",
  OTHER = "outros",
}

export enum DecisionType {
  TERMINATIVE_DECISIONS = "decisoes_terminativas",
  INTERLOCUTORY_DECISIONS = "decisoes_interlocutoria",
}

type UploadingFlowOutput = {
  source: InputType;
  text?: string;
  file?: UploadingFile;
};

export interface CreatePieceAction {
  id: ActionId.CREATE_ONE_PIECE;
  client: string;
  legal_piece_macro_type: LegalPieceMacroType;
  area: Area | string;
  legal_piece_micro_type: LegalPieceMicroType | string;
  decision_type?: DecisionType;
  case_breakdown?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  reference_piece?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  text: string;
}

interface CreateEvidenceAndRequiredDocumentAction {
  id: ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT;
  client: string;
  legal_piece_macro_type?: LegalPieceMacroType;
  area: Area | string;
  legal_piece_micro_type: LegalPieceMicroType | string;
  decision_type?: DecisionType;
  case_breakdown?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  reference_piece?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  text?: string;
}

export interface CreateInterviewScriptAction {
  id: ActionId.CREATE_INTERVIEW_SCRIPT_ACTION;
  client: string;
  case_breakdown: FlowOutput;
}

interface CreateContractsAction {
  id: ActionId.CREATE_CONTRACT;
  party_1: string;
  party_2: string;
  case_breakdown: string;
  contract_micro_type: string;
}

interface SearchPrecedentAction {
  id: ActionId.SEARCH_PRECEDENT;
  case_breakdown: string;
}

export interface CreateExtrajudicialNoticeAction {
  id: ActionId.CREATE_NOTICE_ACTION;
  client: string;
  case_breakdown: string;
  reference_piece: FlowOutput | undefined;
}

export type LoadingState = "FINISHED" | "LOADING" | "REFETCHING";

export type SkillExecutionStatus = undefined | "RUNNING" | "SUCCESS" | "SUCCESS_WITHOUT_DOCUMENT";

export enum ExecutionStatus {
  SUCCESS = 200,
  CONFLICT = 409,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export const TOAST_MESSAGES = {
  ALREADY_COMPLETED:
    "A operação já foi concluída e não pode ser alterada. Por favor, verifique o resultado ou inicie uma nova operação se necessário.",
  CANCELLED_SUCCESS: "Operação cancelada com sucesso.",
  NOT_FOUND: "A operação solicitada não foi encontrada.",
  ERROR_CANCELLING: "Erro ao cancelar a operação.",
  UNEXPECTED_ERROR: "Ocorreu um erro inesperado ao cancelar a operação.",
  RETRY_ERROR: "Erro ao cancelar a operação. Por favor, tente novamente.",
};
