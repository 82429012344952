import axios from "axios";
import { env } from "@/constants/environment";
import { LoggedInUserData } from "@/contexts/AuthContext/types";

export const getCustomTokenWithOfficeToken = async (token: string) => {
  const headers = { headers: { Authorization: `bearer ${token}` } };
  const response = await axios.post<LoggedInUserData & { customToken: string }>(
    `${env.API}/microsoftLogin`,
    {},
    headers
  );

  return response.data.customToken;
};
