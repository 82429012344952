import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  case_breakdown: Type.String(),
  court_code: Type.Optional(Type.Array(Type.String())),
  min_date: Type.Optional(Type.Date()),
});

const actionId = ActionId.SEARCH_PRECEDENT;

export const searchPrecedentSkill: Skill<typeof inputSchema> = {
  id: "search_precedent",
  name: "Buscar jurisprudência",
  description: "Utilize nossa busca de jurisprudência para buscar ementas aplicáveis ao seu caso.",
  credits: CreditPerSkill.BASIC_SKILL,
  inputSchema,
  tags: ["new", "litigation"],
  actionId,
  type: "payload",
  customForm: true,
  flag: "jurisprudenceSearch",
  getPayload: (input) => ({
    skillId: actionId,
    requestId: uuidV4(),
    payload: {
      case_breakdown: input.case_breakdown,
      court_code: input.court_code?.length ? input.court_code : undefined,
      min_date: input.min_date?.toISOString(),
    },
  }),
};
