import { useCallback } from "react";
import { Config } from "@/config/Config";
import { LoggedInUserData } from "@/contexts/AuthContext";
import { logger } from "@/core/logger";
import { useFeatureFlags } from "../useFeatureFlags";

export const useTawk = () => {
  const { tawkForAllUsers } = useFeatureFlags();
  const config = Config.getConfig();

  const loadTawk = (user: LoggedInUserData, isFreeUser: boolean) => {
    const tawkScriptLoaded = document.getElementById("tawk_script");
    if (tawkScriptLoaded !== null) return;

    if (isFreeUser) {
      loadTawkForFreeUsers(user);
    } else if (tawkForAllUsers) {
      loadTawkForPaidUsers(user);
    }
  };

  const loadTawkForPaidUsers = useCallback((user: LoggedInUserData) => {
    try {
      if (config.isStaging || config.isProd) {
        const tawkScript = document.createElement("script");
        tawkScript.innerHTML = `
          var Tawk_API=Tawk_API||{};
          Tawk_API.visitor = {
          name : "${user.userName}",
          email : "${user.userEmail}"
          };
          var Tawk_LoadStart=new Date();
          (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/66ec76e04cbc4814f7db751e/1i85r0nt5';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
          })();
        `;
        tawkScript.id = "tawk_script";
        document.head.appendChild(tawkScript);
      }
    } catch (e) {
      logger.debug(`Error loading Tawk.to: ${JSON.stringify(e)}`);
    }
  }, []);

  const loadTawkForFreeUsers = useCallback((user: LoggedInUserData) => {
    try {
      if (config.isStaging || config.isProd) {
        const tawkScript = document.createElement("script");
        tawkScript.innerHTML = `
          var Tawk_API=Tawk_API||{};
          Tawk_API.visitor = {
          name : "${user.userName}",
          email : "${user.userEmail}"
          };
          var Tawk_LoadStart=new Date();
          (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/66c4ae8150c10f7a009e7cc2/1i5o4jaqt';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
          })();
        `;
        tawkScript.id = "tawk_script";
        document.head.appendChild(tawkScript);
      }
    } catch (e) {
      logger.debug(`Error loading Tawk.to: ${JSON.stringify(e)}`);
    }
  }, []);

  const addProperties = useCallback((properties: Record<string, string | number | undefined>) => {
    try {
      if (window.Tawk_API && window.Tawk_API.setAttributes) {
        window.Tawk_API.setAttributes(properties);
      }
    } catch (e) {
      logger.debug(`Error adding properties to Tawk.to: ${JSON.stringify(e)}`);
    }
  }, []);

  const addTags = useCallback((tags: string[]) => {
    try {
      if (window.Tawk_API && window.Tawk_API.addTags) {
        window.Tawk_API.addTags(tags);
      }
    } catch (e) {
      logger.debug(`Error adding tags to Tawk.to: ${JSON.stringify(e)}`);
    }
  }, []);

  const showWidget = useCallback(() => {
    try {
      if (window.Tawk_API && window.Tawk_API.showWidget) {
        window.Tawk_API.showWidget();
      }
    } catch (e) {
      logger.debug(`Error showing Tawk.to widget: ${JSON.stringify(e)}`);
    }
  }, []);

  return { loadTawk, addProperties, addTags, showWidget };
};
