import { Thread } from "../types";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

import { THREADS_QUERY_KEY } from "@/hooks/threads/usePaginatedThreads";
import { Paginated } from "@/core/api/types";
import { ThreadService } from "@/services/thread";

export const useDeleteThread = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteThread"],
    mutationFn: async ({ threadId }: { threadId: string }) => {
      return ThreadService.deleteThread(threadId);
    },
    onMutate: async ({ threadId }: { threadId?: string }) => {
      await queryClient.invalidateQueries({ queryKey: THREADS_QUERY_KEY });
      const previousThreadsTuple = queryClient.getQueriesData({ queryKey: THREADS_QUERY_KEY });

      for (const [queryKey] of previousThreadsTuple) {
        queryClient.setQueryData(queryKey, (old: InfiniteData<Paginated<Thread>>) => {
          return {
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data.filter((r) => r.id !== threadId),
            })),
            pageParams: old.pageParams,
          };
        });
      }
      return { previousThreadsTuple };
    },
    onError: (_error, _newFavoriteSkill, context) => {
      context?.previousThreadsTuple.forEach(([queryKey, queryData]) => {
        queryClient.setQueryData(queryKey, queryData);
      });
    },
  });
};
