import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { GoBackButton } from "@/components/GoBackButton";
import { ActionId } from "@/contexts/MessagesContext";
import { Precedent, useSocket, WebsocketMessageType } from "@/contexts/WebSocketContext";
import { useApi } from "@/hooks/useApi";
import { TextInput } from "@/components/FormComponents";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";

import { Chip } from "@/components/Chip";
import { Loading } from "@/components/Loading";
import * as logger from "@/core/logger";
import { WebToast } from "@/taskpane/components/core/Toast";
import { Filters } from "./components/Filters";
import { FiltersState } from "./components/Filters/types";
import { PrecedentCard } from "@/pages/PrecedentSearch/components/PrecedentCard";
import { CustomSkillFormProps } from "@/taskpane/components/Chat/Chat";

export const PrecedentSearch = ({ skill, goBack, startSkillProcess }: CustomSkillFormProps) => {
  const { cancelExecution } = useApi();
  const [caseBreakdown, setCaseBreakdown] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [precedents, setPrecedents] = React.useState<Precedent[] | null>(null);
  const [requestId, setRequestId] = React.useState<string>();
  const [filters, setFilters] = useState<FiltersState>();

  const startNewSearch = () => {
    if (loading && requestId) {
      cancelExecution({ executionId: requestId });
    }

    setPrecedents(null);
    setRequestId(undefined);
    setLoading(false);
  };

  useSocket({
    onMessageReceived: ({ type, data }) => {
      if (
        type !== WebsocketMessageType.SKILLS ||
        data.skillId !== ActionId.SEARCH_PRECEDENT ||
        data.requestId !== requestId
      )
        return;

      if (data.success) {
        setPrecedents(data.payload.precedents);
      } else {
        WebToast.error("A busca falhou. Tente novamente.");
      }
      setLoading(false);
    },
  });

  const onSubmit = React.useCallback(() => {
    logger.info("Submitting jurisprudence search message");
    setLoading(true);

    try {
      const courtCodes = filters?.courts?.map((c) => c.code);

      if ("getPayload" in skill && skill.id === "search_precedent") {
        const payload = skill.getPayload({
          case_breakdown: caseBreakdown,
          court_code: courtCodes,
          min_date: filters?.minDate?.toJSDate(),
        });
        startSkillProcess(payload);
        setRequestId(payload.requestId);
        return;
      } else {
        throw new Error("Skill invalida");
      }
    } catch (e) {
      logger.error("Error sending jurisprudence search message", e);
      WebToast.error("A busca falhou");
      setLoading(false);
    }
  }, [caseBreakdown, filters, startSkillProcess]);

  if (loading) {
    return <LoadingWrapper onNewSearch={() => startNewSearch()} />;
  }

  if (requestId && precedents) {
    return <PrecedentsList precedents={precedents} onNewSearch={() => startNewSearch()} requestId={requestId} />;
  }

  return (
    <InternalPageStructure
      onSubmit={onSubmit}
      error={null}
      goBack={goBack}
      submitButtonText={"Buscar"}
      submitButtonDisabled={!caseBreakdown || loading}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box>
            <Typography variant="preTitle" color={"text.primary"}>
              Buscar jurisprudência
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box>
              <Typography variant="preTitle" color={"common.shade"} sx={{ textWrap: "wrap" }}>
                Selecionar opção para direcionamento da busca:
              </Typography>
            </Box>

            <Box>
              <InputOptions />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="preTitle" color={"common.shade"} sx={{ textWrap: "wrap" }}>
              Digite o resumo dos fatos ou palavras-chave relacionadas ao caso:
            </Typography>
          </Box>

          <TextInput
            onChange={(input) => {
              setCaseBreakdown(input || "");
            }}
            placeholder={`Ex: João está buscando regularizar as visitas ao seu filho, José. No entanto, a mãe da criança está proibindo as visitas e está praticando alienação parental contra João.\nPalavras-chave:\nAção de visitas, alienação parental.`}
          />
        </Box>

        <Filters onChange={setFilters} />
      </Box>
    </InternalPageStructure>
  );
};

function InputOptions() {
  {
    /* TODO: usar o componente de FlowInput ou algo semelhante */
  }

  return (
    <FormControl>
      <RadioGroup defaultValue="CONTENT" name="jurisprudence-search" value="TEXT" sx={{ pl: "10px", gap: 1 }}>
        <InputOption value="SELECT" text="Selecionar texto no documento aberto" disabled={true} />
        <InputOption value="UPLOAD" text="Extrair fatos através do upload de nova peça" disabled={true} />
        <InputOption value="TEXT" text="Inserir resumo dos fatos ou palavras-chave" />
      </RadioGroup>
    </FormControl>
  );
}

function InputOption({ value, text, disabled }: { value: string; text: string; disabled?: boolean }) {
  {
    /* TODO: use FlowInput component or something like it */
  }

  return (
    <FormControlLabel
      value={value}
      control={<Radio size="small" />}
      disabled={disabled}
      label={
        <Typography
          variant="body"
          color={disabled ? "common.mediumShade" : "common.shade"}
          sx={{ textWrap: "wrap", display: "flex" }}
        >
          {text}
          {disabled && (
            <Chip
              backgroundColor="common.mediumShade"
              color="common.white"
              sx={{ ml: 1, textWrap: "wrap", overflow: "visible", alignItems: "center" }}
            >
              EM BREVE
            </Chip>
          )}
        </Typography>
      }
      sx={{ display: "flex" }}
    />
  );
}

function LoadingWrapper({ onNewSearch }: { onNewSearch: () => void }) {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "48px",
          backgroundColor: "common.white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pt: 1,
          pl: 2,
          pr: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <GoBackButton onClick={onNewSearch}>Cancelar</GoBackButton>
      </Box>

      <Loading isLoading={true} />
    </Box>
  );
}

function PrecedentsList({
  precedents,
  onNewSearch,
  requestId,
}: {
  precedents: Precedent[];
  onNewSearch: () => void;
  requestId: string;
}) {
  const [search, setSearch] = React.useState<string>("");
  const [filteredPrecedents, setFilteredPrecedents] = React.useState<Precedent[]>(precedents);

  const onChangeSearch = (value: string) => {
    setSearch(value);

    if (!value) {
      setFilteredPrecedents(precedents);
    }

    setFilteredPrecedents(
      precedents.filter((p) => {
        return (
          p.title?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          p.headnote?.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
      })
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "48px",
          backgroundColor: "common.white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pt: 1,
          pl: 2,
          pr: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <GoBackButton onClick={onNewSearch}>Nova Busca</GoBackButton>
      </Box>

      <Box
        sx={{
          width: "100%",
          py: "16px",
          px: "16px",
          mb: "8px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            BUSCAR JURISPRUDÊNCIA
          </Typography>
        </Box>

        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Abaixo você pode visualizar os acórdãos mais próximos ao teor dos fatos enviados.
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            BUSCAR
          </Typography>
        </Box>

        <Box sx={{ mb: 2, width: "100%" }}>
          <TextField
            hiddenLabel
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderColor: "common.lightShade",
                backgroundColor: "common.dorian",
                padding: "0 8px",
                height: "38px",
                width: "100%",
              },
              "& .MuiInputBase-input": {
                padding: "0",
                width: "100%",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "common.lightShade",
                  width: "100%",
                },
                width: "100%",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: Boolean(search) ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => onChangeSearch("")} size="small" sx={{ color: "primary.dark" }}>
                    <CloseIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
            value={search}
            placeholder="Buscar..."
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     onChangeSearch('');
            //   }
            // }}
            // onBlur={() => {
            //   onChangeSearch('');
            // }}
          />
        </Box>

        {!filteredPrecedents.length && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="multiLineBody" color={"text.primary"}>
              Nenhum resultado encontrado.
            </Typography>
          </Box>
        )}

        {filteredPrecedents.map((precedent, idx) => {
          return (
            <Box key={idx} sx={{ mb: 1 }}>
              <PrecedentCard
                precedent={precedent}
                metadata={{
                  id: precedent.cnj_unique_number,
                  position: idx + 1,
                  requestId,
                }}
                key={idx}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
