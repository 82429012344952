import { Area } from "@/contexts/MessagesContext";
import { buildSkillStep } from "@/core/skillForms/builder";

export const createAppealSkillStep1 = buildSkillStep({
  title: "Area jurídica",
  description: "Defina a área de atuação para a qual esta peça jurídica é destinada:",
  fields: [
    {
      name: "area",
      type: "string",
      options: [
        { label: "Cível", value: Area.CIVIL },
        { label: "Trabalhista", value: Area.LABOR },
        { label: "Previdenciário", value: Area.SOCIAL_SECURITY },
        { label: "Tributário", value: Area.TAX },
        { label: "Criminal", value: Area.CRIMINAL },
        { label: "Outros", value: Area.OTHER },
      ],
      ui: { label: "Área jurídica", placeholder: "Selecione a área jurídica" },
      onboarding: {
        enabledOptions: [Area.CIVIL],
      },
    },
    {
      name: "custom_area",
      type: "string",
      overrideField: "area",
      ui: {
        label: "Digite a área jurídica:",
        placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
        widget: "textarea",
      },
      condition: { field: "area", value: Area.OTHER },
    },
  ],
});
