import { Skeleton } from "@mui/material";
import React from "react";

export const PlanChipSkeleton = () => (
  <Skeleton
    variant="rectangular"
    width="120px"
    height="22px"
    sx={{
      borderRadius: "20px",
      bgcolor: "common.lightShade",
    }}
  />
);
