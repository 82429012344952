import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

import WhatsappIcon from "@/assets/svgs/whatsapp-lexter.svg?react";
import { Card } from "../Card";
import { CaseChatInput } from "./components/CaseChatInput";
import { ChatMessages } from "./components/ChatMessages";
import { useParams } from "react-router-dom";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { TimeSince } from "./components/TimeSince";

export const CaseDetailsChat = ({ archived }: { archived: boolean }) => {
  const { caseId } = useParams<{ caseId: string }>();
  const { caseData, isLoading: isLoadingCase } = useLexZapCase({ caseId });

  const { data: messages, isLoading: isLoadingMessages } = useLexZapMessages({ chatId: caseData?.chatId });

  const isLoading = isLoadingCase || isLoadingMessages;

  return (
    <Card
      sx={{
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          padding: 3,
          borderBottomColor: "common.lightShade",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <WhatsappIcon height={32} style={{ margin: "8px" }} />
        <Box>
          <Typography variant="subtitle" fontWeight={600} fontSize="18px" color="text.primary" mb={0.5}>
            Canal de comunicação
          </Typography>
          {caseData && caseData.lastMessage ? (
            <Typography variant="body1" color="text.primary">
              Última mensagem há <TimeSince timestamp={caseData.lastMessage.timestamp} />
            </Typography>
          ) : (
            <Skeleton variant="text" width={200} />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          height: "450px",
        }}
      >
        <ChatMessages messages={messages} isLoading={isLoading} />
      </Box>

      <Box
        sx={{
          padding: 3,
        }}
      >
        <CaseChatInput chatId={caseData?.chatId} disabled={archived} />
      </Box>
    </Card>
  );
};
