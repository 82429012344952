import { Box, Skeleton } from "@mui/material";
import React from "react";

export const CreditsHistoryItemSkeleton = () => (
  <Box
    sx={{
      py: "16px",
      display: "grid",
      gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
      gap: "16px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "common.lightShade",
      "&:first-of-type": {
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: "common.lightShade",
      },
      ":hover": {
        backgroundColor: "common.dorian",
        borderColor: "common.dorian",
        cursor: "pointer",
        marginLeft: "-16px",
        marginRight: "-16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: "-1px",
        paddingTop: "17px",
      },
    }}
  >
    <Skeleton
      variant="circular"
      width="24px"
      height="24px"
      sx={{
        borderRadius: "50%",
      }}
    />

    <Box>
      <Skeleton variant="text" width="140px" height="20px" />

      <Skeleton variant="text" width="110px" height="20px" />
    </Box>

    <Skeleton variant="text" width="40px" height="20px" />
  </Box>
);
