import React, { useState } from "react";
import { applyPhoneMask } from "@/utils/applyInputMask";
import { Phone } from "@/core/Phone";
import { Result } from "@/core/Result";
import { TextField } from "@mui/material";

interface PhoneNumberFieldProps {
  onPhoneNumberChange: (phone: string) => void;
}

export const isPhoneNumberValid = (phone: string): Result<boolean> => {
  const isValid = Phone.new(phone);
  if (isValid.isFailure) return Result.fail("Telefone inválido");
  return Result.ok(true);
};

export const PhoneNumberField = ({ onPhoneNumberChange }: PhoneNumberFieldProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onPhoneBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const isValid = isPhoneNumberValid(e.target.value);
    if (isValid.isFailure) {
      setErrorMessage(isValid.getError());
    }
    onPhoneNumberChange(e.target.value);
  };

  const onPhoneChange = () => {
    setErrorMessage(null);
  };

  return (
    <TextField
      placeholder="Digite o telefone com DDD"
      InputProps={{
        onKeyUp: applyPhoneMask,
      }}
      sx={{
        mt: 3,
        fieldset: {
          borderColor: "common.lightShade",
        },
        label: { fontSize: "14.5px" },
      }}
      onBlur={onPhoneBlur}
      onChange={onPhoneChange}
      error={!!errorMessage}
      helperText={errorMessage}
      label="Telefone"
    />
  );
};
