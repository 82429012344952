import { useCallback, useEffect, useState } from "react";
import { useInterval } from "@/hooks/useInterval";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { isLastClientMessageOlderThan24Hours } from "./utils/isLastClientMessageOlderThan24Hours";

interface UseLexZapChatInfoProps {
  chatId?: string;
}
export const useLexZapChatInfo = ({ chatId }: UseLexZapChatInfoProps) => {
  const { data: messages } = useLexZapMessages({ chatId });

  const [shouldUseTemplateMessage, setShouldUseTemplateMessage] = useState(() =>
    isLastClientMessageOlderThan24Hours(messages)
  );

  const checkLastClientMessage = useCallback(() => {
    setShouldUseTemplateMessage(() => isLastClientMessageOlderThan24Hours(messages));
  }, [messages]);

  useInterval(checkLastClientMessage, 1000 * 60);

  useEffect(() => {
    checkLastClientMessage();
  }, [checkLastClientMessage]);

  return {
    shouldUseTemplateMessage,
  };
};
