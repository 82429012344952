import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";

import { SendRounded as SendRoundedIcon } from "@mui/icons-material";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { MessageTemplateCharacterCounter } from "@/components/LexZap/MessageTemplateCharacterCounter";

interface CaseChatInputProps {
  chatId?: string;
  disabled: boolean;
}

export const CaseChatInput = ({ chatId, disabled }: CaseChatInputProps) => {
  const { shouldUseTemplateMessage } = useLexZapChatInfo({ chatId });
  const [inputValue, setInputValue] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  const { mutate: sendMessage } = useSendLexZapMessage();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputValue.trim() && chatId) {
      sendMessage({
        chatId,
        message: {
          body: inputValue,
        },
      });
      setInputValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  return (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2.5,
          paddingRight: 1.5,
          borderRadius: "5px",
          border: "1px solid",
          borderColor: "common.lightShade",
          backgroundColor: disabled ? "#F4F3F3" : "common.white",
          "&:hover": {
            borderColor: "common.mediumShade",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {shouldUseTemplateMessage && (
            <Typography variant="multiLineBody" color="text.primary" mb={0.5}>
              Não nos falamos faz algum tempo. Segue continuação da nossa conversa:
            </Typography>
          )}

          <TextField
            sx={{
              width: "100%",
              "& label.Mui-focused": {
                color: "common.mediumShade",
              },
              "& .MuiInput-underline:after": {
                border: "none",
                borderBottomColor: "common.mediumShade",
              },
              "& .MuiOutlinedInput-root": {
                padding: 0,
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            autoComplete="off"
            placeholder="Digite sua mensagem aqui"
            multiline
            variant="outlined"
            size="small"
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "20px",
          }}
        >
          <IconButton type="submit">
            <SendRoundedIcon
              fontSize="medium"
              sx={{
                color: disabled ? "#BAB6B5" : "orange.light.90",
              }}
            />
          </IconButton>
        </Box>
      </Box>

      {shouldUseTemplateMessage && <MessageTemplateCharacterCounter inputLength={inputValue.length} />}
    </Box>
  );
};
