import { FavoriteSkill } from "@/hooks/skills/types";
import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const FAVORITE_SKILLS_QUERY_KEY = "favoriteSkills";

export const useFavoriteSkills = () => {
  const api = useApi();
  return useQuery({
    queryKey: [FAVORITE_SKILLS_QUERY_KEY],
    queryFn: () => api.getFavoriteSkills() as Promise<FavoriteSkill[]>,
  });
};
