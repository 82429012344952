import React, { useEffect, useState } from "react";

import { DocumentSearchModal } from "@/components/DocumentSearchModal";
import { ThreadRecord } from "@/hooks/records/types";
import { useApi } from "@/hooks/useApi";
import { useEditorUI } from "@/hooks/useEditorUI";
import { WebToast } from "@/taskpane/components/core/Toast";
import axios from "axios";

interface PrecedentContentProps {
  precedent: ThreadRecord;
}
export const PrecedentContent = ({ precedent }: PrecedentContentProps) => {
  const [loading, setLoading] = useState(true);
  const { getPrecedentPresignedUrl } = useApi();
  const { closeModal } = useEditorUI();
  const [precedentFullText, setPrecedentFullText] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { presignedUrl } = await getPrecedentPresignedUrl({ id: precedent.id });
        const response = await axios.get<string>(presignedUrl);
        setPrecedentFullText(response.data);
        setLoading(false);
      } catch {
        WebToast.error("Erro ao buscar acórdão");
        closeModal();
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DocumentSearchModal
      loading={loading}
      documentText={precedentFullText!}
      onClose={closeModal}
      title={precedent.name}
    />
  );
};
