import { ArrowBack } from "@mui/icons-material";
import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";

export const GoBackButton = ({ children, sx, ...rest }: ButtonProps) => {
  return (
    <Button
      startIcon={<ArrowBack />}
      size="small"
      sx={{
        color: "text.primary",
        alignSelf: "flex-start",
        ...sx,
      }}
      {...rest}
    >
      <Typography variant="button">{children}</Typography>
    </Button>
  );
};
