import { Box, Typography, useTheme } from "@mui/material";
import CollaborationSVG from "@/assets/svgs/collaboration.svg?react";
import React from "react";
import { HideMenusPageLayout } from "@/components/HideMenusPageLayout";
import { Button } from "@/components/Button";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { openUrl } from "@/utils/openUrl";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

export const CallWithSpecialistPage = () => {
  return (
    <HideMenusPageLayout>
      <Illustration />
      <ActionsBox />
    </HideMenusPageLayout>
  );
};

const Illustration = () => {
  const { breakpoints } = useTheme();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "left",
          px: 3,
          [breakpoints.up("md")]: {
            mb: 12,
          },
          [breakpoints.up("lg")]: {
            p: 0,
          },
        }}
      >
        <CollaborationSVG />
        <Typography variant="h4" fontWeight={700}>
          Quer acelerar ainda mais os ganhos do seu escritório?{" "}
        </Typography>
        <Typography variant="h5">
          Você foi selecionada(o) para uma demonstração exclusiva com um especialista Lexter
        </Typography>
      </Box>
    </Box>
  );
};

const ActionsBox = () => {
  const [showAssistantButton, setShowAssistantButton] = React.useState(false);
  const { insideSalesLinks } = useFeatureFlags();

  const navigate = useNavigate();

  const handleButton = (link: string) => {
    openUrl(link);
    setShowAssistantButton(true);
  };

  return (
    <Box bgcolor="grey.50" display="flex" alignItems="center" justifyContent="center" px={3}>
      <Box
        sx={{
          bgcolor: "common.white",
          minWidth: "450px",
          boxShadow: `8px 8px 25px 0px #0000000D, 
      33px 31px 46px 0px #0000000A,  
      75px 70px 61px 0px #00000008, 
      133px 124px 73px 0px #00000003, 
      208px 194px 80px 0px #00000000`,
          borderRadius: "16px",
          border: "1px solid #E0E0E0",
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
        }}
      >
        <Typography textAlign="start" variant="subtitle1" fontWeight={700}>
          Escolha como você deseja continuar:
        </Typography>
        <Button
          variant="contained"
          startIcon={LocalPhoneOutlinedIcon}
          onClick={() => handleButton(insideSalesLinks.speakWithSpecialistNow)}
        >
          Falar com especialista agora
        </Button>
        <Button variant="outlined" startIcon={WhatsAppIcon} onClick={() => handleButton(insideSalesLinks.whatsapp)}>
          Mandar mensagem no WhatsApp
        </Button>
        <Button startIcon={InsertInvitationIcon} onClick={() => handleButton(insideSalesLinks.schedule)}>
          Agendar para mais tarde
        </Button>
        {showAssistantButton && <Button onClick={() => navigate(ROUTE_PATHS.HOME)}>Ir para o Assistente Lexter</Button>}
      </Box>
    </Box>
  );
};
