import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCasesQueryKey } from "../useLexZapCases";
import { Case } from "../types";
import { DateTime } from "luxon";

export const useCreateCase = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["createCase"],
    mutationFn: async ({ name, applicantId }: { name: string; applicantId: string }) => {
      return await api.createCase({ name, applicantId });
    },
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCasesQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getCasesQueryKey());
      queryClient.setQueryData(getCasesQueryKey(), (old: Case[]) => [
        ...old,
        { ...data, timeline: [{ status: "MANUALLY_CREATED", createdAt: DateTime.now() }] },
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCasesQueryKey(), context);
    },
  });
};
