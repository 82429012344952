import { Box, IconButton, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Info as InfoIcon } from "@mui/icons-material";

interface MessageTemplateCharacterCounterProps {
  inputLength: number;
}

const limitLength = 1024;
export const MessageTemplateCharacterCounter = ({ inputLength }: MessageTemplateCharacterCounterProps) => {
  const totalLength = useMemo(() => 72 + inputLength, [inputLength]);
  const color = useMemo(() => {
    const percentage = (totalLength / limitLength) * 100;

    if (percentage < 50) return "success.main";
    if (percentage < 75) return "common.coralDark";
    return "error.dark";
  }, [totalLength]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: 600,
          color,
        }}
      >
        {totalLength}/{limitLength}
      </Typography>

      <Tooltip
        PopperProps={{
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: "common.white",
              maxWidth: 500,
              padding: 3,
              boxShadow: (theme) => theme.shadows[3],
            },
          },
        }}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Typography variant="h5" color="text.primary">
              Restrições da Meta foram aplicadas
            </Typography>
            <Typography variant="body1" color="common.shade" sx={{ fontSize: "16px" }}>
              Depois de 24h sem contato por parte do cliente, a janela de contato se fecha e só pode ser reaberta
              utilizando um modelo pré-aprovado pela Meta, dona do Facebook e Whatsapp.
              <br /> <br />A sua mensagem pode ser livremente escrita dentro desse modelo. Se ela passar de 1024
              caracteres será dividida em mais de um balão de mensagem, cada um com o modelo descrito aqui.
            </Typography>
          </Box>
        }
        placement="top-end"
      >
        <IconButton sx={{ m: -1 }}>
          <InfoIcon
            sx={{
              width: "18px",
              height: "18px",
              color,
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
