import React from "react";
import { Box, useTheme } from "@mui/material";

export const HideMenusPageLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1.25fr",
        "& > div": {
          textAlign: "center",
          border: "1px solid grey",
          borderColor: "grey.200",
        },
        width: "100vw",
        height: "100%",
        // Adicionar versionamento de temas
        "#svg-path-brand-color": {
          fill: "#FF7548",
        },
        [theme.breakpoints.up("xl")]: {
          gridTemplateColumns: "700px 1.25fr",
        },
      }}
    >
      {children}
    </Box>
  );
};
