import { alpha, Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { FormContextType, getTemplate, RJSFSchema, StrictRJSFSchema, WidgetProps } from "@rjsf/utils";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import { getDocxPageCount, getPdfPageCount } from "@/utils/getPageCount";
import { UPLOAD_FILE_PAGE_LIMIT, UPLOAD_FILE_SIZE_LIMIT } from "@/contexts/MessagesContext/constants";
import { UploadFile as UploadFileIcon } from "@mui/icons-material";
import { base64ToFile } from "@/utils/base64ToFile";
import { logger } from "@/core/logger";

const PDF = "application/pdf";
const DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const VALID_FILE_TYPES = [PDF, DOCX];

function addNameToDataURL(dataURL: string, name: string) {
  if (dataURL === null) {
    return null;
  }
  return dataURL.replace(";base64", `;name=${encodeURIComponent(name)};base64`);
}

type FileInfoType = {
  dataURL?: string | null;
  name: string;
  size: number;
  type: string;
};

function processFile(file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = (error) => {
      logger.error(`Erro ao ler o arquivo ${name}`, error);
      reject(error);
    };
    reader.onload = (event) => {
      if (typeof event.target?.result === "string") {
        const dataURL = addNameToDataURL(event.target.result, name);
        if (dataURL) {
          logger.debug(`Arquivo ${name} convertido para data-url: ${dataURL.slice(0, 50)}...`);
        }
        resolve({ dataURL, name, size, type });
      } else {
        logger.warn(`Leitura do arquivo ${name} falhou, dataURL é null.`);
        resolve({ dataURL: null, name, size, type });
      }
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files: File[]) {
  return Promise.all(files.map(processFile));
}

async function checkFileError(file: File): Promise<string | null> {
  try {
    if (!VALID_FILE_TYPES.includes(file.type)) {
      logger.warn(`Arquivo inválido: ${file.name}. Tipo ${file.type} não é permitido.`);
      return "Somente arquivos PDF e DOCX são válidos";
    }

    let pageCount: number | null = null;
    if (file.type === PDF) {
      pageCount = await getPdfPageCount(file);
    }
    if (file.type === DOCX) {
      pageCount = await getDocxPageCount(file);
    }
    if (pageCount !== null && pageCount > UPLOAD_FILE_PAGE_LIMIT) {
      logger.warn(`Arquivo ${file.name} excede o limite de páginas (${UPLOAD_FILE_PAGE_LIMIT}).`);
      return `O limite de páginas é de ${UPLOAD_FILE_PAGE_LIMIT} por arquivo.`;
    }

    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      logger.warn(`Arquivo ${file.name} excede o limite de tamanho (${UPLOAD_FILE_SIZE_LIMIT} bytes).`);
      return `O limite de tamanho é de ${UPLOAD_FILE_SIZE_LIMIT / (1000 * 1000)}MB por arquivo.`;
    }

    return null;
  } catch (e) {
    logger.error(`Erro ao processar o arquivo ${file.name}`, e);
    if (e instanceof Error && e.message.includes("is encrypted")) {
      return "Arquivo com senha";
    }
    return "Erro ao processar o arquivo.";
  }
}

const getFilesErrors = async ({ files, maxFiles }: { files: File[]; maxFiles?: number }) => {
  const exceedsMaxFileLimit = maxFiles ? files.length > maxFiles : false;
  if (exceedsMaxFileLimit) {
    return [`Você pode enviar no máximo ${maxFiles} arquivos`];
  }

  return (await Promise.all(files.map(checkFileError))).filter((error) => error !== null);
};

export const FileWidget = <
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(
  props: WidgetProps<T, S, F>
) => {
  const { palette } = useTheme();
  const { id, disabled, readonly, required, multiple, onChange, value, options, registry, maxFiles } = props;
  const BaseInputTemplate = getTemplate<"BaseInputTemplate", T, S, F>("BaseInputTemplate", registry, options);

  const [files, setFiles] = React.useState<File[]>();
  const [errors, setErrors] = React.useState<string[]>([]);

  useEffect(() => {
    if (value) {
      const file = base64ToFile(value);
      setFiles([file]);
    }
  }, [value]);

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    let droppedFiles: File[];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.items)
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile())
        .filter((item) => !!item) as File[];
    } else {
      // Use DataTransfer interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.files);
    }

    if (!multiple) {
      droppedFiles = droppedFiles.slice(0, 1);
    }

    const errors = await getFilesErrors({ files: droppedFiles, maxFiles });

    if (errors.length) {
      logger.warn(`Erros encontrados ao processar arquivos: ${errors.join(", ")}`);
      setErrors(errors);
    }

    setFiles(droppedFiles);
  };

  const handleAttachment = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const filesList = event.target.files || [];
      const attachedFiles = filesList.length > 0 ? Array.from(filesList) : [];

      const errors = await getFilesErrors({ files: attachedFiles, maxFiles });

      if (errors.length) {
        logger.warn(`Erros encontrados ao processar arquivos: ${errors.join(", ")}`);
        setErrors(errors);
      }

      setFiles(attachedFiles);
    },
    [maxFiles]
  );

  const removeFile = React.useCallback(
    (index: number) => {
      setFiles((prev = []) => {
        const newFiles = [...prev];
        newFiles.splice(index, 1);
        return newFiles;
      });
      setErrors((prev = []) => {
        const newErrors = [...prev];
        newErrors.splice(index, 1);
        return newErrors;
      });
      onChange(undefined);
    },
    [onChange]
  );

  useEffect(() => {
    if (files?.length) {
      if (errors.length) {
        logger.warn(`Erro ao atualizar campo ${id}: ${errors.join(", ")}`);
        onChange(undefined, { __errors: errors }, id);
      } else {
        processFiles(files).then((filesInfoEvent) => {
          const newValue = filesInfoEvent.map((fileInfo) => fileInfo.dataURL);
          onChange(newValue[0]);
        });
      }
    }
  }, [files, onChange, errors, id]);

  if (files?.length) {
    return (
      <>
        {files.map((file, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: "background.paper",
              width: "100%",
            }}
          >
            <Box
              sx={{
                minHeight: 64,
                border: "1px dashed",
                borderColor: props.rawErrors && props.rawErrors?.length > 0 ? "error.main" : "#0000001F",
                borderRadius: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
                py: 2,
                px: 3,
              }}
            >
              <Typography variant="body">{file.name}</Typography>
              <IconButton
                aria-label="Remover arquivo"
                size="medium"
                onClick={() => removeFile(index)}
                sx={{ color: "common.shade" }}
              >
                <DeleteOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  }

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
      }}
    >
      <Button
        aria-label="Anexar arquivo"
        component="label"
        sx={{
          minHeight: 150,
          width: "100%",
          borderRadius: 4,
          px: 3,
          border: "1px dashed",
          borderColor: props.rawErrors && props.rawErrors?.length > 0 ? "error.main" : "#0000001F",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <UploadFileIcon sx={{ color: "primary.main" }} />
        <Box
          color={"text.primary"}
          sx={{
            textAlign: "center",
            fontSize: "14px",
            textTransform: "none",
            letterSpacing: "0.03em",
            lineHeight: "140%",
          }}
        >
          <Typography variant="subtitle1">
            <Typography
              variant="subtitle1"
              color={"text.primary"}
              sx={{
                color: "primary.main",
                textDecoration: "underline",
                display: "inline",
                textUnderlineOffset: "4px",
                textDecorationColor: alpha(palette.primary.main, 0.4),
              }}
            >
              Clique para carregar o arquivo
            </Typography>{" "}
            ou arraste e solte aqui
          </Typography>
          <Typography variant="multiLineBody" sx={{ color: "text.secondary", display: "block", mt: 1 }}>
            Apenas .docx ou .pdf (o limite de pág. é {UPLOAD_FILE_PAGE_LIMIT} ou no máx.{" "}
            {(UPLOAD_FILE_SIZE_LIMIT / (1000 * 1000)).toFixed(0)}
            MB)
          </Typography>
        </Box>
        <BaseInputTemplate
          {...props}
          id={id}
          disabled={disabled || readonly}
          type="file"
          required={value ? false : required} // this turns off HTML required validation when a value exists
          onChangeOverride={handleAttachment}
          value=""
          hidden
          accept={VALID_FILE_TYPES.join(",")}
        />
      </Button>
    </Box>
  );
};
