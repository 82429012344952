/**
 * @desc ValueObjects are objects that we determine their
 * equality through their structural property.
 *
 * Since value objects are defined by their value we should never have an operation
 * that change this value, instead we need to return a new object
 */

export abstract class ValueObject<T extends object> {
  public props: T;

  constructor(props: T) {
    const baseProps: T = {
      ...props,
    };

    this.props = baseProps;
  }

  public equals(vo?: ValueObject<T>): boolean {
    if (vo === undefined) {
      return false;
    }
    return JSON.stringify(this.props) === JSON.stringify(vo.props);
  }
}
