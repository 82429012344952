import { PageLayout } from "@/components/PageLayout";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useState } from "react";
import { Add, Edit, DeleteForever } from "@mui/icons-material";

import { useLexZapUsers } from "@/hooks/lexZap/admin/useLexZapUsers";
import { DateTime } from "luxon";
import { Box, Button } from "@mui/material";
import { EditUserModal } from "./components/EditUserModal";
import { LexZapUser } from "@/hooks/lexZap/types";
import { CreateUserModal } from "./components/CreateUserModal";
import { useAlertModal } from "@/hooks/useAlertModal";
import { useDeleteLexZapUser } from "@/hooks/lexZap/admin/useDeleteLexZapUser";
import { WebToast } from "@/taskpane/components/core/Toast";

export const ManageWhatsappUsers = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const {
    data: paginatedRecords,
    isLoading,
    isFetching,
  } = useLexZapUsers({
    pagination: { ...paginationModel, page: paginationModel.page + 1 },
  });

  const [userToEdit, setUserToEdit] = useState<LexZapUser>();
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { openAlertModal } = useAlertModal();
  const { mutateAsync: deleteUser } = useDeleteLexZapUser();

  const handleDeleteUser = (userId: string) => {
    openAlertModal({
      title: "Tem certeza que deseja deletar este usuário?",
      description: "Ao deletar este usuário, todas as conversas e mensagens associadas a ele serão perdidas.",
      primaryButton: {
        label: "Continuar",
        onClick: async () => {
          try {
            await deleteUser(userId);
            WebToast.success("Usuário deletado com sucesso");
          } catch {
            WebToast.error("Erro ao deletar usuário");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 4,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {}
          <Button variant="contained" startIcon={<Add />} onClick={() => setCreateModalIsOpen(true)}>
            Adicionar Usuário
          </Button>
        </Box>

        <DataGrid
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          getRowId={(row) => row.userId}
          loading={isLoading || isFetching}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={[
            {
              field: "userId",
              headerName: "User Id",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "name",
              headerName: "Nome",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "phoneNumber",
              headerName: "Número do whatsapp",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "notificationNumber",
              headerName: "Número para notificações",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "isActive",
              headerName: "Status",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ row: lexZapuser }) => (lexZapuser.isActive ? "Ativo" : "Pendente"),
            },
            {
              field: "createdAt",
              headerName: "Data de criação",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ row: lexZapuser }) =>
                DateTime.fromMillis(lexZapuser.createdAt).toFormat("dd/MM/yyyy, HH:mm"),
            },
            {
              field: "actions",
              type: "actions",
              headerName: "Ações",
              flex: 1,
              getActions: ({ row: lexZapuser }) => [
                <GridActionsCellItem
                  key={lexZapuser.userId}
                  icon={<Edit />}
                  label="Editar"
                  onClick={() => setUserToEdit(lexZapuser)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={lexZapuser.userId}
                  icon={<DeleteForever />}
                  label="Deletar"
                  onClick={() => handleDeleteUser(lexZapuser.userId)}
                  showInMenu
                />,
              ],
            },
          ]}
          rows={paginatedRecords?.data || []}
          rowSelection={false}
          rowCount={paginatedRecords?.totalResults || 0}
          hideFooterPagination={isLoading}
        />

        <CreateUserModal isOpen={createModalIsOpen} onClose={() => setCreateModalIsOpen(false)} />
        <EditUserModal isOpen={!!userToEdit} onClose={() => setUserToEdit(undefined)} user={userToEdit} />
      </Box>
    </PageLayout>
  );
};
