export enum PlanType {
  TRIAL = "TRIAL",
  FREEMIUM = "FREEMIUM",
  INITIAL = "INITIAL",
  BASIC = "BASIC",
  INTERMEDIARY = "INTERMEDIARY",
  ADVANCED = "ADVANCED",
  PROFESSIONAL = "PROFESSIONAL",
  CUSTOM = "CUSTOM",
  UNLIMITED = "UNLIMITED",
  PROFESSIONAL_WITH_WPP = "PROFESSIONAL_WITH_WPP",
  PERFORMANCE = "PERFORMANCE",
}
