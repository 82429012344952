import { buildSkillStep, createEditorContentField, createSelectTextField } from "@/core/skillForms/builder";

export const editDocumentSkillStep1 = buildSkillStep({
  title: "Editar texto com inteligência artificial",
  description: "Editar texto com inteligência artificial",
  fields: [
    createSelectTextField({
      name: "to_edit_text",
      required: true,
      ui: {
        label: "Selecione o trecho a ser editado",
      },
    }),
    {
      name: "user_instructions",
      type: "string",
      ui: {
        label: "Digite as instruções desejadas para a edição do trecho:",
        placeholder: "Solicite alterações, como inserir novas informações ou reescrever o trecho selecionado.",
        widget: "textarea",
      },
      required: true,
    },
    createEditorContentField({
      name: "document_text",
      hidden: true,
    }),
  ],
  submitButtonText: "Editar texto",
});
