export const HeapService = {
  addUserProperties(properties: Record<string, string | number | undefined>) {
    if (window.heap) {
      window.heap.addUserProperties(properties);
    }
  },
  clearEventProperties() {
    if (window.heap) {
      window.heap.clearEventProperties();
    }
  },
  addEventProperties(properties: Record<string, unknown>) {
    if (window.heap) {
      window.heap.addEventProperties(properties);
    }
  },
  identify(userId: string) {
    if (window.heap) {
      window.heap.identify(userId);
    }
  },
  track(eventName: string, properties: Record<string, string | number>) {
    if (window.heap) {
      window.heap.track(eventName, properties);
    }
  },
};
