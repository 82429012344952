import { buildSkillStep } from "@/core/skillForms/builder";
import { Area, DecisionType, LegalPieceMacroType } from "@/contexts/MessagesContext";

export const createOtherTypeOfPieceSkillStep1 = buildSkillStep<string, Area | LegalPieceMacroType | DecisionType>({
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Nome da peça",
        placeholder: "Digite o nome da peça",
      },
    },
    {
      name: "legal_piece_macro_type",
      type: "string",
      options: [
        {
          label: "Inicial",
          value: LegalPieceMacroType.INAUGURAL_DOCUMENT,
          description:
            "Para elaboração de diferentes tipos de peças que dão início a um processo (ex. petições, ações de divórcio, queixa-crime, habeas corpus e mandados de segurança).",
        },
        {
          label: "Resposta",
          value: LegalPieceMacroType.RESPONSE,
          description:
            "Para responder uma peça existente no processo (ex. contestação, réplica, impugnações e contrarrazões.)",
        },
        // {
        //   label: "Intercorrente",
        //   value: LegalPieceMacroType.INTERCURRENT,
        //   description:
        //     "Para tratar questões ou fatos que surgiram após o início do processo (ex. impugnação a laudos, manifestações sobre laudos e outros documentos, protocolo de documentos).",
        // },
        {
          label: "Recursal",
          value: LegalPieceMacroType.APPEAL,
          description:
            "Para recorrer de uma decisão judicial (ex. apelação, recurso especial, agravo de instrumento e embargos de declaração).",
        },
      ],
      initialValue: LegalPieceMacroType.INAUGURAL_DOCUMENT,
      ui: {
        label: "Momento processual?",
        placeholder: "Selecione o momento processual",
      },
    },
    {
      name: "area",
      type: "string",
      options: [
        { label: "Cível", value: Area.CIVIL },
        { label: "Trabalhista", value: Area.LABOR },
        { label: "Previdenciário", value: Area.SOCIAL_SECURITY },
        { label: "Tributário", value: Area.TAX },
        { label: "Criminal", value: Area.CRIMINAL },
        {
          label: "Outros",
          value: Area.OTHER,
        },
      ],
      ui: {
        label: "Área jurídica",
        placeholder: "Selecione a área jurídica",
      },
    },
    {
      name: "custom_area",
      type: "string",
      overrideField: "area",
      ui: {
        label: "Digite a área jurídica:",
        placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
      },
      condition: {
        field: "area",
        value: Area.OTHER,
      },
    },
  ],
  title: "Área jurídica",
});
