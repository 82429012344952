import React from "react";
import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from "@mui/lab";
import { CaseTimeline as CaseTimelineType } from "@/hooks/lexZap/types";
import { formatDateToISO } from "@/utils/formatDateToISO";

const statusName = {
  SUMMARY_GENERATED: "Resumo gerado",
  CASE_STARTED: "Primeiro contato pelo cliente",
  AWAITING_APPLICANT_APPROVAL: "Aguardando aprovação do cliente",
  CASE_FAILED: "Humano acionado",
  LAWYER_INVOKED_BY_APPLICANT: "Humano acionado",
  MANUALLY_CREATED: "Caso criado",
};

export const CaseTimeline = ({ timeline }: { timeline: CaseTimelineType[] }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "194px",
        gap: 2,
        width: "100%",
        alignItems: "end",
      }}
    >
      <Timeline
        sx={{
          alignItems: "start",
          width: "100%",
          maxHeight: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px",
          "&::-webkit-scrollbar": { width: "0px" },
        }}
      >
        {timeline
          .sort((a, b) => {
            return (
              DateTime.fromISO(formatDateToISO(b.createdAt)).toMillis() -
              DateTime.fromISO(formatDateToISO(a.createdAt)).toMillis()
            );
          })
          .map((item, index) => (
            <TimelineItem
              key={item.status}
              sx={{
                "::before": {
                  padding: "0 1px",
                },
              }}
            >
              <TimelineSeparator sx={{ position: "relative" }}>
                <TimelineDot />
                <TimelineConnector
                  sx={{
                    width: "4px",
                    backgroundColor: "#ECF1F4",
                    borderTopLeftRadius: index === 0 ? "16px" : 0,
                    borderTopRightRadius: index === 0 ? "16px" : 0,
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Typography variant="body" sx={{ fontSize: "10px", fontWeight: 700, color: "common.shade" }}>
                    {DateTime.fromISO(item.createdAt).toFormat("dd/MM/yyyy")}
                  </Typography>
                  <Typography variant="body" sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {statusName[item.status]}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </Box>
  );
};

const TimelineDot = () => {
  return (
    <span>
      <Box
        sx={{
          paddingTop: "22px",
          position: "absolute",
          left: "-2px",
        }}
      >
        <Box
          sx={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: "#FF7548",
          }}
        />
      </Box>
    </span>
  );
};
