import { Box } from "@mui/material";
import BackgroundUrl from "@/assets/svgs/login-background.svg?react";
import React from "react";

interface BackgroundPageProps {
  children?: React.ReactNode;
  bgColor?: string;
}

export const BackgroundPage = ({ children, bgColor }: BackgroundPageProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: bgColor,
        position: "relative",
      }}
    >
      {bgColor === "common.black" && <Background />}
      <Content>{children}</Content>
    </Box>
  );
};

const Background = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${BackgroundUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    }}
  />
);

const Content = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      py: 4,
      px: 3,
    }}
  >
    {children}
  </Box>
);
