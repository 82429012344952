import { PaginationPayload } from "@/core/api/types";
import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const lexZapUsersQueryKey = ["lexZapUsers"];

export const useLexZapUsers = ({ pagination }: { pagination: PaginationPayload }) => {
  const { getLexZapUsers } = useApi();

  return useQuery({
    queryKey: [...lexZapUsersQueryKey, { pagination }],
    queryFn: async () => getLexZapUsers({ pagination }),
  });
};
